import * as Event from './types/EventTypes';

let eventID = 0;

/**
 * イベント登録。削除と一対一に対応しないとメモリリークする
 * @param event イベント名
 * @param listener イベント
 */
export const registerEvent = (event: string, listener: () => void): Event.RegisterEventAction => ({
    type: Event.registerEvent,
    event,
    id: eventID++,
    listener
})

export const removeEvent = (event: string, id: number) => ({
    type: Event.registerEvent,
    remove: true,
    event,
    id
})