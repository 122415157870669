import { HttpRequest } from "actions/utils/HttpRequest"
import { AppConfig } from "app-env"

export interface IReceiveDocumentRegister {
    code: string;
    name: string;
    stationNumber: string;
    stationName: string;
    copies: number;
    received: boolean;
}

export const searchReceivePackageAsync = async (slipNumber: string): Promise<IReceiveDocumentRegister[] | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("receiveRegister/search", {slipNumber});

    if (!response) {
        console.log("receiveRegister/search not responsed");
        return null;
    }
    if (!response.ok) {
        console.log(`error response code = ${response.status}`);
        return null;
    }

    return await response.json();
}

export const registerReceiveAsync = async (slipNumber: string, documents: IReceiveDocumentRegister[]): Promise<boolean>  => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("receiveRegister/register", {slipNumber, documents});

    return response ? response.ok : false;
}