import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as QueryString from 'query-string';
import { IDetailItemData, getProcessStatusAsync, resolveAcceptedNumber, StoringStatus } from 'actions/RO/RODetailAction';
import { getItemDetailAsync, IItemData, getPictureAsync, uploadItemPicture, deleteItemPicture, updateItemStoringStatus, IUpdateItemStoringStatusResult, updateItemStoringStatusMemo, resolveItemNumber, updateSerialNumber, updateStoragedLocation, StoringStatusUpdateOperation, updatePendingState } from 'actions/RO/ROItemDetailAction';
import { Spinner } from 'reactstrap';
import { Grid, makeStyles, createStyles, Theme, Dialog, DialogContent, IconButton, LinearProgress, Button, Card, CardHeader, CardContent, TextField } from '@material-ui/core';
import { BackButton } from 'components/BackButton';
import { FileSelectWithThumbnail } from 'components/FileSelectWithThumbnail';
import CloseIcon from '@material-ui/icons/Close';
import { SwitchableTextbox } from 'components/SwitchableTextbox';
import { Store } from 'store/GlobalState';
import { roDetailPath } from './RODetail';
import { routePush } from 'actions/utils/History';
import { cacheFlagsClear } from 'actions/types/CacheFlagsActionType';
import { toBase64, toDataUrl } from 'actions/utils/File';

export const roItemDetailPath = '/ro/detail/item';

const textAlignmentStyles = makeStyles((theme: Theme) => 
    createStyles({
        body: {
            flexWrap: "nowrap",
            [theme.breakpoints.down('sm')]: {
                flexWrap: "wrap"
            }
        },
        grid: {
            width: "100%"
        },
        headerBorder: {
            [theme.breakpoints.up("sm")]: {
                borderBottom: "medium solid #C0C0C0"
            }
        },
        contentBorder: {
            borderBottom: "medium solid #C0C0C0",
            whiteSpace: "pre-wrap"
        }
    })
);
const oneLineGridStyles = makeStyles((theme: Theme) => 
    createStyles({
        mobileSpacing: {
            [theme.breakpoints.down('xs')]: {
                marginLeft: "3em"
            }
        }
    })
)


const storingStatusStringMap = new Map<number, string>([
    [StoringStatus.NoCheck, "未受領"],
    [StoringStatus.Receipted, "受領済"],
    [StoringStatus.Stored, "入庫済"],
    [StoringStatus.Shipped, "出庫済"]
]);
const processStatusStringMap = new Map<number, string>([
    [0, "受付済"],
    [1, "確認済"],
    [2, "DL済"],
    [3, "完了"]
]);

interface IPageUrlQueryParams
{
    accepted: string;
    item: number;
    branch: number;
}
const parseAcceptedNumber = async (parser: QueryString.ParsedQuery<string>): Promise<string | undefined> => {
    const {accepted, id} = parser;

    let targetAcceptedNumber: string | undefined = undefined;
    if (typeof accepted === "string") {
        targetAcceptedNumber = accepted;
    }
    else if (typeof id === "string") {
        const targetId = parseInt(id, 10);
        if (!isNaN(targetId)) {
            const result = await resolveAcceptedNumber(targetId);
            if (result) {
                targetAcceptedNumber = result;
            }
        }
    }

    return targetAcceptedNumber;
}
const parseItemNumber = async (parser: QueryString.ParsedQuery<string>): Promise<{item: number, branch: number, accepted: string | undefined} | undefined> => {
    const {item, branch, iid} = parser;

    let referedAcceptedNumber: string | undefined = undefined;
    let targetItemNumber: number | undefined = undefined;
    let targetItemBranch: number | undefined = undefined;
    if (typeof item === "string" && typeof branch === "string") {
        const itemNumber = parseInt(item, 10);
        if (!isNaN(itemNumber)) {
            targetItemNumber = itemNumber;
        }

        const itemBranch = parseInt(branch, 10);
        if (!isNaN(itemBranch)) {
            targetItemBranch = itemBranch;
        }
    }
    else if (typeof iid === "string") {
        const targetItemId = parseInt(iid, 10);
        if (!isNaN(targetItemId)) {
            const result = await resolveItemNumber(targetItemId);
            if (result) {
                targetItemNumber = result.item;
                targetItemBranch = result.branch;
                referedAcceptedNumber = result.acceptedNumber;
            }
        }
    }
    if (targetItemNumber === undefined || targetItemBranch === undefined) {
        return undefined;
    }

    return {
        item: targetItemNumber,
        branch: targetItemBranch,
        accepted: referedAcceptedNumber
    };
}
const parseQuery = async (parser: QueryString.ParsedQuery<string>): Promise<IPageUrlQueryParams | undefined> => {
    
    const acceptedNumberResolver = parseAcceptedNumber(parser);
    const itemNumberResolver = parseItemNumber(parser);

    const accepted = await acceptedNumberResolver;
    if (!accepted) {
        return undefined;
    }
    
    const itemNumberData = await itemNumberResolver;
    if (!itemNumberData) {
        return undefined;
    }

    if (itemNumberData.accepted && (itemNumberData.accepted !== accepted)) {
        alert("不正なパラメータです。受付番号と部材の指定が一致しません。");
        return undefined;
    }

    return {
        accepted,
        item: itemNumberData.item,
        branch: itemNumberData.branch
    };
}

interface IPendingState {
    isPending: boolean;
    pendingComment: string;
}

export const ROItemDetail = (props: RouteComponentProps) => {
    const [itemData, setItemData] = React.useState<IItemData | null>(null);
    const [processStatus, setProcessStatus] = React.useState<number | null>(null);
    const [parameter, setParameter] = React.useState<IPageUrlQueryParams | null>(null);
    const [serialNumber1, setSerialNumber1] = React.useState("");
    const [serialNumber2, setSerialNumber2] = React.useState("");
    const [location1, setLocation1] = React.useState("");
    const [location2, setLocation2] = React.useState("");
    const [location3, setLocation3] = React.useState("");
    const [storingStatus, setStoringStatus] = React.useState(0);
    const [isPending, setIsPending] = React.useState<boolean | null>(null);
    const [pendingComment, setPendingComment] = React.useState<string | null>(null);

    const detailDataStyles = oneLineGridStyles();
    const commonDataAlignmentStyles = textAlignmentStyles();

    React.useEffect(() => {
        let isUnmounted = false;
        const asyncFunc = async () => {
            const param = await parseQuery(QueryString.parse(props.location.search));
            if (!param) {
                return;
            }

            const itemDetailGettingTask = getItemDetailAsync(param.accepted, param.item, param.branch);
            const processStatusGettingTask = getProcessStatusAsync(param.accepted);

            const itemResponse = await itemDetailGettingTask;
            const statusResponse = await processStatusGettingTask;
            if (itemResponse && statusResponse !== null && !isUnmounted) {
                setItemData(itemResponse);
                setProcessStatus(statusResponse);
                setParameter(param);
                setSerialNumber1(itemResponse.serialNumber1);
                setSerialNumber2(itemResponse.serialNumber2);
                setLocation1(itemResponse.storagedLoation1);
                setLocation2(itemResponse.storagedLoation2);
                setLocation3(itemResponse.storagedLoation3);
                setStoringStatus(itemResponse.storingStatus);
                setIsPending(itemResponse.isPending ?? false);
                setPendingComment(itemResponse.pendingComment ?? "");
            }
        }

        asyncFunc();

        return () => {isUnmounted = true;};
    }, []);

    if (!itemData || processStatus === null || !parameter) {
        return <Spinner />;
    }

    const {accepted, item, branch} = parameter;

    const updateSerial = async (target: number, serial: string) => {
        let result = true;  // updateが実施されなければ無条件にtrue
        let updater: ((value: React.SetStateAction<string>) => void) | null = null;
        switch (target) {
            case 1:
                if (serialNumber1 !== serial) {
                    updater = setSerialNumber1;
                }
                break;
            
            case 2:
                if (serialNumber2 !== serial) {
                    updater = setSerialNumber2;
                }
                break;

            default:
                result = false;
                break;
        }
        if (updater) {
            result = await updateSerialNumber(accepted, item, branch, target, serial);
            if (result) {
                updater(serial);
            }
        }

        return result;
    }
    const updateLocation = async (target: number, location: string) => {
        let result = true;  // updateが実施されなければ無条件にtrue
        let updater: ((value: React.SetStateAction<string>) => void) | null = null;
        switch (target) {
            case 1:
                if (location1 !== location) {
                    updater = setLocation1;
                }
                break;
            
            case 2:
                if (location2 !== location) {
                    updater = setLocation2;
                }
                break;

            case 3:
                if (location3 !== location) {
                    updater = setLocation3;
                }
                break;
            default:
                result = false;
                break;
        }
        if (updater) {
            result = await updateStoragedLocation(accepted, item, branch, target, location);
            if (result) {
                updater(location);
            }
        }

        return result;
    }
    const onStoringStatusChange = (status: number) => {
        Store.dispatch({type: cacheFlagsClear});
        setStoringStatus(status);
    }

    const roles = Store.getState().loginPage.roles;
    const isWarehouseUser = (roles && (roles.indexOf("ROUser_Warehouse") > -1 || roles.indexOf("ROUser_Admin") > -1)) ?? false;
    const onBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Store.dispatch(routePush(`${roDetailPath}/?accepted=${accepted}`));

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                onClick={onBackButtonClick}
            >
                部材一覧
            </Button>
            <fieldset className={`border border-primary mr-3 mb-2 ${commonDataAlignmentStyles.grid}`}>
                <legend className="w-auto h6">局情報</legend>
                <div className="m-2">
                    <Grid container spacing={2}>
                        <Grid  item xs={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>受付番号(版)</Grid>
                                <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{`${itemData.commonData.acceptedNumber}(${itemData.commonData.loadedVersion})`}</Grid>
                                <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>局番</Grid>
                                <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{itemData.commonData.stationNumber}</Grid>
                                <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>局名</Grid>
                                <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{itemData.commonData.stationName}</Grid>
                                <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>エリア</Grid>
                                <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{itemData.commonData.area}</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>元請会社</Grid>
                                <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{itemData.commonData.primaryContractor}</Grid>
                                <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>案件小G</Grid>
                                <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{`${itemData.commonData.projectDetailGroupCode} : ${itemData.commonData.projectDetailGroupName}`}</Grid>
                                <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>提出日</Grid>
                                <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{itemData.commonData.submitDateString}</Grid>
                                <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>状態</Grid>
                                <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{processStatusStringMap.get(processStatus)}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </fieldset>
            <div className="w-100 mt-2 mb-2" style={{fontSize: "1rem"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">QRコード</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.qrCode}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">撤去設備番号</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.facilityNumber}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">設備カテゴリ</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.facilityCategory}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">撤去設備名</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.facilityName}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">撤去区分</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.removalType}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">受付数量</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.acceptedCount}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">撤去設備番号_枝番</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.acceptedCountBranch}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">単位</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.unit}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">重量</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.weight}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">種別</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.type}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">大型物品</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.isBig}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">処理区分_廃棄_残置</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.disposalTreatment}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">廃棄チェック</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.disposalCheck}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">送付指示_処理区分</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.treatment}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">再利用区分</span></Grid>
                            <Grid item xs={12} sm={8}><span className={detailDataStyles.mobileSpacing}>{itemData.recycleType}</span></Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">製造番号1</span></Grid>
                            <Grid item xs={12} sm={8}>
                                <SwitchableTextbox
                                    placeholder="製造番号1"
                                    value={serialNumber1}
                                    onSave={(current) => updateSerial(1, current)}
                                    tooltip
                                    disabled={isPending ?? false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">製造番号2</span></Grid>
                            <Grid item xs={12} sm={8}>
                                <SwitchableTextbox
                                    placeholder="製造番号2"
                                    value={serialNumber2}
                                    onSave={(current) => updateSerial(2, current)}
                                    tooltip
                                    disabled={isPending ?? false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">保管場所1</span></Grid>
                            <Grid item xs={12} sm={8}>
                                <SwitchableTextbox
                                    placeholder="保管場所1"
                                    value={location1}
                                    onSave={(current) => updateLocation(1, current)}
                                    disabled={processStatus < 2 || !isWarehouseUser || (isPending ?? false)}
                                    tooltip
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">保管場所2</span></Grid>
                            <Grid item xs={12} sm={8}>
                                <SwitchableTextbox
                                    placeholder="保管場所2"
                                    value={location2}
                                    onSave={(current) => updateLocation(2, current)}
                                    disabled={processStatus < 2 || !isWarehouseUser || (isPending ?? false)}
                                    tooltip
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}><span className="font-weight-bold">保管場所3</span></Grid>
                            <Grid item xs={12} sm={8}>
                                <SwitchableTextbox
                                    placeholder="保管場所3"
                                    value={location3}
                                    onSave={(current) => updateLocation(3, current)}
                                    disabled={processStatus < 2 || !isWarehouseUser || (isPending ?? false)}
                                    tooltip
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ItemStoringStatusPanel
                            acceptedNumber={accepted}
                            itemNumber={item}
                            itemBranch={branch}
                            processStatus={processStatus}
                            initData={itemData}
                            isWarehouseUser={isWarehouseUser}
                            storingStatus={storingStatus}
                            isPending={isPending}
                            pendingComment={pendingComment}
                            onStoringStatusChange={onStoringStatusChange}
                            onPendingChange={setIsPending}
                            onPendingCommentChange={setPendingComment}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className="w-100 mt-2">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <PictureForm
                            pictureNumber={1}
                            title="IMG_01"
                            pictureId={itemData.fileID1}
                            acceptedNumber={itemData.commonData.acceptedNumber}
                            itemNumber={itemData.itemNumber}
                            itemBranch={itemData.acceptedCountBranch}
                            disabled={!isWarehouseUser || processStatus < 2 || storingStatus !== StoringStatus.Stored || (isPending ?? false)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PictureForm
                            pictureNumber={2}
                            title="IMG_02"
                            pictureId={itemData.fileID2}
                            acceptedNumber={itemData.commonData.acceptedNumber}
                            itemNumber={itemData.itemNumber}
                            itemBranch={itemData.acceptedCountBranch}
                            disabled={!isWarehouseUser || processStatus < 2 || storingStatus !== StoringStatus.Stored || (isPending ?? false)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PictureForm
                            pictureNumber={3}
                            title="IMG_03"
                            pictureId={itemData.fileID3}
                            acceptedNumber={itemData.commonData.acceptedNumber}
                            itemNumber={itemData.itemNumber}
                            itemBranch={itemData.acceptedCountBranch}
                            disabled={!isWarehouseUser || processStatus < 2 || storingStatus !== StoringStatus.Stored || (isPending ?? false)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PictureForm
                            pictureNumber={4}
                            title="IMG_04"
                            pictureId={itemData.fileID4}
                            acceptedNumber={itemData.commonData.acceptedNumber}
                            itemNumber={itemData.itemNumber}
                            itemBranch={itemData.acceptedCountBranch}
                            disabled={!isWarehouseUser || processStatus < 2 || storingStatus !== StoringStatus.Stored || (isPending ?? false)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PictureForm
                            pictureNumber={5}
                            title="IMG_05"
                            pictureId={itemData.fileID5}
                            acceptedNumber={itemData.commonData.acceptedNumber}
                            itemNumber={itemData.itemNumber}
                            itemBranch={itemData.acceptedCountBranch}
                            disabled={!isWarehouseUser || processStatus < 2 || storingStatus !== StoringStatus.Stored || (isPending ?? false)}
                        />
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}

interface IPendingStateSwitchButtonProps {
    acceptedNumber: string;
    itemNumber: number;
    itemBranch: number;
    isPending: boolean | null;
    comment: string | null;
    onPendingChange: (pending: boolean) => void;
}
const PendingStateSwitchButton = (props: IPendingStateSwitchButtonProps) => {
    const {acceptedNumber, itemNumber, itemBranch, isPending, comment, onPendingChange} = props;

    const [updating, setUpdating] = React.useState<boolean>(false);

    const onSetPending = React.useCallback((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!window.confirm("この部材を保留状態にします。よろしいですか？")) {
            return;
        }
        setUpdating(true);

        // このボタンではコメント編集しない
        updatePendingState(acceptedNumber, itemNumber, itemBranch, true, comment ?? "").then(() => {
            onPendingChange(true);
        }).finally(() => setUpdating(false));
    }, [comment, onPendingChange]);

    const onAwakePending = React.useCallback((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!window.confirm("この部材の保留状態を解除します。よろしいですか？")) {
            return;
        }
        setUpdating(true);
        updatePendingState(acceptedNumber, itemNumber, itemBranch, false, comment ?? "").then(() => {
            onPendingChange(false);
        }).finally(() => setUpdating(false));
    }, [comment, onPendingChange]);

    const button = React.useMemo(() => {
        if (isPending === null || updating) {
            return <Spinner/>
        }

        return <Button size="small" variant='outlined' color="secondary" onClick={(isPending ? onAwakePending : onSetPending)}>{isPending ? "保留解除" : "保留"}</Button>
    }, [isPending, updating, onSetPending, onAwakePending]);

    return button;
}

interface IItemStoringStatusPanelProps {
    acceptedNumber: string;
    itemNumber: number;
    itemBranch: number;
    processStatus: number;
    initData: IItemData;
    isWarehouseUser: boolean;
    storingStatus: number;
    isPending: boolean | null;
    pendingComment: string | null;
    onStoringStatusChange: (status: number) => void;
    onPendingChange: (pending: boolean) => void;
    onPendingCommentChange: (comment: string) => void;
}
const ItemStoringStatusPanel = (props: IItemStoringStatusPanelProps) => {
    const {acceptedNumber, itemNumber, itemBranch, processStatus, initData, isWarehouseUser, storingStatus, isPending, pendingComment, onStoringStatusChange, onPendingChange, onPendingCommentChange} = props;

    const detailDataStyles = oneLineGridStyles();

    const [storedDateString, setStoredDateString] = React.useState(initData.storedDateString);
    const [storedCompany, setStoredCompany] = React.useState(initData.storedCompany);
    const [storedStaffName, setStoredStaffName] = React.useState(initData.storedStaffName);
    const [storedNote, setStoredNote] = React.useState(initData.storedNote);
    const [inventoryDateString, setInventoryDateString] = React.useState(initData.inventoryDateString);
    const [inventoryCompany, setInventoryCompany] = React.useState(initData.inventoryCompany);
    const [inventoryStaffName, setInventoryStaffName] = React.useState(initData.inventoryStaffName);
    const [inventoryNote, setInventoryNote] = React.useState(initData.inventoryNote);
    const [shippingDateString, setShippingDateString] = React.useState(initData.shippingDateString);
    const [shippingCompany, setShippingCompany] = React.useState(initData.shippingCompany);
    const [shippingStaffName, setShippingStaffName] = React.useState(initData.shippingStaffName);
    const [shippingNote, setShippingNote] = React.useState(initData.shippingNote);

    const [updateStoreStatusTask, setUpdateStoreStatusTask] = React.useState<Promise<IUpdateItemStoringStatusResult | null> | null>(null);
    const [updateStockTackingStatusTask, setUpdateStockTackingStatusTask] = React.useState<Promise<IUpdateItemStoringStatusResult | null> | null>(null);
    const [updateShipTask, setUpdateShipTask] = React.useState<Promise<IUpdateItemStoringStatusResult | null> | null>(null);

    const onReceiptAndStoreStatusUpdate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!window.confirm("受領作業をスキップして入庫済にします。よろしいですか？")) {
            return;
        }

        setUpdateStoreStatusTask(updateItemStoringStatus(acceptedNumber, itemNumber, itemBranch, StoringStatusUpdateOperation.Store, true));
    }
    const onStoreStatusUpdate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setUpdateStoreStatusTask(updateItemStoringStatus(acceptedNumber, itemNumber, itemBranch, StoringStatusUpdateOperation.Store));
    const onStockTackingStatusUpdate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setUpdateStockTackingStatusTask(updateItemStoringStatus(acceptedNumber, itemNumber, itemBranch, StoringStatusUpdateOperation.StockTacking));
    const onShipStatusUpdate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setUpdateShipTask(updateItemStoringStatus(acceptedNumber, itemNumber, itemBranch, StoringStatusUpdateOperation.Ship));

    React.useEffect(() => {
        if (!updateStoreStatusTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            const result = await updateStoreStatusTask;
            if (result && !isUnmounted) {
                setStoredCompany(result.companyName);
                setStoredStaffName(result.staffName);
                setStoredDateString(result.dateString);

                setInventoryCompany(result.companyName);
                setInventoryStaffName(result.staffName);
                setInventoryDateString(result.dateString);

                onStoringStatusChange(StoringStatus.Stored);

                setUpdateStoreStatusTask(null);
            }
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [updateStoreStatusTask]);

    React.useEffect(() => {
        if (!updateStockTackingStatusTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            const result = await updateStockTackingStatusTask;
            if (result && !isUnmounted) {
                setInventoryCompany(result.companyName);
                setInventoryStaffName(result.staffName);
                setInventoryDateString(result.dateString);

                setUpdateStockTackingStatusTask(null);
            }
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [updateStockTackingStatusTask]);

    React.useEffect(() => {
        if (!updateShipTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            const result = await updateShipTask;
            if (result && !isUnmounted) {
                setShippingCompany(result.companyName);
                setShippingStaffName(result.staffName);
                setShippingDateString(result.dateString);

                setInventoryCompany(result.companyName);
                setInventoryStaffName(result.staffName);
                setInventoryDateString(result.dateString);

                onStoringStatusChange(StoringStatus.Shipped);

                setUpdateShipTask(null);
            }
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [updateShipTask]);

    const pendingCommentUpdateAction = async (current: string): Promise<boolean> => {
        if (pendingComment === current) {
            return true;
        }

        const result = await updatePendingState(acceptedNumber, itemNumber, itemBranch, isPending!, current);
        if (result) {
            onPendingCommentChange(current);
        }
        return result;
    }

    const storeMemoUpdateAction = async (current: string): Promise<boolean> => {
        if (storedNote === current) {
            return true;
        }

        const result = await updateItemStoringStatusMemo(acceptedNumber, itemNumber, itemBranch, StoringStatusUpdateOperation.Store, current);
        if (result) {
            setStoredNote(current);
        }
        return result;
    }
    const inventoryMemoUpdateAction = async (current: string): Promise<boolean> => {
        if (inventoryNote === current) {
            return true;
        }

        const result = await updateItemStoringStatusMemo(acceptedNumber, itemNumber, itemBranch, StoringStatusUpdateOperation.StockTacking, current);
        if (result) {
            setInventoryNote(current);
        }
        return result;
    }
    const shipMemoUpdateAction = async (current: string): Promise<boolean> => {
        if (shippingNote === current) {
            return true;
        }

        const result = await updateItemStoringStatusMemo(acceptedNumber, itemNumber, itemBranch, StoringStatusUpdateOperation.Ship, current);
        if (result) {
            setShippingNote(current);
        }
        return result;
    }

    let storeButton: React.ReactNode = (
        <Button
            variant="outlined"
            color="primary"
            disabled={storingStatus !== StoringStatus.Receipted || processStatus < 2 || !isWarehouseUser || isPending === null || isPending}
            style={{visibility: isWarehouseUser ? "visible" : "hidden"}}
            className="m-2"
            onClick={onStoreStatusUpdate}
        >
            入庫
        </Button>
    );
    if (storingStatus === StoringStatus.NoCheck) {
        storeButton = (
            <Button
                variant="outlined"
                color="primary"
                disabled={storingStatus !== StoringStatus.NoCheck || !isWarehouseUser}
                style={{visibility: isWarehouseUser ? "visible" : "hidden"}}
                className="m-2"
                onClick={onReceiptAndStoreStatusUpdate}
            >
                受領入庫
            </Button>
        )
    }

    const pendingCommentArea = (isPending === null || !isPending) ? undefined : (
        <React.Fragment>
            {/* <Grid item xs={12}>
                <TextField
                    label="保留コメント"
                    variant="outlined"
                    value={pendingState.pendingComment}
                    style={{width: "100%"}}
                    multiline
                />
                
            </Grid> */}
            <Grid item xs={12} sm={4}>
                <span className='font-weight-bold'>保留コメント</span>
            </Grid>
            <Grid item xs={12} sm={8}>
                <SwitchableTextbox
                    className="w-100"
                    style={{height: "1.5rem"}}
                    value={pendingComment ?? ""}
                    onSave={pendingCommentUpdateAction}
                    tooltip
                />
            </Grid>
        </React.Fragment>
    );

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={4} className="d-flex align-items-center"><div className="font-weight-bold">入出庫ステータス</div></Grid>
            <Grid item xs={12} sm={4} className="d-flex align-items-center"><div className={detailDataStyles.mobileSpacing}>{isPending ? "保留" : storingStatusStringMap.get(storingStatus)}</div></Grid>
            <Grid item xs={12} sm={4} className="text-right">
                <PendingStateSwitchButton acceptedNumber={acceptedNumber} itemNumber={itemNumber} itemBranch={itemBranch} isPending={isPending} comment={pendingComment} onPendingChange={onPendingChange} />
            </Grid>
            {pendingCommentArea}
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        className="pt-2 pl-2 pr-2 pb-0 border-bottom"
                        action={updateStoreStatusTask ? <Spinner className="m-2" /> : storeButton}
                        title="入庫処理"
                    />
                    <CardContent className="p-2">
                        <Grid container>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">入庫処理_日時</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{storedDateString}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">入庫処理_会社名</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{storedCompany}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">入庫処理_担当者名</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{storedStaffName}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">入庫処理_メモ</span></Grid>
                            <Grid item xs={12} sm={7}>
                                <SwitchableTextbox
                                    className="w-100"
                                    style={{height: "1.5rem"}}
                                    value={storedNote}
                                    onSave={storeMemoUpdateAction}
                                    tooltip
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        className="pt-2 pl-2 pr-2 pb-0 border-bottom"
                        action={updateStockTackingStatusTask ? <Spinner className="m-2" /> : <Button variant="outlined" color="primary" disabled={storingStatus !== StoringStatus.Stored || processStatus < 2 || !isWarehouseUser || isPending === null || isPending} style={{visibility: isWarehouseUser ? "visible" : "hidden"}} className="m-2" onClick={onStockTackingStatusUpdate}>棚卸</Button>}
                        title="棚卸処理"
                    />
                    <CardContent className="p-2">
                        <Grid container>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">棚卸処理_日時</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{inventoryDateString}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">棚卸処理_会社名</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{inventoryCompany}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">棚卸処理_担当者名</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{inventoryStaffName}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">棚卸処理_メモ</span></Grid>
                            <Grid item xs={12} sm={7}>
                                <SwitchableTextbox
                                    className="w-100"
                                    style={{height: "1.5rem"}}
                                    value={inventoryNote}
                                    onSave={inventoryMemoUpdateAction}
                                    tooltip
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        className="pt-2 pl-2 pr-2 pb-0 border-bottom"
                        action={updateShipTask ? <Spinner className="m-2" /> : <Button variant="outlined" color="primary" disabled={storingStatus !== StoringStatus.Stored || processStatus < 2 || !isWarehouseUser || isPending === null || isPending} style={{visibility: isWarehouseUser ? "visible" : "hidden"}} className="m-2" onClick={onShipStatusUpdate}>出庫</Button>}
                        title="出庫処理"
                    />
                    <CardContent className="p-2">
                        <Grid container>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">出庫処理_日時</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{shippingDateString}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">出庫処理_会社名</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{shippingCompany}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">出庫処理_担当者名</span></Grid>
                            <Grid item xs={12} sm={7}><span className={detailDataStyles.mobileSpacing}>{shippingStaffName}</span></Grid>
                            <Grid item xs={12} sm={5}><span className="font-weight-bold">出庫処理_メモ</span></Grid>
                            <Grid item xs={12} sm={7}>
                                <SwitchableTextbox
                                    className="w-100"
                                    style={{height: "1.5rem"}}
                                    value={shippingNote}
                                    onSave={shipMemoUpdateAction}
                                    tooltip
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

interface IPictureFormProps {
    pictureNumber: number;
    title: string;
    pictureId: number | null;
    acceptedNumber: string;
    itemNumber: number;
    itemBranch: number;
    disabled: boolean;
}
const PictureForm = (props: IPictureFormProps) => {
    const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

    const [image, setImage] = React.useState<File | null>(null);
    const [showImage, setShowImage] = React.useState<File | null | undefined>(null);
    const [isImageDownloaded, setIsImageDownloaded] = React.useState(false);
    const [isFileLoadingNow, setIsFileLoadingNow] = React.useState(true);
    const [uploadedDateStr, setUploadedDateStr] = React.useState<string | null>(null);
    const [isFileDeleting, setIsFileDeleting] = React.useState(false);
    const [isFileUploadingLocal, setIsFileUploadingLocal] = React.useState(false);

    const capture = React.useMemo(() => (file: File | null) => {
        setIsImageDownloaded(false);
        if (file !== null) {
            setImage(file);
        }
    }, [setIsImageDownloaded, setImage]);

    const onDelete = React.useMemo(() => (filename: string) => {
        console.log(`delete ${filename}`);
        setImage(null);
        setIsFileDeleting(true);
    }, [setImage, setIsFileDeleting]);

    const {pictureNumber, title, pictureId, acceptedNumber, itemNumber, itemBranch, disabled} = props;

    React.useEffect(() => {
        if (!pictureId) {   // 
            setIsFileLoadingNow(false);
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            const result = await getPictureAsync(pictureId);

            if (isUnmounted) {
                return;
            }

            if (!result) {
                setIsFileLoadingNow(false);
                return;
            }
            
            const {file, uploadedDateString} = result;
            setIsImageDownloaded(true);

            if (file) {
                setShowImage(file);
            }

            if (uploadedDateString) {
                setUploadedDateStr(uploadedDateString);
            }
            
            
            setIsFileLoadingNow(false);
        };

        asyncFunc();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (image === null) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            try {
                setIsFileUploadingLocal(true);
                const insertedId = await uploadItemPicture(acceptedNumber, itemNumber, itemBranch, pictureNumber, image, image.name);
    
                if (!insertedId) {  // 0 or null
                    return;
                }

                console.log(`upload ${image.name}`);
    
                const result = await getPictureAsync(insertedId);
                if (result && !isUnmounted) {
                    setShowImage(result.file);
                    setUploadedDateStr(result.uploadedDateString);
                }
            }
            finally {
                setIsFileUploadingLocal(false);
            }
        }

        asyncFunc();
    }, [image, acceptedNumber, itemNumber]);

    React.useEffect(() => {
        if (!isFileDeleting) {
            return;
        }

        const asyncFunc = async () => {
            try {
                const success = await deleteItemPicture(acceptedNumber, itemNumber, itemBranch, pictureNumber);
                if (success) {
                    setShowImage(null);
                }
            }
            finally {
                setIsFileDeleting(false);
            }
        };

        asyncFunc();
    }, [isFileDeleting, acceptedNumber, itemNumber, pictureNumber]);

    const onPreviewOpen = (file: File) => {
        toDataUrl(file)
            .then((value) => {
                const w = window.open("about:blank");
                w?.document.write(`<img style="width: auto; height: 100%" src="${value}" alt="${file.name}" />`);
            })
            .catch((error) => alert("ファイルが破損しています"));
    }

    const thumbnail = React.useMemo(() => {
        const date = uploadedDateStr === null ? undefined : uploadedDateStr;

        let dom = <React.Fragment><br /><Spinner /></React.Fragment>;
        if (!isFileLoadingNow) {
            dom = <FileSelectWithThumbnail
                width="20rem"
                file={showImage}
                onCapture={capture}
                preivewHook={onPreviewOpen}
                deleteHook={onDelete}
                filename={date}
                enable={!isFileUploadingLocal && !disabled}
                iconSize="medium"
            />;
        }

        return dom;
    }, [showImage, capture, onPreviewOpen, onDelete, uploadedDateStr, isFileLoadingNow, isFileUploadingLocal, disabled]);

    const previewDialog = React.useMemo(() => {
        const dialogHeight = window.innerHeight - (window.innerHeight / 100);   // 99%
        const dialogWidth = window.innerWidth - (window.innerWidth / 100);
        const size: {width: number | "auto", height: number | "auto"} = {width: dialogWidth, height: dialogHeight};
        if (dialogHeight > dialogWidth) {
            // 縦長ディスプレイの場合
            size.height = "auto";
        }
        else {
            // 横長ディスプレイの場合
            size.width = "auto";
        }

        let imageDOM = <React.Fragment></React.Fragment>;
        let imageUrl: string | null = null;
        if (showImage) {
            const url = URL.createObjectURL(showImage);
            imageUrl = url;

            const revoker = () => URL.revokeObjectURL(url);
            imageDOM = <img src={imageUrl} style={{...size, marginLeft: "auto", marginRight: "auto"}} alt={title} onLoad={revoker} />;
        }

        const onPreivewCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIsPreviewOpen(false);
        };

        const buttonZIndex = 2000;

        return (
            <Dialog
                open={isPreviewOpen}
                fullScreen={true}
                fullWidth={true}
                aria-describedby="full-screen-preview-dialog-description"
            >
                <DialogContent style={{padding: 0, marginRight: "auto", marginLeft: "auto"}}>
                    <div style={{...size, position: "relative", display: "inline-flex"}}>
                        <IconButton style={{position: "absolute", right: 0, top: 0, zIndex: buttonZIndex}} onClick={onPreivewCloseClick}>
                            <CloseIcon />
                        </IconButton>
                        {imageDOM}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }, [showImage, title, isPreviewOpen, setIsPreviewOpen]);

    return (
        <div key={title}>
            <span className="font-weight-bold h6">{title}</span><br />
            {thumbnail}
            {isFileUploadingLocal || isFileDeleting ? <LinearProgress style={{width: "100%", height: "0.2em"}} /> : ""}
            {previewDialog}
        </div>
    );
};