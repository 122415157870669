import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Spinner } from 'reactstrap';
import { IOperationHistory, researchDayOperationAsync } from 'actions/RO/ROWarehouseAction';
import { Constants } from 'app-env';
import { roDetailPath } from './RODetail';
import { ClickableTableRow } from 'components/ClickableTableRow';
import { TableHead, TableRow, TableCell, Table, TableBody, Button } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Store } from 'store/GlobalState';
import { routePush } from 'actions/utils/History';
import { roListPath } from './ROList';

export const roWarehuoseHistoryPath = '/ro/warehouse/history';
export const roWarehouseHistoryPageName = '作業一覧';


const tableHeaders = [
    '受付番号',
    '局番',
    '局名',
    '倉庫名',
    'アイテム総数',
    '入庫済総数',
    '受領数',
    '入庫数',
    '棚卸数',
    '出庫数'
]

export const ROWarehouseHistory = (props: RouteComponentProps) => {
    const [targetDate, setTargetDate] = React.useState<Date | null>(null);
    React.useEffect(() => {
        setTargetDate(new Date());
    }, []);

    const onClick = (acceptedNumber: string) => {
        props.history.push(`${roDetailPath}/?accepted=${acceptedNumber}`);
    }
    const onBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Store.dispatch(routePush(roListPath));

    return (
        <div className="w-100">
            <div className="d-flex align-items-center">
                <Button
                    variant="outlined"
                    className="mr-3"
                    onClick={onBackButtonClick}
                >
                    局一覧へ
                </Button>
                <span className="h4 mb-0">{roWarehouseHistoryPageName}</span>
            </div>
            <div className="ml-2 mt-4 mb-2">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        label="作業日"
                        value={targetDate}
                        onChange={setTargetDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date'
                        }}
                        style={{minWidth: "10em"}}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <HistoryTable onRowClick={onClick} targetDate={targetDate} />
        </div>
    )
};

interface IHistoryTableProps {
    targetDate: Date | null;
    onRowClick: (acceptedNumber: string) => void;
}
const HistoryTable = (props: IHistoryTableProps) => {
    const {targetDate, onRowClick: onTableRowClick} = props;

    const [list, setList] = React.useState<IOperationHistory[]>([]);
    const [nowLoading, setNowLoading] = React.useState(false);
    React.useEffect(() => {
        setNowLoading(true);

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!isUnmounted) {
                if (targetDate) {
                    setList(await researchDayOperationAsync(targetDate));
                }
    
                setNowLoading(false);
            }
        }
        
        asyncFunc();
        return () => {isUnmounted = true;};
    }, [targetDate]);

    const header = React.useMemo(() => (
        <TableHead>
            <TableRow>
                {tableHeaders.map((h, idx) => (
                    <TableCell key={`${idx}th column ${h}`} style={{backgroundColor: "#8895dc"}}><span className="text-nowrap">{h}</span></TableCell>
                ))}
            </TableRow>
        </TableHead>
    ), []);


    let rowDOMs: React.ReactNode = <React.Fragment></React.Fragment>;
    if (nowLoading) {
        rowDOMs = <TableRow><TableCell><Spinner /></TableCell></TableRow>;
    }
    else {
        rowDOMs = list.map((item, index, allList) => {
            const columns: React.ReactNode[] = [
                item.acceptedNumber,
                item.stationNumber,
                item.stationName,
                Constants.ro.warehouseNameMap.get(item.warehouse) ?? "",
                item.totalCount,
                item.totalStoredCount,
                item.receiptCount,
                item.storeCount,
                item.stocktakeCount,
                item.shipCount
            ];
    
            const onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                onTableRowClick(item.acceptedNumber);
            };
    
            return <ClickableTableRow key={`${index}th row item`} onClick={onRowClick} columns={columns} />;
        });
    
    }

    return (
        <Table stickyHeader>
            {header}
            <TableBody>
                {rowDOMs}
            </TableBody>
        </Table>
    );
}