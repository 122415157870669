import * as React from 'react';
import { IReceiveDocumentRegister, searchReceivePackageAsync, registerReceiveAsync } from 'actions/MartzMix/ReceiveRegisterAction';
import { TableRow, TableCell, TableHead, Checkbox, Table, TableBody, TextField, Tooltip, IconButton, Button, makeStyles, Theme, createStyles } from '@material-ui/core';
import { BarcodeReaderButton } from 'components/BarcodeReaderButton';
import SearchIcon from '@material-ui/icons/Search';
import { Spinner } from 'reactstrap';
import { TooltipWithModal } from 'components/TooltipWithModal';
import { cacheFlagsClear } from 'actions/types/CacheFlagsActionType';
import { Store } from 'store/GlobalState';

export const receiveRegisterPath = '/martzmix/receiveRegister';

const inputFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        forIEStyle: {"ime-mode": "inactive"}
    })
);

export const ReceiveRegister: React.FunctionComponent = () => {
    const [dataList, setDataList] = React.useState<IReceiveDocumentRegister[]>([]);
    const [searchTask, setSearchTask] = React.useState<Promise<IReceiveDocumentRegister[] | null> | null>(null);
    const [slipNumber, setSlipNumber] = React.useState("");
    const [registerTask, setRegisterTask] = React.useState<Promise<boolean> | null>(null);

    const onDataChange = (rowIndex: number, check: boolean) => {
        const newData = {...(dataList[rowIndex])};
        newData.received = check;

        setDataList([...(dataList.slice(0, rowIndex)), newData, ...(dataList.slice(rowIndex + 1))]);
    };

    const onBatchCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => setDataList(dataList.map(d => {
        const newData = {...d};
        newData.received = event.target.checked;
        return newData;
    }));

    const onSlipNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => setSlipNumber(event.target.value);

    const onSearchClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setSearchTask(searchReceivePackageAsync(slipNumber));
    };

    React.useEffect(() => {
        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!searchTask) {
                return;
            }

            const list = await searchTask;
            if (!isUnmounted) {
                if (!list) {
                    alert("データの取得に失敗しました");
                }
                else {
                    if (list.length === 0) {
                        alert("該当するデータがありません");
                    }
                    setDataList(list);
                }

                setSearchTask(null);
            }
        };

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [searchTask]);

    const onRegisterClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!dataList.every(d => d.received)) {
            alert("一部のみの受領はできません。\n全ての文書をチェックしてください");
            return;
        }
        setRegisterTask(registerReceiveAsync(slipNumber, dataList));
    }

    React.useEffect(() => {
        if (!registerTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!registerTask) {
                return;
            }

            const result = await registerTask;
            if (!isUnmounted) {
                if (!result) {
                    alert("登録に失敗しました");
                }
                else {
                    Store.dispatch({type: cacheFlagsClear});
                    alert("登録が完了しました");
                }

                setRegisterTask(null);
            }
        }

        asyncFunc();

        return () => {isUnmounted = true;};
    }, [registerTask]);

    const slipFieldStyles = inputFieldStyles();

    return (
        <React.Fragment>
            <div className="ml-3 mt-3 mb-4">
                <span className="h4">受領登録</span>
            </div>
            <div className="container">
                <div className="row align-items-end">
                    <TextField
                        label="送り状番号"
                        variant="outlined"
                        value={slipNumber} onChange={onSlipNumberChange}
                        inputProps={{className: slipFieldStyles.forIEStyle}} />
                    <TooltipWithModal title="送り状番号バーコードの読込" placement="top">
                        <BarcodeReaderButton onRead={(code: string) => {}} width="2em" size="small" className="ml-1 mr-1" />
                    </TooltipWithModal>
                    <Tooltip title="検索" placement="top">
                        <IconButton size="small" onClick={onSearchClick}><SearchIcon /></IconButton>
                    </Tooltip>
                </div>
                <br />
                <div className="row justify-content-end">
                    <Tooltip title="一括チェック" placement="top">
                        <Checkbox color="primary" onChange={onBatchCheckChange} />
                    </Tooltip>
                </div>
                <div className="row">
                    <ReceiveRegisterTable dataList={dataList} onCheckClick={onDataChange} loading={searchTask !== null} />
                </div>
                <div className="row justify-content-end mt-3">
                    {registerTask ? <Spinner /> : <Button variant="outlined" color="primary" onClick={onRegisterClick}>登録</Button>}
                </div>
            </div>
        </React.Fragment>
    );
};


const tableHeaders = [
    "",
    "文書番号",
    "文書名",
    "局番",
    "局名",
    "部数",
    "受領"
];

interface IReceiveRegisterTableProps {
    dataList: IReceiveDocumentRegister[];
    onCheckClick: (rowIndex: number, check: boolean) => void;
    loading: boolean;
}
const ReceiveRegisterTable = (props: IReceiveRegisterTableProps) => {
    const header = React.useMemo(() => (
        <TableHead>
            <TableRow>
                {tableHeaders.map(h => <TableCell key={h}>{h}</TableCell>)}
            </TableRow>
        </TableHead>
    ), []);


    const {dataList, onCheckClick, loading} = props;

    const rows = dataList.map((data, idx) => {
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            onCheckClick(idx, event.target.checked);
        };

        const checkbox = <Checkbox checked={data.received} onChange={onChange} color="primary" />;

        return (
            <ReceiveRegisterTableRow key={data.code} rowIndex={idx} data={data}>
                {checkbox}
            </ReceiveRegisterTableRow>
        );
    });

    return (
        <Table>
            {header}
            <TableBody>
                {loading ? <TableRow><TableCell><Spinner /></TableCell></TableRow> : rows}
            </TableBody>
        </Table>
    );
}

interface IReceiveRegisterTableRowProps {
    rowIndex: number;
    data: IReceiveDocumentRegister;
}
const ReceiveRegisterTableRow: React.StatelessComponent<IReceiveRegisterTableRowProps> = (props) => {
    const {data, rowIndex, children} = props;

    return (
        <TableRow>
            <TableCell>{rowIndex + 1}</TableCell>
            <TableCell>{data.code}</TableCell>
            <TableCell>{data.name}</TableCell>
            <TableCell>{data.stationNumber}</TableCell>
            <TableCell>{data.stationName}</TableCell>
            <TableCell>{data.copies}</TableCell>
            <TableCell>{children}</TableCell>
        </TableRow>
    );
};