import * as React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

export interface IDateRangeBoxProps {
    startLabel: string;
    startDate: Date | null;
    onStartDateChange: (start: Date | null) => void;
    endLabel: string;
    endDate: Date | null;
    onEndDateChange: (end: Date | null) => void;
    style?: React.CSSProperties;
    className?: string;
}


export const DateRangeBox: React.StatelessComponent<IDateRangeBoxProps> = (props) => {
    const {startLabel: startLable, startDate, onStartDateChange, endLabel, endDate, onEndDateChange, style, className} = props;

    const setStartDate = (date: Date | null) => {
        let setDate = date;
        if (setDate) {
            setDate = new Date(setDate.getFullYear(), setDate.getMonth(), setDate.getDate());
        }
        onStartDateChange(setDate);
    }
    const setEndDate = (date: Date | null) => {
        let setDate = date;
        if (setDate) {
            setDate = new Date(setDate.getFullYear(), setDate.getMonth(), setDate.getDate());
        }
        onEndDateChange(setDate);
    }

    return (
        <div style={style} className={className}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="d-inline-flex align-items-center">
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        label={startLable}
                        value={startDate}
                        onChange={setStartDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date'
                        }}
                        style={{minWidth: "10em"}}
                    />
                    <span className="d-block ml-2 mr-2">~</span>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        label={endLabel}
                        value={endDate}
                        onChange={setEndDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date'
                        }}
                        style={{minWidth: "10em"}}
                    />
                </div>
            </MuiPickersUtilsProvider>
        </div>
    )
}