import { HttpRequest } from "actions/utils/HttpRequest";
import { AppConfig } from "app-env";
import { responsiveFontSizes } from "@material-ui/core";


export enum StoringStatus {
    NoCheck = 0,
    Receipted,
    Stored,
    Shipped
}

export interface IDetailItemData {
    itemNumber: number;
    facilityNumber: string;
    facilityCategory: string;
    facilityName: string;
    removalType: string;
    acceptedCount: number;
    acceptedCountBranch: number;
    unit: string;
    weight: number;
    treatment: string;
    recycleType: string;
    storingStatus: number;
    storedDateString: string;
    isPending?: boolean;
    pendingComment: string;
}

export interface IDetailData {
    acceptedNumber: string;
    loadedVersion: number;
    stationNumber: string;
    stationName: string;
    primaryContractor: string;
    mailAddress: string;
    slipNumber?: string;
    projectDetailGroupCode: string;
    projectDetailGroupName: string;
    area: string;
    submitDateString: string;
    warehouse: string;
    itemDataList: IDetailItemData[];
}

export const getDetailDataAsync = async (acceptedNumber: string): Promise<IDetailData | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("ro/detail/v2", {acceptedNumber});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}

export const updateSlipNumberAsync = async (acceptedNumber: string, slipNumber: string): Promise<ResultWithMessage> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.putAsync("ro/detail/slipnumber", {acceptedNumber, slipNumber});

    if (!response) {
        return {result: false, message: "サーバーからの応答がありません"};
    }
    if (!response.ok) {
        return {result: false, message: `${await response.text()} code=${response.status}`};
    }

    return {result: true};
}

export const exportReturnSlipAsync = async (acceptedNumber: string): Promise<{downloadUrl: string, filename: string} | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("ro/detail/export/slip", {acceptedNumber}, "infinite");

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}

export interface IStatusBeforeAfter {
    before: number;
    after: number;
}
export const updateProcessStatusAsync = async (acceptedNumber: String, status: number): Promise<IStatusBeforeAfter | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.putAsync("ro/detail/status", {acceptedNumber, status});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} status=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}

export const getProcessStatusAsync = async (acceptedNumber: string): Promise<number | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("ro/detail/status", {acceptedNumber});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} status=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    const result = await response.json();
    return result.processStatus;
}

export const resolveAcceptedNumber = async (id: number): Promise<string | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("ro/resolve/id", {id});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} status=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    const {acceptedNumber} = await response.json();

    return acceptedNumber ?? null;
}