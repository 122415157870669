import * as React from 'react';
import { TableRowProps, TableRow, TableCell } from '@material-ui/core';

export interface IClickableTableRowProps extends TableRowProps {
    columns: React.ReactNode[];
}

export const ClickableTableRow = (props: IClickableTableRowProps) => {
    const [isMouseMove, setIsMouseMove] = React.useState(false);
    const onMouseDown = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(false);
    const onMouseMove = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(true);

    const {columns, onClick, ...rowProps} = props;

    const onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if(isMouseMove) {
            event.preventDefault();
            return;
        }

        if (onClick) {
            onClick(event);
        }
    };

    return (
        <TableRow {...rowProps} onClick={onRowClick} style={{cursor: "pointer"}}>
            {columns.map((col, index, allCols) => <TableCell key={`${index}th column`} onMouseMove={onMouseMove} onMouseDown={onMouseDown}>{col}</TableCell>)}
        </TableRow>
    )
}