import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { registerTemporaryUser, getGeneratedUserId } from 'actions/AuthorizationAction';
import { Spinner } from 'reactstrap';
import { Button, Grid, TextField } from '@material-ui/core';

export const temporaryUserRegisterPath = '/management/user/temporary';

const temporaryPassRegex = /^[a-zA-Z0-9!-/:-@¥[-`{-~]{1,32}$/;
const mailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const TemporaryUserRegistration = (props: RouteComponentProps) => {
    const [mailAddress, setMailAddress] = React.useState("");
    const [temporaryPassword, setTemporaryPassword] = React.useState("");
    const [retypeTemporaryPassword, setRetypeTemporaryPassword] = React.useState("");
    const [registerTask, setRegisterTask] = React.useState<Promise<boolean> | null>(null);
    const [isRegisterFinished, setIsRegisterFinished] = React.useState(false);

    const onMailAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => setMailAddress(event.target.value);
    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setTemporaryPassword(event.target.value);
    const onRetypePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setRetypeTemporaryPassword(event.target.value);
    const onCancelButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.history.goBack();
    }
    const onRegisterButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (temporaryPassword !== retypeTemporaryPassword) {
            alert("再入力パスワードが一致しません");
            return;
        }
        if (!temporaryPassRegex.test(temporaryPassword)) {
            alert("パスワードのフォーマットが正しくありません");
            return;
        }
        if (!mailRegex.test(mailAddress)) {
            alert("メールアドレスのフォーマットが正しくありません");
            return;
        }
        setRegisterTask(registerTemporaryUser(mailAddress, temporaryPassword));
    }
    const onFinishButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        props.history.goBack();
    }

    React.useEffect(() => {
        if (!registerTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!registerTask) {
                return;
            }

            const result = await registerTask;
            if (result && !isUnmounted) {
                setIsRegisterFinished(true);
            }

            setRegisterTask(null);
        }

        asyncFunc();

        return () => {isUnmounted = true;};
    }, [registerTask]);

    let buttons: React.ReactNode = <div className="text-right"><Button variant="contained" className="m-2" onClick={onFinishButtonClick}>戻る</Button></div>
    if (!isRegisterFinished) {
        buttons = (
            <div className="text-right">
                {registerTask ? <Spinner /> : (
                    <React.Fragment>
                        <Button variant="outlined" color="secondary" onClick={onCancelButtonClick} className="m-2">
                            キャンセル
                        </Button>
                        <Button variant="outlined" color="primary" onClick={onRegisterButtonClick} className="m-2">
                            仮登録
                        </Button>
                    </React.Fragment>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="ml-3 mt-3 mb-4">
                <span className="h4">ユーザー追加</span>
            </div>
            <div className="container">
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <div className="d-inline-block mr-3">メールアドレス</div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {isRegisterFinished ? mailAddress : <TextField
                            placeholder="追加ユーザーのメールアドレス"
                            onChange={onMailAddressChange}
                            style={{width: "100%"}}
                            value={mailAddress}
                            type="email"
                        />}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className="d-inline-block mr-3">仮パスワード</div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {isRegisterFinished ? temporaryPassword : <TextField
                            placeholder="半角英数記号32文字以内"
                            onChange={onPasswordChange}
                            style={{width: "100%"}}
                            value={temporaryPassword}
                        />}
                    </Grid>
                    {isRegisterFinished ? "" : (
                        <React.Fragment>
                            <Grid item xs={12} md={4}>
                                <div className="d-inline-block mr-3">仮パスワード再入力</div>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    placeholder="半角英数記号32文字以内"
                                    onChange={onRetypePasswordChange}
                                    style={{width: "100%"}}
                                    value={retypeTemporaryPassword}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
                {!isRegisterFinished ? "" : <div className="text-center h6 text-wrap" style={{color: "red"}}>仮パスワードは本登録に必要となります。必ずお控えになり、登録希望者様にお伝えください。</div>}
                {buttons}
            </div>
        </React.Fragment>
    )
}