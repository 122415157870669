import { HttpRequest } from "actions/utils/HttpRequest"
import { AppConfig } from "app-env"
import { IMartzMixServerConstants, ServerConstants } from "store/ServerConstants";
import { LoadServerConstants, loadServerConstants } from "actions/types/ServerConstantsActionType";
import { Token } from "store/Token";
import { Store } from "store/GlobalState";

export const loadServerConstantsAsync = async (): Promise<IMartzMixServerConstants | null> => {
    if (!Token.instance.token) {
        // not authorized
        return null;
    }
    const roles = Store.getState().loginPage.roles;
    if (!roles) {
        return null;
    }
    else if (roles.indexOf("MartzMixUser") <= -1) {
        return null;
    }

    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getAsync("ClientSecureData/constants/martzmix");

    if (!response) {
        console.error("サーバーに接続できません");
        return null;
    }
    if (!response.ok) {
        console.error(`${await response.text()} code=${response.status}`);
        return null;
    }

    return await response.json();
}

export const updateServerConstants = (data: IMartzMixServerConstants, prevState: ServerConstants): LoadServerConstants => {
    const newState: ServerConstants = {...prevState};
    newState.martzmix = data;

    return {type: loadServerConstants, data: newState};
}