import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IWorkingSchedule, getWorkingScheduleAsync } from 'actions/RO/ROWarehouseAction';
import { TableHead, TableRow, TableCell, Table, TableBody, Button, Select, MenuItem } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { Constants } from 'app-env';
import { ClickableTableRow } from 'components/ClickableTableRow';
import { Store } from 'store/GlobalState';
import { routePush } from 'actions/utils/History';
import { roListPath } from './ROList';
import { roDetailPath } from './RODetail';

export const roWarehouseSchedulePath = '/ro/warehouse/schedule';
export const roWarehouseSchedulePageName = '作業予定一覧';

const tableHeaders = [
    "受付番号",
    "局番",
    "局名",
    "ステータス",
    "倉庫名"
];

const dayPatterns = Array.from(Array(8).keys());

export const ROWarehouseSchedule = (props: RouteComponentProps) => {
    const [dayBefore, setDayBefore] = React.useState(2);

    const onClick = (acceptedNumber: string) => {
        props.history.push(`${roDetailPath}/?accepted=${acceptedNumber}`);
    }

    const onBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Store.dispatch(routePush(roListPath));

    const onDaySelectChange = (event: React.ChangeEvent<{name?: string | undefined, value: unknown}>, child: React.ReactNode) => {
        const current = event.target.value as string;
        const data = parseInt(current, 10);
        if (!isNaN(data)) {
            setDayBefore(data);
        }
    }

    const selectItems = React.useMemo(() => dayPatterns.map(i => <MenuItem value={i} key={`${i}th item`}>{i}</MenuItem>), []);

    return (
        <div className="w-100">
            <div className="d-flex align-items-center">
                <Button
                    variant="outlined"
                    className="mr-3"
                    onClick={onBackButtonClick}
                >
                    局一覧へ
                </Button>
                <span className="h4 mb-0">{roWarehouseSchedulePageName}</span>
            </div>
            <div className="ml-2 mt-4 mb-2 d-flex align-items-center">
                <Select
                    value={dayBefore}
                    onChange={onDaySelectChange}
                >
                    {selectItems}
                </Select>
                <span className="d-block">日以上前に伝票発行済</span>
            </div>
            <ScheduleTable dayBefore={dayBefore} onRowClick={onClick} />
        </div>
    )
}

interface IScheduleTableProps {
    dayBefore: number;
    onRowClick: (acceptedNumber: string) => void;
}

const ScheduleTable = (props: IScheduleTableProps) => {
    const {dayBefore, onRowClick: onTableRowClick} = props;

    const [list, setList] = React.useState<IWorkingSchedule[]>([]);
    const [nowLoading, setNowLoading] = React.useState(false);
    React.useEffect(() => {
        setNowLoading(true);

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!isUnmounted) {
                setList(await getWorkingScheduleAsync(dayBefore));
    
                setNowLoading(false);
            }
        }
        
        asyncFunc();
        return () => {isUnmounted = true;};
    }, [dayBefore]);

    const header = React.useMemo(() => (
        <TableHead>
            <TableRow>
                {tableHeaders.map((h, idx) => (
                    <TableCell key={`${idx}th column ${h}`} style={{backgroundColor: "#8895dc"}}><span className="text-nowrap">{h}</span></TableCell>
                ))}
            </TableRow>
        </TableHead>
    ), []);


    let rowDOMs: React.ReactNode = <React.Fragment></React.Fragment>;
    if (nowLoading) {
        rowDOMs = <TableRow><TableCell><Spinner /></TableCell></TableRow>;
    }
    else {
        rowDOMs = list.map((item, index, allList) => {
            const columns: React.ReactNode[] = [
                item.acceptedNumber,
                item.stationNumber,
                item.stationName,
                Constants.ro.processStatusNameMap.get(item.status) ?? "",
                Constants.ro.warehouseNameMap.get(item.warehouse) ?? ""
            ];
    
            const onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                onTableRowClick(item.acceptedNumber);
            };
    
            return <ClickableTableRow key={`${index}th row item`} onClick={onRowClick} columns={columns} />;
        });
    
    }

    return (
        <Table stickyHeader>
            {header}
            <TableBody>
                {rowDOMs}
            </TableBody>
        </Table>
    );
}