import * as React from 'react';
import { BaseStationSammary } from 'types/StationTypes';
import { connect } from 'react-redux';
import { GlobalState } from 'store/GlobalState';
import { Card, CardContent } from '@material-ui/core';

export interface IStationCardListViewProps {
    onItemClick: (station: BaseStationSammary) => void;
}
export interface IStationCardListViewConnectionProps extends IStationCardListViewProps {
    stations: BaseStationSammary[];
}
export const StationCardListView = connect(
    (state: GlobalState, ownProps: IStationCardListViewProps) => ({
        stations: state.homePage.stations,
        ...ownProps
    })
)((props: IStationCardListViewConnectionProps) => {
    const {stations, onItemClick} = props;

    const cards = React.useMemo(() => stations.map((st) => {
        const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            onItemClick(st);
        }
        
        return (
            <Card className="m-1" style={{width: "18em"}} key={st.planned.id}>
                <CardContent onClick={onClick} style={{cursor: "pointer"}}>
                    <span className="text-truncate">計画局番: {st.planned.id}</span><br />
                    <span className="text-truncate">計画局名: {st.planned.name}</span><br />
                    {st.implemented === null ? "" : (
                        <React.Fragment>
                            <span className="text-truncate">正式局番: {st.implemented.id}</span><br />
                            <span className="text-truncate">正式局名: {st.implemented.name}</span>
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
        );
    }), [stations, onItemClick]);
    

    return (
        <div className="w-100 h-100 overflow-auto">
            <div className="d-flex flex-wrap">
                {cards}
            </div>
        </div>
    );
})