import * as Login from '../store/LoginPageState';
import * as LoginAction from '../actions/types/LoginActionType';
import { ActionType } from '../actions/types/ActionType';

import clonedeep from 'lodash/cloneDeep';

export const LoginReducer = (prevState: Login.State | undefined, action: ActionType): Login.State => {
    if (prevState === undefined) {
        return Login.createInitialState();
    }
    
    let state = prevState;

    switch (action.type) {

        case LoginAction.login:
            state = login(prevState, action as LoginAction.LoginResult);
            break;

        case LoginAction.checkID:
            state = checkID(prevState, action as LoginAction.IDFormatValidate);
            break;

        case LoginAction.checkPassword:
            state = checkPassword(prevState, action as LoginAction.PasswordFormatValidate);
            break;

        case LoginAction.checkAccessKey:
            state = checkAccessKey(prevState, action as LoginAction.AccessKeyFormatValidate);
            break;

        default:
            // do nothing
    }

    return state;
}

const login = (prevState: Login.State, action: LoginAction.LoginResult): Login.State => {
    return {
        id: action.id,
        password: action.password,
        accessKey: action.accessKey,
        userName: action.userName,
        companyName: action.companyName,
        roles: action.roles,
        message: action.message,
        expire: action.result ? Login.getLoginExpireDate() : undefined
    };
}

const checkID = (prevState: Login.State, action: LoginAction.IDFormatValidate): Login.State => {
    const newState = clonedeep(prevState);

    newState.id = action.id;
    newState.message = action.message;

    return newState;
};

const checkPassword = (prevState: Login.State, action: LoginAction.PasswordFormatValidate): Login.State => {
    const newState = clonedeep(prevState);

    newState.password = action.password;
    newState.message = action.message;

    return newState;
};

const checkAccessKey = (prevState: Login.State, action: LoginAction.AccessKeyFormatValidate): Login.State => {
    const newState = clonedeep(prevState);

    newState.accessKey = action.accessKey;
    newState.message = action.message;

    return newState;
}