import * as Types from 'actions/types/SystemConstantsActionType';
import { ActionType } from 'actions/types/ActionType';
import { SystemConstants, systemConstantsInitial } from 'store/SystemConstants';

export const SystemConstantsReducer = (prevState: SystemConstants | undefined, action: ActionType): SystemConstants => {
    if (prevState === undefined) {
        return systemConstantsInitial;
    }

    if (action.type !== Types.loadSystemConstants) {
        return prevState;
    }

    const {data} = action as Types.LoadSystemConstants;

    return data;
}