import * as React from 'react';
import { Modal } from '@material-ui/core';

export interface IPageOverlayProps {
    open: boolean;
    zIndex?: number;
}

const defaultZIndex = 10000;

export const PageOverlay: React.StatelessComponent<IPageOverlayProps> = (props) => {
    const {open, zIndex, children} = props;
    
    const actualZIndex = zIndex ? zIndex : defaultZIndex;

    if (open) {
        const active = document.activeElement;
        if (active instanceof HTMLElement) {
            active.blur();
        }
    }
    
    return (
        <Modal disableEnforceFocus open={open} style={{zIndex: actualZIndex}}>
            <React.Fragment>
                {children}
            </React.Fragment>
        </Modal>
    );
}