import { GlobalState } from '../store/GlobalState';
import { LoginReducer } from './LoginReducer';
import { HomeReducer } from './HomeReducer';
import { GoogleMapReducer } from './GoogleMapReducer';
import { CombineReducerMap } from '../types/Redux';

import * as Redux from "redux"; // combineを書くのが大変なので最小限の導入
import * as Types from 'actions/types/ActionType';
import { EventRegisterReducer } from './EventRegisterReducer';
import { GlobalMenuReducer } from './GlobalMenuReducer';
import { History } from 'history';
import { createHistoryReducer } from './HistoryReducer';
import { ServerConstantsReducer } from './ServerConstantsReducer';
import { SystemConstantsReducer } from './SystemConstantsReducer';
import { CacheFlagsReducer } from './CacheFlagsReducer';


export const createReducer = (history: History<any>) => {
    const reducerMap: CombineReducerMap<GlobalState> = {
        event: EventRegisterReducer,
        loginPage: LoginReducer,
        homePage: HomeReducer,
        googleMap: GoogleMapReducer,
        globalMenu: GlobalMenuReducer,
        history: createHistoryReducer(history),
        serverConstants: ServerConstantsReducer,
        systemConstants: SystemConstantsReducer,
        cacheFlags: CacheFlagsReducer
    };

    const appReducer = Redux.combineReducers(reducerMap);

    const Reducer = (state: GlobalState | undefined, action: Types.ActionType) => {
        let nextState = state;
        if (action.type === Types.reset) {
            nextState = undefined;
        }
    
        return appReducer(nextState, action);
    };

    return Reducer;
}



