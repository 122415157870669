import * as Login from './LoginPageState';
import * as Home from './HomeState';
import { createStore, compose, applyMiddleware, Reducer as ReduxReducer, AnyAction } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
//import storageSession from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage'; 
import { createReducer } from '../reducers/Reducer';
import { GlobalEvent } from 'types/GlobalEvent';
import { createBrowserHistory } from 'history';
import { IHistoryWrapper } from 'actions/types/HistoryType';
import { ServerConstants } from './ServerConstants';
import { SystemConstants } from './SystemConstants';

const persistConfig: PersistConfig<GlobalState> = {
    key: 'root',
    storage: storage,
    blacklist: [
        /* シリアライズできないので除外 → */
        'event',
        'googleMap',
        'globalMenu'/*← */,
        'history' /* URL直打ちで遷移できなくなるので保存しない */,
        'cacheFlags' /* 再読み込みでキャッシュ破棄 */]
};

export const BrowserHistory = createBrowserHistory();
const persistedReducer = persistReducer(persistConfig, createReducer(BrowserHistory));

export const Store = createStore(
    persistedReducer
);
export const Persistor = persistStore(Store);


export interface GlobalState {
    event: GlobalEvent;
    loginPage: Login.State;
    homePage: Home.State;
    googleMap: google.maps.Map | null;
    globalMenu: JSX.Element | null | undefined;
    history: IHistoryWrapper; // connected-react-routerが再描画かからなくて使えないので自分で書く
    serverConstants: ServerConstants;    // サーバー側で定義する定数群
    systemConstants: SystemConstants;   // サブシステムが起動時に定義するクライアント側定数
    cacheFlags: Map<string, boolean>;   // キャッシュ制御フラグ
}