import * as React from 'react';
import { TextField, Button } from '@material-ui/core';
import * as LoginAction from '../actions/AuthorizationAction';
import { LoadingButton } from '../components/LoadingButton';
import { RouteComponentProps } from 'react-router';
import { Store, GlobalState, Persistor } from '../store/GlobalState';
import { connect } from 'react-redux';
import { ActionType, reset } from 'actions/types/ActionType';
import { AppConfig } from 'app-env';
import { NextRootPath } from './Next/NextApp';
import { martzMixRootPath } from './MartzMix/MartzMixApp';
import { routePush } from 'actions/utils/History';
import { roRootPath } from './RO/ROApp';
import { LoginForm } from 'components/LoginForm';
import * as QueryString from 'query-string';
import { Token } from 'store/Token';

interface ILoginProps extends RouteComponentProps {
    id: string;
    password: string;
    accessKey: string;
    message?: string;
    expire?: Date;
    checkIDFormat: (id: string) => void;
    checkPasswordFormat: (password: string) => void;
    checkAccessKeyFormat: (accessKey: string) => void;
}

const persistorDateDeserializeWrapper = (date: Date | string | undefined) => {
    if (date instanceof Date) {
        return date;
    }
    else if (typeof date === "string") {
        return new Date(date);
    }
    else {
        return date;
    }
}
const parseUrlParams = (parser: QueryString.ParsedQuery<string>): {redirect: string, decoded: (undefined | true)} | null => {
    const {redirect: preRedirect, decoded: preDecoded} = parser;
    if (typeof preRedirect !== "string") {
        return null;
    }
    const redirect = preRedirect;

    let decoded: (undefined | true) = undefined;
    if (preDecoded) {
        if (typeof preDecoded === "string" && Boolean(preDecoded)) {
            decoded = true;
        }
    }

    return {
        redirect,
        decoded
    };
}
export const Login = connect(
    (state: GlobalState, ownProps: RouteComponentProps) => ({
        id: state.loginPage.id,
        password: state.loginPage.password,
        accessKey: state.loginPage.accessKey,
        message: state.loginPage.message,
        expire: persistorDateDeserializeWrapper(state.loginPage.expire),    // redux persistがDateオブジェクトのデシリアライズをしてくれない(文字列のままになる)ので判定してDateオブジェクトに戻す
        ...ownProps
    }),
    (dispatch: React.Dispatch<ActionType>, ownProps: RouteComponentProps) => ({
        checkIDFormat: (id: string) => dispatch(LoginAction.checkIDFormat(id)),
        checkPasswordFormat: (password: string) => dispatch(LoginAction.checkPasswordFormat(password)),
        checkAccessKeyFormat: (accessKey: string) => dispatch(LoginAction.checkAccessKeyFormat(accessKey)),
        ...ownProps
    }),
    ({id, password, accessKey, message, expire}, {checkIDFormat, checkPasswordFormat, checkAccessKeyFormat}, ownProps: RouteComponentProps) => ({
        id, password, accessKey, message, expire, checkIDFormat, checkPasswordFormat, checkAccessKeyFormat, ...ownProps
    })
)((props: ILoginProps) => {
    const {id, password, accessKey, message, expire, checkIDFormat, checkPasswordFormat, checkAccessKeyFormat, ...ownProps} = props;

    const onIDChanged = (id: string) => checkIDFormat(id);
    const onPasswordChanged = (password: string) => checkPasswordFormat(password);
    const onAccessKeyChanged = (accessKey: string) => checkAccessKeyFormat(accessKey);

    const param = parseUrlParams(QueryString.parse(props.location.search));

    const [isAuthorizing, setIsAuthorizing] = React.useState(false);
    const onLoginClick = () => setIsAuthorizing(true);

    // ログイン実行
    React.useEffect(() => {
        const asyncFunc = async () => {
            if (!isAuthorizing) {
                return;
            }

            const result = await LoginAction.loginAsync(id, password, accessKey);

            setIsAuthorizing(false);

            Store.dispatch(result);
    
            if (result.result) {
                localStorage.setItem("accessKey", accessKey);   // 次回以降このアクセスキーを最初に表示する
                console.log(`update saved key = ${accessKey}`);

                if (!result.roles) {
                    return;
                }


                let redirectUrl: string | undefined = undefined;
                let decoded: undefined | true = undefined;
                if (param) {
                    redirectUrl = param.redirect;
                    decoded = param.decoded;
                }

                LoginAction.jumpToTopPagesByFunction(ownProps, result.roles, redirectUrl, decoded);
            }
        };

        asyncFunc();
    }, [isAuthorizing]);

    React.useEffect(() => {
        const asyncFunc = async () => {
            const appVersion = await LoginAction.getAppVersion();
            if (AppConfig.appVersion !== appVersion) {
                console.log(`app version = (Client: ${AppConfig.appVersion}, Server: ${appVersion})`);
                window.location.reload();   // 最新のJSコードをサーバーから再取得する
            }
        }

        asyncFunc();

        if (expire && (expire.getTime() > (new Date()).getTime())) {    // 前回のログイン操作から規定時間以内なら自動的にログイン操作を行う
            setIsAuthorizing(true);
        }
    }, []);

    return <LoginForm
        isLoading={isAuthorizing}
        id={id}
        password={password}
        userAccessKey={accessKey}
        message={message}
        onIDChanged={onIDChanged}
        onPasswordChanged={onPasswordChanged}
        onAccessKeyChanged={onAccessKeyChanged}
        onLoginClick={onLoginClick}    
    />;
});