import * as React from 'react';
import { connect } from 'react-redux';
import { GlobalState, Store } from 'store/GlobalState';
import { RouteComponentProps } from 'react-router';
import { Token } from 'store/Token';
import { Grid, TextField, Button } from '@material-ui/core';
import * as QueryString from 'query-string';
import { registerUser, loginAsync, jumpToTopPagesByFunction, checkIDFormat, checkPasswordFormat, checkAccessKeyFormat, getTemporaryRegisteredUserId } from 'actions/AuthorizationAction';
import { Spinner } from 'reactstrap';

export const userRegistrationPath = '/registration';

const tempPassRegex = /^[a-zA-Z0-9!-/:-@¥[-`{-~]{0,32}$/;
const passwordMinLength = 8;
const passwordInputRegex = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/;
const accessKeyLength = 12;
const accessKeyRegex = /^[0-9]{0,12}$/;
const katakanaRegex = /^[ァ-ヶー]+$/;

const parseQuery = (parser: QueryString.ParsedQuery<string>): ({id: number, accessKey: string, token: string} | null) => {
    const {id, token, ak} = parser;
    if (typeof id !== "string" || typeof token !== "string" || typeof ak !== "string") {
        return null;
    }

    const idNum = parseInt(id, 10);
    if (isNaN(idNum)) {
        return null;
    }

    return {id: idNum, accessKey: ak, token};
}
const selectTextFieldError = (input: string, isError: boolean, helperText?: string): (string | undefined) => {
    if (!isError) {
        return undefined;
    }

    if (!input) {
        return "必須項目です";
    }

    return helperText;
}

export const UserRegistration = (props: RouteComponentProps) => {
    const [userId, setUserId] = React.useState("");
    const [accessKey, setAccessKey] = React.useState("");
    const [temporaryPass, setTemporaryPass] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [retypePassword, setRetypePassword] = React.useState("");
    const [firstName, setFirstName] = React.useState("");
    const [howToReadFirstName, setHowToReadFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [howToReadLastName, setHowToReadLastName] = React.useState("");

    const [checkTempPassError, setCheckTempPassError] = React.useState(false);
    const [checkPasswordError, setCheckPasswordError] = React.useState(false);
    const [passwordErrorMsg, setPasswordErrorMsg] = React.useState("");
    const [checkFirstNameError, setCheckFirstNameError] = React.useState(false);
    const [checkHowToReadFirstNameError, setCheckHowToReadFirstNameError] = React.useState(false);
    const [checkLastNameError, setCheckLastNameError] = React.useState(false);
    const [checkHowToReadLastNameError, setCheckHowToReadLastNameError] = React.useState(false);

    const [isRegistered, setIsRegistered] = React.useState(false);
    
    const onTemporaryPassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.target.value;
        if (tempPassRegex.test(current)) {
            setTemporaryPass(current);
            setCheckTempPassError(false);
        }
    }
    const onTemporaryPassPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const current = event.clipboardData.getData("text/plain");
        if (current && tempPassRegex.test(current)) {
            console.log(`paste ${current}`);
            setTemporaryPass(current);
            setCheckTempPassError(false);
        }
        event.preventDefault(); // これしないとonChangeも動く
    }
    const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.target.value;
        if (passwordInputRegex.test(current)) {
            setPassword(current);
            setCheckPasswordError(false);
        }
    }
    const onRetypePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.target.value;
        if (passwordInputRegex.test(current)) {
            console.log(`retype=${current}`);
            setRetypePassword(current);
            setCheckPasswordError(false);
        }
    }
    const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.target.value;
        setFirstName(current);
        setCheckFirstNameError(false);
    }
    const onHowToReadFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.target.value;
        setHowToReadFirstName(current);
        setCheckHowToReadFirstNameError(false);
    }
    const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.target.value;
        setLastName(current);
        setCheckLastNameError(false);
    }
    const onHowToReadLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.target.value;
        setHowToReadLastName(current);
        setCheckHowToReadLastNameError(false);
    }

    const [registerTask, setRegisterTask] = React.useState<Promise<boolean> | null>(null);
    React.useEffect(() => {
        if (!registerTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!registerTask) {
                return;
            }

            const result = await registerTask;
            if (result) {
                // ログイン
                Store.dispatch(checkIDFormat(userId));
                Store.dispatch(checkPasswordFormat(password));
                Store.dispatch(checkAccessKeyFormat(accessKey));
                const loginResult = await loginAsync(userId, password, accessKey);

                setIsRegistered(true);

                if (loginResult.result) {
                    if (!isUnmounted) {

                        localStorage.setItem("accessKey", accessKey);   // 次回以降このアクセスキーを最初に表示する
                        console.log(`update saved key = ${accessKey}`);

                        Store.dispatch(loginResult);

                        const roles = loginResult.roles;
                        if (!roles) {
                            return;
                        }

                        jumpToTopPagesByFunction(props, roles, undefined, undefined, true);
                    }
                }
                else {
                    alert("ユーザーの作成には成功しましたが、ログインできませんでした。ログインページからログインしてください。");
                    window.location.href = '/login';
                }
            }

            setRegisterTask(null);
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [registerTask]);

    React.useEffect(() => {
        let isUnmounted = false;
        const asyncFunc = async () => {
            const urlArgs = parseQuery(QueryString.parse(props.location.search));
            if (!urlArgs) {
                return; // ここに来るならアラートが既に出ている
            }

            const {id, accessKey} = urlArgs;
            const registeredUserId = await getTemporaryRegisteredUserId(id);
            if (registeredUserId) {
                setUserId(registeredUserId);
            }

            setAccessKey(accessKey);
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [])


    const urlArgs = parseQuery(QueryString.parse(props.location.search));
    if (!urlArgs) {
        alert("不正なパラメータです。");
        return <React.Fragment></React.Fragment>;
    }
    if (Token.instance.token && !isRegistered) {
        alert("ログイン済みユーザーは使用できないページです。前のページへ戻ります。");
        props.history.goBack();
        return <React.Fragment></React.Fragment>;
    }
    else if (isRegistered) {
        return <div>トップページへ遷移します<Spinner /></div>;
    }

    const onRegisterButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!urlArgs) {
            return;
        }

        let validationOK = true;

        if (!temporaryPass.length) {
            setCheckTempPassError(true);
            validationOK = false;
        }

        if (password.length < passwordMinLength) {
            setCheckPasswordError(true);
            setPasswordErrorMsg("パスワードは最低8文字必要です");
            validationOK = false;
        }
        else if (password !== retypePassword) {
            setCheckPasswordError(true);
            setPasswordErrorMsg("再入力パスワードと一致しません");
            validationOK = false;
        }

        if (!firstName.length) {
            setCheckFirstNameError(true);
            validationOK = false;
        }

        if (!katakanaRegex.test(howToReadFirstName)) {
            setCheckHowToReadFirstNameError(true);
            validationOK = false;
        }

        if (!lastName.length) {
            setCheckLastNameError(true);
            validationOK = false;
        }

        if (!katakanaRegex.test(howToReadLastName)) {
            setCheckHowToReadLastNameError(true);
            validationOK = false;
        }

        if (!validationOK) {
            return;
        }

        setRegisterTask(registerUser(
            urlArgs.id,
            urlArgs.token,
            userId,
            temporaryPass,
            password,
            urlArgs.accessKey,
            firstName,
            howToReadFirstName,
            lastName,
            howToReadLastName
        ));
    }


    let content: React.ReactNode = <Spinner />;
    if (userId && accessKey) {
        content = (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12} md={3} className="d-inline-flex align-items-center">
                        <div>ユーザーID</div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {userId}
                    </Grid>
                    <Grid item xs={12} md={3} className="d-inline-flex align-items-center">
                        <div>アクセスキー</div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {urlArgs.accessKey}
                    </Grid>
                    <Grid item xs={12} style={{height: "1rem"}}>{/* no content */}</Grid>
                    <Grid item xs={12} md={3} className="d-inline-flex align-items-center">
                        <div>仮パスワード</div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            value={temporaryPass}
                            onChange={onTemporaryPassChange}
                            onPaste={onTemporaryPassPaste}
                            placeholder="仮登録時に設定したパスワード"
                            className="w-100"
                            error={checkTempPassError}
                            helperText={selectTextFieldError(temporaryPass, checkTempPassError)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className="d-inline-flex align-items-center">
                        <div>本パスワード</div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            value={password}
                            onChange={onPasswordChange}
                            placeholder="半角英数記号32文字以内"
                            className="w-100"
                            type="password"
                            error={checkPasswordError}
                            helperText={selectTextFieldError(password, checkPasswordError, passwordErrorMsg)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className="d-inline-flex align-items-center">
                        <div>本パスワード再入力</div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            value={retypePassword}
                            onChange={onRetypePasswordChange}
                            placeholder="本パスワードの再入力"
                            className="w-100"
                            type="password"
                            error={checkPasswordError}
                            helperText={selectTextFieldError(password, checkPasswordError, passwordErrorMsg)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className="d-inline-flex align-items-center">
                        <div>名前</div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField
                                    value={lastName}
                                    onChange={onLastNameChange}
                                    placeholder="姓"
                                    className="w-100 pr-3"
                                    error={checkLastNameError}
                                    helperText={selectTextFieldError(lastName, checkLastNameError)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={firstName}
                                    onChange={onFirstNameChange}
                                    placeholder="名"
                                    className="w-100"
                                    error={checkFirstNameError}
                                    helperText={selectTextFieldError(firstName, checkFirstNameError)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} className="d-inline-flex align-items-center">
                        <div>フリガナ(全角)</div>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container>
                            <Grid item xs={6}>
                                <TextField
                                    value={howToReadLastName}
                                    onChange={onHowToReadLastName}
                                    placeholder="セイ"
                                    className="w-100 pr-3"
                                    error={checkHowToReadLastNameError}
                                    helperText={selectTextFieldError(howToReadLastName, checkHowToReadLastNameError, "全角カタカナで入力してください")}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={howToReadFirstName}
                                    onChange={onHowToReadFirstName}
                                    placeholder="メイ"
                                    className="w-100"
                                    error={checkHowToReadFirstNameError}
                                    helperText={selectTextFieldError(howToReadFirstName, checkHowToReadFirstNameError, "全角カタカナで入力してください")}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className="text-right mt-5">
                    {registerTask ? <Spinner /> : (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onRegisterButtonClick}
                            disabled={isRegistered}
                        >
                            登録
                        </Button>
                    )}
                </div>
            </React.Fragment>
        );
    }



    

    return (
        <div className="container">
            <div className="text-center h1">ユーザー登録</div>
            {content}
        </div>
    )
}