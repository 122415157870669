import { HttpRequest } from 'actions/utils/HttpRequest';
import { AppConfig } from 'app-env';
import { toDateString } from 'actions/utils/StringFormatter';
import React from 'react';

export interface IDocumentSammary {
    id: number;
    code: string;
    registered: string;
    lastModified: string;
    stationNumber: string;
    stationName: string;
    documentName: string;
    sendCopies: number;
    returnCopies: number;
    status: number;
}

export interface IDocumentSammaryChunk {
    page: number;
    chunkSize: number;
    totalCount: number;
    chunk: IDocumentSammary[];
}

export interface IDocumentSammarySearchPeriod {
    type: "registered" | "lastUpdated";
    start?: Date;
    end?: Date;
}

export interface IDocumentSammarySearch {
    status?: number;
    stationNumber?: string;
    stationName?: string;
    documentCode?: string;
    slipNumber?: string;
    companyCode?: string;
    period?: IDocumentSammarySearchPeriod;
}
export type orderType = "asc" | "desc";
export interface ISortSpecification {
    target: string;
    order: orderType;
}

let documentSammaryListResourceId: string | null = null;
export const getDocumentSammaryListAsync = async (size: number, page: number, search?: IDocumentSammarySearch, sort?: ISortSpecification, refresh = false): Promise<IDocumentSammaryChunk | null> => {
    const request = new HttpRequest(AppConfig.serverURL);

    let propsBase: any = {size, page};
    if (documentSammaryListResourceId && !refresh) {
        propsBase = {...propsBase, resourceId: documentSammaryListResourceId};
    }
    if (sort) {
        propsBase = {...propsBase, sort};
    }

    let response: Response | undefined;
    if (search) {
        response = await request.getWithQueryAsync("Document/search", {...propsBase, search}, "infinite");
    }
    else {
        response = await request.getWithQueryAsync("Document/list", propsBase, "infinite");
    }

    if (!response) {
        console.error("no response");
        return null;
    }
    else if (!response.ok) {
        console.warn(`error response code=${response.status}`);
        return null;
    }

    const {resourceId, ...result} = await response.json();
    documentSammaryListResourceId = resourceId;

    return result.chunkedData;
}

export const exportDocumentSammaryListAsync = async (search?: IDocumentSammarySearch): Promise<{downloadUrl: string, filename: string} | null | undefined> => {

    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("Document/list/export", {resourceId: documentSammaryListResourceId, search});

    if (!response) {
        alert("サーバーからの応答がありません");
        return undefined;
    }
    if (!response.ok) {
        alert(`${await response.text()} code=${response.status}`);
        return null;
    }

    const {downloadUrl, filename, resourceId} = await response.json();

    documentSammaryListResourceId = resourceId;

    return {downloadUrl, filename};
}


export interface IRouteInfo {
    startStep: number;
    endStep: number;
    copies: number;
}
export const getDocumentRouteStructureAsync = async (documentId: number): Promise<IRouteInfo[] | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("Document/route", {documentId});

    if (!response) {
        console.error("no response from 'route'");
        return null;
    }
    else if (!response.ok) {
        console.warn(`error response code=${response.status}`);
        return null;
    }

    return await response.json();
}

export interface IDocumentStatus {
    code: string;
    name: string;
    stationNumber: string;
    stationName: string;
    statuses: IDocumentOneOfStatus[];
}
export interface IDocumentOneOfStatus {
    status: {step: number, lastModified: string};
    slipNumber?: string;
}

export const getDocumentStatusDetailAsync = async (documentId: number): Promise<IDocumentStatus | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("Document/status/all", {documentId});

    if (!response) {
        console.error("no response from 'status/all'");
        return null;
    }
    else if (!response.ok) {
        console.warn(`error response code=${response.status}`);
        return null;
    }

    return await response.json();
}


export interface IRouteSet {
    sourceCompanyCode: string;
    destinationCompanyCode: string;
    sendCopiesNum: number;
    returnCopiesNum: number;
}

export interface INewDocument {
    stationNumber: string;
    documentCode: string;
    documentName: string;
    comment: string;
    routeList: IRouteSet[];
}

export const createNewDocumentAsync = async (document: INewDocument): Promise<ResultWithMessage> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("document/register/new", document);

    if (!response) {
        return {result: false, message: "タイムアウト"};
    }
    if (!response.ok) {
        return {result: false, message: `${await response.text()} code=${response.status}`};
    }

    return {result: true};
}

export const resolveStationNameAsync = async (number: string): Promise<string | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("station/resolve/name", {number});

    if (!response) {
        alert("サーバーからの応答がありません");
        return null;
    }
    if (!response.ok) {
        alert(`${await response.text()} code=${response.status}`);
        return null;
    }

    const {name} = await response.json();

    return name;
}

export const modifyDocumentSendCopies = async (code: string, copies: number, step: number, slipNumber?: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("document/modify/copies", {documentCode: code, copies, step, slipNumber: slipNumber ?? ""});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return false;
    }
    const responseData = await response.json();

    if (!response.ok) {
        const msg = `${responseData.message} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}


export interface ILoadedMartzDocumentRoute {
    nextCompanyWithBranch: string;
    sendCopies: number;
    returnCopies: number;
}
export interface ILoadedMartzDocument {
    code: string;
    name: string;
    stationNumber: string;
    stationName: string;
    startCompanyWithBranch: string;
    temporaryRoutings: ILoadedMartzDocumentRoute[];
}


export interface ILoadMartzDocumentRequestResult {
    success: ILoadedMartzDocument[];
    failed: IRegisterFailedDocument[];
}
export const loadMartzDocument = async (documentCodeList: string[]): Promise<ILoadMartzDocumentRequestResult | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("document/load/martz", {documentCodeList}, "infinite");

    if (!response) {
        alert("サーバーからの応答がありません");
        return null;
    }
    if (!response.ok) {
        let msg: string;
        if (response.status === 400) {
            msg = (await response.json()).message;
        }
        else {
            msg = await response.text();
        }

        console.error(msg);
        alert(msg);
        return null;
    }

    const {documents, failedDocuments} = await response.json();

    return {success: documents, failed: failedDocuments};
}

export interface IImportRoutingCopies {
    sendCopies: number;
    primarySendCopies?: number;
    primaryReturnCopies: number;
    secondaryReturnCopies?: number;
}
export interface IImportDocument {
    documentCode: string;
    overwriteRouting: IImportRoutingCopies;
}
export interface IRegisterFailedDocument {
    documentCode: string;
    reason: string;
}
export const importDocumentFromMartz = async (documentList: IImportDocument[]): Promise<IRegisterFailedDocument[] | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("document/register/import/martz", {documentList}, "infinite");

    if (!response) {
        alert("サーバーからの応答がありません");
        return null;
    }
    if (!response.ok) {
        let msg: string;
        if (response.status === 400) {
            msg = (await response.json()).message;
        }
        else {
            msg = await response.text();
        }

        console.error(msg);
        alert(msg);
        return null;
    }

    return (await response.json()).failedDocuments;
}


export interface IValidateDocumentRouteCompleteResponse {
    code: string;
    isSettableComplete: boolean;
    comment: string;
}

const validateDocumentRouteComplete = async (codes: string[]): Promise<IValidateDocumentRouteCompleteResponse[] | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("document/register/completion/validate", {codes}, "infinite");

    if (!response) {
        alert("サーバーからの応答がありません");
        return null;
    }
    if (!response.ok) {
        let msg: string;
        if (response.status === 400) {
            msg = (await response.json()).message;
        }
        else {
            msg = await response.text();
        }

        console.error(msg);
        alert(msg);
        return null;
    }

    return (await response.json()).validations;
}
const registerDocumentRouteCompelete = async (codes: string[]): Promise<void> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("document/register/completion", {codes}, "infinite");

    if (!response) {
        alert("サーバーからの応答がありません");
        return;
    }
    if (!response.ok) {
        let msg: string;
        if (response.status === 400) {
            msg = (await response.json()).message;
        }
        else {
            msg = await response.text();
        }

        console.error(msg);
        alert(msg);
        return;
    }
}

export const useValidateDocumentRouteCompleteApi = (onValid?: (result: IValidateDocumentRouteCompleteResponse[]) => void) => {
    const [task, setTask] = React.useState<ReturnType<typeof validateDocumentRouteComplete> | null>(null);
    
    const [validateResult, setValidateResult] = React.useState<IValidateDocumentRouteCompleteResponse[] | null>(null);
    const validate = React.useCallback((codes: string[]) => setTask(validateDocumentRouteComplete(codes)), [setTask]);

    React.useEffect(() => {
        if (!task) {
            return;
        }

        task.then(result => {
            setValidateResult(result);
            setTask(null);
            if (onValid && result) {
                onValid(result);
            }
        });
    }, [task, onValid]);

    return {isProcessing: !!task, validate, result: validateResult};
}

export const useRegisterDocumentRouteCompleteApi = (onFinish?: () => void) => {
    const [task, setTask] = React.useState<ReturnType<typeof registerDocumentRouteCompelete> | null>(null);

    const registrar = React.useCallback((codes: string[]) => setTask(registerDocumentRouteCompelete(codes)), [setTask]);

    React.useEffect(() => {
        if (!task) {
            return;
        }

        task.then(result => {
            if (onFinish) {
                onFinish();
            }
            setTask(null);
        });
    }, [task]);

    return {isProcessing: !!task, registrar};
}