import * as React from 'react';
import { BaseStationSammary, BaseStationDetail, ProjectForBaseStaion } from 'types/StationTypes';
import { Divider, List, ListItemText, Button, LinearProgress, makeStyles, Theme, createStyles, Card, CardHeader, CardContent, CardActions, IconButton, Collapse, Drawer, useTheme } from '@material-ui/core';
import { changeSelectedStation, updateEntryAndExitHistory, pickupProjects } from 'actions/Next/HomeAction';
import { Store } from 'store/GlobalState';
import { Spinner } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const drawerWidth = "20rem";
const drawerMobileWidth = "70vw";

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            height: "100vh",
            flexShrink: 0,
            [theme.breakpoints.down("sm")]: {
                width: drawerMobileWidth
            },
            [theme.breakpoints.up("md")]: {
                width: drawerWidth
            }
        },
        drawerPaper: {
            height: "100vh",
            position: "static",
            [theme.breakpoints.up("md")]: {
                width: drawerWidth
            },
            [theme.breakpoints.down("sm")]: {
                width: drawerMobileWidth
            }
        }
    }
));

export interface IStationInfoMenuProps {
    isMobile: boolean;
    station: BaseStationSammary | null;
    isOpen: boolean;
    onDrawerToggle: (open: boolean) => void;
}
export const StationInfoMenu = (props: IStationInfoMenuProps) => {
    const {station, isOpen, onDrawerToggle: onClose, isMobile} = props;

    const classes = useStyle();
    const theme = useTheme();

    const upperMenu = React.useMemo(() => (<UpperMenu station={station} />), [station]);
    const lowerMenu = React.useMemo(() => (<LowerMenu station={station} />), [station]);

    const mobileModeInfoMenu = React.useMemo(() => {
        const onDrawerToggle = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
            onClose(false);
        };

        return (
            <Drawer
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={isOpen}
                onClose={onDrawerToggle}
                classes={{
                    paper: classes.drawerPaper,
                }}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                <div style={{height: "100%", width: "100%"}}>
                    <div style={{height: "49.9%", overflow: "auto"}}>
                        {upperMenu}
                    </div>
                    <Divider />
                    <div style={{height: "49.9%", overflow: "auto"}}>
                        {lowerMenu}
                    </div>
                </div>
            </Drawer>
        );
    }, [isOpen, classes.drawerPaper, theme.direction]);

    const normalModeInfoMenu = (
        <Drawer
            id="InfoDrawer"
            className={classes.drawer}
            classes={{
                paper: classes.drawerPaper
            }}
            variant="permanent"
            open
        >
            <div style={{height: "100%", width: "100%"}}>
                <div style={{height: "49.9%", overflow: "auto"}}>
                    {upperMenu}
                </div>
                <Divider />
                <div style={{height: "49.9%", overflow: "auto"}}>
                    {lowerMenu}
                </div>
            </div>
        </Drawer>
    );

    return isMobile ? mobileModeInfoMenu : normalModeInfoMenu;
}


interface IUpperMenuProps {
    station: BaseStationSammary | null;
}

const buttonTitles = [
    "出発",
    "入局",
    "退局",
    "帰投"
];

/**
 * メニューの上側
 * @param selectedStationDetail 表示する局
 */
const UpperMenu = (props: IUpperMenuProps) => {
    
    const menu = (content: JSX.Element) => (
        <div style={{overflow: "auto", height: "100%", width: "100%"}}>
            <span className="h4">局情報</span>
            <Divider />
            {content}
        </div>
    );
    
    const {station} = props;
    const [stationDetail, setStationDetail] = React.useState<BaseStationDetail | null>(null);
    const [isLoadingNow, setIsLoadingNow] = React.useState(false);
    const [entryAndExitStep, setEntryAndExitStep] = React.useState<number | null>(null);
    const toNextStep = () => {
        if (entryAndExitStep !== null) {
            setEntryAndExitStep((entryAndExitStep + 1) % 4);
        }
    }

    React.useEffect(() => {
        if (station === null) {
            return;
        }
        const asyncFunc = async () => {
            setIsLoadingNow(true);

            const dispatcher = await changeSelectedStation(station);
            const detail = dispatcher.station;
            setStationDetail(detail);
            if (detail !== null) {
                setEntryAndExitStep(detail.entryAndExitStep % 4);
            }

            Store.dispatch(dispatcher);

            setIsLoadingNow(false);
        };

        asyncFunc();
    }, [station]);

    if (isLoadingNow) {
        return menu(<Spinner />);
    }

    if (station === null || stationDetail === null) {
        return menu(<span>局を選択してください</span>);
    }

    let contents = [
        {title: "計画局番", value: stationDetail.planned.id},
        {title: "計画局名", value: stationDetail.planned.name}
    ];

    if (stationDetail.implemented !== null) {
        contents = [
            ...contents,
            {title: "正式局番", value: stationDetail.implemented.id},
            {title: "正式局名", value: stationDetail.implemented.name}
        ]
    }

    contents = [
        ...contents,
        {title: "住所", value: stationDetail.address},
        {title: "局種別", value: stationDetail.stationType},
        {title: "鉄塔種別", value: stationDetail.towerType ? stationDetail.towerType : "-"},
        {title: "GL", value: `${stationDetail.groundLine}`}
    ];

    const {departureDate, entryDate, exitDate, returnDate} = stationDetail;
    const titleDatePair: [string, string | null][] = [];
    [departureDate, entryDate, exitDate, returnDate].forEach((v, i) => titleDatePair.push([buttonTitles[i], v]));
    
    const buttons: JSX.Element[] = [];
    titleDatePair.forEach((v, i) => buttons.push(
        <EntryAndExitDateButton
            stationID={stationDetail.planned.id}
            step={i}
            title={v[0]}
            date={v[1]}
            disabled={entryAndExitStep !== i}
            onClick={toNextStep}
            key={`${v[0]} ${v[1]}`}
        />
    ));

    return menu(
        <div>
            <div className="container">
                {buttons}
            </div>
            <Divider />
            <List>
                {contents.map((c) => (
                    <React.Fragment key={c.title}>
                        <ListItemText
                            primary={<span style={{fontSize: "0.7em"}} className="text-muted">{c.title}</span>}
                            secondary={<span style={{color: "black"}}>{c.value}</span>}
                        />
                        <Divider variant="middle" component="li" light={true} />
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
}

interface IEntryAndExitDateButtonProps {
    stationID: string;
    title: string;
    step: number;
    date: string | null;
    disabled: boolean;
    onClick: () => void;
}
const EntryAndExitDateButton = (props: IEntryAndExitDateButtonProps) => {
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [dateHistory, setDateHistory] = React.useState(props.date);

    const {stationID, step} = props;

    React.useEffect(() => {
        if (isUpdating === false) {
            return;
        }

        const asyncFunc = async () => {
            const date = await updateEntryAndExitHistory(stationID, step);
            if (date) {
                setDateHistory(date);
            }
            setIsUpdating(false);
        }

        asyncFunc();
    }, [isUpdating, stationID, step]);

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsUpdating(true);
        props.onClick();
    }

    const zeroPaddingStr = (num: number) => `0${num}`.slice(-2);
    const toDateString = (dateStr: string | null) => {
        if (dateStr === null) {
            return "-";
        }

        const date = new Date(dateStr);
        return `${zeroPaddingStr(date.getMonth() + 1)}/${zeroPaddingStr(date.getDate())} ${zeroPaddingStr(date.getHours())}:${zeroPaddingStr(date.getMinutes())}`;
    }

    return (
        <div className="row align-items-center">
            <Button variant="outlined" className="p-0" disabled={isUpdating || props.disabled} onClick={onClick} >{props.title}</Button>
            {isUpdating ? <LinearProgress style={{width: "50%", height: "0.2em"}} /> : <span>{toDateString(dateHistory)}</span>}
        </div>
    );
}


interface IProjectCardProps extends RouteComponentProps {
    project: ProjectForBaseStaion;
}
const cardStyles = makeStyles((theme: Theme) => 
    createStyles({
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          },
          expandOpen: {
            transform: 'rotate(180deg)',
          }
    })
);

const projectActions = (project: ProjectForBaseStaion) => new Map([
    ["DO", {
        enable: false, //project.enableDerivaliyOrderAction,
        linkTo: (stationId: string, projectId: number) => `/next/books/?stationId=${stationId}&projectId=${projectId}&class=${project.detailClassificationCode}&action=DerivaliyOrder`
    }],
    ["撤去", {
        enable: false, //project.enableClearingAction,
        linkTo: (stationId: string, projectId: number) => `/next/books/?stationId=${stationId}&projectId=${projectId}&class=${project.detailClassificationCode}&action=Clearing`
    }],
    ["返品", {
        enable: false, //project.enableReturnsAction,
        linkTo: (stationId: string, projectId: number) => `/next/books/?stationId=${stationId}&projectId=${projectId}&class=${project.detailClassificationCode}&action=Returns`
    }],
    ["図書", {
        enable: true, //project.enableBooksAction,
        linkTo: (stationId: string, projectId: number) => `/next/books/?stationId=${stationId}&projectId=${projectId}&class=${project.detailClassificationCode}&action=Books`
    }],
    ["進捗", {
        enable: false, //project.enableProgressAction,
        linkTo: (stationId: string, projectId: number) => `/books/?stationId=${stationId}&projectId=${projectId}&class=${project.detailClassificationCode}&action=Progress`
    }],
    ["工事", {
        enable: false, //project.enableConstructionAction,
        linkTo: (stationId: string, projectId: number) => `/books/?stationId=${stationId}&projectId=${projectId}&class=${project.detailClassificationCode}&action=Construction`
    }]
]);

/**
 * 案件ごとのカード
 * @param props 案件情報
 */
const ProjectCard = withRouter((props: IProjectCardProps) => {
    const classes = cardStyles();
    const [isExpand, setIsExpand] = React.useState(false);
    const handleExpandClick = () => {
        setIsExpand(!isExpand);
    };

    const {project} = props;

    const buttons: JSX.Element[] = [];

    const map = projectActions(project);
    map.forEach((value, key) => {
        const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => props.history.push(value.linkTo(project.stationPlannedID, project.id));
        buttons.push(<Button variant="outlined" disabled={!value.enable} onClick={onClick} key={key}>{key}</Button>);
    });
      
    return (
        <Card>
            <CardHeader title={project.detailClassificationCode} titleTypographyProps={{variant: "body1"}} style={{maxHeight: "1.5em"}} />
            <CardContent style={{padding: "0.5em"}} className="text-center">
                {project.detailClassification}
            </CardContent>
            <CardActions disableSpacing style={{padding: 0}}>
                <IconButton 
                    size="small"
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: isExpand
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={isExpand}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
                
            </CardActions>
            <Collapse in={isExpand} timeout="auto" unmountOnExit>
                <CardContent>
                    <div className="row">
                        {buttons.slice(0,2)}
                    </div>
                    <div className="row">
                        {buttons.slice(2, 4)}
                    </div>
                    <div className="row">
                        {buttons.slice(4)}
                    </div>
                </CardContent>
            </Collapse>
        </Card>
    );
});


interface ILowerMenuProps {
    station: BaseStationSammary | null;
}
/**
 * メニューの下側
 * @param selectedStationDetail 表示する局
 */
const LowerMenu = (props: ILowerMenuProps) => {
    const menu = (content: JSX.Element | JSX.Element[]) => (
        <div style={{overflow: "auto", height: "100%", width: "100%"}}>
            <span className="h4">案件情報</span>
            <Divider />
            {content}
        </div>
    );

    const {station} = props;
    const [pickupedProjects, setPickupedProjects] = React.useState<ProjectForBaseStaion[] | null>(null);
    const [isNowLoading, setIsNowLoading] = React.useState(false);

    React.useEffect(() => {
        if (station === null) {
            return;
        }

        const asyncFunc = async () => {
            setIsNowLoading(true);

            const dispatcher = await pickupProjects(station);
            setPickupedProjects(dispatcher.projects);

            Store.dispatch(dispatcher);

            setIsNowLoading(false);
        };

        asyncFunc();
    }, [station]);

    if (isNowLoading) {
        return menu(<Spinner />);
    }

    if (pickupedProjects === null || station === null) {
        return menu(<span>局を選択してください</span>);
    }

    const cards: JSX.Element[] = [];
    for (let i = 0; i < pickupedProjects.length; i++) {
        // keyはuniqueでなければならない。計画局番はunique
        cards.push(<ProjectCard project={pickupedProjects[i]} key={`${pickupedProjects[i].stationPlannedID}: list ${i}th item`}/>);
    }

    return menu(cards);
}