import { Store, Persistor } from '../../store/GlobalState';
import * as LoginState from '../../store/LoginPageState';   // 依存関係的にはよろしくないが、すべてのViewモジュールに依存関係を埋め込むよりはマシ
import { Token } from 'store/Token';
import { reset } from 'actions/types/ActionType';
import { login, LoginResult } from 'actions/types/LoginActionType';
import { AppConfig, Constants } from 'app-env';
import { Mutex, Semaphore } from 'await-semaphore';

export interface IFormData {
    params: {[index: string]: string};
    files: {[index: string]: File};
}

const connectionTimeout = 60000;

class CustomizedFetch {
    public constructor(tokenGetter: () => Promise<string | null>) {
        this.tokenGetter = tokenGetter;
    }
    private tokenGetter: () => Promise<string | null>;

    private expireLogout = () => {
        console.log("token expired");

        Token.instance.deleteToken();
        Persistor.purge();
        const {loginPage} = Store.getState();
        Store.dispatch({type: reset});

        const defaultData: LoginResult = {type: login, id: "", password: "", accessKey: loginPage.accessKey, result: false, message: "認証期限切れです"};
        Store.dispatch(defaultData)
        window.location.reload();
    }

    private static mutex = new Mutex();
    private static tokenLastUpdated: Date | null = null;
    private updateToken = async () => {
        const release = await CustomizedFetch.mutex.acquire();

        try {
            const now = new Date();
            
            // 前回更新時から一分経過していなければなにもしない
            if (CustomizedFetch.tokenLastUpdated !== null && (now.getTime() - CustomizedFetch.tokenLastUpdated.getTime()) < 60000) {
                return;
            }
            CustomizedFetch.tokenLastUpdated = now;
            
            const token = await this.tokenGetter();
            if (token) {
                Token.instance.token = token;
                console.log("token updated");
            }
        }
        finally {
            release();
        }
    }


    private static apiSemaphore = new Semaphore(Constants.ApiSemaphoreLimit);
    public rawFetch = async (input: RequestInfo, init?: RequestInit | undefined, timeout?: "infinite" | number): Promise<Response> => {
        const promise = fetch(input, init);

            if (timeout === "infinite") {
                return promise;
            }

            const actualTimeout = timeout ? timeout : connectionTimeout;

            return new Promise((resolve, reject) => {
                setTimeout(() => reject(new Error("timeout")), actualTimeout);
                promise.then(resolve, reject);
            });
    }

    
    private noAuthHeader = { "Content-Type": "application/json; charset=utf-8" };
    public async fetch(input: RequestInfo, init: RequestInit, timeout?: "infinite" | number, noAuth?: boolean): Promise<Response> {
        const headers = noAuth ? this.noAuthHeader : { ...this.noAuthHeader, 'Authorization': `Bearer ${Token.instance.token}` };

        const authedInit: RequestInit = {...init, headers};

        let response: Response;
        const release = await CustomizedFetch.apiSemaphore.acquire();
        try {
            response = await this.rawFetch(input, authedInit, timeout);
        }
        finally {
            release();
        }

        if (response.status === 401) {
            this.expireLogout();
        }
        else if (response.ok) {
            if (!noAuth) {
                this.updateToken();
            }
        }

        return response;
    }
}

export class HttpRequest {
    constructor(baseurl: string) {
        this.url = baseurl;
        this.loginInfo = Store.getState().loginPage;

        const tokenGetter = async (): Promise<string | null> => {
            const request = new HttpRequest(AppConfig.serverURL);
            const response = await request.postAsync("Authorize", {
                id: this.loginInfo.id,
                password: this.loginInfo.password,
                accessKey: this.loginInfo.accessKey
            }, connectionTimeout, false, true);

            if (!response || !response.ok) {
                return null;
            }

            const responseData = await response.json();
            if (!responseData.result || !responseData.token || !responseData.expire) {
                return null;
            }

            return responseData.token;
        }

        this.connection = new CustomizedFetch(tokenGetter);
    }
    
    private connection: CustomizedFetch;

    private combineIdentifyData = (data: any): IQueryParams => ({id: this.loginInfo.id, userName: this.loginInfo.userName, accessKey: this.loginInfo.accessKey, appVersion: AppConfig.appVersion, ...data});

    private url: string;
    
    private loginInfo: LoginState.State;

    public async postAsync(api: string, data: any, timeout?: "infinite" | number, identify = true, noAuth = false) {
        const postData = identify ? this.combineIdentifyData(data) : data;

        let response: Response;
        try {
            response = await this.connection.fetch(`${this.url}/${api}`, {
                method: "post",
                body: JSON.stringify(postData)
            }, timeout, noAuth);
        }
        catch {
            return undefined;
        }
        
        return response;
    }

    public async postFormDataAsync(api: string, data: IFormData, timeout?: "infinite" | number) {
        const headers = { 'Authorization': `Bearer ${Token.instance.token}` };  // multipart/form-dataのときにContent-Typeを指定するとboundaryが付かないらしい(未定義動作っぽい)

        const formData = new FormData();
        Object.keys(data.params).forEach((key) => {
            formData.append(key, data.params[key]);
        });

        formData.append("id", this.loginInfo.id);
        formData.append("accessKey", this.loginInfo.accessKey);
        formData.append("userName", this.loginInfo.userName ? this.loginInfo.userName : "");

        Object.keys(data.files).forEach((key) => {
            formData.append(key, data.files[key], data.files[key].name);
        });

        let response: Response;
        try {
            response = await this.connection.rawFetch(`${this.url}/${api}`, {
                method: "post",
                body: formData,
                headers
            }, timeout);
        }
        catch {
            return undefined;
        }

        return response;
    }

    public async putAsync(api: string, data: any, timeout?: "infinite" | number, identify = true, noAuth = false) {

        const postData = identify ? this.combineIdentifyData(data) : data;

        let response: Response;
        try {
            response = await this.connection.fetch(`${this.url}/${api}`, {
                method: "put",
                body: JSON.stringify(postData),
            }, timeout, noAuth);
        }
        catch {
            return undefined;
        }

        return response;
    }

    public async getAsync(api: string, timeout?: "infinite" | number, noAuth = false) {

        const queryData = this.combineIdentifyData({});
        const queryString = this.queryStringBuilder(queryData);

        let response: Response;
        try {
            response = await this.connection.fetch(`${this.url}/${api}/${queryString}`, {
                method: "get",
            }, timeout, noAuth);
        }
        catch {
            return undefined;
        }

        return response;
    }

    private queryStringBuilder(data: IQueryParams) {
        let queryString = "?";
        Object.keys(data).forEach((key) => {
            queryString = queryString + this.objectToQuery(key, data[key]);
        });

        return queryString.slice(0, -1);
    }
    private isObject = (obj: unknown): obj is object => obj && (typeof obj === 'object' || typeof obj === 'function');
    private objectToQuery(key: string, obj: unknown): string {
        if (obj === undefined) {
            return "";
        }
        if (obj === null) {
            return `${key}=&`;
        }

        if (!this.isObject(obj)) {
            // 再帰終了
            if (typeof obj === "string") {
                return `${key}=${encodeURIComponent(obj)}&`;  // urlエンコードしておく
            }
            else {
                return `${key}=${obj}&`;   
            } 
        }
        if (obj instanceof Date) {
            return `${key}=${obj.toISOString()}&`;   // 再帰終了
        }

        let query = "";

        if (Array.isArray(obj)) {
            // 配列なら同じキーで並べる or 再帰

            for (const val of obj) {
                if (this.isObject(val)) {
                    query = query + this.objectToQuery(key, val);
                }
                else {
                    query = query + `${key}=${val}&`;
                }
            }
        }
        else {
            // objectかつ配列でない

            Object.keys(obj).forEach((objKey) => {
                query = query + this.objectToQuery(`${key}.${objKey}`, obj[objKey]);    // ネストオブジェクトは{objectName}.{propertyName}でASPに認識される
            });
        }

        return query;
    }

    public async getWithQueryAsync(api: string, param: IQueryParams, timeout?: "infinite" | number, identify = true, noAuth = false) {

        const queryData = identify ? this.combineIdentifyData(param) : param;

        const queryString = this.queryStringBuilder(queryData);
        
        let response: Response;
        try {
            response = await this.connection.fetch(`${this.url}/${api}/${queryString}`, {
                method: "get",
            }, timeout, noAuth);
        }
        catch {
            return undefined;
        }

        return response;
    }

    public async deleteAsync(api: string, param: IQueryParams, timeout?: "infinite" | number, identify = true, noAuth = false) {

        const queryData = identify ? this.combineIdentifyData(param) : param;

        const queryString = this.queryStringBuilder(queryData);

        let response: Response;
        try {
            response = await this.connection.fetch(`${this.url}/${api}/${queryString}`, {
                method: "delete",
            }, timeout, noAuth);
        }
        catch {
            return undefined;
        }

        return response;
    }
}

export interface IQueryParams {
    [index: string]: (string | number | string[] | number[] | Date | Date[] | null | undefined);
}