/**
 * 開発環境/本番環境を判定して定数を生成する。
 * JSコードなのでクライアント側で改ざん可能。
 * セキュリティ上問題になるデータはここに書き込まないこと。
 */


const isLocalDebugMode = (): boolean => {
    var url = window.location.href;
    var serverRootRegex = /^https:\/\/localhost.*$/;

    var isDebug = serverRootRegex.test(url);
    if (isDebug) {
        console.warn("is debug mode");
    }

    return isDebug;
}

export const AppConfig = ((isDebug: boolean) => (
    {
        serverURL: `https://${window.location.host}/api`,
        appVersion: "2.001.00002" // <メジャー>.<マイナー>.<ビルド番号>
    }
))(isLocalDebugMode());

export const Constants = ((isDebug: boolean) => (
    {
        isIE: (window.navigator.userAgent.toLocaleLowerCase().indexOf("msie") !== -1 || window.navigator.userAgent.toLocaleLowerCase().indexOf("trident") !== -1),
        materialUiZIndex: {
            mobileStepper: 1000,
            speedDial: 1050,
            appBar: 1100,
            drawer: 1200,
            modal: 1300,
            snackbar: 1400,
            tooltip: 1500
        },
        LocationSearchRadius: 1000,
        ApiSemaphoreLimit: isDebug ? 10 : 20,
        ro: {
            warehouseNameMap: new Map<number, string>([
                [0, "現地産廃のみ"],
                [1, "岩槻"],
                [2, "八王子"]
            ]),
            processStatusNameMap: new Map<number, string>([
                [0, "受付済"],
                [1, "確認済"],
                [2, "DL済"],
                [3, "一部入庫済"],
                [4, "入庫済"],
                [5, "完了済"]
            ])
        }
    }
))(isLocalDebugMode());