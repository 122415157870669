import { ActionType } from '../actions/types/ActionType';
import * as Types from '../actions/types/EventTypes';
import * as lodash from 'lodash';
import { GlobalEvent } from 'types/GlobalEvent';

export const EventRegisterReducer = (prevState: GlobalEvent | undefined, action: ActionType): GlobalEvent => {
    if (prevState === undefined) {
        return new GlobalEvent();
    }

    if (action.type !== Types.registerEvent) {
        return prevState;
    }

    const registerAction = action as Types.RegisterEventAction;
    const newState = lodash.cloneDeep(prevState);

    const {event, id, listener} = registerAction;

    if (registerAction.remove) {
        newState.remove(event, id);
    }
    else {
        if (listener) {
            newState.register(event, id, listener);
        } 
    }

    return newState;
}