import * as React from 'react';
import { IDetailData, getDetailDataAsync, exportReturnSlipAsync, updateSlipNumberAsync, IDetailItemData, updateProcessStatusAsync, getProcessStatusAsync, resolveAcceptedNumber, StoringStatus } from 'actions/RO/RODetailAction';
import { makeStyles, useTheme, Theme, createStyles, duration } from '@material-ui/core/styles';
import { Spinner } from 'reactstrap';
import { Divider, TextField, Button, IconButton, Tooltip, Table, TableHead, TableCell, TableRow, TableBody, Checkbox, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DownloadButton } from 'components/DownloadButton';
import { toDateString } from 'actions/utils/StringFormatter';
import * as QueryString from 'query-string';
import { RouteComponentProps } from 'react-router';
import { BackButton } from 'components/BackButton';
import { roItemDetailPath } from './ROItemDetail';
import { Store } from 'store/GlobalState';
import { routePush } from 'actions/utils/History';
import { cacheFlagsClear } from 'actions/types/CacheFlagsActionType';
import { roListPath } from './ROList';

export const roDetailPath = '/ro/detail';

const textAlignmentStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            flexWrap: "nowrap",
            [theme.breakpoints.down('sm')]: {
                flexWrap: "wrap"
            }
        },
        grid: {
            width: "70%",
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        },
        headerBorder: {
            [theme.breakpoints.up("sm")]: {
                borderBottom: "medium solid #C0C0C0"
            }
        },
        contentBorder: {
            borderBottom: "medium solid #C0C0C0",
            whiteSpace: "pre-wrap"
        }
    })
);
const tableStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemColor: {
            backgroundColor: "white"
        },
        showOnlyItemColor: {
            backgroundColor: "gainsboro"
        },
        pendingItem: {
            backgroundColor: "red",
            cursor: "pointer"
        }
    })
);

const tableHeaders = [
    "項番",
    "撤去設備番号",
    "設備カテゴリ",
    "撤去設備名",
    "撤去区分",
    "受付数量",
    "枝番",
    "単位",
    "重量",
    "送付指示_処理区分",
    "状態",
    "入庫日時"
];
const isShowOnlyItem = (item: IDetailItemData): boolean => {
    return item.treatment !== "UQ倉庫へ送付";
}
const slipNumberRegex = /^[!-~]*$/u;
const processStatusStringMap = new Map<number, string>([
    [0, "受付済"],
    [1, "確認済"],
    [2, "DL済"],
    [3, "完了"]
]);

const queryParamParse = async (parser: QueryString.ParsedQuery<string>): Promise<({ accepted: string } | null)> => {
    const { accepted, id } = parser;
    if (typeof accepted === "string") {
        return {
            accepted
        };
    }
    else if (typeof id === "string") {
        const targetId = parseInt(id, 10);
        if (!isNaN(targetId)) {
            const result = await resolveAcceptedNumber(targetId);
            if (result) {
                return {
                    accepted: result
                };
            }
        }
    }

    return null;
}

const recycleHighWarehouse = "岩槻倉庫";
const recycleLowWarehouse = "八王子倉庫";
const recycleNone = "送付なし";
export const RODetail = (props: RouteComponentProps) => {
    const [detailData, setDetailData] = React.useState<IDetailData | null>(null);
    const [updateSlipNumberTask, setUpdateSlipNumberTask] = React.useState<Promise<ResultWithMessage> | null>(null);
    const [slipNumber, setSlipNumber] = React.useState("");
    const [isExportable, setIsExportable] = React.useState(false);
    const [processStatus, setProcessStatus] = React.useState<number | null>(null);
    const [accepted, setAccepted] = React.useState("");

    // 送り状番号更新
    React.useEffect(() => {
        if (!updateSlipNumberTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!updateSlipNumberTask) {
                return;
            }

            const result = await updateSlipNumberTask;
            if (!result.result) {
                alert(result.message);
                setIsExportable(false);
            }
            else {
                setIsExportable(true);
            }

            if (!isUnmounted) {
                setUpdateSlipNumberTask(null);
            }
        }

        asyncFunc();

        return () => { isUnmounted = false; };
    }, [updateSlipNumberTask]);


    // 初回読み込み
    React.useEffect(() => {
        let isUnmounted = false;
        const asyncFunc = async () => {
            const queryParam = await queryParamParse(QueryString.parse(props.location.search));
            if (!queryParam) {
                return;
            }

            const result = await getDetailDataAsync(queryParam.accepted);
            if (!isUnmounted) {
                setAccepted(queryParam.accepted);

                if (result) {
                    const roles = Store.getState().loginPage.roles;
                    if (roles && roles.indexOf("ROUser_General") > -1) {
                        const updateResult = await updateProcessStatusAsync(queryParam.accepted, 1);

                        if (updateResult) {
                            const status = await getProcessStatusAsync(queryParam.accepted);

                            if (isUnmounted) {
                                return;
                            }
                            if (status !== null) {
                                setProcessStatus(status);
                            }

                            if (updateResult.after > updateResult.before) {
                                // 更新が実施された
                                Store.dispatch({ type: cacheFlagsClear });
                            }

                            setDetailData(result);

                            if (result.slipNumber) {
                                setSlipNumber(result.slipNumber);
                                setIsExportable(true);
                            }
                        }
                    }
                    else {
                        const status = await getProcessStatusAsync(queryParam.accepted);

                        if (isUnmounted) {
                            return;
                        }
                        if (status !== null) {
                            setProcessStatus(status);
                        }


                        setDetailData(result);

                        if (result.slipNumber) {
                            setSlipNumber(result.slipNumber);
                            setIsExportable(true);
                        }
                    }
                }
            }
        }

        asyncFunc();

        return () => { isUnmounted = true; };
    }, []);

    const commonDataAlignmentStyles = textAlignmentStyles();

    console.log(`number=${accepted}`);

    const onSlipNumberUpdateClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setUpdateSlipNumberTask(updateSlipNumberAsync(accepted, slipNumber));
    }

    const onSlipNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.target.value;
        if (current.match(slipNumberRegex)) {
            setSlipNumber(current);
        }
    }

    let pageContent = <Spinner />;
    if (detailData && processStatus !== null) {
        const downloadAction = async (): Promise<{ downloadUrl: string, filename: string } | null | undefined> => {
            const response = await exportReturnSlipAsync(accepted);
            if (!response) {
                return response;
            }

            const roles = Store.getState().loginPage.roles;
            if (roles && roles.indexOf("ROUser_General") > -1) {
                const updateResult = await updateProcessStatusAsync(accepted, 2);
                if (!updateResult) {
                    return null;
                }

                if (updateResult.after > updateResult.before) {
                    // 更新が実施された
                    Store.dispatch({ type: cacheFlagsClear });
                    setProcessStatus(2);
                }
            }

            return response;
        }

        pageContent = (
            <div className="mx-auto p-3">
                <div className={`d-flex align-items-end ${commonDataAlignmentStyles.body}`}>
                    <fieldset className={`border border-primary mr-3 ${commonDataAlignmentStyles.grid}`}>
                        <legend className="w-auto h6">局情報</legend>
                        <div className="m-2">
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>受付番号(版)</Grid>
                                        <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{`${detailData.acceptedNumber}(${detailData.loadedVersion})`}</Grid>
                                        <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>局番</Grid>
                                        <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{detailData.stationNumber}</Grid>
                                        <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>局名</Grid>
                                        <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{detailData.stationName}</Grid>
                                        <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>エリア</Grid>
                                        <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{detailData.area}</Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>元請会社</Grid>
                                        <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{detailData.primaryContractor}</Grid>
                                        <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>案件小G</Grid>
                                        <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{`${detailData.projectDetailGroupCode} : ${detailData.projectDetailGroupName}`}</Grid>
                                        <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>提出日</Grid>
                                        <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{detailData.submitDateString}</Grid>
                                        <Grid item xs={12} sm={4} className={commonDataAlignmentStyles.headerBorder}>状態</Grid>
                                        <Grid item xs={12} sm={8} className={commonDataAlignmentStyles.contentBorder}>{processStatusStringMap.get(processStatus)}</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </fieldset>
                    <div className={`mt-3 ${detailData.warehouse === "" ? "" : "ml-auto"}`}>
                        <span className="d-block h5">{`宛先: ${(detailData.warehouse === "" ? recycleNone : (`${detailData.warehouse}倉庫`))}`}</span>
                        {detailData.warehouse === "" ? <React.Fragment></React.Fragment> : (
                            <div className="d-inline-flex align-items-baseline">
                                <TextField label="送り状番号" value={slipNumber} onChange={onSlipNumberChange} />
                                {!updateSlipNumberTask ? <Button className="ml-2 mr-2" variant="outlined" onClick={onSlipNumberUpdateClick} disabled={!slipNumber}>更新</Button> : <Spinner className="ml-2 mr-2" />}
                                <DownloadButton
                                    urlGetter={downloadAction}
                                    disabled={!isExportable}
                                    variant="outlined"
                                >
                                    返品伝票
                                </DownloadButton>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-100 mt-3 pr-5 text-right"><div className="d-inline-block">{`全 ${detailData.itemDataList.length ?? 0}件`}</div></div>
                <div className="row">
                    <div className="w-100 border" style={{ overflowX: "auto" }}>
                        <DetailTable dataList={detailData.itemDataList} accepted={accepted} />
                    </div>
                </div>
            </div>
        )
    }

    const roles = Store.getState().loginPage.roles;
    const isWarehouse = (roles && roles.indexOf("ROUser_Warehouse") > -1) ?? false;
    const onBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Store.dispatch(routePush(roListPath));

    return (
        <div>
            <div className="d-flex align-items-center">
                <Button
                    disabled={isWarehouse}
                    variant="outlined"
                    style={{ visibility: isWarehouse ? "collapse" : "visible" }}
                    className="mr-3"
                    onClick={onBackButtonClick}
                >
                    一覧
                </Button>
                <span className="h4 mb-0">詳細</span>
            </div>
            {pageContent}
        </div>
    )
}

const storingStatusToStringMap = new Map<number, string>([
    [StoringStatus.NoCheck, "未受領"],
    [StoringStatus.Receipted, "受領済"],
    [StoringStatus.Stored, "入庫済"],
    [StoringStatus.Shipped, "出庫済"]
]);
interface IDetailTableProps {
    accepted: string;
    dataList: IDetailItemData[];
}
const DetailTable = (props: IDetailTableProps) => {
    const [isMouseMove, setIsMouseMove] = React.useState(false);
    const onMouseDown = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(false);
    const onMouseMove = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(true);

    const classes = tableStyles();

    const { dataList, accepted } = props;

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {tableHeaders.map(h => <TableCell key={h} style={{ backgroundColor: "#8895dc" }}><span className="text-nowrap">{h}</span></TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {dataList.map((row, rowIndex) => {
                    const onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                        if (isMouseMove) {
                            event.preventDefault();
                            return;
                        }

                        Store.dispatch(routePush(`${roItemDetailPath}/?accepted=${accepted}&item=${row.itemNumber}&branch=${row.acceptedCountBranch}`));
                    };

                    const isShowOnly = isShowOnlyItem(row);

                    let rowColoring: string;
                    let fontColoring = "text-nowrap";
                    let clickEventListener: ((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void) | undefined = undefined;
                    let pointerStyle: React.CSSProperties | undefined = undefined;
                    if (row.isPending) {
                        rowColoring = classes.pendingItem;
                        clickEventListener = onRowClick;
                    }
                    else if (isShowOnly) {
                        rowColoring = classes.showOnlyItemColor;
                    }
                    else {
                        rowColoring = classes.itemColor;
                        clickEventListener = onRowClick;
                        pointerStyle = { cursor: "pointer" };

                        if (row.storingStatus >= StoringStatus.Stored) {
                            fontColoring = `${fontColoring} text-primary`;
                        }
                    }

                    return (
                        <TableRow hover key={`data list of ${rowIndex}th row`} className={rowColoring} onClick={clickEventListener} style={pointerStyle}>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{rowIndex + 1}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.facilityNumber}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.facilityCategory}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.facilityName}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.recycleType}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.acceptedCount}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.acceptedCountBranch}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.unit}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.weight}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.treatment}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{isShowOnly ? "-" : (row.isPending ? "保留" : storingStatusToStringMap.get(row.storingStatus))}</span></TableCell>
                            <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.storedDateString}</span></TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    );
}