import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Action from 'actions/Next/HomeAction';
import { BaseStationsOnGoogleMap } from 'components/GoogleMap';
import { Spinner } from 'reactstrap';
import { Theme, Drawer, Divider, Card, CardHeader, CardContent, Collapse, CardActions, IconButton, Button, LinearProgress, List, ListItemText, ListItem, Grid, GridListTile, Hidden, GridList, Typography, Fab } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getLocationAsync } from 'actions/utils/LocationUtil';
import { BaseStationSammary } from 'types/StationTypes';
import { Store } from 'store/GlobalState';
import { Constants } from 'app-env';
import { StationCardListView } from 'components/StationCardListView';
import { StationInfoMenu } from 'components/StationInfoMenu';
import { ProjectListView } from 'components/ProjectListView';
import { registerEvent, removeEvent } from 'actions/EventRegisterAction';
import { Role, RoleFilter } from 'components/Role';

const defaultCurrentLocation: google.maps.LatLngLiteral = {lat: 35.681236, lng: 139.767125};  // 東京駅

const roles = [Role.NextUser];

export const NextHomePath = '/next';

/**
 * ページの構造定義
 */
export const Home: React.FunctionComponent = () => {
    React.useEffect(() => {
        const asyncFunc = async () => {
            const secureData = await Action.getSecureData();
            Store.dispatch(secureData);
        }
        asyncFunc();
    }, []);

    return (
        <RoleFilter accessableRoles={roles}>
            <PageContent />
        </RoleFilter>
    );
};


// マップ

const mapStyles = makeStyles((theme: Theme) => 
    createStyles({
        content: {
            width: "100%",
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${2 * theme.spacing(3)}px)`,  // 上下のマージン分
        },
        map: {
            width: "100%",
            height: "100%",
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        },
        mapOnMobile: {
            width: "100%",
            height: "100%",
            [theme.breakpoints.up("md")]: {
                display: "none"
            }
        }
    })
);

enum StationViewMode {
    MAP = "MAP",
    CardView = "一覧",
    ProjectView = "詳細"
}


/**
 * マップとメニュー
 * @param props state, dispatcher
 */
const PageContent: React.FunctionComponent = () => {

    const classes = mapStyles();

    const [isOpen, setIsOpen] = React.useState(false);
    const [forcusLocation, setForcusLocation] = React.useState<google.maps.LatLngLiteral | null>(null);
    const [isLocationSearching, setIsLocationSearching] = React.useState(false);
    const [stationViewMode, setStationViewMode] = React.useState(StationViewMode.MAP);

    React.useEffect(() => {
        const asyncFunc = async () => {
            try {
                const position = await getLocationAsync();
    
                console.log(`current location loaded latitude=${position.coords.latitude}, longitude=${position.coords.longitude}`);
    
                const location: google.maps.LatLngLiteral = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
    
                setForcusLocation(location);
            }
            catch (err) {
                // ブラウザ側で位置情報取得を拒否されたらここにくる
                console.log(`Cannot get current location. Subsutitute with Tokyo station location (latitude: ${defaultCurrentLocation.lat}, longitude: ${defaultCurrentLocation.lng})`);
    
                setForcusLocation(defaultCurrentLocation);
            }
        };

        asyncFunc();
    }, []);

    React.useEffect(() => {
        const action = registerEvent("on station filtered", () => setStationViewMode(StationViewMode.MAP));
        Store.dispatch(action);
        return () => {
            Store.dispatch(removeEvent("on station filtered", action.id));
        }
    }, []);

    React.useEffect(() => {
        if (forcusLocation === null) {
            return;
        }

        const asyncFunc = async () => {
            setIsLocationSearching(true);
            const baseStations = await Action.SearchBaseStationsByLocation(forcusLocation, Constants.LocationSearchRadius);
            Store.dispatch(baseStations);
            setIsLocationSearching(false);
        };
        asyncFunc();
    }, [forcusLocation]);

    const [infoWindowClickedStation, setInfoWindowClickedStation] = React.useState<BaseStationSammary | null>(null);

    const onLocationChanged = React.useMemo(() => (location: google.maps.LatLngLiteral) => {
        setForcusLocation(location);
    }, [setForcusLocation]);

    const onMobileModeItemClicked = React.useMemo(() => (station: BaseStationSammary) => {
        setInfoWindowClickedStation(station);
        setIsOpen(true);
    }, [setInfoWindowClickedStation, setIsOpen]);

    const onItemClicked = React.useMemo(() => (station: BaseStationSammary) => {
        setInfoWindowClickedStation(station);
    }, [setInfoWindowClickedStation]);

    let loadingBar = <div style={{width: "100%", height: "0.2rem"}} />;
    if (isLocationSearching) {
        loadingBar = <LinearProgress style={{width: "100%", height: "0.2rem"}} />;
    }

    // Hidden APIを使いたいけどスタイル指定できないのでメディアクエリで対応
    return (
        <div className={classes.content} id="HomeContent">
            {loadingBar}
            <div className={classes.mapOnMobile}>
                <ViewArea
                    stationViewMode={stationViewMode}
                    forcusLocation={forcusLocation}
                    onItemClick={onMobileModeItemClicked}
                    onLocationChanged={onLocationChanged}
                    onStationViewModeChange={setStationViewMode}
                >
                    <StationInfoMenu
                        isMobile={true}
                        station={infoWindowClickedStation}
                        isOpen={isOpen}
                        onDrawerToggle={setIsOpen}
                    />
                </ViewArea>
            </div>
            <div className={classes.map}>
                <ViewArea
                    stationViewMode={stationViewMode}
                    forcusLocation={forcusLocation}
                    onItemClick={onItemClicked}
                    onLocationChanged={onLocationChanged}
                    onStationViewModeChange={setStationViewMode}
                >
                    <StationInfoMenu
                        isMobile={false}
                        station={infoWindowClickedStation}
                        isOpen={isOpen}
                        onDrawerToggle={setIsOpen}
                    />
                </ViewArea>
            </div>
        </div>
    );
};


interface IViewProps {
    stationViewMode: StationViewMode;
    forcusLocation: google.maps.LatLngLiteral | null;
    onItemClick: (station: BaseStationSammary) => void;
    onLocationChanged: (location: google.maps.LatLngLiteral) => void;
    onStationViewModeChange: (mode: StationViewMode) => void;
}

const ViewArea: React.FunctionComponent<IViewProps> = (props) => {
    const {stationViewMode, forcusLocation, onItemClick, onLocationChanged, onStationViewModeChange, children} = props;
    

    const mapView = React.useMemo(() => (
        <MapView forcusLocation={forcusLocation} onLocationChanged={onLocationChanged} onItemClick={onItemClick} />
    ), [forcusLocation, onLocationChanged, onItemClick]);

    const cardView = React.useMemo(() => (
        <StationCardListView onItemClick={onItemClick} />
    ), [onItemClick]);

    const projectView = React.useMemo(() => (
        <ProjectListView onItemClick={onItemClick} />
    ), [onItemClick]);

    const style: React.CSSProperties = {
        position: stationViewMode === StationViewMode.MAP ? "static" : "relative",
        width: "100%",
        height: "100%",
        display: "inline-flex",
        alignItems: "start"
    };

    
    let view = <span className="text-danger h1">Not support value. Maybe repair it after reload</span>;

    switch (stationViewMode) {
        case StationViewMode.MAP:
            view = mapView;
            break;
        
        case StationViewMode.CardView:
            view = cardView;
            break;

        case StationViewMode.ProjectView:
            view = projectView;
            break;

        default:
            break;
    }
    
    return (
        <div style={style}>
            <div style={{width: "100%", height: "100%", overflowY: "hidden", position: "relative"}}>
                <div className="d-flex">
                    <ModeSwitchButtonSet mode={stationViewMode} onModeChange={onStationViewModeChange} />
                    
                </div>
                {view}
            </div>
            {children}
        </div>
    );
}


interface IModeSwitchButtonSetProps {
    mode: StationViewMode;
    onModeChange: (mode: StationViewMode) => void;
}
const areaAbsoluteModeStyle: React.CSSProperties = {position: "absolute", left: 0, top: 0, zIndex: 1, display: "flex"};
const areaBaseStyle: React.CSSProperties = {display: "flex"};

const ModeSwitchButtonSet: React.StatelessComponent<IModeSwitchButtonSetProps> = (props) => {
    const {mode, onModeChange} = props;

    const areaStyle = mode === StationViewMode.MAP ? areaAbsoluteModeStyle : areaBaseStyle;

    const onSwitchMapButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onModeChange(StationViewMode.MAP);
    const onSwicthCardButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onModeChange(StationViewMode.CardView);
    const onProjectViewButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onModeChange(StationViewMode.ProjectView);

    const buttonStyle = (isShow: boolean): React.CSSProperties => ({width: "auto", backgroundColor: "midnightblue", display: isShow ? "inline" : "none"});

    return (
        <div style={{position: "relative", display: mode === StationViewMode.MAP ? "inline" : "block"}}>
            <div style={areaStyle}>
                <Button variant="contained" onClick={onSwitchMapButtonClick} className="m-1" style={buttonStyle(mode !== StationViewMode.MAP)}><Typography style={{color: "white", whiteSpace: "nowrap"}}>MAP</Typography></Button>
                <Button variant="contained" onClick={onSwicthCardButtonClick} className="m-1" style={buttonStyle(mode !== StationViewMode.CardView)}><Typography style={{color: "white", whiteSpace: "nowrap"}}>一覧</Typography></Button>
                <Button variant="contained" onClick={onProjectViewButtonClick} className="m-1" style={buttonStyle(mode !== StationViewMode.ProjectView)}><Typography style={{color: "white", whiteSpace: "nowrap"}}>詳細</Typography></Button>
            </div>
        </div>
    );
}


interface IMapViewProps {
    forcusLocation: google.maps.LatLngLiteral | null;
    onLocationChanged: (location: google.maps.LatLngLiteral) => void;
    onItemClick: (station: BaseStationSammary) => void;
}
const MapView = (props: IMapViewProps) => {
    if (props.forcusLocation === null) {
        return <Spinner />;
    }

    const onShowStationDetailListenerBuilder = (station: BaseStationSammary) => () => props.onItemClick(station);
    return (
        <BaseStationsOnGoogleMap
            currentLocation={props.forcusLocation}
            onShowStationDetailListenerBuilder={onShowStationDetailListenerBuilder}
            onForcusChanged={props.onLocationChanged}
        />
    );
}