import * as Login from './types/LoginActionType';
import { AppConfig } from '../app-env';
import { HttpRequest } from './utils/HttpRequest';
import { Token } from 'store/Token';
import { NextRootPath } from 'pages/Next/NextApp';
import { martzMixRootPath } from 'pages/MartzMix/MartzMixApp';
import { roRootPath } from 'pages/RO/ROApp';
import { RouteComponentProps } from 'react-router';

const idFormatErrorMsg = "IDには半角英数字またはメールアドレスのみ使用できます";
const passwordFormatErrorMsg = "パスワードには半角英数記号のみ使用できます"
const accessKeyFormatErrorMsg = "アクセスキーの形式ではありません";

const alphabetAndNumberRegex = new RegExp('^[(a-zA-Z0-9)]+$');
const mailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const asciiRegex = new RegExp('^[a-zA-Z0-9!-/:-@¥[-`{-~]+$');
const accessKeyRegex = new RegExp('^[0-9]{12}$');

interface LoginApiResponseData {
    result: boolean;
    code: number | null;
    userName?: string;
    companyName?: string;
    message?: string;
    token?: string;
    roles?: string[];
    expire?: number;
}
const illegalResponseDataMsg = "予期しないデータです";

export const loginAsync = async (id: string, password: string, accessKey: string): Promise<Login.LoginResult> => {

    const part = { type: Login.login, id, password, accessKey };

    const idPatternOK = alphabetAndNumberRegex.test(id) || mailRegex.test(id);
    const passwordPatternOK = asciiRegex.test(password);

    if (!idPatternOK) {
        return {
            ...part,
            result: idPatternOK,
            message: idFormatErrorMsg
        };
    }
    if (!passwordPatternOK) {
        return {
            ...part,
            result: passwordPatternOK,
            message: passwordFormatErrorMsg
        };
    }

    const accessKeyPatternOK = accessKeyRegex.test(accessKey);
    if (!accessKeyPatternOK) {
        return {
            ...part,
            result: accessKeyPatternOK,
            message: accessKeyFormatErrorMsg
        };
    }

    console.log("format check at client : OK");

    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("Authorize", {
        id,
        password,
        accessKey
    }, undefined, false, true);

    if (!response) {
        window.location.reload();
        return {
            ...part,
            result: false,
            message: "タイムアウトしました"
        };
    }
    if (!response.ok) {
        return {
            ...part,
            result: false,
            message: await response.text()
        };
    }

    const responseData: LoginApiResponseData = await response.json();

    if (!responseData.result) {
        return {
            ...part,
            result: responseData.result,
            message: responseData.message
        };
    }

    const {token, expire, roles, companyName} = responseData;

    if (token === undefined || expire === undefined || roles === undefined) {
        throw new Error(illegalResponseDataMsg);
    }

    console.log(`get token result: ${response.status}`);

    Token.instance.token = token;

    return {
        ...part,
        userName: responseData.userName,
        companyName,
        roles,
        result: responseData.result
    };
};

export const jumpToTopPagesByFunction = (routeProps: RouteComponentProps, roles: string[], redirect?: string | undefined, decoded?: undefined | true, reloadPage?: boolean) => {
    const toNextPage = (url: string) => {
        if (reloadPage) {
            window.location.href = url;
        }
        else {
            routeProps.history.push(url);
        }
    }

    if (redirect) {
        const preUrl = decodeURIComponent(redirect);
        let url: string;
        if (!decoded) {
            url = atob(preUrl);
        }
        else {
            url = preUrl;
        }

        console.log(`redirect = ${url}`);

        toNextPage(`/${url}`);
    }
    else if (roles.indexOf("NextUser") > -1) {
        toNextPage(NextRootPath);
    }
    else if (roles.indexOf("MartzMixUser") > -1) {
        toNextPage(martzMixRootPath);
    }
    else if (roles.indexOf("ROUser") > -1)
    {
        toNextPage(roRootPath);
    }
}

export const checkIDFormat = (currentID: string): Login.IDFormatValidate => {
    let isValid = currentID.length === 0;
    isValid = isValid || alphabetAndNumberRegex.test(currentID) || mailRegex.test(currentID);

    let msg: string | undefined;
    if (!isValid) {
        msg = idFormatErrorMsg;
    }

    return {
        type: Login.checkID,
        id: currentID,
        ok: isValid,
        message: msg
    };
};

export const checkPasswordFormat = (currentPassword: string): Login.PasswordFormatValidate => {
    let isValid = currentPassword.length === 0;
    isValid = isValid || asciiRegex.test(currentPassword);

    let msg: string | undefined;
    if (!isValid) {
        msg = passwordFormatErrorMsg;
    }

    return {
        type: Login.checkPassword,
        password: currentPassword,
        ok: isValid,
        message: msg
    };
};

export const checkAccessKeyFormat = (currentAccessKey: string): Login.AccessKeyFormatValidate => {
    const isValid = accessKeyRegex.test(currentAccessKey);

    let msg: string | undefined = undefined;
    if (!isValid) {
        msg = accessKeyFormatErrorMsg;
    }

    return {
        type: Login.checkAccessKey,
        accessKey: currentAccessKey,
        ok: isValid,
        message: msg
    };
}

export const getAppVersion = async (): Promise<string> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getAsync("AppInfo", 60000, true);

    if (!response || !response.ok) {
        return "0.000.00000";
    }

    const responseData = await response.json();
    return responseData.appVersion;
}

export const getGeneratedUserId = async (): Promise<string | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getAsync("Authorize/user/register/temporary/id");

    if (!response) {
        alert("サーバーからの応答がありません");
        return null;
    }
    else if (!response.ok) {
        const msg = await response.text();
        alert(`${msg} code=${response.status}`);
        console.error(msg);
        return null;
    }

    const json = await response.json();
    return json.userID;
}

export const registerTemporaryUser = async (  mailAddress: string, temporaryPass: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("Authorize/user/register/temporary", {mailAddress, temporaryPass});

    if (!response) {
        alert("サーバーの応答がありません");
        return false;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}

export const getTemporaryRegisteredUserId = async (resourceID: number): Promise<string | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync(
        "Authorize/user/register/temporary",
        {resourceID},
        undefined,
        false,
        true
    );

    if (!response) {
        alert("サーバーの応答がありません");
        console.error("not responsed (/api/Authorize/user/register/temporary)");
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    const {userId} = await response.json();

    return userId;
}

export const registerUser = async (
    resourceID: number,
    token: string,
    userID: string,
    temporaryPass: string,
    password: string,
    accessKey: string,
    firstName: string,
    howToReadFirstName: string,
    lastName: string,
    howToReadLastName: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync(
        "Authorize/user/register",
        {
            resourceID,
            token,
            userID,
            temporaryPass,
            password,
            accessKey,
            firstName,
            howToReadFirstName,
            lastName,
            howToReadLastName
        },
        undefined,
        false,
        true
    );

    if (!response) {
        alert("サーバーの応答がありません");
        console.error("not responsed (/api/Authorize/register)");
        return false;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }


    return true;
}

export interface IPublishableUserData {
    userId: string;
    name: string;
    lastLoginDate: Date | null;
    accountExpireDate: Date | null;
    enableNotification: boolean;
}
export const getUsers = async (): Promise<IPublishableUserData[] | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getAsync("Authorize/user/list");

    if (!response) {
        alert("サーバーの応答がありません");
        console.error("not responseed (/api/Authorize/register");
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    const rawData: any[] = await response.json();
    return rawData.map(raw => ({
        userId: raw.userId,
        name: raw.name,
        lastLoginDate: raw.lastLoginDate ? new Date(raw.lastLoginDate) : null,
        accountExpireDate: raw.accountExpireDate ? new Date(raw.accountExpireDate) : null,
        enableNotification: raw.enableNotification
    }));
}

export const deleteUser = async (userID: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.deleteAsync("Authorize/user", {userID});

    if (!response) {
        alert("サーバーの応答がありません");
        console.error("not responseed (/api/Authorize/register");
        return false;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}