import { HttpRequest } from "actions/utils/HttpRequest";
import { AppConfig } from "app-env";
import { IDetailItemData } from "./RODetailAction";

export interface IROListData {
    receiptID: number;
    stationNumber: string;
    stationName: string;
    acceptedNumber: string;
    loadedVersion: number;
    itemsCount: number;
    status: string;
    contractorName: string;
    state: number;
    warehouse: number;
    pendingExists: boolean;
}

export interface IROListDataChunk {
    page: number;
    chunkSize: number;
    totalCount: number;
    chunk: IROListData[];
}

export interface IListFilter {
    state?: number;
    search?: string;
    pending?: boolean;
}

let roListResourceId: string | null = null;
export const getROListAsync = async (size: number, page: number, filter?: IListFilter, refresh = false): Promise<IROListDataChunk | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    
    let props: any = {size, page};
    if (filter) {
        props = {...props, filter};
    }
    if (roListResourceId && !refresh) {
        props = {...props, resourceId: roListResourceId};
    }

    const response = await request.getWithQueryAsync("ro/list", props, "infinite");

    if (!response) {
        alert("サーバーからの応答がありません");
        return null;
    }
    if (!response.ok) {
        alert(`${await response.text()} code=${response.status}`);
        return null;
    }

    const {resourceId, chunkedData} = await response.json();

    roListResourceId = resourceId;

    return chunkedData;
}

export interface IFlattenItemData extends IDetailItemData {
    acceptedNumber: string;
    status: string;
    warehouse: string;
    stationNumber: string;
    stationName: string;
    inventoryDateString: string;
    shippedDateString: String;
}
export interface IROFlattenItemsListDataChunk {
    page: number;
    chunkSize: number;
    totalCount: number;
    chunk: IFlattenItemData[];
}
export interface IItemFilter {
    storedRangeStartDate?: Date;
    storedRangeEndDate?: Date;
    inventoryRangeStartDate?: Date;
    inventoryRangeEndDate?: Date;
    shippedRangeStartDate?: Date;
    shippedRangeEndDate?: Date;
}

let roItemListResourceId: string | null = null;
export const getROFlattenItemsAync = async (size: number, page: number, listFilter?: IListFilter, itemFilter?: IItemFilter, refresh = false): Promise<IROFlattenItemsListDataChunk | null> => {
    const request = new HttpRequest(AppConfig.serverURL);

    let prop: any = {size, page};
    if (listFilter) {
        prop = {...prop, listFilter};
    }
    if (itemFilter) {
        prop = {...prop, itemFilter};
    }
    if (roItemListResourceId && !refresh) {
        prop = {...prop, resourceId: roItemListResourceId};
    }

    const response = await request.getWithQueryAsync("ro/list/items", prop, "infinite");

    if (!response) {
        alert("サーバーからの応答がありません");
        return null;
    }
    if (!response.ok) {
        alert(`${await response.text()} code=${response.status}`);
        return null;
    }

    const {resourceId, chunkedData} = await response.json();

    roItemListResourceId = resourceId;

    return chunkedData;
}

export const exportROFlattenItemsAsync = async (listFilter?: IListFilter, itemFilter?: IItemFilter): Promise<{downloadUrl: string, filename: string} | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);

    let prop: any = {};
    if (listFilter) {
        prop = {...prop, listFilter};
    }
    if (itemFilter) {
        prop = {...prop, itemFilter};
    }

    const response = await request.getWithQueryAsync("ro/list/items/export", prop);

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}