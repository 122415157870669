import * as React from 'react';
import { RoleFilter, Role } from 'components/Role';
import { Switch, Route, Router, RouteComponentProps, useLocation } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { List, Divider, TextField, IconButton, Select, MenuItem, Checkbox, Typography } from '@material-ui/core';
import { MenuLinkButtonItem, MenuCollapseItem } from 'components/MenuCollapseItem';
import * as GlobalMenuSetter from 'actions/types/GlobalMenuActionType';
import { SystemTypes } from 'store/SystemConstants';
import * as SystemDataSetter from 'actions/types/SystemConstantsActionType';
import { Store, GlobalState } from 'store/GlobalState';
import { connect } from 'react-redux';
import { IHistoryWrapper } from 'actions/types/HistoryType';
import { loadServerConstantsAsync, updateServerConstants } from 'actions/MartzMix/LoadServerConstantsActions';
import { roListPath, ROList } from './ROList';
import { roDetailPath, RODetail } from './RODetail';
import SearchIcon from '@material-ui/icons/Search';
import { routePush } from 'actions/utils/History';
import { SelectorWithLabel } from 'components/SelectorWithLabel';
import { ROItemDetail, roItemDetailPath } from './ROItemDetail';
import { roWarehuoseHistoryPath, ROWarehouseHistory, roWarehouseHistoryPageName } from './ROWarehouseHistory';
import { roWarehouseSchedulePath, roWarehouseSchedulePageName, ROWarehouseSchedule } from './ROWarehouseSchedule';
import { IListFilter } from 'actions/RO/ROListAction';
import * as QueryString from 'query-string';

export const roRootPath = '/ro';

export const ROApp = connect(
    (state: GlobalState): IHistoryWrapper => (state.history)
)((props: IHistoryWrapper) => {
    return (
        <React.Fragment>
            <MenuBuildViewAction />
            <Router history={props.history} children={(
                <Switch>
                    <Route exact path={roWarehuoseHistoryPath} component={ROWarehouseHistory}></Route>
                    <Route exact path={roWarehouseSchedulePath} component={ROWarehouseSchedule} />
                    <Route path={roItemDetailPath} component={ROItemDetail} />
                    <Route path={roDetailPath} component={RODetail} />
                    <Route path={roListPath} component={ROList} />
                </Switch>
            )} />
        </React.Fragment>
    );
});

export const MenuBuildViewAction = () => {
    const globalMenuSetAction: GlobalMenuSetter.SetGlobalMenuAction = {
        type: GlobalMenuSetter.setGlobalMenu,
        element: <Menu />
    }

    Store.dispatch(globalMenuSetAction);


    const systemNameSetAction: SystemDataSetter.LoadSystemConstants = {
        type: SystemDataSetter.loadSystemConstants,
        data: { system: SystemTypes.RO }
    };

    Store.dispatch(systemNameSetAction);

    return <React.Fragment></React.Fragment>;
};

const menuStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper
        }
    })
);

const selectTitleSet: {title: string, query: string}[] = [
    {title: "受付済", query: "state=0"},
    {title: "確認済", query: "state=1"},
    {title: "DL済", query: "state=2"},
    {title: "一部入庫済", query: "state=3"},
    {title: "入庫済", query: "state=4"},
    {title: "完了済", query: "state=5"}
];

const getFilter = (parser: QueryString.ParsedQuery<string>): IListFilter => {
    const {state, search, pending} = parser;

    const filter: IListFilter = {};

    if (typeof state === "string") {
        filter.state = parseInt(state, 10);
    }
    if (typeof search === "string") {
        filter.search = search;
    }
    if (typeof pending === "string") {
        filter.pending = (pending.toLowerCase() === "true");
    }

    return filter;
}

const Menu: React.FunctionComponent = () => {
    const location = useLocation();
    const [selectedQuery, setSelectedQuery] = React.useState<string | null>(null);
    const [searchString, SetSearchString] = React.useState<string | null>(null);
    const [isPendingOnly, setIsPendingOnly] = React.useState<boolean | null>(null);

    const togglePendingOnlyCheckButtonClick = React.useCallback((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsPendingOnly(!isPendingOnly);
    }, [isPendingOnly]);

    const onSelectChange = (current: string) => {
        setSelectedQuery(current);
    }

    const onSearchStringChange = (event: React.ChangeEvent<HTMLInputElement>) => SetSearchString(event.currentTarget.value);

    const onSearchClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let searchStringQuery = "?";

        if (searchString) {
            searchStringQuery = searchStringQuery + `search=${searchString}&`;
        }
        if (selectedQuery) {
            searchStringQuery = searchStringQuery + selectedQuery + '&';
        }
        if (isPendingOnly) {
            searchStringQuery = searchStringQuery + 'pending=true&';
        }

        searchStringQuery = searchStringQuery.slice(0, -1); // 何もなければ空文字になる

        const path = `${roListPath}/${searchStringQuery}`;
        Store.dispatch(routePush(path));
    }

    const roles = Store.getState().loginPage.roles;
    const isWarehouse = (roles && roles.indexOf("ROUser_Warehouse") > -1);
    const isAdmin = (roles && roles.indexOf("ROUser_Admin") > -1);

    React.useEffect(() => {
        if (!location.search) {
            return;
        }
        const filter = getFilter(QueryString.parse(location.search));
        if (typeof filter.state === "number") {
            setSelectedQuery(`state=${filter.state}`);
        }
        if (typeof filter.search === "string") {
            SetSearchString(filter.search);
        }
        if (typeof filter.pending === "boolean") {
            setIsPendingOnly(filter.pending);
        }
    }, []);

    return (
        <React.Fragment>
            <MenuCollapseItem title="局検索" defaultOpen={true}>
                <div className="w-100">
                    <div style={{width: "90%"}} className="mx-auto mt-2">
                        {isWarehouse ? null : (
                            <React.Fragment>
                                <SelectorWithLabel
                                    label="状態"
                                    onChange={onSelectChange}
                                    value={selectedQuery}
                                    style={{width: "100%"}}
                                    className="mt-1 mb-1"
                                >
                                    {selectTitleSet.map(st => <MenuItem key={st.title} value={st.query}>{st.title}</MenuItem>)}
                                </SelectorWithLabel>
                                <TextField label="局番/局名/受付番号/RO番号" value={searchString} className="mt-1 mb-1 w-100" onChange={onSearchStringChange} />
                            </React.Fragment>
                        )}
                        <div className="d-flex align-items-center"><Typography>保留がある局のみ表示</Typography><Checkbox color="secondary" onClick={togglePendingOnlyCheckButtonClick} checked={isPendingOnly ?? false} /></div>
                        <IconButton onClick={onSearchClick} className="d-block ml-auto"><SearchIcon /></IconButton>
                    </div>
                </div>
            </MenuCollapseItem>
            <Divider />
            {(!isAdmin && !isWarehouse) ? <React.Fragment></React.Fragment> : (
                <React.Fragment>
                    <MenuCollapseItem title="倉庫作業" defaultOpen={true}>
                        <MenuLinkButtonItem path={roWarehouseSchedulePath}>{roWarehouseSchedulePageName}</MenuLinkButtonItem>
                        <Divider />
                        <MenuLinkButtonItem path={roWarehuoseHistoryPath}>{roWarehouseHistoryPageName}</MenuLinkButtonItem>
                    </MenuCollapseItem>
                    <Divider />
                </React.Fragment>
            )}
            <div>
                <label style={{ color: 'red', display: 'block' }}>
                    <br />
                    ★送付先変更のお知らせ★<br />
                    送付先倉庫が、下記日程で、<br />
                    野田倉庫 ⇒ 岩槻倉庫へ<br />
                    変更になります。<br />
                    5/13～6/7　 移行期間<br />
                    6/7～ 　　　完全切替<br />
                    使用する配送伝票を確認し、<br />
                    お間違えないようお願い申し上げます。<br />
                </label>
            </div>
        </React.Fragment>
    );
};