import * as React from 'react';
import { Store } from 'store/GlobalState';
import * as GlobalMenuSetter from 'actions/types/GlobalMenuActionType';
import * as SystemDataSetter from 'actions/types/SystemConstantsActionType';
import { Role, RoleFilter } from 'components/Role';
import { Home, NextHomePath } from './Home';
import { BaseStationSammary } from 'types/StationTypes';
import { Spinner, Button } from 'reactstrap';
import { getInvidualProjectNames, loadFilteredBaseStation, getInvidualTroubleShootingProjectNames, searchByFuzzyWord, saveSearchedProjectCode } from 'actions/Next/HomeAction';
import { IconButton, Collapse, Theme, List, ListSubheader, ListItem, TextField, ListItemText, LinearProgress, Divider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { GlobalState } from 'store/GlobalState';
import { LoadBaseStation, SaveSearchedProjectCode } from 'actions/types/HomeActionType';
import { ActionType } from 'actions/types/ActionType';
import { Switch, RouteComponentProps } from 'react-router';
import { BooksForm, NextBooksFormPath } from './Books/BooksForm';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router';
import { SystemTypes } from 'store/SystemConstants';


const roles = [Role.NextUser];

interface IRouteData {
    allowNestPath?: boolean;
    path: string;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

const routeInfoList: IRouteData[] = [
    {path: NextHomePath, component: Home},
    {path: NextBooksFormPath, component: BooksForm, allowNestPath: true}
];

export const NextRootPath = '/next';

/**
 * Wicky Nextの構築
 */
export const NextApp: React.FunctionComponent = () => {
    
    const routeList = React.useMemo(() => {
        const ret: JSX.Element[] = [];
        for (const info of routeInfoList) {
            if (info.allowNestPath) {
                ret.push(<Route path={info.path} component={info.component} key={info.path} />);
            }
            else {
                ret.push(<Route exact path={info.path} component={info.component} key={info.path} />);
            }
        }

        return ret;
    }, []);

    return (
        <RoleFilter accessableRoles={roles}>
            <NextAppViewActions />
            <BrowserRouter>
                <Switch>
                    {routeList}
                </Switch>
            </BrowserRouter>
        </RoleFilter>
    );
};

const NextAppViewActions: React.FunctionComponent = () => {

    const globalMenu = React.useMemo(() => <GlobalMenu />, []);

    const globalMenuSetAction: GlobalMenuSetter.SetGlobalMenuAction = {
        type: GlobalMenuSetter.setGlobalMenu,
        element: globalMenu
    };

    Store.dispatch(globalMenuSetAction);

    const systemNameSetAction: SystemDataSetter.LoadSystemConstants = {
        type: SystemDataSetter.loadSystemConstants,
        data: { system: SystemTypes.Next }
    };

    Store.dispatch(systemNameSetAction);

    return <React.Fragment></React.Fragment>;
}

// menu定義

const itemStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper
        }
    })
);

const GlobalMenu: React.FunctionComponent<{}> = () => {
    const [projectFilters, setProjectFilters] = React.useState<{code: string, name: string}[] | null>(null);
    const [troubleShootingProjectFilters, setTroubleShootingProjectFilters] = React.useState<{code: string, name: string}[] | null>(null);

    React.useEffect(() => {
        if (projectFilters !== null && troubleShootingProjectFilters !== null) {
            return;
        }

        const asyncFunc = async () => {
            const projects = await getInvidualProjectNames();
            const troubleShooting = await getInvidualTroubleShootingProjectNames();
            setProjectFilters(projects);
            setTroubleShootingProjectFilters(troubleShooting);
        };

        asyncFunc();
    });

    const classes = itemStyles();

    if (projectFilters === null || troubleShootingProjectFilters === null) {
        return <Spinner />;
    }

    const filterButtons = projectFilters.map((project) => (
        <React.Fragment key={project.code}>
            <FilterButton project={project} />
            <Divider />
        </React.Fragment>
    ));
    const troubleShootingFilterButtons = troubleShootingProjectFilters.map((project) => (
        <React.Fragment key={project.code}>
            <FilterButton project={project} />
            <Divider />
        </React.Fragment>
    ));

    return (
        <List 
            component="nav"
            aria-labelledby="search-filter-subheader"
            subheader={
                <ListSubheader component="div" id="search-filter-subheader">
                    検索
                </ListSubheader>
            }
            className={classes.root}
        >
            <ListItem divider={true}>
                <SearchBoxContainer />
            </ListItem>
            <FilterListItem title="個別案件一覧">
                {filterButtons}
            </FilterListItem>
            <FilterListItem title="故障対応一覧">
                {troubleShootingFilterButtons}
            </FilterListItem>
        </List>
    );
}


interface ISearchBoxContainerProps {
    mapRef: google.maps.Map | null;
    loadBaseStations: (loadDispatcher: LoadBaseStation) => void;
    saveProjectCode: (saveDispatcher: SaveSearchedProjectCode) => void;
}

const SearchBoxContainer = connect(
    (state: GlobalState) => ({
        mapRef: state.googleMap
    }),
    (dispatch: React.Dispatch<ActionType>) => ({
        loadBaseStations: (loadDispatcher: LoadBaseStation) => dispatch(loadDispatcher),
        saveProjectCode: (saveDispatcher: SaveSearchedProjectCode) => dispatch(saveDispatcher)
    }),
    ({mapRef}, {loadBaseStations, saveProjectCode}) => ({
        mapRef, loadBaseStations, saveProjectCode
    })
)((props: ISearchBoxContainerProps) => {
    const {mapRef, loadBaseStations, saveProjectCode} = props;
    
    const [searchString, setSearchString] = React.useState<string | null>(null);
    const [isSearching, setIsSearching] = React.useState(false);
    const [searchedStations, setSearchedStations] = React.useState<BaseStationSammary[] | null>(null);

    const onSearch = (word: string) => {
        setIsSearching(true);
        setSearchString(word);
    }

    React.useEffect(() => {
        if (searchString === null) {
            return;
        }

        const asyncFunc = async () => {
            const dispatcher = await searchByFuzzyWord(searchString);

            const {stations} = dispatcher;
            setSearchedStations(stations);

            loadBaseStations(dispatcher);
            saveProjectCode(saveSearchedProjectCode(null));

            setSearchString(null);
            setIsSearching(false);

            Store.getState().event.fire("on station filtered");
        };

        asyncFunc();
    });

    React.useEffect(() => {
        if (searchedStations !== null && searchedStations.length === 1 && mapRef !== null) {
            const location: google.maps.LatLngLiteral = {lat: searchedStations[0].location.latitude, lng: searchedStations[0].location.longitude};
            mapRef.panTo(location);
        }
    }, [searchedStations, mapRef]);

    return <SearchBoxPresentational onSearchClick={onSearch} isSearching={isSearching} />;
});

interface ISearchBoxPresentationalProps {
    onSearchClick: (word: string) => void;
    isSearching: boolean;
}

const SearchBoxPresentational = (props: ISearchBoxPresentationalProps) => {
    const [formText, setFormText] = React.useState("");
    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setFormText(event.target.value);

    const {onSearchClick, isSearching} = props;

    const onSearchButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onSearchClick(formText);

    return (
        <div className="d-flex w-100 flex-column">
            <div className="d-flex w-100">
                <TextField
                    placeholder="局番号/局名"
                    margin="none"
                    variant="standard"
                    fullWidth
                    InputProps={{type: 'search' }}
                    className="text-nowrap"
                    value={formText}
                    onChange={onChange}
                    disabled={isSearching}
                />
                <IconButton aria-label="search" onClick={onSearchButtonClick} disabled={isSearching}>
                    <SearchIcon />
                </IconButton>
            </div>
            {isSearching ? <LinearProgress style={{width: "100%", height: "0.1em"}} /> : ""}
        </div>
    )
}

interface IFilterButtonProps {
    project: {code: string, name: string};
}

const FilterButton = (props: IFilterButtonProps) => {
    const [isLoaded, setIsLoaded] = React.useState(true);

    React.useEffect(() => {
        if (isLoaded) {
            return;
        }

        const asyncFunc = async () => {
            Store.dispatch(await loadFilteredBaseStation(props.project.code));
            Store.dispatch(saveSearchedProjectCode(props.project.code));
            setIsLoaded(true);

            Store.getState().event.fire("on station filtered");
        }

        asyncFunc();
    });

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsLoaded(false);
    };

    return (
        <React.Fragment>
            <Button
                style={{
                    backgroundColor: "transparent", 
                    color: "black",
                    border: "none",
                    width: "100%",
                    textAlign: "left"
                }}
                onClick={onClick}
                disabled={!isLoaded}
            >
                <div className="d-flex flex-column w-100">
                    {props.project.name}
                    {isLoaded ? "" : <LinearProgress style={{width: "100%", height: "0.1em"}} />}
                </div>
            </Button>
        </React.Fragment>
    );
}


const filterItemStyles = makeStyles((theme: Theme) =>
    createStyles({
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        }
    })
);

interface IFilterListItemProps {
    title: string;
}

const FilterListItem: React.FunctionComponent<IFilterListItemProps> = (props) => {

    const [filterExpaned, setFilterExpanded] = React.useState(false);
    const handleExpandClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setFilterExpanded(!filterExpaned);
    };

    const classes = filterItemStyles();

    return (
        <React.Fragment>
            <ListItem divider={true} style={{backgroundColor: "lightcyan"}}>
                <ListItemText primary={props.title} />
                <IconButton 
                    size="small"
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: filterExpaned
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={filterExpaned}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </ListItem>
            <Collapse in={filterExpaned} timeout="auto" unmountOnExit>
                {props.children}
            </Collapse>
        </React.Fragment>
    );
}