import * as React from 'react';
import { BaseStationSammary, BaseStationInfo } from 'types/StationTypes';
import { TableHead, TableCell, makeStyles, Theme, createStyles, TableRow, Table, Fab, TableBody } from '@material-ui/core';
import { IProjectData, getProjectStatusInfoList, IRowData, convertProjectStatusToRowData, exportProjectStatusList } from 'actions/Next/HomeAction';
import { connect } from 'react-redux';
import { GlobalState } from 'store/GlobalState';
import { Spinner } from 'reactstrap';
import { DownloadButton } from './DownloadButton';
import GetAppIcon from '@material-ui/icons/GetApp';

export interface IProjectListViewProps {
    onItemClick: (station: BaseStationSammary) => void;
}
interface IProjectListViewConnectionProps extends IProjectListViewProps {
    stations: BaseStationSammary[];
    projectCode: string | null;
}

export const ProjectListView = connect(
    (state: GlobalState, ownProps: IProjectListViewProps) => ({
        stations: state.homePage.stations,
        projectCode: state.homePage.searchedProjectCode,
        ...ownProps
    })
)((props: IProjectListViewConnectionProps) => {
    const {onItemClick, stations, projectCode} = props;

    const [isLoaded, setIsLoaded] = React.useState(true);

    const [rowData, setRowData] = React.useState<IRowData[]>([]);
    React.useEffect(() => {
        if (!projectCode) {
            return;
        }

        const asyncFunc = async () => {
            setIsLoaded(false);

            const list = await getProjectStatusInfoList(projectCode);

            if (list === undefined) {
                alert("データの読み込みに失敗しました");
                return;
            }

            setRowData(convertProjectStatusToRowData(stations, list));
            setIsLoaded(true);
        }

        asyncFunc();
    }, [projectCode, stations]);
    
    if (!isLoaded) {
        return (
            <div className="w-100 h-100 overflow-auto">
                <Spinner />
            </div>
        )
    }

    return <ProjectListViewPresentation onItemClick={onItemClick} rowData={rowData} stations={stations} />;
})


const headers: string[] = [
    "計画局番",
    "計画局名",
    "正式局番",
    "正式局名",
    "開始",
    "終了",
    "確認",
    "提出",
    "DL"
]

interface IProjectListViewPresentationProps {
    onItemClick: (station: BaseStationSammary) => void;
    rowData: IRowData[];
    stations: BaseStationSammary[];
}
const ProjectListViewPresentation = (props: IProjectListViewPresentationProps) => {
    const {rowData, onItemClick, stations} = props;

    const header = React.useMemo(() => (
        <TableHead>
            {headers.map((h) => <TableCell>{h}</TableCell>)}
        </TableHead>
    ), []);

    const stationMap = React.useMemo(() => new Map<string, BaseStationSammary>(stations.map((st) => [st.planned.id, st])), [stations]);

    if (rowData.length === 0) {
        return <span className="h1 text-muted">データがありません。左メニューから案件名を選択してください</span>
    }

    const rows = rowData.map((r) => {

        let projectDataColumns: JSX.Element;
        if (!r.booksStatus) {
            projectDataColumns = (
                // まだレコードに登録されていない(一度も図書ページをひらいたことがない)場合
                <React.Fragment>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                </React.Fragment>
            );
        }
        else {
            projectDataColumns = (
                <React.Fragment>
                    <TableCell>{r.booksStatus.started ? <span style={{color: "springgreen"}}>済</span>: <span style={{color: "indigo"}}>未</span>}</TableCell>
                    <TableCell>{r.booksStatus.finished ? <span style={{color: "springgreen"}}>済</span>: <span style={{color: "indigo"}}>未</span>}</TableCell>
                    <TableCell>{r.booksStatus.checked ? <span style={{color: "springgreen"}}>済</span>: <span style={{color: "indigo"}}>未</span>}</TableCell>
                    <TableCell>{r.booksStatus.submited ? <span style={{color: "springgreen"}}>済</span>: <span style={{color: "indigo"}}>未</span>}</TableCell>
                    <TableCell>{r.booksStatus.downloaded ? <span style={{color: "springgreen"}}>済</span>: <span style={{color: "indigo"}}>未</span>}</TableCell>
                </React.Fragment>
            );
        }

        const onClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
            const st = stationMap.get(r.planned.id);
            if (st) {
                onItemClick(st);
            }
        }

        const {planned, implemented} = r;

        return (
            <React.Fragment>
                <TableRow key={planned.id} onClick={onClick} hover style={{cursor: "pointer"}}>
                    <TableCell>{planned.id}</TableCell>
                    <TableCell>{planned.name}</TableCell>
                    {!implemented ? <React.Fragment></React.Fragment> : (
                        <React.Fragment>
                            <TableCell>{implemented.id}</TableCell>
                            <TableCell>{implemented.name}</TableCell>
                        </React.Fragment>
                    )}
                    {projectDataColumns}
                </TableRow>
            </React.Fragment>
        )
    });

    const downloadTask = () => exportProjectStatusList(rowData, headers, "詳細一覧");
    
    return (
        <div className="w-100 h-100 overflow-auto">
            <DownloadButton urlGetter={downloadTask} style={{position: "absolute", right: "0.5rem", top: "0.5rem"}}>
                <GetAppIcon />
            </DownloadButton>
            <Table stickyHeader className="text-nowrap">
                {header}
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </div>
    );
}