export const toBase64 = async (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        const result = reader.result;
        if (typeof result === "string") {
            resolve(btoa(result));
        }
        resolve("");
    }
    reader.onerror = error => reject(error);
    reader.readAsBinaryString(file);
});

export const fromBase64 = async (b64String: string, filename: string, mime: string, lastModifiedDate: Date): Promise<File> => new Promise((resolve, reject) => {
    try {
        const charArray = atob(b64String);
        const binary = new Uint8Array(charArray.length);
        for (let i = 0; i < charArray.length; i++) {
            binary[i] = charArray.charCodeAt(i);
        }

        const file: any = new Blob([binary], {type: mime});
        file.lastModifiedDate = lastModifiedDate;
        file.name = filename;

        resolve(file);
    }
    catch (ex) {
        reject(ex);
    }
})

export const toDataUrl = async (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        const result = reader.result;
        if (typeof result === "string") {
            resolve(result);
        }
        else {
            reject(reader.error);
        }
    }
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file);
})

export const toArrayBuffer = async (file: File): Promise<ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === "string") {
                resolve(null);
            }
            else {
                resolve(result);
            }
        };
        reader.onerror = () => { reject(reader.error) };
        reader.readAsArrayBuffer(file);
    });
}