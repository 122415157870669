import * as React from 'react';
import { TextField, IconButton, LinearProgress, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

export interface ISwitchableTextboxProps {
    label?: string;
    multiline?: true;
    value?: string;
    variant?: "filled" | "outlined" | "standard" | undefined;
    style?: React.CSSProperties;
    className?: string;
    disabled?: boolean;
    onSave?: (current: string) => Promise<boolean>;
    onEditStart?: () => void;
    tooltip?: true;
    inputProps?: any;
    placeholder?: string;
}

export const SwitchableTextbox = (props: ISwitchableTextboxProps) => {
    const {label, multiline, value, variant, style, className, disabled, onSave, tooltip, placeholder} = props;

    const [isNowEditing, setIsNowEditing] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [text, setText] = React.useState(value ?? "");

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!isNowEditing) {
            setIsNowEditing(true);
        }
        else {
            setIsSaving(true);
        }
    }

    const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isNowEditing) {
            setText(event.target.value);
        }
    };

    React.useEffect(() => {
        if (props.onEditStart && isNowEditing) {
            console.log("edit start");
            props.onEditStart();
        }
    }, [isNowEditing]);

    React.useEffect(() => {
        if (!isNowEditing || !isSaving) {
            return;
        }

        const asyncFunc = async () => {
            if (onSave) {
                const result = await onSave(text);
                if (!result) {
                    alert("更新に失敗しました");
                    setText(value ?? "");   // 初期値に戻す
                }

                setIsNowEditing(false);
                setIsSaving(false);
            }
            else {
                setIsNowEditing(false);
                setIsSaving(false);
            }
        }

        asyncFunc();
    }, [isNowEditing, isSaving, onSave]);

    React.useEffect(() => {
        setText(value ?? "");
    }, [value]);

    let saveEditSwichingButton: React.ReactNode;
    if (tooltip) {
        let title: string;
        if (isNowEditing) {
            title = "保存";
        }
        else {
            title = "編集";
        }

        saveEditSwichingButton = (
            <Tooltip title={title} placement="top">
                <IconButton onClick={onEditClick} size="small" disabled={isSaving || disabled}>
                    {isNowEditing ? <SaveIcon /> : <EditIcon />}
                </IconButton>
            </Tooltip>
        );
    }
    else {
        saveEditSwichingButton = (
            <IconButton onClick={onEditClick} size="small" disabled={isSaving || disabled}>
                {isNowEditing ? <SaveIcon /> : <EditIcon />}
            </IconButton>
        );
    }

    return (
        <div style={style} className={className}>
            <div className="d-flex align-items-end">
                <TextField
                    label={label}
                    multiline={multiline}
                    value={text}
                    onChange={onTextChange}
                    variant={variant as any /** VS のコンパイラがバグっててオーバロード解決できないのでanyで回避 */}
                    style={{width: "90%"}}
                    disabled={!isNowEditing}
                    placeholder={placeholder}
                    inputProps={props.inputProps}
                />
                {saveEditSwichingButton}
            </div>
            {isSaving ? <LinearProgress style={{width: "100%", height: "0.1em"}} /> : ""}
        </div>
    )
}