import * as React from 'react';
import { Button, CircularProgress } from '@material-ui/core';

export interface LoadingButtonProps {
    styles?: string;
    spinnerSize?: string | number;
}

export const LoadingButton = (props: LoadingButtonProps) => {
    const size = props.spinnerSize === undefined ? 20 : props.spinnerSize;

    return (
        <Button className={props.styles} disabled={true}>
            <CircularProgress size={size} />
        </Button>
    );
};