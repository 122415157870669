import { ActionType } from "./ActionType";
import { BaseStationSammary, ProjectForBaseStaion, BaseStationDetail } from "../../types/StationTypes";

export const preparateFinished = "Preparate finished";
export interface PreparateFinished extends ActionType {
    apiKey?: string;
}

export const loadBaseStation = "Load base station";
export interface LoadBaseStation extends ActionType {
    stations: BaseStationSammary[];
}

export const changeSelectedStation = "Change selected station";
export interface ChangeSelectedStation extends ActionType {
    station: BaseStationDetail | null;
}

export const pickupProjects = "pickup projects";
export interface PickupProject extends ActionType {
    projects: ProjectForBaseStaion[];
}

export const updateCurrentLocation = "Update current location";
export interface UpdateCurrentLocation extends ActionType {
    location: google.maps.LatLngLiteral;
}

export const saveSearchedProjectCode = "save searched project code";
export interface SaveSearchedProjectCode extends ActionType {
    projectCode: string | null;
}