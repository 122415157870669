import * as React from 'react';

export const LeftBackMinusIcon: React.StatelessComponent<React.CSSProperties> = (props) => {
    return (
        <svg className="MuiSvgIcon-root"
            style={props}
            viewBox="0 0 50 50">
                <path id="leftBackMinus"
                    d="M 39.00,2.00
                    C 39.00,2.00 19.00,22.00 19.00,22.00
                      19.00,22.00 39.00,43.00 39.00,43.00
                      39.00,43.00 43.00,40.00 43.00,40.00
                      38.46,33.65 32.35,27.54 26.00,23.00
                      26.00,23.00 43.00,6.00 43.00,6.00
                      43.00,6.00 39.00,2.00 39.00,2.00 Z
                    M 12.00,40.00
                    C 12.00,40.00 12.00,43.00 12.00,43.00
                      12.00,43.00 28.00,43.00 28.00,43.00
                      28.00,43.00 28.00,40.00 28.00,40.00
                      28.00,40.00 12.00,40.00 12.00,40.00 Z"
                />
        </svg>
    );
};