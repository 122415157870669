import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route, Router } from 'react-router-dom';
import { GlobalState } from 'store/GlobalState';
import { IHistoryWrapper } from 'actions/types/HistoryType';
import { connect } from 'react-redux';
import { userManagementPath, UserManagement } from './UserManagement';
import { temporaryUserRegisterPath, TemporaryUserRegistration } from './TemporaryUserRegister';

export const managementRootPath = '/management';

export const ManagementApp = connect(
    (state: GlobalState): IHistoryWrapper => (state.history)
)((props: IHistoryWrapper) => {
    return (
        <React.Fragment>
            <Router history={props.history} children={(
                <Switch>
                    <Route exact path={userManagementPath} component={UserManagement} />
                    <Route exact path={temporaryUserRegisterPath} component={TemporaryUserRegistration} />
                </Switch>
            )} />
        </React.Fragment>
    );
});