import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Button, Theme, Divider, Grid, Hidden, Dialog, DialogContent, IconButton, LinearProgress, Typography, Collapse, TextField } from '@material-ui/core';
import * as QueryString from 'query-string';
import { Store } from 'store/GlobalState';
import { RedirectToHome } from 'components/RedirectToHome';
import { FileSelectWithThumbnail } from 'components/FileSelectWithThumbnail';
import { Spinner } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import * as Action from 'actions/Next/BooksFormAction';
import { BackButton } from 'components/BackButton';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { PageOverlay } from 'components/PageOverlay';
import { DownloadButton } from 'components/DownloadButton';
import { Role, RoleFilter } from 'components/Role';

const roles = [Role.NextUser];

interface IBooksFromInfoProps {
    stationId: string;
    projectId: string;
    classification: string;
    action: string;
}

const FileUploadContext = React.createContext<{value: boolean, setter: (val: boolean) => void}>({value: false, setter: (val) => {}});

const BooksFormContext = React.createContext<IBooksFromInfoProps>({
    stationId: "",
    projectId: "",
    classification: "",
    action: ""
});

const PageControlContext = React.createContext<{isPageControlable: boolean, setIsPageControlable: (val: boolean) => void}>({isPageControlable: false, setIsPageControlable: (val: boolean) => {}});

const backButtonStyle = makeStyles((theme: Theme) => 
    createStyles({
        buttonPosition: {
            [theme.breakpoints.up("md")]: {
                position: "absolute"
            }
        }
    })
);

const statusViewDataMap = new Map<string, {defaultCompleted: boolean, executeButtonTitle: string, id: number}>([
    ["開始報告", {defaultCompleted: false, executeButtonTitle: "開始報告をする", id: 0}],
    ["終了報告", {defaultCompleted: false, executeButtonTitle: "終了報告をする", id: 1}],
    ["確認1", {defaultCompleted: false, executeButtonTitle: "確認完了", id: 2}],
    ["確認2", {defaultCompleted: false, executeButtonTitle: "確認完了", id: 3}],
    ["提出", {defaultCompleted: false, executeButtonTitle: "提出済にする", id: 4}],
    ["ダウンロード", {defaultCompleted: true, executeButtonTitle: "報告書をDL", id: 5}]
]);


export const NextBooksFormPath = '/next/books';

export const BooksForm = (props: RouteComponentProps) => {

    const [isFileUploadNow, setIsFileUploadNow] = React.useState(false);

    const {stationId, projectId, class: classification, action} = QueryString.parse(props.location.search);

    const [isBooksOpened, setIsBooksOpened] = React.useState(false);
    React.useEffect(() => {
        if (typeof stationId !== "string"
        || typeof projectId !== "string"
        || typeof classification !== "string"
        || typeof action !== "string")
        {
            return;
        }

        const asyncFunc = async () => {
            await Action.openBooks(stationId, projectId, classification, action);
            setIsBooksOpened(true);
        }

        asyncFunc();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [isPageControlable, setIsPageControlable] = React.useState(false);

    const classes = backButtonStyle();

    if (!isBooksOpened) {
        return <Spinner />;
    }
    
    if (typeof stationId !== "string"
    || typeof projectId !== "string"
    || typeof classification !== "string"
    || typeof action !== "string")
    {
        return <RedirectToHome message="データが不正です。" />;
    }

    const {homePage} = Store.getState();    // 変更されても連動して再描画する必要ない
    const station = homePage.selectedStationDetail;
    if (station === null || station.implemented === null) {
        // 計画局の段階では案件は紐付かないはず
        return <RedirectToHome message="データがありません。" />;
    }

    
    
    return (
        <RoleFilter accessableRoles={roles}>
            <PageControlContext.Provider value={{isPageControlable, setIsPageControlable}}>
                <BooksFormContext.Provider value={{stationId, projectId, classification, action}}>
                    <FileUploadContext.Provider value={{value: isFileUploadNow, setter: setIsFileUploadNow}}>
                        <React.Fragment>
                            <PageOverlay open={isFileUploadNow}>
                                <div style={{position: "absolute", width: "100vw", height: "100vw", display: "flex", backgroundColor: "transparent", justifyContent: "center", top: "50%"}}>
                                    <div>
                                        <Spinner style={{display: "block", marginLeft: "auto", marginRight: "auto"}} />
                                        <Typography style={{color: "white", textAlign: "center"}}>アップロード中です</Typography>
                                    </div>
                                </div>
                            </PageOverlay>
                            <BackButton className={classes.buttonPosition} />
                            <div className="container mt-3">
                                <div className="container">
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <span className="h6">局番号</span>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <span className="font-weight-bold">{station.implemented.id}</span>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="container">
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <span className="h6">局名</span>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <span className="font-weight-bold">{station.implemented.name}</span>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="container">
                                <Grid container>
                                        <Grid item xs={3}>
                                            <span className="h6">所在地</span>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <span className="font-weight-bold">{station.address}</span>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Divider />
                                <BooksControlPanel />
                                <br />
                                <Divider />
                                <div className="row">
                                    <BooksFormInputArea />
                                </div>
                            </div>
                        </React.Fragment>
                    </FileUploadContext.Provider>
                </BooksFormContext.Provider>
            </PageControlContext.Provider>
        </RoleFilter>
    );
};

const expandStyles = makeStyles((theme: Theme) => 
    createStyles({
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          },
          expandOpen: {
            transform: 'rotate(180deg)',
          }
    })
);
const BooksControlPanel: React.FunctionComponent<{}> = () => {
    const [isPanelExpanded, setIsPanelExpanded] = React.useState(true);
    const handleExpandClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setIsPanelExpanded(!isPanelExpanded);

    const {isPageControlable, setIsPageControlable} = React.useContext(PageControlContext);

    const classes = expandStyles();
    
    const cards: JSX.Element[] = [];
    const viewDataEntries = Array.from(statusViewDataMap.entries());


    const first = viewDataEntries[0];
    const startExecute = () => setIsPageControlable(true);
    const startOnLoad = (aleadyCompleted: boolean) => setIsPageControlable(aleadyCompleted);
    cards.push(
        <StatusCard
            id={first[1].id}
            title={first[0]}
            key={`${first[1].id} ${first[1].executeButtonTitle}`}
            disabled={false}
            onExecute={startExecute}
            onLoad={startOnLoad}
        />
    );

    viewDataEntries.slice(1, viewDataEntries.length - 1).forEach((it, idx) => {
        cards.push(
            <StatusCard
                id={idx + 1 /* sliceした分 */}
                title={it[0]}
                key={`${idx} ${it[1].executeButtonTitle}`}
                disabled={!isPageControlable}
            />
        );
    });

    const last = viewDataEntries[viewDataEntries.length - 1];
    cards.push(
        <DownloadCard
            id={last[1].id}
            title={last[0]}
            key={`${last[1].id} ${last[1].executeButtonTitle}`}
            disabled={!isPageControlable}
        />
    );

    return (
        <React.Fragment>
            <div className="row justify-content-end">
                <IconButton 
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: isPanelExpanded
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={isPanelExpanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>   
            </div>
            <Collapse in={isPanelExpanded} timeout="auto" unmountOnExit>
                <div className="container">
                    <div className="row">
                        {cards}
                    </div>
                </div>
            </Collapse>
            <br />
            <CommentBlock id={0} title="KCCS指示欄"/>
            <CommentBlock id={1} title="作業者コメント"/>
        </React.Fragment>
    );
}




const BooksFormInputArea: React.FunctionComponent<{}> = () => {
    const {stationId, projectId, classification, action} = React.useContext(BooksFormContext);

    const [pictureForms, setPictureForms] = React.useState<JSX.Element[] | null>(null);
    React.useEffect(() => {
        const asyncFunc = async () => {
            // const titles = await Action.getImageTitles(stationId, projectId, classification, action);

            // if (!titles) {
            //     alert("ページの情報を取得できませんでした。5秒後にページの再読み込みを行います");
            //     setTimeout(() => window.location.reload(), 5000);
            //     return;
            // }

            // console.log(titles);
            const overview = await Action.getOverview(stationId, projectId, classification, action);

            if (!overview) {
                alert("ページの情報を取得できませんでした。5秒後にページの再読み込みを行います");
                setTimeout(() => window.location.reload(), 5000);
                return;
            }

            console.log(overview);
            
            const forms: JSX.Element[] = [];
            for (let i = 0; i < overview.length; i++) {
                forms.push(<PictureForm title={overview[i].title} id={i} imageExists={overview[i].imageExists} key={i} />);
            }

            setPictureForms(forms);
        };

        asyncFunc();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const pictureFormOddDummy = React.useMemo(() => (
        <div style={{visibility: "hidden"}} key="oddDummyPictureForm">
            <FileSelectWithThumbnail
                width="20rem"
                onCapture={(file) => {}}
                preivewHook={(file) => {}}
                deleteHook={(filename) => {}}
            />;
        </div>
    ), []); // eslint-disable-line react-hooks/exhaustive-deps

    const rowDoms: JSX.Element[] = React.useMemo(() => {
        if (pictureForms === null) {
            return [];
        }

        const rowPicturePair: JSX.Element[][] = [];

        const sliced = Array.from(pictureForms);
        while (sliced.length > 0) {
            rowPicturePair.push(sliced.splice(0, 2));
        }

        if (rowPicturePair[rowPicturePair.length - 1].length === 1) {
            // 奇数個の場合
            rowPicturePair[rowPicturePair.length - 1].push(pictureFormOddDummy);
        }

        return rowPicturePair.map((pair) => (
            <div className="d-flex align-items-center justify-content-around" key={pair.map((form) => `${form.key}`).join()}>
                {pair}
            </div>
        ));
    }, [pictureForms]);

    if (pictureForms === null) {
        return <Spinner />;
    }
    

    return (
        <div style={{width: "100%"}}>
            <Hidden mdUp implementation="css">
                {pictureForms}
            </Hidden>
            <Hidden smDown implementation="css">
                {rowDoms}
            </Hidden>
        </div>
    );
};


interface IPictureFormProps {
    title: string;
    id: number;
    imageExists: boolean;
}
const PictureForm = (props: IPictureFormProps) => {
    const {setter: setIsFileUploading, value: isFileUploading} = React.useContext(FileUploadContext);
    const {isPageControlable, setIsPageControlable} = React.useContext(PageControlContext);

    const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
    const onPreviewOpen = React.useMemo(() => (file: File) => setIsPreviewOpen(true), [setIsPreviewOpen]);

    const [image, setImage] = React.useState<File | null>(null);
    const [showImage, setShowImage] = React.useState<File | null | undefined>(null);
    const [isImageDownloaded, setIsImageDownloaded] = React.useState(false);
    const [isFileLoadingNow, setIsFileLoadingNow] = React.useState(true);
    const {stationId, projectId, classification, action} = React.useContext(BooksFormContext);
    const [uploadedDateStr, setUploadedDateStr] = React.useState<string | null>(null);
    const [isFileDeleting, setIsFileDeleting] = React.useState(false);
    const [isFileUploadingLocal, setIsFileUploadingLocal] = React.useState(false);

    const capture = React.useMemo(() => (file: File | null) => {
        setIsImageDownloaded(false);
        if (file !== null) {
            setImage(file);
        }
    }, [setIsImageDownloaded, setImage]);

    const onDelete = React.useMemo(() => (filename: string) => {
        console.log(`delete ${filename}`);
        setImage(null);
        setIsFileDeleting(true);
    }, [setImage, setIsFileDeleting]);

    const {title, id, imageExists} = props;

    React.useEffect(() => {
        if (!imageExists) {
            setIsFileLoadingNow(false);
            return;
        }

        const asyncFunc = async () => {
            const file = await Action.getImage(stationId, projectId, classification, action, id);
            if (file === null) {
                setIsFileLoadingNow(false);
                return;
            }
            const fileInfo = await Action.getImageFileInfo(stationId, classification, id);
            setIsImageDownloaded(true);

            if (file !== null) {
                setShowImage(file);
            }

            if (fileInfo) {
                setUploadedDateStr(fileInfo.uploadedDate);
                setIsPageControlable(true); // 後方互換。既に画像がアップロードされている案件については無条件でenableにする
            }
            
            
            setIsFileLoadingNow(false);
        };

        asyncFunc();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (image === null || isImageDownloaded) {
            return;
        }

        const asyncFunc = async () => {
            try {
                setIsPageControlable(false);
                setIsFileUploading(true);
                setIsFileUploadingLocal(true);
                const success = await Action.uploadImage(title, id, image, stationId, classification, projectId, action);
    
                if (!success) {
                    return;
                }

                console.log(`upload ${image.name}`);
    
                setShowImage(image);    // 遅延設定
    
                const fileInfo = await Action.getImageFileInfo(stationId, classification, id);
                if (fileInfo) {
                    setUploadedDateStr(fileInfo.uploadedDate);
                }
            }
            finally {
                setIsFileUploadingLocal(false);
                setIsFileUploading(false);
                setIsPageControlable(true);
            }
        }

        asyncFunc();
    }, [id, image, isImageDownloaded, title, id, stationId, classification, projectId, action]);

    React.useEffect(() => {
        if (!isFileDeleting) {
            return;
        }

        const asyncFunc = async () => {
            try {
                const success = await Action.deleteImage(stationId, classification, id);
                if (success) {
                    setShowImage(null);
                }
            }
            finally {
                setIsFileDeleting(false);
            }
        };

        asyncFunc();
    }, [isFileDeleting, stationId, classification, id]);

    const thumbnail = React.useMemo(() => {
        const date = uploadedDateStr === null ? undefined : uploadedDateStr;

        let dom = <React.Fragment><br /><Spinner /></React.Fragment>;
        if (!isFileLoadingNow) {
            dom = <FileSelectWithThumbnail
                width="20rem"
                file={showImage}
                onCapture={capture}
                preivewHook={onPreviewOpen}
                deleteHook={onDelete}
                filename={date}
                enable={isPageControlable}
            />;
        }

        return dom;
    }, [showImage, capture, onPreviewOpen, onDelete, uploadedDateStr, isFileLoadingNow, isPageControlable]);

    const previewDialog = React.useMemo(() => {
        const dialogHeight = window.innerHeight - (window.innerHeight / 100);   // 99%
        const dialogWidth = window.innerWidth - (window.innerWidth / 100);
        const size: {width: number | "auto", height: number | "auto"} = {width: dialogWidth, height: dialogHeight};
        if (dialogHeight > dialogWidth) {
            // 縦長ディスプレイの場合
            size.height = "auto";
        }
        else {
            // 横長ディスプレイの場合
            size.width = "auto";
        }

        let imageDOM = <React.Fragment></React.Fragment>;
        let imageUrl: string | null = null;
        if (showImage) {
            const url = URL.createObjectURL(showImage);
            imageUrl = url;

            const revoker = () => URL.revokeObjectURL(url);
            imageDOM = <img src={imageUrl} style={{...size, marginLeft: "auto", marginRight: "auto"}} alt={title} onLoad={revoker} />;
        }

        const onPreivewCloseClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setIsPreviewOpen(false);
        };

        const buttonZIndex = 2000;

        return (
            <Dialog
                open={isPreviewOpen}
                fullScreen={true}
                fullWidth={true}
                aria-describedby="full-screen-preview-dialog-description"
            >
                <DialogContent style={{padding: 0, marginRight: "auto", marginLeft: "auto"}}>
                    <div style={{...size, position: "relative", display: "inline-flex"}}>
                        <IconButton style={{position: "absolute", right: 0, top: 0, zIndex: buttonZIndex}} onClick={onPreivewCloseClick}>
                            <CloseIcon />
                        </IconButton>
                        {imageDOM}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }, [showImage, title, isPreviewOpen, setIsPreviewOpen]);

    return (
        <div key={title}>
            <span className="font-weight-bold h6">{title}</span><br />
            {thumbnail}
            {isFileUploadingLocal || isFileDeleting ? <LinearProgress style={{width: "100%", height: "0.2em"}} /> : ""}
            {previewDialog}
        </div>
    );
};


const toZeroPaddingString = (num: number) => `0${num}`.slice(-2);
const dateStrFormatter = (dateStr: string | null | undefined) => {
    let str = "-";
    if (dateStr) {
        const date = new Date(dateStr);
        str = `${date.getFullYear()}/${toZeroPaddingString(date.getMonth() + 1)}/${toZeroPaddingString(date.getDate())} ${toZeroPaddingString(date.getHours())}:${toZeroPaddingString(date.getMinutes())}:${toZeroPaddingString(date.getSeconds())}`;
    }
    return str;
}

const statusStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacing: {
            marginBottom: "1em"
        },
        gridTextRoot: {
            display: "flex",
            alignItems: "center"
        },
        gridTextVerticalCenter: {
            display: "block"
        },
        buttonPosition: {
            [theme.breakpoints.down("sm")]: {
                textAlign: "right"
            }
        }
    })
)

interface IStatusCardProps {
    id: number;
    title: string;
    disabled: boolean;
    onExecute?: () => void;
    onLoad?: (aleadyCompleted: boolean) => void;
}
const StatusCard: React.FunctionComponent<IStatusCardProps> = (props) => {
    const {id, title, disabled, onExecute, onLoad} = props;

    const {stationId, projectId, classification, action} = React.useContext(BooksFormContext);

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isUpdateNow, setIsUpdateNow] = React.useState(false);
    const [completedDateStr, setCompletedDateStr] = React.useState<string | null | undefined>(null);
    const [staffName, setStaffName] = React.useState<string | null | undefined>(null);

    const classes = statusStyles();

    React.useEffect(() => {
        if (isLoaded) {
            return;
        }

        const asyncFunc = async () => {
            const result = await Action.getStatusInfo(stationId, classification, id);

            let aleadyCompleted = false;

            if (result === undefined) {
                setCompletedDateStr(undefined);
                setStaffName(undefined);
            }
            else if (result === null) {
                setCompletedDateStr(null);
                setStaffName(null);
            }
            else {
                setCompletedDateStr(result.date);
                setStaffName(result.userName);

                if (result.date && result.userName) {
                    aleadyCompleted = true;
                }
            }

            if (onLoad !== undefined) {
                onLoad(aleadyCompleted);
            }

            setIsLoaded(true);
        }

        asyncFunc();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (!isUpdateNow) {
            return;
        }

        const asyncFunc = async () => {
            const result = await Action.updateStatusInfo(stationId, projectId, classification, action, id);
            if (result) {
                setCompletedDateStr(result.date);
                setStaffName(result.userName);

                if (onExecute !== undefined) {
                    onExecute();
                }
            }

            setIsUpdateNow(false);
        }

        asyncFunc();
    }, [isUpdateNow, stationId, projectId, classification, action, id, onExecute]);

    const onExecuteButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setIsUpdateNow(true);
    
    return (
        <div className={`container ${classes.spacing}`}>
            <div className="row align-items-center">
                <Grid container>
                    <Grid item xs={12} md={3} className={classes.gridTextRoot}>
                        <span className={classes.gridTextVerticalCenter}>{title}</span>
                    </Grid>
                    {!isLoaded ? <Grid item xs={12} md={9}><Spinner /></Grid> : (
                        <React.Fragment>
                            <Grid item xs={7} md={5} className={classes.gridTextRoot}>
                                <span className={classes.gridTextVerticalCenter}>{dateStrFormatter(completedDateStr)}</span>
                            </Grid>
                            <Grid item xs={5} md={3} className={classes.gridTextRoot}>
                                <span className={classes.gridTextVerticalCenter}>{staffName ? staffName : "-"}</span>
                            </Grid>
                            <Grid item xs={12} md={1} className={classes.buttonPosition}>
                                <Button variant="outlined" size="small" onClick={onExecuteButtonClick} disabled={isUpdateNow || !isLoaded || disabled}>実行</Button>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </div>
            {isUpdateNow ? <LinearProgress style={{width: "100%", height: "0.1em"}} /> : ""}
        </div>
    )
}

interface IDownloadCardProps {
    id: number;
    title: string;
    disabled: boolean;
}
const DownloadCard: React.FunctionComponent<IDownloadCardProps> = (props) => {
    const {stationId, projectId, classification, action} = React.useContext(BooksFormContext);
    const {value: isFileUploading} = React.useContext(FileUploadContext);

    const {id, title, disabled} = props;

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isUpdateNow, setIsUpdateNow] = React.useState(false);
    const [completedDateStr, setCompletedDateStr] = React.useState<string | null | undefined>(null);
    const [staffName, setStaffName] = React.useState<string | null | undefined>(null);

    const classes = statusStyles();

    React.useEffect(() => {
        if (isLoaded) {
            return;
        }

        const asyncFunc = async () => {
            const result = await Action.getStatusInfo(stationId, classification, id);

            if (result === undefined) {
                setCompletedDateStr(undefined);
                setStaffName(undefined);
            }
            else if (result === null) {
                setCompletedDateStr(null);
                setStaffName(null);
            }
            else {
                setCompletedDateStr(result.date);
                setStaffName(result.userName);
            }

            setIsLoaded(true);
        }

        asyncFunc();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (!isUpdateNow) {
            return;
        }

        const asyncFunc = async () => {
            const result = await Action.updateStatusInfo(stationId, projectId, classification, action, id);
            if (result) {
                setCompletedDateStr(result.date);
                setStaffName(result.userName);
            }

            setIsUpdateNow(false);
        }

        asyncFunc();
    }, [isUpdateNow, stationId, projectId, classification, action, id]);

    const onExecuteButtonClick = () => setIsUpdateNow(true);
    
    const urlGetter = () => Action.downloadReport(stationId, projectId, classification, action);
    const onUrlGetFail = () => alert("報告書の取得に失敗しました。リトライしてください");
    
    return (
        <div className={`container ${classes.spacing}`}>
            <div className="row align-items-center">
                <Grid container>
                    <Grid item xs={12} md={3} className={classes.gridTextRoot}>
                        <span className={classes.gridTextVerticalCenter}>{title}</span>
                    </Grid>
                    {!isLoaded ? <Grid item xs={12} md={9}><Spinner /></Grid> : (
                        <React.Fragment>
                            <Grid item xs={7} md={5} className={classes.gridTextRoot}>
                                <span className={classes.gridTextVerticalCenter}>{dateStrFormatter(completedDateStr)}</span>
                            </Grid>
                            <Grid item xs={5} md={3} className={classes.gridTextRoot}>
                                <span className={classes.gridTextVerticalCenter}>{staffName ? staffName : "-"}</span>
                            </Grid>
                            <Grid item xs={12} md={1} className={classes.buttonPosition}>
                                <DownloadButton
                                    size="small"
                                    variant="outlined"
                                    onClick={onExecuteButtonClick}
                                    style={{marginLeft: "auto"}}
                                    disabled={isUpdateNow || !isLoaded || disabled || isFileUploading}
                                    urlGetter={urlGetter}
                                    onFail={onUrlGetFail}
                                >
                                    実行
                                </DownloadButton>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </div>
            {isUpdateNow ? <LinearProgress style={{width: "100%", height: "0.1em"}} /> : ""}
        </div>
    );
}

interface ICommentBlockProps {
    id: number;
    title: string;
    style?: React.CSSProperties;
}
const CommentBlock = (props: ICommentBlockProps) => {
    const [isNowEditing, setIsNowEditing] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [isLoaded, setIsLoaded] = React.useState(false);

    const {isPageControlable} = React.useContext(PageControlContext);
    
    const {stationId, projectId, classification, action} = React.useContext(BooksFormContext);

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!isNowEditing) {
            setIsNowEditing(true);
        }
        else {
            setIsSaving(true);
        }
    }

    const [text, setText] = React.useState("");
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newText = event.target.value;
        if (newText.length > 150 || !isNowEditing) {
            return;
        }
        setText(newText);
    };

    React.useEffect(() => {
        if (isLoaded) {
            return;
        }

        const asyncFunc = async () => {
            const comment = await Action.getComment(stationId, projectId, classification, action, props.id);
            if (comment != null) {
                setText(comment);
            }

            setIsLoaded(true);
        }

        asyncFunc();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (!isNowEditing || !isSaving) {
            return;
        }

        const asyncFunc = async () => {
            const result = await Action.updateComment(stationId, projectId, classification, action, props.id, text);
            if (!result) {
                alert("更新に失敗しました");
            }
            else {
                setIsNowEditing(false);
            }

            setIsSaving(false);
        }

        asyncFunc();
    }, [isNowEditing, isSaving, text, stationId, projectId, classification, action, props.id]);

    const content = !isLoaded ? <Spinner /> : (
        <React.Fragment>
            <div className="d-flex align-items-end">
                <TextField
                    label={props.title}
                    multiline
                    value={text}
                    onChange={onChange}
                    variant="outlined"
                    style={{width: "90%"}}
                />
                <IconButton onClick={onEditClick} size="small" disabled={isSaving || !isPageControlable}>
                    {isNowEditing ? <SaveIcon /> : <EditIcon />}
                </IconButton>
            </div>
            {isSaving ? <LinearProgress style={{width: "100%", height: "0.1em"}} /> : ""}
        </React.Fragment>
    );

    return (
        <div style={props.style} className="m-1">
            {content}
        </div>
    )
}