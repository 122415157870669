import * as Home from '../store/HomeState';
import * as HomeAction from '../actions/types/HomeActionType';
import { ActionType } from '../actions/types/ActionType';
import * as lodash from 'lodash';
import { CombineReducerMap } from '../types/Redux';
import { combineReducers } from 'redux';
import { BaseStationSammary, ProjectForBaseStaion, BaseStationDetail } from '../types/StationTypes';

const pagePreparation = (prevState: string | null | undefined, actionBase: ActionType) => {
    if (prevState === undefined) {
        return null;
    }

    if (actionBase.type !== HomeAction.preparateFinished) {
        return prevState;
    }
    
    const action = actionBase as HomeAction.PreparateFinished;
    if (action.apiKey === undefined) {
        return null;
    }

    return action.apiKey;
}

const loadBaseStation = (prevState: BaseStationSammary[] | undefined, actionBase: ActionType): BaseStationSammary[] => {
    const action = actionBase as HomeAction.LoadBaseStation;
    const newState = action.stations;

    if (prevState === undefined) {
        return [];
    }

    if (actionBase.type !== HomeAction.loadBaseStation) {
        return prevState;
    }

    if (prevState.length !== action.stations.length) {
        return newState;
    }

    const beforeArray = lodash.sortBy(lodash.cloneDeep(prevState), (station) => station.planned.id);
    const afterArray = lodash.sortBy(lodash.cloneDeep(action.stations), (station) => station.planned.id);

    const isSequeceEqual = lodash.isEqual(beforeArray, afterArray);
    
    if (!isSequeceEqual) {
        return newState;
    }

    return prevState;
}

const changeSelectedStation = (prevState: BaseStationDetail | null | undefined, actionBase: ActionType): (BaseStationDetail | null) => {
    if (prevState === undefined) {
        return null;
    }
    if (actionBase.type !== HomeAction.changeSelectedStation) {
        return prevState;
    }
    const action = actionBase as HomeAction.ChangeSelectedStation;

    return action.station;
}

const pickupProjects = (prevState: ProjectForBaseStaion[] | undefined, actionBase: ActionType): ProjectForBaseStaion[] => {
    if (prevState === undefined) {
        return [];
    }
    
    if (actionBase.type !== HomeAction.pickupProjects) {
        return prevState;
    }

    const action = actionBase as HomeAction.PickupProject;

    if (prevState === null) {
        return action.projects;
    }

    if (prevState.length !== action.projects.length) {
        return action.projects;
    }

    const beforeArray = lodash.sortBy(lodash.cloneDeep(prevState), (project) => project.id);
    const afterArray = lodash.sortBy(lodash.cloneDeep(action.projects), (project) => project.id);

    const isSequeceEqual = lodash.isEqual(beforeArray, afterArray);

    if (!isSequeceEqual) {
        return action.projects;
    }
    
    return prevState;
}

const saveSearchedProjectCode = (prevState: string | null | undefined, actionBase: ActionType): string | null => {
    if (prevState === undefined) {
        return null;
    }
    if (actionBase.type !== HomeAction.saveSearchedProjectCode) {
        return prevState;
    }

    const action = actionBase as HomeAction.SaveSearchedProjectCode;

    return action.projectCode;
}

const homeReducerMap: CombineReducerMap<Home.State> = {
    googleMapsAPIKey: pagePreparation,
    selectedStationDetail: changeSelectedStation,
    stations: loadBaseStation,
    pickupedProjects: pickupProjects,
    searchedProjectCode: saveSearchedProjectCode
};

export const HomeReducer = combineReducers(homeReducerMap);