import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { IPublishableUserData, getUsers, deleteUser } from 'actions/AuthorizationAction';
import { Spinner } from 'reactstrap';
import { BackButton } from 'components/BackButton';
import { IconButton, Table, TableRow, TableCell, TableHead, TableBody, Tooltip, LinearProgress, Checkbox } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Store } from 'store/GlobalState';
import { routePush } from 'actions/utils/History';
import { temporaryUserRegisterPath } from './TemporaryUserRegister';
import { setNotificationConfig } from 'actions/UserConfigAction';

export const userManagementPath = '/management';

const userListTableHeader = [
    "ユーザーID",
    "ユーザー名",
    "最終ログイン日時",
    "有効期限",
    "通知",
    ""
];

export const UserManagement = (props: RouteComponentProps) => {
    const [userList, setUserList] = React.useState<IPublishableUserData[] | null>(null);
    const [getUserListTask, setGetUserListTask] = React.useState<Promise<IPublishableUserData[] | null> | null>(null);

    const loginedUserId = Store.getState().loginPage.id;

    React.useEffect(() => {
        setGetUserListTask(getUsers());
    }, []);

    React.useEffect(() => {
        if (!getUserListTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!getUserListTask) {
                return;
            }
            const result = await getUserListTask;
            if (result) {
                if (!isUnmounted) {
                    setUserList(result);
                }
            }
            else {
                window.location.reload();
            }
        }

        asyncFunc();
        return () => {
            isUnmounted = true;
        };
    }, [getUserListTask]);

    const onAddButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Store.dispatch(routePush(temporaryUserRegisterPath));
    const onDeleteButtonClick = (deleteUserId: string, onFinish: () => void) => {
        const agree = window.confirm(`${deleteUserId}を削除します。\nよろしいですか？`);
        if (agree) {
            deleteUser(deleteUserId).then(result => {
                if (result) {
                    setGetUserListTask(getUsers());
                    onFinish();
                }
            })
        }
    }

    const tableHeader = React.useMemo(() => (
        <TableHead>
            <TableRow>
                {userListTableHeader.map(h => <TableCell key={h}>{h}</TableCell>)}
            </TableRow>
        </TableHead>
    ), []);

    return (
        <React.Fragment>
            <BackButton />
            <div className="container">
                <div className="text-center h2">ユーザー管理ページ</div>
                <div className="text-right">
                    <Tooltip title="ユーザー追加" placement="top">
                        <IconButton size="small" onClick={onAddButtonClick}><AddIcon fontSize="large" /></IconButton>
                    </Tooltip>
                </div>
                <Table stickyHeader style={{maxHeight: "100vh"}}>
                    {tableHeader}
                    <TableBody>
                        {!userList ? <Spinner /> : userList.map(u => <UserTableRow key={u.userId} user={u} onDeleteClick={onDeleteButtonClick} deleteDisable={u.userId === loginedUserId} />)}
                    </TableBody>
                </Table>
            </div>
        </React.Fragment>
    );
}
interface IUserTableRowProps {
    user: IPublishableUserData;
    onDeleteClick: (userId: string, onFinish: () => void) => void;
    deleteDisable: boolean;
}
const UserTableRow = (props: IUserTableRowProps) => {
    const {user, onDeleteClick, deleteDisable} = props;

    const [isDeleting, setIsDeleting] = React.useState(false);
    const [enableNotification, setEnableNotification] = React.useState(user.enableNotification);
    const [setNotificationTask, setSetNotificationTask] = React.useState<Promise<boolean> | null>(null);
    React.useEffect(() => {
        if (!setNotificationTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!setNotificationTask) {
                return;
            }

            const result = await setNotificationTask;
            if (!result && !isUnmounted) {
                setEnableNotification(!enableNotification);
            }

            setSetNotificationTask(null);
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [setNotificationTask]);

    const onDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsDeleting(true);
        onDeleteClick(user.userId, () => setIsDeleting(false));
    }

    const onNotificationCheckChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setEnableNotification(checked);
        setSetNotificationTask(setNotificationConfig(user.userId, checked));
    }
    console.log(`notification ${user.enableNotification}`);

    return (
        <TableRow style={{backgroundColor: user.accountExpireDate ? "yellow" : undefined}}>
            <TableCell>{user.userId}</TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.lastLoginDate?.toLocaleString("ja") ?? "-"}</TableCell>
            <TableCell>{user.accountExpireDate?.toLocaleString("ja") ?? "-"}</TableCell>
            <TableCell>
                <Checkbox
                    checked={enableNotification}
                    onChange={onNotificationCheckChange}
                    disabled={!!setNotificationTask}
                    color="primary"
                />
                {setNotificationTask ? <LinearProgress style={{width: "100%", height: "0.2rem"}} /> : <React.Fragment></React.Fragment>}
            </TableCell>
            <TableCell>
                <React.Fragment>
                    <Tooltip title="削除" placement="top">
                        <IconButton style={{backgroundColor: (deleteDisable || isDeleting) ? "gray" : "red"}} size="small" onClick={onDeleteButtonClick} disabled={deleteDisable}>
                            <DeleteIcon fontSize="large" style={{color: "white"}} />
                        </IconButton>
                    </Tooltip>
                    {isDeleting ? <LinearProgress style={{width: "100%", height: "0.2rem"}} /> : <React.Fragment></React.Fragment>}
                </React.Fragment>
            </TableCell>
        </TableRow>
    );
}