import * as React from 'react';
import { RoleFilter, Role } from 'components/Role';
import { Switch, Route, Router } from 'react-router-dom';
import { documentListPath, DocumentList } from './DocumentList';
import { DocumentStatusPath, DocumentStatus } from './DocumentStatus';
import { sendRegisterPath, SendRegister } from './SendRegister';
import { receiveRegisterPath, ReceiveRegister } from './ReceiveRegister';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { List, Divider } from '@material-ui/core';
import { MenuCollapseItem, MenuLinkButtonItem } from 'components/MenuCollapseItem';
import * as GlobalMenuSetter from 'actions/types/GlobalMenuActionType';
import { SystemTypes } from 'store/SystemConstants';
import * as SystemDataSetter from 'actions/types/SystemConstantsActionType';
import { Store, GlobalState } from 'store/GlobalState';
import { connect, useSelector } from 'react-redux';
import { IHistoryWrapper } from 'actions/types/HistoryType';
import { sendHistoryPath, SendHistory } from './SendHistory';
import { sendHistoryDetailPath, SendHistoryDetail } from './SendHistoryDetail';
import { loadServerConstantsAsync, updateServerConstants } from 'actions/MartzMix/LoadServerConstantsActions';
import { importDocumentPageName, importDocumentPath, ImportDocument } from './ImportDocument';
import { SetComplete, setCompletePageName, setCompletePath } from './SetComplete';

export const martzMixRootPath = '/martzmix';

export const MartzMixApp = connect(
    (state: GlobalState): IHistoryWrapper => (state.history)
)((props: IHistoryWrapper) => {
    React.useEffect(() => {
        const asyncFunc = async () => {
            const response = await loadServerConstantsAsync();
            
            if (response) {
                Store.dispatch(updateServerConstants(response, Store.getState().serverConstants));
            }
        }

        asyncFunc();
    }, []);

    return (
        <RoleFilter accessableRoles={[Role.MartzMixUser]}>
            <MenuBuildViewAction />
            <Router history={props.history} children={(
                <Switch>
                    <Route exact path={documentListPath} component={DocumentList} />
                    <Route exact path={importDocumentPath} component={ImportDocument} />
                    <Route path={DocumentStatusPath} component={DocumentStatus} />
                    <Route exact path={sendRegisterPath} component={SendRegister} />
                    <Route exact path={receiveRegisterPath} component={ReceiveRegister} />
                    <Route exact path={sendHistoryPath} component={SendHistory} />
                    <Route path={sendHistoryDetailPath} component={SendHistoryDetail} />
                    <Route exact path={setCompletePath} component={SetComplete} />
                </Switch>
            )} />
        </RoleFilter>
    );
});

export const MenuBuildViewAction: React.StatelessComponent = () => {
    const globalMenuSetAction: GlobalMenuSetter.SetGlobalMenuAction = {
        type: GlobalMenuSetter.setGlobalMenu,
        element: <Menu />
    }

    Store.dispatch(globalMenuSetAction);


    const systemNameSetAction: SystemDataSetter.LoadSystemConstants = {
        type: SystemDataSetter.loadSystemConstants,
        data: { system: SystemTypes.Mix }
    };

    Store.dispatch(systemNameSetAction);

    return <React.Fragment></React.Fragment>;
};

const menuStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            width: "100%",
            backgroundColor: theme.palette.background.paper
        }
    })
);

const judgeIsAdminRole = (accessKey: string | undefined) => {
    if (!accessKey) {   // 空文字でも該当
        return false;
    }
    
    return accessKey[0] === "2";
}
const titleLinkSet: {title: string, path: string, rolesJudge?: (accessKey: string | undefined) => boolean}[] = [
    {title: "書類一覧", path: documentListPath},
    {title: "送付登録", path: sendRegisterPath},
    {title: "受領登録", path: receiveRegisterPath},
    {title: "送付履歴", path: sendHistoryPath},
    {title: importDocumentPageName, path: importDocumentPath},
    {title: setCompletePageName, path: setCompletePath, rolesJudge: judgeIsAdminRole}
];


const Menu = () => {
    const accessKey = useSelector<GlobalState, string>((s) => s.loginPage.accessKey);
    const classes = menuStyles();

    const items: React.ReactNode[] = [];
    if (accessKey) {
        for (const tl of titleLinkSet) {
            if (tl.rolesJudge && !tl.rolesJudge(accessKey)) {
                continue;
            }
    
            items.push((
                <React.Fragment key={`${tl.title} linkto=${tl.path}`}>
                    <MenuLinkButtonItem path={tl.path}>{tl.title}</MenuLinkButtonItem>
                    <Divider />
                </React.Fragment>
            ));
        }
    }

    return (
        <List
            component="nav"
            className={classes.root}
        >
            <MenuCollapseItem title="文書管理" defaultOpen={true}>
                {items}
            </MenuCollapseItem>
        </List>
    );
};