import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export interface IBackButtonProps extends RouteComponentProps {
    className?: string;
}
export const BackButton = withRouter((props: IBackButtonProps) => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => props.history.goBack();
    return (
        <IconButton
            color="inherit"
            onClick={onClick}
            className={props.className}
        >
            <ArrowBackIosIcon />
        </IconButton>
    );
});