import { ActionType } from './ActionType';
import { History } from 'history';

export interface IHistoryWrapper { history: History<any>; }

export const routePushAction = "location url push";
export interface IRoutePushAction extends ActionType {
    path: string;
}

export const routeGoBackAction = "location url back";
export interface IRouteGoBackAction extends ActionType {}

export const inject = "inject history";
export interface IRouterInject extends ActionType {
    history: History<any>;
}