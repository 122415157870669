import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { BackButton } from 'components/BackButton';
import { IDocumentOneOfStatus, getDocumentStatusDetailAsync, IRouteInfo, getDocumentRouteStructureAsync, IDocumentStatus, modifyDocumentSendCopies } from 'actions/MartzMix/DocumentActions';
import { TableHead, TableRow, TableCell, Button, Table, TableBody, TextField, Grid } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import * as QueryString from 'query-string';
import { RouteComponentProps } from 'react-router';
import { toDateTimeString } from 'actions/utils/StringFormatter';
import { checkRole } from 'actions/utils/Role';
import { SwitchableTextbox } from 'components/SwitchableTextbox';
import { Data } from '@react-google-maps/api';
import { Store } from 'store/GlobalState';

export const DocumentStatusPath = '/martzmix/status';


const statusText: string[] = [
    "作成",
    "管財発送済",
    "一次送付先発送受領済",
    "一次送付先発送済",
    "二次送付先発送受領済",
    "二次送付先返送済",
    "一次送付先返送受領済",
    "一次送付先返送済",
    "管財返送受領済",
    "完了"
];

export const DocumentStatus = (props: RouteComponentProps) => {
    const [status, setStatus] = React.useState<IDocumentStatus | null>(null);

    const [routeList, setRouteList] = React.useState<IRouteInfo[] | null>(null);
    
    const {id: documentIdStr} = QueryString.parse(props.location.search);
    const documentId = parseInt(documentIdStr as string, 10);

    React.useEffect(() => {
        const asyncFunc = async () => {
            const details = await getDocumentStatusDetailAsync(documentId);
            setStatus(details);

            const routes = await getDocumentRouteStructureAsync(documentId);
            setRouteList(routes);
        };

        asyncFunc();
    }, []);

    if (!status || !routeList) {
        return <Spinner />;
    }

    const accessKey = Store.getState().loginPage.accessKey;

    return (
        <React.Fragment>
            <BackButton />
            <div>
                <fieldset className="border border-primary d-inline-flex">
                    <legend className="w-auto h6">文書情報</legend>
                    <div className="d-inline-flex flex-column m-2">
                        <div className="d-inline-flex">
                            <div style={{width: "6em"}}>局番:</div><div style={{minWidth: "15em"}}>{status.stationNumber}</div>
                            <div style={{width: "6em"}}>局名:</div><div style={{minWidth: "15em"}}>{status.stationName}</div>
                        </div>
                        <div className="d-inline-flex">
                            <div style={{width: "6em"}}>文書番号:</div><div style={{minWidth: "15em"}}>{status.code}</div>
                            <div style={{width: "6em"}}>文書名:</div><div style={{minWidth: "15em"}}>{status.name}</div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <StatusTable
                statusList={status.statuses}
                routeList={routeList}
                documentCode={status.code}
                accessKey={accessKey}
            />
        </React.Fragment>
    );
}

interface IStatusTableProps {
    statusList: IDocumentOneOfStatus[] | null;
    routeList: IRouteInfo[] | null;
    documentCode: string;
    accessKey: string;
}
interface IStatusTableRowData {
    step: number;
    finished: boolean;
    copies?: number;
    label: string;
    lastModified?: string;
    slipNumber?: string;
    documentCode: string;
}
const StatusTable = (props: IStatusTableProps) => {
    const {statusList, routeList, documentCode, accessKey} = props;

    const header = React.useMemo(() => (
        <TableHead>
            <TableRow>
                <TableCell>ステップ</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>部数</TableCell>
                <TableCell>更新日時</TableCell>
                <TableCell>送り状番号</TableCell>
            </TableRow>
        </TableHead>
    ), []);


    if (!statusList || !routeList) {
        return (
            <Table>
                {header}
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Spinner />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    const routeInfoMap = new Map<number, IRouteInfo>(routeList.map(r => [{step: r.startStep, value: r}, {step: r.endStep, value: r}]).flat().map(v => [v.step, v.value]));
    const availableSteps = routeList.map(r => [r.startStep, r.endStep]).flat();
    const stepMap = new Map<number, IDocumentOneOfStatus>(statusList.map(value => [value.status.step, value]));

    const rowDataList: IStatusTableRowData[] = [];
    for (const step of availableSteps) {
        const status = stepMap.get(step);

        const rowData: IStatusTableRowData = {
            step: step,
            finished: !!status,
            copies: routeInfoMap.get(step)?.copies,
            label: statusText[step],
            lastModified: status?.status.lastModified,
            slipNumber: status?.slipNumber,
            documentCode
        };

        rowDataList.push(rowData);
    }

    const rowDOMs: JSX.Element[] = [];
    rowDOMs.push(<StatusRow key="document created history row" step={0} documentCode={documentCode} label={statusText[0]} finished={true} lastModified={statusList[0].status.lastModified} />);

    // 2行ずつセットで
    for (let i = 0; i < rowDataList.length; i = i + 2) {
        const sendData = rowDataList[i];
        const receiptData = rowDataList[i + 1];

        rowDOMs.push(<StatusRowSet key={`${i}th row set`} accessKey={accessKey} send={sendData} receipt={receiptData} />);
    }

    let isRouteCompleted = false;
    let routeCompletedTime: string | undefined = undefined;

    const completeStatus = statusList.find(s => s.status.step === 9);
    if (completeStatus) {
        isRouteCompleted = true;
        routeCompletedTime = completeStatus.status.lastModified;
    }
    rowDOMs.push(<StatusRow key="document route complete history row" step={9} documentCode={documentCode} label={statusText[9]} finished={isRouteCompleted} lastModified={routeCompletedTime} />); 

    return (
        <Table>
            {header}
            <TableBody>
                {rowDOMs}
            </TableBody>
        </Table>
    );
}

interface IStatusRowProps {
    step: number;
    label: string;
    finished: boolean;
    lastModified?: string;
    documentCode: string;
    copies?: number | undefined;
    enableCopiesModify?: boolean | undefined;
    onCopiesChange?: (current: number) => void;
    slipNumber?: string | undefined;
}
interface IStatusRowSetProps {
    accessKey: string;
    send: IStatusRowProps;
    receipt: IStatusRowProps;
}

const StatusRow: React.FunctionComponent<IStatusRowProps> = (props) => {
    const {label, finished, lastModified, documentCode, copies, step, enableCopiesModify, onCopiesChange, slipNumber} = props;

    let copiesField: React.ReactNode = copies ?? "-";
    if (enableCopiesModify) {
        const onSave = async (current: string) => {
            const currentCopies = parseInt(current, 10);
            if (isNaN(currentCopies)) {
                console.error(`${current} is not number`);
                return false;
            }
    
            console.log(`before=${copies} after=${currentCopies}`);
            if (currentCopies === copies) {
                return true;
            }
    
            const result = await modifyDocumentSendCopies(documentCode!, currentCopies, step, slipNumber);
            if (result && onCopiesChange) {
                onCopiesChange(currentCopies);
            }
    
            return result;    
        }
        copiesField = !copies ? "-" : (  
            <SwitchableTextbox
                value={`${copies}`}
                variant="standard"
                onSave={onSave}
                inputProps={{type: "number", min: "0"}}
                style={{width: "5rem"}}
            />
        );
    }

    return (
        <TableRow>
            <TableCell>{label}</TableCell>
            <TableCell>{finished ? <span style={{color: "springgreen"}}>済</span> : "未"}</TableCell>
            <TableCell>{copiesField}</TableCell>
            <TableCell>{lastModified ? toDateTimeString(new Date(lastModified)) : "-"}</TableCell>
            <TableCell>{slipNumber ?? "-"}</TableCell>
        </TableRow>
    );
};

const StatusRowSet = (props: IStatusRowSetProps) => {
    const {send, receipt, accessKey} = props;
    const [copies, setCopies] = React.useState<number>(0);

    React.useEffect(() => {
        setCopies(send.copies!);
    }, []);

    const isUQ = accessKey.charAt(0) === '2';

    return (
        <React.Fragment>
            <StatusRow
                key={`${send.label} row`}
                step={send.step}
                label={send.label}
                finished={send.finished}
                lastModified={send.lastModified}
                documentCode={send.documentCode}
                copies={copies}
                enableCopiesModify={isUQ}
                onCopiesChange={setCopies}
                slipNumber={send.slipNumber} />
            <StatusRow
                key={`${receipt.label} row`}
                step={receipt.step}
                label={receipt.label}
                finished={receipt.finished}
                lastModified={receipt.lastModified}
                documentCode={receipt.documentCode}
                copies={copies}
                slipNumber={receipt.slipNumber} />
        </React.Fragment>
    )
}
