import * as React from 'react';
import { TooltipProps, Tooltip } from '@material-ui/core';

export const TooltipWithModal: React.FunctionComponent<TooltipProps> = (props) => {
    const property: TooltipProps = {...props};

    const outerDivRef = React.useRef<HTMLDivElement>(null);

    const onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
        console.log("blur");
        outerDivRef.current?.click();
    }

    return (
        <div className="d-inline-flex">
            <Tooltip {...property}>
                <div className="d-inline-flex" onBlur={onBlur}>{props.children}</div>
            </Tooltip>
        </div>
    )
}