import  * as React from 'react';
import { ISendRegisterDocument, ISendRegister, registerSendAsync, getSlipAsync, IDocumentData, getDocumentDataAsync, validateDocumentRoutingAsync, getGeneratedSlipNumber, getDocumentHistoryDataAsync } from 'actions/MartzMix/SendRegisterAction';
import { TableHead, TableRow, TableCell, TextField, FormControl, Select, MenuItem, Table, TableBody, Button, Tooltip, IconButton, Divider, TextFieldProps, makeStyles, Theme, createStyles } from '@material-ui/core';
import { BarcodeReaderButton } from 'components/BarcodeReaderButton';
import AddIcon from '@material-ui/icons/Add';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DownloadButton } from 'components/DownloadButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { Spinner } from 'reactstrap';
import { TooltipWithModal } from 'components/TooltipWithModal';
import { QrCodeReaderButton } from 'components/QrCodeReaderButton';
import { cacheFlagsClear } from 'actions/types/CacheFlagsActionType';
import { Store } from 'store/GlobalState';
import { RouteComponentProps } from 'react-router';
import * as QueryString from 'query-string';
import { getSendHistoryDetailAsync, updateSendHistoryDetailAsync } from 'actions/MartzMix/SendHistoryAction';

export const sendRegisterPath = '/martzmix/sendRegister';

const inputFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        forIEStyle: {"ime-mode": "inactive"}
    })
);

export const sendUnitRegister = (mode: "new" | "rewrite"): React.FunctionComponent<RouteComponentProps> => (() => {
    const tableHeaders = [
        "",
        "文書番号",
        "文書名",
        "局番",
        "局名",
        "部数",
        "",
        ""
    ];
    
    
    const getValidater = (current = "") => {
        let oldCode = current;
        const validateWithgetDocumentData = (code: string) => {
            if (oldCode === code) {
                return null;
            }
            oldCode = code;
    
            if (!code) {
                return null;
            }
    
            return (async (): Promise<IDocumentData | null> => {
                const isValid = await validateDocumentRoutingAsync(code);
                if (!isValid) {
                    return null;
                }
            
                const data = await getDocumentDataAsync(code);
                return data;
            })();
        }
    
        return validateWithgetDocumentData;
    }

    const getLoader = (current = "") => {
        let oldCode = current;
        return (code: string, slipId: number) => {
            if (oldCode === code) {
                return null;
            }
            oldCode = code;
    
            if (!code) {
                return null;
            }
    
            return (async (): Promise<IDocumentData | null> => {
                return getDocumentHistoryDataAsync(code, slipId);
            })();
        }
    }
    
    const SendRegisterTable: React.FunctionComponent = (props) => {
        const header = (
            <TableHead>
                <TableRow>
                    {tableHeaders.map((h, idx) => <TableCell key={`${idx} th table head: ${h}`}>{h}</TableCell>)}
                </TableRow>
            </TableHead>
        );
    
        const {children} = props;
    
        return (
            <Table>
                {header}
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        )
    }
    
    const enterKeycode = 13;
    interface ISendRegisterTableRowProps {
        rowIndex: number;
        code: string;
        reloadTargetCodeList: string[];
        allCodeList: string[];
        setValid: (value: boolean) => void;
        onCodeChange: (rowIndex: number, current: string) => void;
        onRowDelete: (rowIndex: number) => void;
        slipId: number;
        slipNumber?: string;
    }
    const SendRegisterTableRow = (props: ISendRegisterTableRowProps) => {
        const {rowIndex, code, reloadTargetCodeList, allCodeList, setValid, onCodeChange: onChange, onRowDelete} = props;
    
        const [documentData, setDocumentData] = React.useState<IDocumentData | null>(null);
        const [documentDataResolver, setDocumentDataResolver] = React.useState<Promise<IDocumentData | null> | null>(null);
        React.useEffect(() => {
            if (!documentDataResolver) {
                return;
            }
    
            let isUnmounted = false;
            const asyncFunc = async () => {
                const result = await documentDataResolver;
                if (!isUnmounted) {
                    setDocumentData(result);
                    setDocumentDataResolver(null);
                }
            }
    
            asyncFunc();
    
            return () => {isUnmounted = true;};
        }, [documentDataResolver]);
    
        const [copies, setCopies] = React.useState<number>(0);
        React.useEffect(() => {
            setValid(!!documentData);
        }, [documentData]);

        React.useEffect(() => {
            if (documentData) {
                setCopies(documentData.copies);
            }
        }, [documentData?.copies]);
    
        const validateWithgetDocumentData = React.useMemo(() => getValidater(code), [rowIndex]);
        const documentDataLoader = React.useMemo(() => getLoader(), [rowIndex]);
        

        // 保持しているコードと違うなら再検索をかける(初回/他の行の削除時)
        const [tempCode, setTempCode] = React.useState("");
        React.useEffect(() => {
            if (code === tempCode || !code) {
                return;
            }

            setTempCode(code);

            if (props.slipId !== 0) {
                setDocumentDataResolver(documentDataLoader(code, props.slipId));
            }
            else {
                setDocumentDataResolver(validateWithgetDocumentData(code));
            }
        }, [code]);
    
        // 変更時はfocus outで検索をかける
        const onBlur = (event: any) => {
            if (!documentDataResolver && code) {    // enter keyと競合しないようにする
                console.log(`focus out code=${code}`);

                if (mode === "rewrite" && reloadTargetCodeList.indexOf(code) >= 0 && props.slipId !== 0) {
                    setDocumentDataResolver(documentDataLoader(code, props.slipId));
                }
                else {
                    setDocumentDataResolver(validateWithgetDocumentData(code));
                }
            }
        }
        const onCodeFieldKeyCode = (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.keyCode === enterKeycode && code) {
                console.log(`enter key down code=${code}`);

                if (mode === "rewrite" && reloadTargetCodeList.indexOf(code) >= 0 && props.slipId !== 0) {
                    setDocumentDataResolver(documentDataLoader(code, props.slipId));
                }
                else {
                    setDocumentDataResolver(validateWithgetDocumentData(code));
                }
            }
        }
    
        const onCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const currentCode = (event.target.value as string) ?? null;
            console.log(`row = ${rowIndex} code = ${currentCode}`);
            onChange(rowIndex, currentCode);
            setTempCode(currentCode);
            setDocumentData(null);  // 一度検索したあとに変更された場合はnullに戻す
        };

        const codeFieldStyles = inputFieldStyles();
    
        const codeField = <TextField
            onChange={onCodeChange}
            onBlur={onBlur}
            onKeyDown={onCodeFieldKeyCode}
            value={code}
            style={{minWidth: "15em"}}
            inputProps={{className: codeFieldStyles.forIEStyle}} />;
    
    
        const onBarcodeRead = (code: string) => {
            console.log(`barcode read code=${code}`);
            onChange(rowIndex, code);
            setDocumentDataResolver(validateWithgetDocumentData(code));
        };
    
        const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            onRowDelete(rowIndex);
        }
    
        let additionalDataView = <React.Fragment><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell><TableCell></TableCell></React.Fragment>;
        if (documentDataResolver) {
            additionalDataView = (
                <React.Fragment>
                    <TableCell><Spinner /></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </React.Fragment>
            );
        }
        else if (documentData) {
            additionalDataView = (
                <React.Fragment>
                    <TableCell>{documentData.name}</TableCell>
                    <TableCell>{documentData.stationNumber}</TableCell>
                    <TableCell>{documentData.stationName}</TableCell>
                    <TableCell>{documentData.copies}</TableCell>
                </React.Fragment>
            );
        }
    
        return (
            <TableRow>
                <TableCell><span className="font-weight-bold">{rowIndex + 1}</span></TableCell>
                <TableCell>{codeField}</TableCell>
                {additionalDataView}
                <TableCell>
                    <TooltipWithModal title="文書情報バーコード読込" placement="right">
                        <QrCodeReaderButton size="small" onRead={onBarcodeRead} styles={{width: "2em"}} />
                    </TooltipWithModal>
                </TableCell>
                <TableCell>
                    <Tooltip title="行削除" placement="right">
                        <IconButton size="small" onClick={onDeleteClick}><CancelIcon style={{width: "1rem", height: "1rem"}} /></IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        );
    }



    const pageTitle = mode === "new" ? "送付登録" : "送付履歴編集";

    return (props: RouteComponentProps) => {
        const [codeList, setCodeList] = React.useState<string[]>([]);
        const [codeListOnLoad, setCodeListOnLoad] = React.useState<string[]>([]);
        const [rowValidList, setRowValidList] = React.useState<boolean[]>([]);
        const [nowInitializing, setNowInitializing] = React.useState(mode === "rewrite");
    
        const [slipId, setSlipId] = React.useState(0);
        const [slipNumber, setSlipNumber] = React.useState("");
        const [generateSlipNumberTask, setGenerateSlipNumberTask] = React.useState<Promise<string | null> | null>(null);
        React.useEffect(() => {
            if (!generateSlipNumberTask) {
                return;
            }
    
            let isUnmounted = false;
            const asyncFunc = async () => {
                if (!generateSlipNumberTask) {
                    return;
                }
    
                const result = await generateSlipNumberTask;
                if (result) {
                    setSlipNumber(result);
                }
    
                setGenerateSlipNumberTask(null);
            }
    
            asyncFunc();
            return () => {isUnmounted = true;};
        }, [generateSlipNumberTask]);
    
        const onSlipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const current = event.target.value;
            setSlipNumber(current);
        }
        const onSlipBarcodeRead = (code: string) => setSlipNumber(code);
        const onSlipNumberGenerate = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setGenerateSlipNumberTask(getGeneratedSlipNumber());
    
        const [comment, setComment] = React.useState("");
        const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const current = event.target.value;
            setComment(current);
        }
    
        const onDataChange = (rowIndex: number, value: string) => {
            setCodeList([...codeList.slice(0, rowIndex), value, ...codeList.slice(rowIndex + 1)]);
        }
    
        const addRowClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setCodeList([...codeList, ""]);
            setRowValidList([...rowValidList, false]);
        };
    
        const removeRowClick = (rowIndex: number) => {
            if (mode === "rewrite" && codeList.length === 1) {
                alert("文書リストを空にはできません");
            }
            
            setCodeList([...codeList.slice(0, rowIndex), ...codeList.slice(rowIndex + 1)]);
            setRowValidList([...rowValidList.slice(0, rowIndex), ...rowValidList.slice(rowIndex + 1)]);
        }
    
        const [historyId, setHistoryId] = React.useState<number | null>(null);
        const downloadButtonRef = React.useRef<HTMLButtonElement>(null);
        const slipUrlGetter = () => getSlipAsync(historyId!);   // nullならボタンを押せない
    
    
        const [registerTask, setRegisterTask] = React.useState<Promise<number | null> | null>(null);
        const registerClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (codeList.filter((code, idx, self) => self.indexOf(code) !== self.lastIndexOf(code)).length > 0) {
                alert("重複があります");
                return;
            }

            const sendData = {
                slipId,
                slipNumber,
                comment,
                documentCodeList: codeList
            };
    
            if (mode === "new") {
                setRegisterTask(registerSendAsync(sendData));
            }
            else {
                if (historyId !== null) {
                    setRegisterTask(updateSendHistoryDetailAsync(historyId, sendData));
                }
            }
        };
    
        React.useEffect(() => {
            if (!registerTask) {
                return;
            }
    
            let isUnmounted = false;
            const asyncFunc = async () => {
                if (!registerTask) {
                    return;
                }
    
                const result = await registerTask;
                if (!isUnmounted) {
                    if (!result) {
                        alert("登録に失敗しました");
                    }
                    else {
                        setHistoryId(result);
                        downloadButtonRef.current?.click();
                        Store.dispatch({type: cacheFlagsClear});
                        alert("登録が完了しました");

                        if (mode === "new") {
                            // 初期化
                            setSlipNumber("");
                            setCodeList([]);
                            setRowValidList([]);
                            setComment("");
                        }
                    }
                    
                    setRegisterTask(null);
                }
            }
    
            asyncFunc();
    
            return () => {isUnmounted = true;};
        }, [registerTask, downloadButtonRef]);
    
    
        const onBarcodeBatchRead = (addList: string[]) => {
            setCodeList([...codeList, ...addList]);
        }


        React.useEffect(() => {
            if (mode === "new") {
                return;
            }

            let isUnmounted = false;

            const asyncFunc = async () => {
                const {id: historyIdStr} = QueryString.parse(props.location.search);
                const historyIdArg = parseInt(historyIdStr as string, 10);
                if (isNaN(historyIdArg)) {
                    alert("パラメータが不正です。前のページへ戻ります");
                    props.history.goBack();
                    return;
                }

                setSlipId(historyIdArg);

                const response = await getSendHistoryDetailAsync(historyIdArg);

                if (isUnmounted) {
                    return;
                }

                if (!response) {
                    alert("データの取得に失敗しました");
                    return;
                }

                setSlipNumber(response.slipNumber);

                const codes = response.documentCodeList;
                setRowValidList(new Array<boolean>(codes.length));
                setCodeList(codes);
                setCodeListOnLoad(codes);
                setHistoryId(historyIdArg);

                setNowInitializing(false);
            }

            asyncFunc();
            return () => {isUnmounted = true;};
        }, []);
        

        const slipFieldStyles = inputFieldStyles();
    
        const isRegisterable = rowValidList.length ? rowValidList.every(v => v) : false;
        

        const button = mode === "new" ? (
            <Button
                variant="outlined"
                color="primary"
                onClick={registerClick}
                disabled={!isRegisterable}>
                    登録
                </Button>
        ) : (
            <Button
                variant="outlined"
                color="secondary"
                onClick={registerClick}
                disabled={!isRegisterable}>
                    更新
                </Button>
        );
        let pageContent: React.ReactNode = (
            <div className="container">
                <div className="row align-items-end">
                    <TextField
                        label="送り状番号"
                        variant="outlined"
                        onChange={onSlipChange}
                        value={slipNumber}
                        inputProps={{className: slipFieldStyles.forIEStyle}} />
                    <TooltipWithModal title="送り状番号バーコードの読込" placement="top">
                        <BarcodeReaderButton onRead={onSlipBarcodeRead} width="2em" size="small" className="ml-1 mr-1" />
                    </TooltipWithModal>
                    {generateSlipNumberTask ? <Spinner /> : (
                        <Tooltip title="手渡し番号の生成" placement="top">
                            <IconButton size="small" onClick={onSlipNumberGenerate}><FontAwesomeIcon icon={["fas", "people-carry"]} style={{width: "2em", height: "1.5em"}} /></IconButton>
                        </Tooltip>
                    )}
                </div>
                <br />
                <div className="row justify-content-between align-items-center">
                    <div className="d-inline-flex align-items-center">
                        <Tooltip title="行追加" placement="top">
                            <IconButton onClick={addRowClick}><AddIcon /></IconButton>
                        </Tooltip>
                        {mode === "rewrite" ? <React.Fragment></React.Fragment> : (
                            <TooltipWithModal title="一括読込" placement="top">
                                <QrCodeReaderButton onFinish={onBarcodeBatchRead} styles={{width: "2em"}} size="small" className="ml-1 mr-1" />
                            </TooltipWithModal>
                        )}
                    </div>
                    <Tooltip title="送り状DL" placement="top">
                        <div className="d-inline-block align-items-center">
                            <DownloadButton urlGetter={slipUrlGetter} disabled={historyId == null} buttonRef={downloadButtonRef}>
                                <FontAwesomeIcon icon={["fas", "file-download"]} style={{width: "2em", height: "1.5em"}} />
                            </DownloadButton>
                        </div>
                    </Tooltip>
                </div>
                <div className="row">
                    <SendRegisterTable>
                        {codeList.map((code, idx, allList) => {
                            const setValid = (value: boolean) => setRowValidList([...rowValidList.slice(0, idx), value, ...rowValidList.slice(idx + 1)]);
                            return <SendRegisterTableRow key={`register ${idx}th row`} slipNumber={slipNumber} slipId={slipId} rowIndex={idx} code={code} reloadTargetCodeList={codeListOnLoad} allCodeList={allList} setValid={setValid} onCodeChange={onDataChange} onRowDelete={removeRowClick} />
                        })}
                    </SendRegisterTable>
                </div>
                <div className="row">
                    <TextField label="コメント" variant="outlined" onChange={onCommentChange} value={comment} rows={4} multiline={true} className="w-100 mt-3 mb-3" />    
                </div>
                <div className="row justify-content-end">
                    {registerTask ? <Spinner /> : button}
                </div>
            </div>
        );
        if (mode === "rewrite" && nowInitializing) {  // 0では送付登録できないはず
            pageContent = <Spinner />;
        }

        return (
            <React.Fragment>
                <div className="ml-3 mt-3 mb-4">
                    <span className="h4">{pageTitle}</span>
                </div>
                {pageContent}
            </React.Fragment>
        );
    };
})();

export const SendRegister: React.FunctionComponent<RouteComponentProps> = sendUnitRegister("new");