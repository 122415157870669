import * as React from 'react';
import { Button } from '@material-ui/core';
import * as Xlsx from 'xlsx';
import * as File from 'actions/utils/File';

export interface IXlsxLoaderProps<RowDataType> {
    noHeader?: true;
    colSize: number;
    sheet: number | string;
    rowOffset?: number;
    colOffset?: number;
    rowLoader: (cols: string[]) => RowDataType;
    onLoadStart?: (file: File) => boolean;
    onLoadEnd: (result: RowDataType[]) => void;
    identifierCol: number;
    buttonVariant?: "text" | "outlined" | "contained" | undefined;
    disabled?: boolean;
}

interface IXlsxCell {
    v: string;
    w: any;
    t: any;
}

type XlsxLoaderComponentType = <RowDataType>(props: React.PropsWithChildren<IXlsxLoaderProps<RowDataType>>) => JSX.Element;
export const XlsxLoader: XlsxLoaderComponentType = (props) => {
    const {noHeader, colSize, sheet, rowOffset, colOffset, rowLoader, onLoadStart, onLoadEnd, identifierCol, buttonVariant, disabled, children} = props;

    const [loadTask, setLoadTask] = React.useState<Promise<any[]> | null>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const onFinish = (result: any[]) => {
        inputRef.current!.value = "";
        onLoadEnd(result);
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length !== 0) {
            const file = event.target.files[0];

            if (onLoadStart) {
                if (!onLoadStart(file)) {
                    onFinish([]);
                    return;
                }
            }
    
            setLoadTask(async (): Promise<any[]> => {
                const binary = await File.toArrayBuffer(file);
                if (binary === null) {
                    console.log("is null");
                    return [];
                }
                
                const book = Xlsx.read(binary, {type: 'array'});

                let sheetName;
                if (typeof sheet === "number") {
                    sheetName = book.SheetNames[sheet];
                }
                else {
                    sheetName = sheet;
                }

                const workSheet = book.Sheets[sheetName];

                const rowStart = (rowOffset ?? 0) + (noHeader ? 0 : 1);
                const colStart = colOffset ?? 0;
                const colEnd = colStart + colSize;

                const response = Array<any>();

                
                let end = false;
                for(let row = rowStart; !end; row++) {
                    const rowStrData = Array<string>();
                    for (let col = colStart; col < colEnd; col++) {
                        const address = Xlsx.utils.encode_cell({c: col, r: row});
                        const cell: IXlsxCell = workSheet[address];
                        rowStrData.push(cell?.w ?? "");
                    }

                    if (rowStrData[identifierCol]) {
                        const rowData = rowLoader(rowStrData);
                        response.push(rowData);
                    }
                    else {
                        end = true;
                    }
                }

                return response;
            });
        }
    };

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        inputRef.current?.click();
    }

    React.useEffect(() => {
        if (!loadTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            const response = await loadTask;
            if (!isUnmounted) {
                setLoadTask(null);
                onFinish(response);
            }
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [loadTask]);

    return (
        <React.Fragment>
            <input type="file" onChange={onInputChange} ref={inputRef} style={{display: "none"}} />
            <Button variant={buttonVariant} onClick={onClick} disabled={!!loadTask || disabled}>{children}</Button>
        </React.Fragment>
    )
}