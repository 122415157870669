export interface State {
    id: string;
    password: string;
    accessKey: string;
    userName?: string;
    companyName?: string;
    roles?: string[];
    message?: string;
    expire?: Date;
}

export const createInitialState = (): State => {
    const savedAccessKey = localStorage.getItem("accessKey");
    return {
        id: "",
        password: "",
        accessKey: savedAccessKey === null ? "" : savedAccessKey
    };
}

export const getLoginExpireDate = (): Date => {
    const date = new Date();
    date.setHours(date.getHours() + 1);
    return date;
}