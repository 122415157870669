import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { PageTitle } from 'components/PageTitle';
import { XlsxLoader } from 'components/XlsxLoader';
import { TableCell, TableRow, Table, TableHead, TableBody, Button, TextField } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { IImportRoutingCopies, ILoadedMartzDocument, ILoadMartzDocumentRequestResult, importDocumentFromMartz, IRegisterFailedDocument, loadMartzDocument } from 'actions/MartzMix/DocumentActions';
import { Store } from 'store/GlobalState';
import { cacheFlagsClear } from 'actions/types/CacheFlagsActionType';
import { TableCollapseRow } from 'components/TableCollapseRow';

export const importDocumentPath = '/martzmix/import/martz';
export const importDocumentPageName = 'Martz文書インポート';

const tableHeaders = [
    "No.",
    "文書番号",
    "文書名",
    "結果"
];

const xlsxColSize = 2;
export const ImportDocument = (props: RouteComponentProps) => {
    const [listData, setListData] = React.useState<ILoadedMartzDocument[]>([]);
    const [unresolvedList, setUnresolvedList] = React.useState<IRegisterFailedDocument[]>([]);
    const [registerFailedCodeMap, setRegisterFailedCodeMap] = React.useState<Map<string, string>>(new Map<string, string>([]));
    const [isNowLoading, setIsNowLoading] = React.useState(false);
    const [loadTask, setLoadTask] = React.useState<Promise<ILoadMartzDocumentRequestResult | null> | null>(null);
    const [processingTask, setProcessingTask] = React.useState<Promise<IRegisterFailedDocument[] | null> | null>(null);
    const [rowCollapse, setRowCollapse] = React.useState<boolean[]>([]);
    const [onceRegistered, setOnceRegistered] = React.useState(false);

    const rowLoader = (cols: string[]) => cols[1];
    const onLoadStart = (file: File) => {
        const agree = window.confirm(`${file.name}の読み込みを開始します。\nよろしいですか？`);

        if (agree) {
            setIsNowLoading(true);
            setOnceRegistered(false);
            setUnresolvedList([]);
        }
        
        return agree;
    }
    const onLoadEnd = (result: string[]) => {
        if (result.length > 0) {
            setLoadTask(loadMartzDocument(result));
        }
    }
    React.useEffect(() => {
        if (!loadTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!loadTask) {
                return;
            }

            const result = await loadTask;
            if (result && !isUnmounted) {
                setListData(result.success);
                setUnresolvedList(result.failed);
                setRegisterFailedCodeMap(new Map<string, string>([]));
                setIsNowLoading(false);
                setRowCollapse(new Array<boolean>(result.success.length).fill(false));
            }
        };

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [loadTask]);

    const runButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProcessingTask(importDocumentFromMartz(listData.map(data => {
            const routingCopies: IImportRoutingCopies = {
                sendCopies: data.temporaryRoutings[0].sendCopies,
                primaryReturnCopies: data.temporaryRoutings[0].returnCopies
            };
            if (data.temporaryRoutings.length > 1) {
                routingCopies.primarySendCopies = data.temporaryRoutings[1].sendCopies;
                routingCopies.secondaryReturnCopies = data.temporaryRoutings[1].returnCopies;
            }

            return {
                documentCode: data.code,
                overwriteRouting: routingCopies
            };
        })));
    }
    React.useEffect(() => {
        if (!processingTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            const failed = await processingTask;
            if (!isUnmounted) {

                // 非nullなら成功
                if (failed) {
                    setRegisterFailedCodeMap(new Map<string, string>(failed.map(f => [f.documentCode, f.reason])));
                    Store.dispatch({type: cacheFlagsClear});
                    setOnceRegistered(true);    // 一度の読み込みで一回だけ実行できる
                }
                setProcessingTask(null);
            }
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [processingTask]);

    const tableHeaderRow = React.useMemo(() => (
        <TableRow>
            {listData.length > 0 ? <TableCell></TableCell> : <React.Fragment></React.Fragment>}
            {tableHeaders.map(h => (
                <TableCell key={h}>{h}</TableCell>
            ))}
        </TableRow>
    ), [listData.length]);
    const tableLoadSpinner = React.useMemo(() => <TableRow><TableCell><Spinner /></TableCell></TableRow>, []);

    const unresolvedRows = unresolvedList.map((item, idx) => (
        <TableRow key={`unresolved ${idx}th row`}>
            {listData.length > 0 ? <TableCell></TableCell> : <React.Fragment></React.Fragment>}
            <TableCell>
                {idx + 1}
            </TableCell>
            <TableCell>
                {item.documentCode}
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
                <span style={{color: "red"}}>{item.reason}</span>
            </TableCell>
        </TableRow>
    ));
    const resolvedRows = listData.map((item, idx) => {
        const onExpandClick = (open: boolean) => {
            setRowCollapse([...rowCollapse.slice(0, idx), open, ...rowCollapse.slice(idx + 1)]);
        }

        let resultText = <TableCell></TableCell>;
        const failedInfo = registerFailedCodeMap.get(item.code);
        if (failedInfo) {
            resultText = <TableCell><span style={{color: "red"}}>{`失敗 : ${failedInfo}`}</span></TableCell>;
        }
        else if (onceRegistered) {
            resultText = <TableCell><span style={{color: "springgreen"}}>成功</span></TableCell>;
        }

        const onPrimaryReceiveCopiesChange = (current: number) => {
            const newItem = {...item};
            newItem.temporaryRoutings[0].sendCopies = current;
            setListData([...listData.slice(0, idx), newItem, ...listData.slice(idx + 1)]);
        }
        const onPrimaryReturnCopiesChange = (current: number) => {

            const newItem = {...item};
            newItem.temporaryRoutings[0].returnCopies = current;
            setListData([...listData.slice(0, idx), newItem, ...listData.slice(idx + 1)]);
        }

        const textFieldDisabled = !!processingTask;

        const copiesRows = [(
            <TableRow key={`primary target row`}>
                <TableCell>一次送付先</TableCell>
                <CopiesTextFieldCell
                    onChange={onPrimaryReceiveCopiesChange}
                    value={item.temporaryRoutings[0].sendCopies}
                    disabled={textFieldDisabled}
                />
                <CopiesTextFieldCell
                    onChange={onPrimaryReturnCopiesChange}
                    value={item.temporaryRoutings[0].returnCopies}
                    disabled={textFieldDisabled}
                />
            </TableRow>
        )];
        if (item.temporaryRoutings.length > 1) {
            const onSecondaryReceiveCopiesChange = (current: number) => {
                const newItem = {...item};
                newItem.temporaryRoutings[1].sendCopies = current;
                setListData([...listData.slice(0, idx), newItem, ...listData.slice(idx + 1)]);
            }

            const onSecondaryReturnCopiesChange = (current: number) => {
                const newItem = {...item};
                newItem.temporaryRoutings[1].returnCopies = current;
                setListData([...listData.slice(0, idx), newItem, ...listData.slice(idx + 1)]);
            }

            copiesRows.push((
                <TableRow key={`secondary target row`}>
                    <TableCell>二次送付先</TableCell>
                    <CopiesTextFieldCell
                        onChange={onSecondaryReceiveCopiesChange}
                        value={item.temporaryRoutings[1].sendCopies}
                        disabled={textFieldDisabled}
                    />
                    <CopiesTextFieldCell
                        onChange={onSecondaryReturnCopiesChange}
                        value={item.temporaryRoutings[1].returnCopies}
                        disabled={textFieldDisabled}
                    />
                </TableRow>
            ));
        }

        return (
            <TableCollapseRow
                key={`${idx}th collapse table row`}
                open={rowCollapse[idx]}
                onToggle={onExpandClick}
                cells={[
                    <TableCell key={`No. ${idx}`}>{unresolvedRows.length + idx + 1}</TableCell>,
                    <TableCell key={`code ${idx}`}>{item.code}</TableCell>,
                    <TableCell key={`name ${idx}`}>{item.name}</TableCell>,
                    resultText
                ]}
            >
                <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableCell></TableCell>
                        <TableCell>発送部数</TableCell>
                        <TableCell>返送部数</TableCell>
                    </TableHead>
                    <TableBody>
                        {copiesRows}
                    </TableBody>
                </Table>
            </TableCollapseRow>
        )
    });

    

    return (
        <React.Fragment>
            <PageTitle>
                <span className="h4">{importDocumentPageName}</span>
            </PageTitle>
            <XlsxLoader<string>
                colSize={xlsxColSize}
                sheet={0}
                rowLoader={rowLoader}
                onLoadStart={onLoadStart}
                onLoadEnd={onLoadEnd}
                identifierCol={0}
                buttonVariant="outlined"
                disabled={!!processingTask || isNowLoading}
            >
                Excelから読み込み
            </XlsxLoader>
            <Table stickyHeader>
                <TableHead>
                    {tableHeaderRow}
                </TableHead>
                <TableBody>
                    {isNowLoading ? tableLoadSpinner : [...unresolvedRows, ...resolvedRows]}
                </TableBody>
            </Table>
            <br />
            <div className="text-right">
                {processingTask ? <Spinner /> : <Button variant="outlined" color="primary" onClick={runButtonClick} disabled={unresolvedList.length > 0 || isNowLoading || onceRegistered}>実行</Button>}
            </div>
        </React.Fragment>
    )
}

interface ICopiesTextFieldCell {
    onChange: (current: number) => void;
    value: number;
    disabled?: boolean;
}
const CopiesTextFieldCell = (props: ICopiesTextFieldCell) => {
    const {onChange, value, disabled} = props;

    const [currentValue, setCurrentValue] = React.useState("");
    React.useEffect(() => {
        setCurrentValue(`${value}`);
    }, [value]);

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const current = event.currentTarget.value;
        setCurrentValue(current);
        if (!current) {
            return;
        }

        const parsed = parseInt(current, 10);
        if (isNaN(parsed)) {
            return;
        }

        onChange(parsed);
    };

    return (
        <TableCell>
            <TextField
                disabled={disabled}
                onChange={onInputChange}
                value={currentValue}
                type="number"
                InputLabelProps={{
                    shrink: true
                }} />
        </TableCell>
    )
}