import * as React from 'react';
import { List, ListItem, IconButton, ListItemText, Collapse, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { RouteComponentProps, withRouter } from 'react-router';
import { Store } from 'store/GlobalState';
import { routePush } from 'actions/utils/History';

const itemStyles = makeStyles((theme: Theme) => 
    createStyles({
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        }
    })
);

export interface IMenuCollapseItemProps {
    title: string;
    defaultOpen?: true;
}
export const MenuCollapseItem: React.FunctionComponent<IMenuCollapseItemProps> = (props) => {
    const classes = itemStyles();
    const [expand, setExpand] = React.useState(false);
    const handleExpandClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setExpand(!expand);

    React.useEffect(() => {
        setExpand(props.defaultOpen ?? false);
    }, [props.defaultOpen]);

    return (
        <React.Fragment>
            <ListItem divider={true} style={{backgroundColor: "lightcyan"}}>
                <ListItemText primary={props.title} />
                <IconButton
                    size="small"
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expand
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expand}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </ListItem>
            <Collapse in={expand} timeout="auto" unmountOnExit>
                {props.children}
            </Collapse>
        </React.Fragment>
    )
    // return (
    //     <List
    //         component="nav"
    //         className={classes.root}
    //     >
            
    //     </List>
    // )
}

export interface IMenuLinkButtonItemProps extends RouteComponentProps {
    path: string;
    disabled?: boolean;
}
export const MenuLinkButtonItem = withRouter((props: React.PropsWithChildren<IMenuLinkButtonItemProps>) => {
    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        Store.dispatch(routePush(props.path));
    }

    return (
        <Button
            style={{
                backgroundColor: "transparent", 
                color: "black",
                border: "none",
                width: "100%",
                textAlign: "left"
            }}
            onClick={onClick}
            disabled={props.disabled}
        >
            {props.children}
        </Button>
    )
});