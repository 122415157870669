import * as React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

export interface ISelectorWithLabelValueAndView {
    value: string;
    view: string;
}
export interface ISelectorWithLabelProps {
    variant?: "outlined" | "standard" | "filled";
    className?: string;
    style: React.CSSProperties;
    label: string;
    onChange?: (changedValue: string) => void;
    value: string | null | undefined;
}
export const SelectorWithLabel: React.FunctionComponent<ISelectorWithLabelProps> = (props) => {
    const {label, onChange, children, value, ...formProps} = props;

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, [inputLabel]);

    const onSelectChanged = (event: React.ChangeEvent<{name?: string | undefined, value: unknown}>, child: React.ReactNode) => {
        const current = event.target.value as string;
        if (onChange) {
            onChange(current);
        }
    }

    return (
        <FormControl {...formProps}>
            <InputLabel ref={inputLabel}>
                {label}
            </InputLabel>
            <Select
                value={value ? value : "" /* falsyなら"-"を選択している状態にする */}
                onChange={onSelectChanged}
                labelWidth={labelWidth}
            >
                <MenuItem value=""><em>-</em></MenuItem>
                {children}
            </Select>
        </FormControl>
    );
}