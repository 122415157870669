import * as Types from 'actions/types/GlobalMenuActionType';
import { ActionType } from 'actions/types/ActionType';

export const GlobalMenuReducer = (prevState: JSX.Element | null | undefined, action: ActionType): JSX.Element | null => {
    if (prevState === undefined) {
        return null;
    }

    if (action.type !== Types.setGlobalMenu) {
        return prevState;
    }

    const {element} = action as Types.SetGlobalMenuAction;

    return element;
}