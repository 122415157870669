import * as React from 'react';
import { ISendHistory, getSendHistoryAsync, IHistorySearch, ISendHistoryChunk, IHistorySearchPeriod } from 'actions/MartzMix/SendHistoryAction';
import { TableRow, TableCell, TableHead, Table, TableBody, TableFooter, TablePagination, FormControl, InputLabel, Select, MenuItem, TextField, IconButton } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { TablePaginationWithAction } from 'components/TablePagination';
import { Store } from 'store/GlobalState';
import { routePush } from 'actions/utils/History';
import { toDateString } from 'actions/utils/StringFormatter';
import 'styles/common.min.css';
import { sendHistoryDetailPath } from './SendHistoryDetail';
import { DateRangeBox } from 'components/DateRangeBox';
import { SelectorWithLabel } from 'components/SelectorWithLabel';
import SearchIcon from '@material-ui/icons/Search';
import { IMartzMixCompanyNameWithBranch } from 'store/ServerConstants';
import { CompanySelector } from 'components/CompanySelector';
import { DateRangeSelector } from 'components/DateRangeSelector';
import { cacheFlagSet } from 'actions/types/CacheFlagsActionType';

export const sendHistoryPath = '/martzmix/sendhistory';

const chunkSizeCandidate = [10, 50, 100];
const cacheFlagName = "history list cache";

export const SendHistory: React.FunctionComponent = () => {
    const [sendHistoryChunk, setSendHistoryChunk] = React.useState<ISendHistoryChunk | null>(null);
    const [chunkSize, setChunkSize] = React.useState(chunkSizeCandidate[0]);
    const [page, setPage] = React.useState(0);
    const [historyGetTask, setHistoryGetTask] = React.useState<Promise<ISendHistoryChunk | null> | null>(null);
    const [searchData, setSearchData] = React.useState<IHistorySearch | null>(null);

    const isTableCached = Store.getState().cacheFlags.get(cacheFlagName) ?? false;

    React.useEffect(() => {
        setHistoryGetTask(getSendHistoryAsync(chunkSize, 0, undefined, !isTableCached));
    }, []);
    React.useEffect(() => {
        if (!historyGetTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!historyGetTask) {
                return;
            }

            const response = await historyGetTask;
            if (isUnmounted) {
                return;
            }

            if (!response) {
                alert("データの読込に失敗しました");
            }
            else {
                setSendHistoryChunk(response);
                Store.dispatch({type: cacheFlagSet, target: cacheFlagName, value: true});
            }

            setHistoryGetTask(null);
        }

        asyncFunc();

        return () => {isUnmounted = true;};
    }, [historyGetTask]);

    const onSearch = (search: IHistorySearch) => {
        setPage(0);
        setHistoryGetTask(getSendHistoryAsync(chunkSize, 0, search));
        setSearchData(search);
    }
    const onChangePage = (page: number) => {
        setPage(page);
        setHistoryGetTask(getSendHistoryAsync(chunkSize, page, searchData ?? undefined));
    }
    const onChangeChunkSize = (size: number) => {
        setPage(0);
        setChunkSize(size);
        setHistoryGetTask(getSendHistoryAsync(size, 0, searchData ?? undefined));
    }

    return (
        <React.Fragment>
            <div className="ml-3 mt-3 mb-4">
                <span className="h4">送付履歴</span>
            </div>
            <SearchPanel onSearch={onSearch} />
            <SendHistoryTable
                sendHistoryChunk={sendHistoryChunk}
                onPageChange={onChangePage}
                onChunkSizeChange={onChangeChunkSize}
                loading={historyGetTask != null}
            />
        </React.Fragment>
    );
}


interface ISearchPanelProps {
    onSearch: (search: IHistorySearch) => void;
}
const SearchPanel = (props: ISearchPanelProps) => {
    const {onSearch} = props;

    const [dateSearchStyle, setDateSearchStyle] = React.useState<"registered" | "receipted" | null>(null);
    const onDateSearchStyleSelectChange = (style: string | null) => {
        if (style === "registered" || style === "receipted") {
            setDateSearchStyle(style);
        }
        else if (style === "") {
            setDateSearchStyle(null);
        }
    }

    const [searchStartDate, setSearchStartDate] = React.useState<Date | null>(null);
    const [searchEndDate, setSearchEndDate] = React.useState<Date | null>(null);

    const [searchSlipNumber, setSearchSlipNumber] = React.useState("");
    const onSlipNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchSlipNumber(event.target.value);

    const [searchCompanyCode, setSearchCompanyCode] = React.useState("");

    const onSearchClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const search: IHistorySearch = {};
        if (dateSearchStyle) {
            const period: IHistorySearchPeriod = {type: dateSearchStyle};
            if (searchStartDate) {
                period.start = searchStartDate;
            }
            if (searchEndDate) {
                period.end = new Date(searchEndDate.getFullYear(), searchEndDate.getMonth(), searchEndDate.getDate() + 1);
            }

            search.period = period;
        }
        if (searchSlipNumber) {
            search.slipNumber = searchSlipNumber;
        }
        if (searchCompanyCode) {
            search.companyCode = searchCompanyCode;
        }

        onSearch(search);
    }

    return (
        <fieldset className="border border-primary d-inline-flex m-3 p-3">
            <legend className="w-auto h6">検索</legend>
            <div className="d-inline-flex align-items-end">
                <div className="d-inline-flex flex-column">
                    <DateRangeSelector
                        startLabel="開始"
                        endLabel="終了"
                        startDate={searchStartDate}
                        endDate={searchEndDate}
                        onStartDateChange={setSearchStartDate}
                        onEndDateChange={setSearchEndDate}
                        onTypeChange={onDateSearchStyleSelectChange}
                        typeValue={dateSearchStyle}
                        variant="outlined"
                        typeOptions={[
                            {value: "registered", label: "登録日"},
                            {value: "receipted", label: "受領日"}
                        ]}
                    />
                    <div className="d-inline-flex align-items-center m-2">
                        <TextField variant="outlined" label="送り状番号" value={searchSlipNumber} onChange={onSlipNumberChange} />
                        <CompanySelector variant="outlined" orientation="row" onSelect={setSearchCompanyCode} />
                    </div>
                </div>
                <IconButton size="small" className="ml-5" onClick={onSearchClick}><SearchIcon /></IconButton>
            </div>
        </fieldset>
    );
}


const sendHistoryTableHeaders = [
    "登録日",
    "送り状番号",
    "宛先会社名",
    "宛先支社名",
    "元請会社名",
    "元請支社名",
    "受領日"
];

interface ISendHistoryTableProps {
    sendHistoryChunk: ISendHistoryChunk | null;
    onChunkSizeChange: (size: number) => void;
    onPageChange: (nextPage: number) => void;
    loading: boolean;
}
const SendHistoryTable: React.StatelessComponent<ISendHistoryTableProps> = (props) => {
    const header = React.useMemo(() => (
        <TableHead>
            <TableRow>
                {sendHistoryTableHeaders.map((h, idx) => <TableCell key={`${idx}th column`}>{h}</TableCell>)}
            </TableRow>
        </TableHead>
    ), []);

    const rowClick = (rowIndex: number) => (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        Store.dispatch(routePush(`${sendHistoryDetailPath}/?id=${props.sendHistoryChunk?.chunk[rowIndex].id}`));
    }
    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.onChunkSizeChange(parseInt(event.target.value, 10));
    };
    const onChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        props.onPageChange(newPage);
    }

    const chunkData = props.sendHistoryChunk;
    if (!chunkData || props.loading) {
        return (
            <Table>
                {header}
                <TableBody>
                    <TableRow>
                        <TableCell><Spinner /></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    return (
        <Table stickyHeader>
            {header}
            <TableBody>
                {!props.sendHistoryChunk ? <Spinner /> : props.sendHistoryChunk.chunk.map((r, idx)=> (
                    <TableRow hover key={`${idx}th row`} onClick={rowClick(idx)} style={{cursor: "pointer"}}>
                        <TableCell>{r.registeredDate ? toDateString(new Date(r.registeredDate)) : "-"}</TableCell>
                        <TableCell>{r.slipNumber}</TableCell>
                        <TableCell><span className="company-name">{r.next?.name ?? "-"}</span></TableCell>
                        <TableCell>{r.next?.branch ?? "-"}</TableCell>
                        <TableCell><span className="company-name">{r.last?.name ?? "-"}</span></TableCell>
                        <TableCell>{r.last?.branch ?? "-"}</TableCell>
                        <TableCell>{r.receiptedDate ? toDateString(new Date(r.receiptedDate)) : "-"}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePaginationWithAction
                        colSpan={sendHistoryTableHeaders.length}
                        count={chunkData.totalCount}
                        rowsPerPage={chunkData.chunkSize}
                        rowsPerPageOptions={chunkSizeCandidate}
                        page={chunkData.page}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
}