import { ActionType } from './ActionType';

export const connectServer = "connect server";
export interface ConnectServer extends ActionType {
    wait: boolean;
}

export const login = "login";
export interface LoginResult extends ActionType {
    id: string;
    password: string;
    accessKey: string;
    result: boolean;
    userName?: string;  // ログイン成功したらidから解決して返してくれる
    companyName?: string;   // ログイン成功したらaccessKeyから解決して返してくれる
    roles?: string[];
    message?: string;
}

export const checkID = "check id format";
export interface IDFormatValidate extends ActionType {
    id: string;
    ok: boolean;
    message?: string;
}

export const checkPassword = "check password format";
export interface PasswordFormatValidate extends ActionType {
    password: string;
    ok: boolean;
    message?: string;
}

export const checkAccessKey = "check access key format";
export interface AccessKeyFormatValidate extends ActionType {
    accessKey: string;
    ok: boolean;
    message?: string;
}