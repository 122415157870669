import * as React from 'react';
import { Token } from '../store/Token';
import { Redirect } from 'react-router';

export const Auth = (props: any) => {
    if (Token.instance.token !== null) {
        return props.children;
    }
    else {
        console.log("Not authorized. Redirect to login page");
        
        return <Redirect to={'/login'} />
    }
};