import { HttpRequest } from "actions/utils/HttpRequest";
import { AppConfig } from "app-env";
import { IDetailItemData } from "./RODetailAction";
import { toBase64, fromBase64 } from "actions/utils/File";
import { toDateTimeString } from "actions/utils/StringFormatter";

export interface IItemCommonData {
    acceptedNumber: string;
    loadedVersion: number;
    stationNumber: string;
    stationName: string;
    primaryContractor: string;
    mailAddress: string;
    slipNumber?: string;
    projectDetailGroupCode: string;
    projectDetailGroupName: string;
    area: string;
    submitDateString: string;
}
export interface IItemData extends IDetailItemData {
    commonData: IItemCommonData;

    qrCode: string;
    
    type: string;
    isBig: boolean;
    disposalTreatment: string;
    disposalCheck: string;

    storedCompany: string;
    storedStaffName: string;
    storedNote: string;
    inventoryDateString: string;
    inventoryCompany: string;
    inventoryStaffName: string;
    inventoryNote: string;
    shippingDateString: string;
    shippingCompany: string;
    shippingStaffName: string;
    shippingNote: string;
    fileID1: number | null;
    fileID2: number | null;
    fileID3: number | null;
    fileID4: number | null;
    fileID5: number | null;
    serialNumber1: string;
    serialNumber2: string;
    storagedLoation1: string;
    storagedLoation2: string;
    storagedLoation3: string;
    isPending?: boolean;
    pendingComment: string;
}

export const getItemDetailAsync = async (acceptedNumber: string, itemNumber: number, itemBranch: number): Promise<IItemData | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("ro/detail/item", {acceptedNumber, itemNumber, itemBranch});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        console.log(msg);
        alert(msg);
        return null;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        console.log(msg);
        alert(msg);
        return null;
    }

    return await response.json();
}

interface IPictureBinaryAPIResponse {
    b64Binary: string;
    mime: string;
    filename: string;
    uploadedDate: string;
}
export const getPictureAsync = async (fileID: number): Promise<{file: File, uploadedDateString: string} | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("export/picture/binary", {fileID, compressionRate: 20});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    const {b64Binary, mime, filename, uploadedDate} = (await response.json()) as IPictureBinaryAPIResponse;
    const date = new Date(uploadedDate);

    const file = await fromBase64(b64Binary, filename, mime, date);

    return {file, uploadedDateString: toDateTimeString(date)};
}

export const uploadItemPicture = async (acceptedNumber: string, itemNumber: number, itemBranch: number, pictureNumber: number, file: File, filename: string): Promise<number | null> => {
    const b64Binary = await toBase64(file);

    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("ro/detail/item/picture", {acceptedNumber, itemNumber, itemBranch, pictureNumber, b64Binary, filename});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return (await response.json()).insertedID;
}

export const deleteItemPicture = async (acceptedNumber: string, itemNumber: number, itemBranch: number, pictureNumber: number): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.deleteAsync("ro/detail/item/picture", {acceptedNumber, itemNumber, itemBranch, pictureNumber});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return false;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}

export enum StoringStatusUpdateOperation {
    Receipt = 0,
    Store,
    StockTacking,
    Ship
}
export interface IUpdateItemStoringStatusResult {
    companyName: string;
    staffName: string;
    dateString: string;
}
export const updateItemStoringStatus = async (acceptedNumber: string, itemNumber: number, itemBranch: number, operationNumber: StoringStatusUpdateOperation, skipReceipt?: boolean): Promise<IUpdateItemStoringStatusResult | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("ro/detail/item/status", {acceptedNumber, itemNumber, itemBranch, operationNumber, skipReceipt});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}

export const updateItemStoringStatusMemo = async (acceptedNumber: string, itemNumber: number, itemBranch: number, operationNumber: StoringStatusUpdateOperation, memo: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.putAsync("ro/detail/item/status/memo", {acceptedNumber, itemNumber, itemBranch, operationNumber, memo});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return false;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}

export const resolveItemNumber = async (itemId: number): Promise<{acceptedNumber: string, item: number, branch: number} | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("ro/resolve/item/id", {itemId});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} status=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}

export const updateSerialNumber = async (acceptedNumber: string, itemNumber: number, itemBranch: number, targetNumber: number, serial: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.putAsync("ro/detail/item/serial", {acceptedNumber, itemNumber, itemBranch, targetNumber, serial});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return false;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} status=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}

export const updateStoragedLocation = async (acceptedNumber: string, itemNumber: number, itemBranch: number, targetNumber: number, location: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.putAsync("ro/detail/item/location", {acceptedNumber, itemNumber, itemBranch, targetNumber, location});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return false;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} status=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}

export const updatePendingState = async (acceptedNumber: string, itemNumber: number, itemBranch: number, isPending: boolean, comment: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.putAsync("ro/detail/item/pending", {acceptedNumber, itemNumber, itemBranch, isPending, comment});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return false;
    }
    else if (!response.ok) {
        const msg = `${await response.text()} status=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }
    
    return true;
}