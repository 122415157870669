import * as React from 'react';
import { Table, TableHead, TableRow, TableBody, TableCell, TableFooter, IconButton, Modal, Button, Paper, FormControl, InputLabel, Select, MenuItem, TextField, Tooltip, Popover, FormHelperText } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { Spinner } from 'reactstrap';
import { IDocumentSammary, getDocumentSammaryListAsync, exportDocumentSammaryListAsync, IRouteSet, INewDocument, createNewDocumentAsync, IDocumentSammaryChunk, IDocumentSammarySearch, IDocumentSammarySearchPeriod, resolveStationNameAsync, orderType, ISortSpecification } from 'actions/MartzMix/DocumentActions';
import { DocumentStatusPath } from './DocumentStatus';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DownloadButton } from 'components/DownloadButton';
import { RightArrowIcon } from 'components/icons/RightArrowIcon';
import { LeftArrowIcon } from 'components/icons/LeftArrowIcon';
import { RightNextPlusIcon } from 'components/icons/RightNextPlusIcon';
import { LeftBackMinusIcon } from 'components/icons/LeftBackMinusIcon';
import { TablePaginationWithAction } from 'components/TablePagination';
import { Store, GlobalState } from 'store/GlobalState';
import { routePush } from 'actions/utils/History';
import { IMartzMixCompanyNameWithBranch, IMartzMixCompanyList, ServerConstants } from 'store/ServerConstants';
import { toDateString } from 'actions/utils/StringFormatter';
import { TooltipWithModal } from 'components/TooltipWithModal';
import { DateRangeSelector } from 'components/DateRangeSelector';
import { CompanySelector, CompanyDataSelector } from 'components/CompanySelector';
import { QrCodeReaderButton } from 'components/QrCodeReaderButton';
import { cacheFlagSet, cacheFlagsClear } from 'actions/types/CacheFlagsActionType';
import { MixDocumentField } from 'components/MixDocumentField';
import { connect } from 'react-redux';
import { randomString } from 'actions/utils/Randomize';
import { SortableTableHeaderCell } from 'components/SortableTableHeaderCell';

export const documentListPath = '/martzmix';

const statusText: string[] = [
    "作成",
    "管財発送済",
    "一次送付先発送受領済",
    "一次送付先発送済",
    "二次送付先発送受領済",
    "二次送付先返送済",
    "一次送付先返送受領済",
    "一次送付先返送済",
    "管財返送受領済",
    "完了"
];

const cacheFlagName = "all list cache";

const tableHeaders: {title: string, sortable: boolean, targetName?: string | undefined, default?: orderType | undefined}[] = [
    {title: "登録日", sortable: true, targetName: "Registered", default: "desc"},
    {title: "局番", sortable: false, targetName: "StationNumber"},
    {title: "局名", sortable: false, targetName: "StationName"},
    {title: "文書番号", sortable: false, targetName: "Code"},
    {title: "文書名", sortable: false, targetName: "DocumentName"},
    {title: "発送部数", sortable: false, targetName: "SendCopies"},
    {title: "返送部数", sortable: false, targetName: "ReturnCopies"},
    {title: "ステータス", sortable: false, targetName: "Status"},
    {title: "最終更新日", sortable: true, targetName: "LastModified"}
];
const defaultOrder: ISortSpecification = {target: "Registered", order: "desc"};

export const DocumentList: React.FunctionComponent = () => {
    const [documentListChunk, setDocumentListChunk] = React.useState<IDocumentSammaryChunk | null>(null);
    const [isNewDocumentDialogOpen, setIsNewDocumentDialogOpen] = React.useState(false);
    const [chunkSize, setChunkSize] = React.useState(rowsPerPageSelection[0]);
    const [page, setPage] = React.useState(0);

    const [searchData, setSearchData] = React.useState<IDocumentSammarySearch>({});
    const [sort, setSort] = React.useState(defaultOrder);

    const isTableCached = Store.getState().cacheFlags.get(cacheFlagName) ?? false;

    const [getSammaryListChunkTask, setGetSammaryListChunkTask] = React.useState<Promise<IDocumentSammaryChunk | null> | null>(null);
    React.useEffect(() => {
        if (!getSammaryListChunkTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            const result = await getSammaryListChunkTask;
            if (!isUnmounted) {
                if (result) {
                    setDocumentListChunk(result);
                    Store.dispatch({type: cacheFlagSet, target: cacheFlagName, value: true});
                }
                setGetSammaryListChunkTask(null);
            }
        };

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [getSammaryListChunkTask]);

    React.useEffect(() => {
        setGetSammaryListChunkTask(getDocumentSammaryListAsync(chunkSize, page, undefined, sort, !isTableCached));
    }, []);

    const header: React.ReactNode = React.useMemo(() => (
        <TableHead>
            <TableRow>
                {tableHeaders.map((h, idx) => {
                    if (!h.sortable) {
                        return <TableCell key={`${idx}th column ${h.title}`}>{h.title}</TableCell>
                    }
                    else {
                        const onToggle = (order: orderType) => {
                            const newSort: ISortSpecification = {target: h.targetName!, order};
                            setSort(newSort);

                            // ソート変更したらページングはリセット
                            setChunkSize(rowsPerPageSelection[0]);
                            setPage(0);

                            setGetSammaryListChunkTask(getDocumentSammaryListAsync(chunkSize, page, undefined, newSort));
                        }

                        return (<SortableTableHeaderCell
                            key={`${idx}th column ${h.title}`}
                            title={h.title}
                            order={h.targetName === sort.target ? sort.order : undefined}
                            onToggle={onToggle}/>);
                    }
                })}
            </TableRow>
        </TableHead>
    ), [sort]);
    
    const onChangeChunkSize = (size: number) => {
        setChunkSize(size);
        setPage(0);

        setGetSammaryListChunkTask(getDocumentSammaryListAsync(size, 0, searchData, sort));
    }
    const onChangePage = (newPage: number) => {
        setPage(newPage);

        setGetSammaryListChunkTask(getDocumentSammaryListAsync(chunkSize, newPage, searchData, sort));
    }

    const onSearchClick = (search: IDocumentSammarySearch) => {
        setSearchData(search);
        setPage(0);
        setGetSammaryListChunkTask(getDocumentSammaryListAsync(chunkSize, 0, search, sort));
    };

    const onAddNewDocumentClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsNewDocumentDialogOpen(true);
    };
    const onNewDocumentDialogClose = () => {
        setIsNewDocumentDialogOpen(false);
    };

    const onCreateNewDocument = (input: INewDocument) => {
        return createNewDocumentAsync(input).then(resolve => {
            Store.dispatch({type: cacheFlagsClear});
            setPage(0);
            setGetSammaryListChunkTask(getDocumentSammaryListAsync(chunkSize, 0, searchData, defaultOrder, true));
            return resolve;
        });
    }
    
    return (
        <React.Fragment>
            <div className="ml-3 mt-3 mb-4">
                <span className="h4">書類一覧</span>
            </div>
            <div className="d-flex w-100 align-items-end justify-content-between">
                <SearchPanel onSearch={onSearchClick} />
                <div className="d-inline-flex align-items-center">
                    <Tooltip title="一覧DL" placement="top">
                        <div>
                            <DownloadButton
                                urlGetter={() => exportDocumentSammaryListAsync(searchData)}
                                size="small"
                            >
                                <GetAppIcon width="2em" />
                            </DownloadButton>
                        </div>
                    </Tooltip>
                    <Tooltip title="新規文書追加" placement="top">
                        <IconButton onClick={onAddNewDocumentClick}><AddIcon width="2em" /></IconButton>
                    </Tooltip>
                </div>
            </div>
            <br />
            <DocumentTable
                documentSammaryChunk={documentListChunk}
                onChunkSizeChange={onChangeChunkSize}
                onPageChange={onChangePage}
                loading={getSammaryListChunkTask != null}
                header={header}/>
            <NewDocumentDialog isOpen={isNewDocumentDialogOpen} onCloseClick={onNewDocumentDialogClose} onSaveClick={onCreateNewDocument} />
        </React.Fragment>
    );
};


interface ISearchPanelProps {
    onSearch: (search: IDocumentSammarySearch) => void;
}
const SearchPanel = (props: ISearchPanelProps) => {

    const [selectedStatus, setSelectedStatus] = React.useState("");
    const [inputedDocumentCode, setInputedDocumentCode] = React.useState("");
    const [inputedStationNumber, setInputedStationNumber] = React.useState("");
    const [inputedStationName, setInputedStationName] = React.useState("");

    const inputLabel = React.useRef<HTMLLabelElement>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current!.offsetWidth);
    }, []);

    const [dateSearchStyle, setDateSearchStyle] = React.useState<"registered" | "lastUpdated" | null>(null);
    const onDateSearchStyleSelectChange = (style: string | null) => {
        if (style === "registered" || style === "lastUpdated") {
            setDateSearchStyle(style);
        }
        else if (style === "") {
            setDateSearchStyle(null);
        }
    }

    const [searchStartDate, setSearchStartDate] = React.useState<Date | null>(null);
    const [searchEndDate, setSearchEndDate] = React.useState<Date | null>(null);

    const [searchSlipNumber, setSearchSlipNumber] = React.useState("");
    const onSlipNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchSlipNumber(event.target.value);

    const [searchCompanyCode, setSearchCompanyCode] = React.useState("");

    const onStatusChanged = (event: React.ChangeEvent<{value: unknown}>) => {
        setSelectedStatus(event.target.value as string);
    };
    const onDocumentCodeChanged = (event: React.ChangeEvent<{value: unknown}>) => {
        setInputedDocumentCode(event.target.value as string);
    };
    const onNumberChanged = (event: React.ChangeEvent<{value: unknown}>) => {
        setInputedStationNumber(event.target.value as string);
    };
    const onNameChanged = (event: React.ChangeEvent<{value: unknown}>) => {
        setInputedStationName(event.target.value as string);
    };
    const onSearchClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const search: IDocumentSammarySearch = {};

        if (selectedStatus) {
            search.status = statusText.indexOf(selectedStatus);
        }
        if (inputedDocumentCode) {
            search.documentCode = inputedDocumentCode;
        }
        if (inputedStationNumber) {
            search.stationNumber = inputedStationNumber;
        }
        if (inputedStationName) {
            search.stationName = inputedStationName;
        }
        if (dateSearchStyle) {
            const period: IDocumentSammarySearchPeriod = {type: dateSearchStyle};
            if (searchStartDate) {
                period.start = searchStartDate;
            }
            if (searchEndDate) {
                period.end = new Date(searchEndDate.getFullYear(), searchEndDate.getMonth(), searchEndDate.getDate() + 1);
            }

            search.period = period;
        }
        if (searchSlipNumber) {
            search.slipNumber = searchSlipNumber;
        }
        if (searchCompanyCode) {
            search.companyCode = searchCompanyCode;
        }

        props.onSearch(search);
    };

    

    const selector = (
        <FormControl variant="outlined" className="m-2" style={{minWidth: "10em"}}>
            <InputLabel ref={inputLabel} id="status-selector-label">
                ステータス
            </InputLabel>
            <Select
                id="status-selector"
                value={selectedStatus}
                onChange={onStatusChanged}
                labelWidth={labelWidth}
            >
                <MenuItem value=""><em>-</em></MenuItem>
                {statusText.map(s => <MenuItem value={s} key={`status ${s}`}>{s}</MenuItem>)}
            </Select>
        </FormControl>
    );

    
    const documentCodeField = <TextField value={inputedDocumentCode} label="文書番号" variant="outlined" onChange={onDocumentCodeChanged} className="mt-2 ml-2 mb-2 mr-1" />;

    const onBarcodeRead = (code: string) => {
        console.log(`${code}`);
        setInputedDocumentCode(code);
    };

    const numberField = <TextField label="局番" variant="outlined" onChange={onNumberChanged} className="m-2" />;
    const nameField = <TextField label="局名" variant="outlined" onChange={onNameChanged} className="m-2" />;

    
    
    return (
        <fieldset className="border border-primary d-inline-flex m-3">
            <legend className="w-auto h6">検索</legend>
            <div className="d-inline-flex align-items-end">
                <div className="d-inline-flex align-items-center">
                    <div className="d-inline-flex flex-column">
                        {selector}
                        <div className="d-inline-flex align-items-end">
                            {documentCodeField}
                            <TooltipWithModal title="バーコード読込" placement="top">
                                <QrCodeReaderButton size="small" onRead={onBarcodeRead} className="mr-2 mt-2 mb-2" styles={{width: "2em"}} />
                            </TooltipWithModal>
                        </div>
                    </div>
                    <div className="d-inline-flex flex-column">
                        {numberField}
                        {nameField}
                    </div>
                    <div className="d-inline-flex flex-column">
                        <DateRangeSelector
                            startLabel="開始"
                            endLabel="終了"
                            startDate={searchStartDate}
                            endDate={searchEndDate}
                            onStartDateChange={setSearchStartDate}
                            onEndDateChange={setSearchEndDate}
                            onTypeChange={onDateSearchStyleSelectChange}
                            typeValue={dateSearchStyle}
                            variant="outlined"
                            typeOptions={[
                                {value: "registered", label: "登録日"},
                                {value: "lastUpdated", label: "ステータス更新日"}
                            ]}
                        />
                        <div className="d-inline-flex align-items-center ml-2 mr-2 mb-2">
                            <TextField variant="outlined" label="送り状番号" value={searchSlipNumber} onChange={onSlipNumberChange} />
                            <CompanySelector variant="outlined" orientation="row" onSelect={setSearchCompanyCode} />
                        </div>
                    </div>
                </div>
                <div className="d-inline-flex flex-column justify-content-end">
                    <Tooltip title="検索" placement="top">
                        <IconButton size="small" className="ml-5 mb-2 mr-2 mt-2" onClick={onSearchClick}><SearchIcon width="2em" /></IconButton>
                    </Tooltip>
                </div>
            </div>
        </fieldset>
    );
};

const rowsPerPageSelection = [10, 50, 100];

interface IDocumentTableProps {
    documentSammaryChunk: IDocumentSammaryChunk | null;
    onChunkSizeChange: (size: number) => void;
    onPageChange: (page: number) => void;
    loading: boolean;
    header: React.ReactNode;
}

const DocumentTable = (props: IDocumentTableProps) => {
    

    const {documentSammaryChunk, onChunkSizeChange, onPageChange, loading, header} = props;

    const onChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        onPageChange(newPage);
    }

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChunkSizeChange(parseInt(event.target.value, 10));
    };

    if (!documentSammaryChunk || loading) {
        return (
            <Table>
                {header}
                <TableBody>
                    <TableRow>
                        <TableCell><Spinner /></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    

    const {chunk, chunkSize, page, totalCount} = documentSammaryChunk;

    const rowDOMs = <DocumentTableRows list={chunk} />;

    return (
        <Table stickyHeader>
            {header}
            <TableBody>
                {rowDOMs}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePaginationWithAction
                        colSpan={tableHeaders.length /* 列数と同じ値を設定する */}
                        count={totalCount}
                        rowsPerPage={chunkSize}
                        rowsPerPageOptions={rowsPerPageSelection}
                        page={page}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
};


interface IDocumentTableRowProps {
    list: IDocumentSammary[];
}
const DocumentTableRows = (props: IDocumentTableRowProps) => {
    const {list} = props;

    const [isMouseMove, setIsMouseMove] = React.useState(false);
    const onMouseDown = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(false);
    const onMouseMove = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(true);

    return (
        <React.Fragment>
            {list.map((sammary, idx) => {
                const onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                    if(isMouseMove) {
                        event.preventDefault();
                        return;
                    }

                    Store.dispatch(routePush(`${DocumentStatusPath}/?id=${sammary.id}`));
                };

                return (
                    <TableRow hover key={`row of ${idx}`} onClick={onRowClick} style={{cursor: "pointer"}}>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}>{toDateString(new Date(sammary.registered))}</TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}>{sammary.stationNumber}</TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove} style={{maxWidth: "10em"}}><span style={{wordBreak: "break-all"}}>{sammary.stationName}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove} style={{maxWidth: "12rem"}}><span style={{wordBreak: "break-all"}}>{sammary.code}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove} style={{maxWidth: "15em"}}><span style={{wordBreak: "break-all"}}>{sammary.documentName}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}>{sammary.sendCopies}</TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}>{sammary.returnCopies}</TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}>{statusText[sammary.status]}</TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}>{toDateString(new Date(sammary.lastModified))}</TableCell>
                    </TableRow>
                );
            })}
        </React.Fragment>
    )
}


const newDocumentInputErrorContext = React.createContext<{error: boolean, setter: (error: boolean) => void}>({error: false, setter: (error) => {}});

const initialRoute: IRouteSet[] = [{
    sourceCompanyCode: "",
    destinationCompanyCode: "",
    sendCopiesNum: 0,
    returnCopiesNum: 0
}];


interface INewDocumentProps {
    isOpen: boolean;
    onCloseClick: () => void;
    onSaveClick: (input: INewDocument) => Promise<ResultWithMessage>;
}
const NewDocumentDialog = (props: INewDocumentProps) => {
    const {isOpen, onCloseClick, onSaveClick} = props;

    const [saveTask, setSaveTask] = React.useState<Promise<ResultWithMessage> | null>(null);
    const [error, setError] = React.useState(false);

    const [routeList, setRouteList] = React.useState<IRouteSet[]>(initialRoute);
    const [stationNumber, setStationNumber] = React.useState("");
    const [documentCode, setDocumentCode] = React.useState("");
    const [documentName, setDocumentName] = React.useState("");
    const [comment, setComment] = React.useState("");

    const [stationName, setStationName] = React.useState("");
    const [resolveStationNameTask, setResolveStationNameTask] = React.useState<Promise<string | null> | null>(null);

    const closer = () => {
        setError(false);
        setStationNumber("");
        setStationName("");
        setDocumentCode("");
        setDocumentName("");
        setComment("");
        setRouteList(initialRoute);

        onCloseClick();
    }

    const onCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        closer();
    }
    const onCreate = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!stationNumber || !documentName || routeList.some(r => !r.destinationCompanyCode || !r.sourceCompanyCode) || routeList.some(r => r.sendCopiesNum === 0)) {
            setError(true);
            console.warn(`${stationNumber}:${documentName}`);
            console.warn(routeList);
            return;
        }
        setError(false);

        let code = documentCode;
        if (!documentCode) {
            const agree = window.confirm("文書番号が空です。\n自動生成して続行しますか？");
            if (!agree) {
                return;
            }
        }

        setSaveTask(onSaveClick({
            stationNumber,
            documentCode: code,
            documentName,
            comment,
            routeList
        }));
    }

    React.useEffect(() => {
        if (!saveTask) {
            return;
        }

        let isUnmount = false;
        const asyncFunc = async () => {
            if (!saveTask) {
                return;
            }

            const result = await saveTask;
            if (!isUnmount) {
                setSaveTask(null);
                
                if (result.result) {
                    closer();
                }
                else {
                    alert(result.message);
                }
            }
        };

        asyncFunc();
        return () => {isUnmount = true;};
    }, [saveTask]);
    

    
    React.useEffect(() => {
        if (!resolveStationNameTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!resolveStationNameTask) {
                return;
            }

            const name = await resolveStationNameTask;
            if (!isUnmounted) {
                setStationName(name ?? "");
                setResolveStationNameTask(null);
            }
        }

        asyncFunc();

        return () => {isUnmounted = true;};
    }, [resolveStationNameTask]);

    const onStationNumberBlur = (event: any) => {
        if (stationNumber) {
            setResolveStationNameTask(resolveStationNameAsync(stationNumber));
        }
    }

    const onStationNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStationNumber(event.target.value);
    }
    const stationNumberFieldIsError = error && !stationNumber;
    const stationNumberField = <TextField
        label="局番"
        variant="outlined"
        value={stationNumber}
        onChange={onStationNumberChange}
        error={stationNumberFieldIsError}
        helperText={stationNumberFieldIsError ? "必須項目です" : undefined}
        onBlur={onStationNumberBlur}
    />;

    const onDocumentCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDocumentCode(event.target.value);
    }
    const documentCodeField = <TextField
        label="文書番号"
        variant="outlined"
        value={documentCode}
        onChange={onDocumentCodeChanged}
    />;

    const onBarcodeRead = (code: string) => {
        console.log(`input to document list page's document code ${code}`);
        setDocumentCode(code);
    }

    const documentNameFieldIsError = error && !documentName;
    const documentNameField = <MixDocumentField onChange={setDocumentName} value={documentName} error={documentNameFieldIsError} helperText={documentNameFieldIsError ? "必須項目です" : undefined} />;

    const onCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    }
    const commentField = <TextField label="コメント" className="w-100" variant="outlined" multiline={true} rows={4} value={comment} onChange={onCommentChange} />;

    const onRouteChange = (newRouteList: IRouteSet[]) => {
        setRouteList(newRouteList);
    }

    return (
        <newDocumentInputErrorContext.Provider value={{error, setter: setError}}>
            <Modal disableBackdropClick={true} open={isOpen}>
                <Paper style={{width: "90vw", height: "90vh", left: 0, right:0, top: 0, bottom: 0}} className="position-absolute m-auto overflow-auto p-3">
                    <div className="container">
                        <div className="row justify-content-center">
                            <span className="h4">新規文書追加</span>
                        </div>
                        <div className="row m-2">
                            {stationNumberField}
                            <div className="d-flex align-items-center ml-5">
                                {resolveStationNameTask ? <Spinner /> : <span className="h6 font-weight-bold m-0 d-block">{stationName}</span>}
                            </div>
                        </div>
                        <div className="row m-2 align-items-center">
                            <div className="d-flex align-items-end">
                                {documentCodeField}
                                <TooltipWithModal title="文書バーコードの読込" placement="top">
                                    <QrCodeReaderButton size="small" onRead={onBarcodeRead} className="mr-2 mt-2" styles={{width: "2em"}} />
                                </TooltipWithModal>
                            </div>
                            {documentNameField}
                        </div>
                        <div className="row m-2">
                            {commentField}
                        </div>
                        <div className="row">
                            <RoutePanel routeList={routeList} onRouteChange={onRouteChange} />
                        </div>
                        <div className="row justify-content-end">
                            <Button variant="outlined" color="secondary" onClick={onCancelClick} className="m-1" disabled={!!saveTask}>キャンセル</Button>
                            {saveTask ? <Spinner /> : <Button variant="outlined" color="primary" onClick={onCreate} className="m-1">保存</Button>}
                        </div>
                    </div>
                </Paper>
            </Modal>
        </newDocumentInputErrorContext.Provider>
    )
}



interface ICompanySelectionMap {
    uq: IMartzMixCompanyNameWithBranch;
    cmCodeMap: Map<string, IMartzMixCompanyNameWithBranch>;    // key: code
    coNameBranchMap: Map<string, Map<string, IMartzMixCompanyNameWithBranch>>;  // primary key: comapny name, secondary key: code
}
const compayMapping = (data: IMartzMixCompanyList): ICompanySelectionMap => {
    const cmCodeMap = new Map<string, IMartzMixCompanyNameWithBranch>(data.cm.map(cm => [cm.code, cm]));
    
    const coNames = data.co.map(co => co.name).filter((name, idx, self) => self.indexOf(name) === idx);
    const coNameBranchMap = new Map<string, Map<string, IMartzMixCompanyNameWithBranch>>(coNames.map(name => {
        const sameCompanies = data.co.filter(co => co.name === name);
        const branchMap = new Map<string, IMartzMixCompanyNameWithBranch>(sameCompanies.map(co => [co.code, co]));
        return [name, branchMap];
    }));

    return {uq: data.uq, cmCodeMap, coNameBranchMap};
}

interface IRouteArrowProps {
    width: string | number;
    onBlur: (current: number) => void;
    flow: "send" | "return"
}
const RouteArrow = (props: IRouteArrowProps) => {
    const {onBlur, width, flow} = props;
    const {error} = React.useContext(newDocumentInputErrorContext);

    const [copiesNum, setCopiesNum] = React.useState<number | null>(null);

    const onCopiesNumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentStr = event.target.value;
        if (!currentStr) {
            setCopiesNum(null);
            return;
        }

        const current = parseInt(currentStr, 10);
        if (current !== NaN) {
            setCopiesNum(current);
        }
    }

    const onFocusOut = (event: React.FocusEvent<HTMLInputElement>) => onBlur(copiesNum ?? 0);

    let copiesNumFieldHelperText: string;
    let copiesNumFieldIsError = error;
    if (flow === "send") {
        copiesNumFieldIsError = copiesNumFieldIsError && (copiesNum === null || copiesNum === 0);
        copiesNumFieldHelperText = copiesNum === null ? "必須項目です" : "0にはできません";
    }
    else {
        copiesNumFieldIsError = copiesNumFieldIsError && (copiesNum === null);  // 返送は0でもよい
        copiesNumFieldHelperText = "必須項目です";
    }


    return (
        <div className="d-flex flex-column align-items-center justify-content-center m-3" style={{width}}>
            {flow === "return" ? <LeftArrowIcon width={width} /> : <React.Fragment></React.Fragment>}
            <TextField
                label={flow === "send" ? "送付部数" : "返送部数"}
                type="number"
                value={copiesNum ?? ""}
                onChange={onCopiesNumChange}
                onBlur={onFocusOut}
                error={copiesNumFieldIsError}
                helperText={copiesNumFieldIsError ? copiesNumFieldHelperText : undefined}
            />
            {flow === "send" ? <RightArrowIcon width={width} /> : <React.Fragment></React.Fragment>}
        </div>
    )
}


interface ICompanyNameSelectorProps {
    onDeside: (company: IMartzMixCompanyNameWithBranch | null) => void;
}
const CompanyNameSelector = (props: ICompanyNameSelectorProps) => {
    const {onDeside} = props;
    const [selectedCompany, setSelectedCompany]  = React.useState<IMartzMixCompanyNameWithBranch | null>(null);

    const onOKClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onDeside(selectedCompany);
    }
    const onSelect = (company: IMartzMixCompanyNameWithBranch | null) => setSelectedCompany(company);

    return (
        <Paper className="p-2">
            {/* {companyLevelSelector}
            {companySelector} */}
            <CompanyDataSelector variant="outlined" onSelect={onSelect} />
            <Button variant="outlined" className="d-block text-primary ml-auto mr-1 mt-1" onClick={onOKClick}>OK</Button>
        </Paper>
    )
}

interface ICompanyBoxProps {
    onCompanyChange: (code: string) => void;
}
const CompanyBox = (props: ICompanyBoxProps) => {
    const {onCompanyChange} = props;
    const {error} = React.useContext(newDocumentInputErrorContext);

    const [companyName, setCompanyName] = React.useState("");
    const [anchorElement, setAnchorElement] = React.useState<HTMLInputElement | null>(null);

    const onTextClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setAnchorElement(event.currentTarget);
    }

    const isError = error && !companyName;

    const textbox = <TextField
        variant="outlined"
        label="会社名"
        value={companyName}
        style={{minWidth: "10em"}}
        onClick={onTextClick}
        error={isError}
        helperText={isError ? "必須項目です" : undefined}
    />;

    const onPopoverClose = () => {
        anchorElement?.blur();
        setAnchorElement(null);
    }

    const onDeside = (company: IMartzMixCompanyNameWithBranch | null) => {
        console.log(`select code=${company?.code};name=${company?.name};branch=${company?.branch}`);

        onCompanyChange(company?.code ?? "");
        
        let companyFullName = company?.name ?? "";
        if (company?.branch) {
            companyFullName = companyFullName + ` ${company.branch}`;
        }
        setCompanyName(companyFullName);

        setAnchorElement(null);
    }

    return (
        <div className="m-3">
            {textbox}
            <Popover
                open={Boolean(anchorElement)}
                anchorEl={anchorElement}
                onClose={onPopoverClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
            >
                <CompanyNameSelector onDeside={onDeside} />
            </Popover>
        </div>
    )
}

interface IRoutePanelProps {
    routeList: IRouteSet[];
    onRouteChange: (newRouteList: IRouteSet[]) => void;
}
interface IRoutePanelConnectionProps extends IRoutePanelProps {
    serverConstants: ServerConstants;
}
const RoutePanel = connect(
    (state: GlobalState, ownProps: IRoutePanelProps) => ({
        serverConstants: state.serverConstants,
        ...ownProps
    })
)((props: IRoutePanelConnectionProps) => {
    const {routeList, onRouteChange, serverConstants} = props;

    const companyList = serverConstants.martzmix?.companies;
    const companies = React.useMemo(() => companyList ? compayMapping(companyList) : null, [companyList]);    // 一度しか更新されないステータスなので一回だけ実行される

    if (!companies) {
        return <Spinner />;
    }

    const alignItems: JSX.Element[] = [];

    const routeModify = (index: number, obj: IRouteSet) => {//onRouteChange([...routeList.slice(0, index), obj, ...routeList.slice(index + 1)]);
        const replaced = [...routeList.slice(0, index), obj, ...routeList.slice(index + 1)];
        if (replaced.length > (index + 1)) { // 置換した要素の次の要素がある場合

            // 次のルーティングのスタート地点はこの位置のルーティングのゴール地点
            const nextItem: IRouteSet = {...replaced[index + 1]};
            nextItem.sourceCompanyCode = obj.destinationCompanyCode;

            replaced[index + 1] = nextItem;
        }

        onRouteChange(replaced);
    }
    const onFirstCompanyBoxChange = (code: string) => {
        const after = {...routeList[0]};
        after.sourceCompanyCode = code;
        routeModify(0, after);
    };
    alignItems.push(<CompanyBox onCompanyChange={onFirstCompanyBoxChange} key="first company box" />); // 一番左をループ外で作る

    for (let i = 0; i < routeList.length; i++)
    {
        const route = routeList[i];

        const onSendArrowBlur = (current: number) => {
            const after: IRouteSet = {...route};
            after.sendCopiesNum = current;

            routeModify(i, after);
        }
        const onReturnArrowBlur = (current: number) => {
            const after = {...route} as IRouteSet;
            after.returnCopiesNum = current;

            routeModify(i, after);
        }

        alignItems.push((
            <div className="d-flex flex-column justify-content-around align-items-center" key={`${i}th arrows`}>
                <RouteArrow width="10em" onBlur={onSendArrowBlur} flow="send" />
                <RouteArrow width="10em" onBlur={onReturnArrowBlur} flow="return" />
            </div>
        ));

        const onLastCompanyBoxChange = (code: string) => {
            const after = {...route} as IRouteSet;
            after.destinationCompanyCode = code;

            if (i > 0) {    // 一つ前がfirts boxではない
                const before = routeList[i - 1];
                after.sourceCompanyCode = before.destinationCompanyCode;    // sourceは一つ前のdestination
            }

            routeModify(i, after);
        }
        alignItems.push(<CompanyBox onCompanyChange={onLastCompanyBoxChange} key={`${i}th company box`} />);
    }


    const turnaround = routeList[routeList.length - 1];

    const onRouteAddClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const newRoute: IRouteSet = {
            sourceCompanyCode: turnaround.destinationCompanyCode,
            destinationCompanyCode: "",
            sendCopiesNum: 0,
            returnCopiesNum: 0
        };

        onRouteChange([...routeList, newRoute]);
    }

    const onRouteDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onRouteChange(routeList.slice(0, routeList.length - 1));
    }

    const routeAddButton = (
        <Tooltip title="経路の追加" placement="top">
            <span><IconButton size="small" onClick={onRouteAddClick} disabled={routeList.length >= 2}><RightNextPlusIcon /></IconButton></span>
        </Tooltip>
    );
    const routeDeleteButton = (
        <Tooltip title="経路の削除" placement="top">
            <span><IconButton size="small" onClick={onRouteDeleteClick} disabled={routeList.length <= 1}><LeftBackMinusIcon /></IconButton></span>
        </Tooltip>
    );

    return (
        <fieldset className="border border-primary m-3">
            <legend className="w-auto">経路設定</legend>
            <div className="m-1 overflow-auto">
                <div className="d-flex justify-content-end">
                    {routeDeleteButton}{routeAddButton}
                </div>
                <div className="d-inline-flex align-items-center">
                    {alignItems}
                </div>
            </div>
        </fieldset>
    );
})