export class Token {
    private static singleton: Token;
    private constructor() { }

    public static get instance() {
        if (this.singleton === undefined) {
            this.singleton = new Token();
        }

        return this.singleton;
    }

    public set token(token: string | null) {
        if (token !== null) {
            sessionStorage.setItem("token", token);
        }
        else {
            sessionStorage.removeItem("token");
        }
    }

    public get token() {
        return sessionStorage.getItem("token");
    }

    public deleteToken() {
        return sessionStorage.removeItem("token");
    }
}