import { HttpRequest } from "actions/utils/HttpRequest";
import { AppConfig } from "app-env";
import { ISendRegisterDocument } from "./SendRegisterAction";

export interface ISendHistoryCompany {
    name: string;
    branch?: string;
}

export interface ISendHistory {
    id: number;
    registeredDate?: string;
    slipNumber: string;
    next?: ISendHistoryCompany;
    last?: ISendHistoryCompany;
    receiptedDate?: string;
}
export interface ISendHistoryChunk {
    page: number;
    chunkSize: number;
    totalCount: number;
    chunk: ISendHistory[];
}

export interface IHistorySearchPeriod {
    type: "registered" | "receipted";
    start?: Date;
    end?: Date;
}

export interface IHistorySearch {
    period?: IHistorySearchPeriod;
    slipNumber?: string;
    companyCode?: string;
}

let sendHistoryResourceId: string | null = null;
export const getSendHistoryAsync = async (size: number, page: number, search?: IHistorySearch, refresh = false): Promise<ISendHistoryChunk | null> => {
    const request = new HttpRequest(AppConfig.serverURL);

    let propsBase: any = {size, page};
    if (sendHistoryResourceId && !refresh) {
        propsBase = {...propsBase, resourceId: sendHistoryResourceId};
    }

    let response: Response | undefined;
    if (search) {
        response = await request.getWithQueryAsync("SendHistory/search", {...propsBase, search}, "infinite");
    }
    else {
        response = await request.getWithQueryAsync("SendHistory", propsBase, "infinite");
    }

    if (!response) {
        console.log("no response");
        return null;
    }
    if (!response.ok) {
        console.log(`error code = ${response.status}`);
        return null;
    }

    const {resourceId, ...result} = await response.json();
    sendHistoryResourceId = resourceId;

    return result.chunkedData;
}


export interface IHistoryDetailDocument {
    code: string;
    name: string;
    copies: number;
    stationNumber: string;
    stationName: string;
}
export interface IHistoryDetail {
    documentCodeList: string[];
    slipId: number;
    slipNumber: string;
    comment: string;
}
export const getSendHistoryDetailAsync = async (historyID: number): Promise<IHistoryDetail | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("SendHistory/detail", {historyID});

    if (!response) {
        console.error("no response");
        return null;
    }
    if (!response.ok) {
        console.error(`${await response.text()} code=${response.status}`);
        return null;
    }

    return await response.json();
}

export const updateSendHistoryDetailAsync = async (historyID: number, data: IHistoryDetail): Promise<number | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("SendHistory/detail", {historyID, data});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return historyID;
}