import * as React from 'react';
import { Button, Theme, IconButton, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { makeStyles, createStyles } from '@material-ui/styles';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { isCameraUseable } from 'actions/utils/UserDevices';

export interface IFileUploadWithThumbnailProps {
    onCapture: (file: File | null) => void;
    width: string | number;
    preivewHook: (file: File) => void;
    deleteHook: (filename: string) => void;
    enable?: boolean;
    file?: File | null | undefined;
    filename?: string;
    iconSize?: "small" | "medium";
}

const addAPhothoIconStyle = makeStyles((theme: Theme) =>
    createStyles({
        addIcon: {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            margin: "auto"
        }
    })
);

const AddAPhotoIconDom = <AddAPhotoIcon style={{
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: "auto",
    fontSize: "10rem"
}} />;
const filenameAreaDummy = <span style={{visibility: "hidden"}}>filename</span>;

type getUserMediaType = (constraints: MediaStreamConstraints, successCallback: NavigatorUserMediaSuccessCallback, errorCallback: NavigatorUserMediaErrorCallback) => void;

export const FileSelectWithThumbnail = (props: IFileUploadWithThumbnailProps) => {
    const {file: showFile, onCapture, width, preivewHook, deleteHook, enable, filename, iconSize} = props;

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let file: File | null = null;
        if (event.target.files !== null && event.target.files.length !== 0) {
            file = event.target.files[0];
        }
        onCapture(file);
    };

    const [inputByCameraRef, setInputByCameraRef] = React.useState<HTMLInputElement | null>(null);
    const [inputByGalleryRef, setInputByGalleryRef] = React.useState<HTMLInputElement | null>(null);
    const [isThumbnailAreaClicked, setIsThumbnailAreaClicked] = React.useState(false);
    const [isCameraOrGallerySelecterOpen, setIsCameraOrGallerySelecterOpen] = React.useState(false);

    const onThumbnailAreaClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsThumbnailAreaClicked(true);
    };
    const bindInputByCameraRef = (instance: HTMLInputElement | null) => {
        setInputByCameraRef(instance);
    };
    const bindInputByGalleryRef = (instance: HTMLInputElement | null) => {
        setInputByGalleryRef(instance);
    }
    const onImageSelectionMethodSelecterClose = () => setIsCameraOrGallerySelecterOpen(false);
    const onCaptureByCameraSelected = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!inputByCameraRef) {
            return;
        }

        inputByCameraRef.click();
        setIsCameraOrGallerySelecterOpen(false);
    }
    const onCaptureByGallerySelected = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!inputByGalleryRef) {
            return;
        }

        inputByGalleryRef.click();
        setIsCameraOrGallerySelecterOpen(false);
    }

    React.useEffect(() => {
        if (!inputByGalleryRef || !isThumbnailAreaClicked) {
            return;
        }

        const asyncFunc = async () => {
            try {
                const cameraUseable = await isCameraUseable();
                
                if (cameraUseable) {
                    // camera or folder choice by Dialog
                    setIsCameraOrGallerySelecterOpen(true);
                }
                else {
                    inputByGalleryRef.click();
                }
            }
            finally {
                setIsThumbnailAreaClicked(false);
            }
        }

        asyncFunc();
    }, [isThumbnailAreaClicked, inputByGalleryRef]);

    const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

    const onPreviewButtonClick = React.useMemo(() => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (showFile !== undefined && showFile !== null) {
            preivewHook(showFile);
        }
    }, [preivewHook, showFile]);
    const onPreviewClose = () => setIsPreviewOpen(false);

    const onDeleteButtonClick = React.useMemo(() => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const agree = window.confirm("削除します。よろしいですか？");
        if (!agree) {
            return;
        }

        if (showFile !== undefined && showFile !== null) {
            deleteHook(showFile.name);
        }
        if (inputByCameraRef !== null) {
            inputByCameraRef.value = "";    // clear input
        }
        if (inputByGalleryRef !== null) {
            inputByGalleryRef.value = "";
        }
    }, [showFile, deleteHook, inputByCameraRef, inputByGalleryRef]);

    let content = AddAPhotoIconDom;
    let filenameArea = filenameAreaDummy;
    const buttonEnable = enable === undefined ? true : enable;
    const height = !showFile ? width : "auto";
    const style = {width: width, height};


    if (showFile !== null) {
        const iconSizing = iconSize ?? "small";
        filenameArea = (
            <div className="d-flex align-items-center justify-content-center">
                <span className="text-center text-truncate" style={{maxWidth: `calc(${width} - 3rem)`}}>{filename === undefined ? "-" : filename}</span>
                <IconButton style={{marginLeft: "auto"}} size={iconSizing} onClick={onPreviewButtonClick} >
                    <FullscreenIcon />
                </IconButton>
                <IconButton size={iconSizing} onClick={onDeleteButtonClick} disabled={!buttonEnable} >
                    <DeleteIcon />
                </IconButton>
            </div>
        );

        if (showFile !== undefined) {
            const imageUrl = URL.createObjectURL(showFile);

            const revoker = () => URL.revokeObjectURL(imageUrl);
            content = <img src={imageUrl} style={{width: "100%", height: "auto"}} alt={showFile.name} onLoad={revoker} />;
        }
        else {
            content = <img src="dummy.png" style={{width: "100%", height: "auto"}} />;  // 破損表示
        }
    }

    let dialog = <React.Fragment></React.Fragment>;
    if (preivewHook !== undefined) {
        dialog = (
            <Dialog
                open={isPreviewOpen}
                onClose={onPreviewClose}
                aria-describedby="full-screen-dialog-description"
            >
                <DialogContent className="p-0">
                    <div>
                        <IconButton style={{position: "absolute", right: 0, top: 0}}>
                            <CloseIcon />
                        </IconButton>
                        {content}
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <div className="d-inline-flex flex-column">
            <input type="file" onChange={onInputChange} ref={bindInputByCameraRef} style={{display: "none"}} accept="image/*" capture="environment"/>{/* カメラを使う場合 */}
            <input type="file" onChange={onInputChange} ref={bindInputByGalleryRef} style={{display: "none"}} accept="image/*" />{/* ファイル参照の場合 */}
            <Button variant="outlined" onClick={onThumbnailAreaClick} disabled={!buttonEnable} style={{padding: 1}} >
                <div style={style}>
                    {content}
                </div>
            </Button>
            {filenameArea}
            {dialog}
            <Dialog
                open={isCameraOrGallerySelecterOpen}
                onClose={onImageSelectionMethodSelecterClose}
                aria-describedby="image-capture-method-selection-dialog-description"
            >
                <DialogContent><DialogContentText>選択してください</DialogContentText></DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={onCaptureByCameraSelected} className="text-center">カメラを起動</Button>
                    <Button variant="outlined" onClick={onCaptureByGallerySelected} className="text-center">ファイルを選択</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};