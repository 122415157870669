import * as React from 'react';
import { Button, createStyles, makeStyles, TableCell, Theme } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import clsx from 'clsx';
import { Constants } from 'app-env';

export interface ISortableTableHeaderCellProps {
    title: string;
    order?: "asc" | "desc" | undefined;
    onToggle: (order: "asc" | "desc") => void;
}

const arrowStyles = makeStyles((theme: Theme) => 
    createStyles({
        asc: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        },
        desc: {
            transform: 'rotate(180deg)',
        }
    })
);

export const SortableTableHeaderCell = (props: ISortableTableHeaderCellProps) => {
    const {title, order, onToggle} = props;

    const classes = arrowStyles();

    const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const nextOrder = order === "asc" ? "desc" : "asc";
        onToggle(nextOrder);
    }

    let arrowIcon: React.ReactNode = <React.Fragment></React.Fragment>;
    if (order && !Constants.isIE) {
        arrowIcon = <ArrowUpwardIcon
            className={clsx(classes.asc, {
                [classes.desc]: order === "desc"
            })}
            style={{fontSize: "1rem"}}
        />;
    }

    return (
        <TableCell>
            <Button
                variant="text"
                onClick={onClick}
            >
                {title}
                {arrowIcon}
            </Button>
        </TableCell>
    );
}