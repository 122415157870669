import * as React from 'react';

export const BarcodeReaderIcon: React.StatelessComponent<React.CSSProperties> = (props) => {
    return (
        <svg className="MuiSvgIcon-root"
            style={props}
            viewBox="0 0 250 120">
            <path id="barcodeReader"
                    d="M 0.00,25.00
                    C 0.00,25.00 6.00,25.00 6.00,25.00
                        6.00,22.21 5.83,17.57 6.57,15.00
                        9.22,5.78 17.24,6.00 25.00,6.00
                        25.00,6.00 25.00,0.00 25.00,0.00
                        18.77,0.00 7.86,-1.31 3.28,3.28
                        -1.31,7.86 0.00,18.77 0.00,25.00 Z
                    M 218.00,0.00
                    C 218.00,0.00 218.00,6.00 218.00,6.00
                        227.03,6.00 240.19,3.71 243.43,15.00
                        244.17,17.57 244.00,22.21 244.00,25.00
                        244.00,25.00 250.00,25.00 250.00,25.00
                        250.00,25.00 248.69,6.02 248.69,6.02
                        248.69,6.02 243.00,0.89 243.00,0.89
                        243.00,0.89 218.00,0.00 218.00,0.00 Z
                    M 29.00,25.00
                    C 29.00,25.00 29.00,95.00 29.00,95.00
                        29.00,95.00 34.00,95.00 34.00,95.00
                        34.00,95.00 34.00,25.00 34.00,25.00
                        34.00,25.00 29.00,25.00 29.00,25.00 Z
                    M 42.00,25.00
                    C 42.00,25.00 42.00,95.00 42.00,95.00
                        42.00,95.00 47.00,95.00 47.00,95.00
                        47.00,95.00 47.00,25.00 47.00,25.00
                        47.00,25.00 42.00,25.00 42.00,25.00 Z
                    M 50.00,25.00
                    C 50.00,25.00 50.00,95.00 50.00,95.00
                        50.00,95.00 53.00,95.00 53.00,95.00
                        53.00,95.00 53.00,25.00 53.00,25.00
                        53.00,25.00 50.00,25.00 50.00,25.00 Z
                    M 62.00,25.00
                    C 62.00,25.00 62.00,95.00 62.00,95.00
                        62.00,95.00 63.00,76.00 63.00,76.00
                        63.00,76.00 63.00,42.00 63.00,42.00
                        63.00,42.00 62.00,25.00 62.00,25.00 Z
                    M 72.00,25.00
                    C 72.00,25.00 72.00,95.00 72.00,95.00
                        72.00,95.00 75.00,95.00 75.00,95.00
                        75.00,95.00 75.00,25.00 75.00,25.00
                        75.00,25.00 72.00,25.00 72.00,25.00 Z
                    M 79.00,25.00
                    C 79.00,25.00 79.00,95.00 79.00,95.00
                        79.00,95.00 86.00,95.00 86.00,95.00
                        86.00,95.00 86.00,25.00 86.00,25.00
                        86.00,25.00 79.00,25.00 79.00,25.00 Z
                    M 89.00,25.00
                    C 89.00,25.00 89.00,95.00 89.00,95.00
                        89.00,95.00 90.00,76.00 90.00,76.00
                        90.00,76.00 90.00,42.00 90.00,42.00
                        90.00,42.00 89.00,25.00 89.00,25.00 Z
                    M 93.00,25.00
                    C 93.00,25.00 93.00,95.00 93.00,95.00
                        93.00,95.00 100.00,95.00 100.00,95.00
                        100.00,95.00 100.00,25.00 100.00,25.00
                        100.00,25.00 93.00,25.00 93.00,25.00 Z
                    M 102.00,25.00
                    C 102.00,25.00 102.00,95.00 102.00,95.00
                        102.00,95.00 103.00,76.00 103.00,76.00
                        103.00,76.00 103.00,42.00 103.00,42.00
                        103.00,42.00 102.00,25.00 102.00,25.00 Z
                    M 107.00,25.00
                    C 107.00,25.00 107.00,95.00 107.00,95.00
                        107.00,95.00 108.00,76.00 108.00,76.00
                        108.00,76.00 108.00,42.00 108.00,42.00
                        108.00,42.00 107.00,25.00 107.00,25.00 Z
                    M 112.00,25.00
                    C 112.00,25.00 112.00,95.00 112.00,95.00
                        112.00,95.00 115.00,95.00 115.00,95.00
                        115.00,95.00 115.00,25.00 115.00,25.00
                        115.00,25.00 112.00,25.00 112.00,25.00 Z
                    M 125.00,25.00
                    C 125.00,25.00 125.00,95.00 125.00,95.00
                        125.00,95.00 128.00,95.00 128.00,95.00
                        128.00,95.00 128.00,25.00 128.00,25.00
                        128.00,25.00 125.00,25.00 125.00,25.00 Z
                    M 137.00,25.00
                    C 137.00,25.00 137.00,95.00 137.00,95.00
                        137.00,95.00 142.00,95.00 142.00,95.00
                        142.00,95.00 142.00,25.00 142.00,25.00
                        142.00,25.00 137.00,25.00 137.00,25.00 Z
                    M 158.00,25.00
                    C 158.00,25.00 158.00,95.00 158.00,95.00
                        158.00,95.00 159.00,78.00 159.00,78.00
                        159.00,78.00 159.00,44.00 159.00,44.00
                        159.00,44.00 158.00,25.00 158.00,25.00 Z
                    M 161.00,25.00
                    C 161.00,25.00 161.00,95.00 161.00,95.00
                        161.00,95.00 162.00,76.00 162.00,76.00
                        162.00,76.00 162.00,42.00 162.00,42.00
                        162.00,42.00 161.00,25.00 161.00,25.00 Z
                    M 164.00,25.00
                    C 164.00,25.00 164.00,95.00 164.00,95.00
                        164.00,95.00 165.00,78.00 165.00,78.00
                        165.00,78.00 165.00,44.00 165.00,44.00
                        165.00,44.00 164.00,25.00 164.00,25.00 Z
                    M 170.00,25.00
                    C 170.00,25.00 170.00,95.00 170.00,95.00
                        170.00,95.00 175.00,95.00 175.00,95.00
                        175.00,95.00 175.00,25.00 175.00,25.00
                        175.00,25.00 170.00,25.00 170.00,25.00 Z
                    M 178.00,25.00
                    C 178.00,25.00 178.00,95.00 178.00,95.00
                        178.00,95.00 183.00,95.00 183.00,95.00
                        183.00,95.00 183.00,25.00 183.00,25.00
                        183.00,25.00 178.00,25.00 178.00,25.00 Z
                    M 192.00,25.00
                    C 192.00,25.00 192.00,95.00 192.00,95.00
                        192.00,95.00 195.00,95.00 195.00,95.00
                        195.00,95.00 195.00,25.00 195.00,25.00
                        195.00,25.00 192.00,25.00 192.00,25.00 Z
                    M 200.00,25.00
                    C 200.00,25.00 200.00,95.00 200.00,95.00
                        200.00,95.00 203.00,95.00 203.00,95.00
                        203.00,95.00 203.00,25.00 203.00,25.00
                        203.00,25.00 200.00,25.00 200.00,25.00 Z
                    M 210.00,25.00
                    C 210.00,25.00 210.00,95.00 210.00,95.00
                        210.00,95.00 215.00,95.00 215.00,95.00
                        215.00,95.00 215.00,25.00 215.00,25.00
                        215.00,25.00 210.00,25.00 210.00,25.00 Z
                    M 244.00,95.00
                    C 244.00,97.95 244.19,103.34 243.43,106.00
                        240.26,117.06 227.87,115.00 219.00,115.00
                        219.00,115.00 219.00,119.84 219.00,119.84
                        219.00,119.84 243.00,119.84 243.00,119.84
                        244.93,119.19 246.21,118.40 247.49,116.85
                        251.10,112.49 250.00,100.73 250.00,95.00
                        250.00,95.00 244.00,95.00 244.00,95.00 Z
                    M 0.00,96.00
                    C 0.00,102.41 -1.54,114.28 4.13,118.40
                        7.71,121.00 21.11,120.00 26.00,120.00
                        26.00,120.00 26.00,115.00 26.00,115.00
                        18.02,115.00 9.33,115.63 6.57,106.00
                        5.83,103.43 6.00,98.79 6.00,96.00
                        6.00,96.00 0.00,96.00 0.00,96.00 Z" />
        </svg>
    );
};