import * as React from 'react';
import { BarcodeReaderIcon } from 'components/icons/BarcodeReaderIcon';
import { IconButton, Modal, Paper, Tooltip, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Quagga from 'quagga';
import { isCameraUseable } from 'actions/utils/UserDevices';
import 'styles/barcode.min.css';
import { Spinner } from 'reactstrap';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { toTimeString } from 'actions/utils/StringFormatter';

export interface IBarcodeReaderButtonProps extends React.CSSProperties {
    size?: "small" | "medium";
    supportBarcodeTypes?: string[];
    className?: string;
    onRead: (code: string) => void;
    onFinish?: () => void;
    onClick?: () => void;
}

export interface IMultiBarcodeReaderButtonProps extends React.CSSProperties {
    size?: "small" | "medium";
    supportBarcodeTypes?: string[];
    className?: string;
    onFinish: (codeList: string[]) => void;
    onClick?: () => void;
}

const isSingleProps = (arg: any): arg is IBarcodeReaderButtonProps => {
    return (typeof arg === "object") && (typeof arg.onRead === "function");
}


export const BarcodeReaderButton: React.FunctionComponent<IBarcodeReaderButtonProps | IMultiBarcodeReaderButtonProps> = (props) => {
    const {size, className, width, onClick, ...others} = props;

    const [open, setOpen] = React.useState(false);

    let styles: React.CSSProperties;

    let modal: JSX.Element;
    if (open) {
        if (isSingleProps(others)) {
            const {supportBarcodeTypes, onRead, onFinish, ...cssProperties} = others;
    
            styles = cssProperties;
        
            const onReadComplete = (code: string) => {
                if (code) {
                    onRead(code);
                }
        
                setOpen(false);
        
                if (onFinish) {
                    onFinish();
                }
            }
        
            const onClose = () => {
                setOpen(false);
                if (onFinish) {
                    onFinish();
                }
            }
    
            modal = <ScannerModalSingle onRead={onReadComplete} supportBarcodeTypes={supportBarcodeTypes} onClose={onClose} />;
        }
        else {
            const {supportBarcodeTypes, onFinish, ...cssProperties} = others;
    
            styles = cssProperties;
    
            const onCompelete = onFinish as ((codeList: string[]) => void);
            const onClose = (codeList: string[]) => {
                onCompelete(codeList);
                setOpen(false);
            }
            modal = <ScannerModalMultiple onClose={onClose} supportBarcodeTypes={supportBarcodeTypes} />;
        }
    }
    else {
        styles = {};
        modal = <React.Fragment></React.Fragment>;
    }

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(true);

        if (onClick) {
            onClick();
        }
    }

    const [isCameraExists, setIsCameraExists] = React.useState(false);
    React.useEffect(() => {
        const asyncFunc = async () => setIsCameraExists(await isCameraUseable());
        asyncFunc();
    }, []);

    return (
        <div>
            <IconButton size={size} style={styles} onClick={onButtonClick} className={className} disabled={!isCameraExists}><BarcodeReaderIcon width={width} /></IconButton>
            {modal}
        </div>
    );
};


const chatteringCount = 10;
let chatteringQueue: string[] = [...Array(chatteringCount - 1)].map(_ => "");
const clearChatteringQueue = () => {chatteringQueue = [...Array(chatteringCount - 1)].map(_ => "");};

/**
 * 連続n回一致でtrue
 * @param check
 */
const checkChattering = (check: string): boolean => {
    console.log(`before read ${chatteringQueue}`);

    if (chatteringQueue.every(before => before === check)) {
        clearChatteringQueue();
        return true;
    }

    chatteringQueue.push(check);
    chatteringQueue.shift();

    return false;
}

const defaultReaders = [
    "code_128_reader",
    "ean_reader",
    "ean_8_reader",
    "code_39_reader",
    "code_39_vin_reader",
    "codabar_reader",
    "upc_reader",
    "upc_e_reader",
    "i2of5_reader"
];

interface IScannerModalProps {
    onRead: (code: string) => void;
    supportBarcodeTypes?: string[];
    onClose: () => void;
}
const ScannerModalSingle = (props: IScannerModalProps) => {
    const {onRead, supportBarcodeTypes, onClose} = props;

    const onProcessed = (result: any) => {
        var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;
    
        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                result.boxes.filter(function (box) {
                    return box !== result.box;
                }).forEach(function (box) {
                    Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
                });
            }
    
            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
            }
    
            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
            }
        }
    }
    
    const onDetected = (result: any) => {
        const readCode: string = result.codeResult.code;

        if (!checkChattering(readCode)) {
            return;
        }

        console.log("Barcode detected and processed : [" + readCode + "]", result);
        onRead(readCode);
    }

    const onBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClose();
    }

    const [isStarted, setIsStarted] = React.useState(false);
    const onStarted = () => setIsStarted(true);


    // material-uiのmodalがfocusをrestoreしてしまうので、むりやりフォーカスを外す
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        buttonRef.current?.focus();
        modalRef.current?.click();
    }, [buttonRef.current, modalRef.current]);

    const focusRestore = (event: React.FocusEvent<HTMLButtonElement>) => {
        buttonRef.current?.focus();
    }

    return (
        <Modal ref={modalRef} open={true} style={{width: "100vw", height: "100vh"}} disableRestoreFocus={true}>
            <Paper className="w-100 h-100">
                <Tooltip title="キャンセル" placement="bottom">
                    <IconButton
                        color="inherit"
                        onClick={onBackButtonClick}
                        ref={buttonRef}
                        onBlur={focusRestore}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                </Tooltip>
                <div className="ml-auto mr-auto">
                    {isStarted ? null : <Spinner />}
                    <ScannerCanvas onStarted={onStarted} onProcessed={onProcessed} onDetected={onDetected} readers={supportBarcodeTypes ?? defaultReaders} />
                </div>
            </Paper>
        </Modal>
    );
}


interface IMultipleScannerModalProps {
    supportBarcodeTypes?: string[];
    onClose: (codeList: string[]) => void;
}
const ScannerModalMultiple = (props: IMultipleScannerModalProps) => {
    const {supportBarcodeTypes, onClose} = props;
    const [readHistory, setReadHistory] = React.useState<{time: Date, code: string}[]>([]);

    const onProcessed = (result: any) => {
        var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;
    
        if (result) {
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                result.boxes.filter(function (box) {
                    return box !== result.box;
                }).forEach(function (box) {
                    Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
                });
            }
    
            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "#00F", lineWidth: 2 });
            }
    
            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
            }
        }
    }
    
    const onDetected = (result: any) => {
        const readCode: string = result.codeResult.code;

        if (!checkChattering(readCode)) {
            return;
        }

        console.log("Barcode detected and processed : [" + readCode + "]", result);

        if (readHistory.some(before => before.code === readCode)) {
            console.log(`same code ${readCode} read before`);
            return;
        }

        readHistory.unshift({time: new Date(), code: readCode});
        setReadHistory([...readHistory]);
    }

    const onBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClose(readHistory.map(h => h.code).reverse());    // 読み取った順で渡す
    }

    const [isStarted, setIsStarted] = React.useState(false);
    const onStarted = () => setIsStarted(true);

    // material-uiのmodalがfocusをrestoreしてしまうので、むりやりフォーカスを外す
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        buttonRef.current?.focus();
        modalRef.current?.click();
    }, [buttonRef.current, modalRef.current]);

    const focusRestore = (event: React.FocusEvent<HTMLButtonElement>) => {
        buttonRef.current?.focus();
    }

    return (
        <Modal ref={modalRef} open={true} style={{width: "100vw", height: "100vh"}} disableRestoreFocus={true}>
            <Paper className="w-100 h-100 overflow-auto">
                <Tooltip title="終了" placement="bottom">
                    <IconButton
                        color="inherit"
                        onClick={onBackButtonClick}
                        ref={buttonRef}
                        onBlur={focusRestore}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                </Tooltip>
                <div className="ml-auto mr-auto">
                    {isStarted ? null : <Spinner />}
                    <ScannerCanvas onStarted={onStarted} onProcessed={onProcessed} onDetected={onDetected} readers={supportBarcodeTypes ?? defaultReaders} />
                </div>
                <div className="w-75 h-25 overflow-auto border border-primary mx-auto">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>時刻</TableCell>
                                <TableCell>読み取り値</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {readHistory.map((h, idx) => (
                                <TableRow key={`history ${idx}th row`}>
                                    <TableCell>{toTimeString(h.time)}</TableCell>
                                    <TableCell>{h.code}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </Modal>
    );
}


const canvasID = "interactive"; // Quaggaのデフォルトで#interactiveの要素をターゲットにする
interface IScannerCanvasProps {
    onStarted: () => void;
    onProcessed: (result: any) => void;
    onDetected: (result: any) => void;
    readers: string[];
}
const ScannerCanvas: React.StatelessComponent<IScannerCanvasProps> = (props) => {
    const {onStarted, onProcessed, onDetected, readers} = props;
    const canvasRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        Quagga.init({
            inputStream: {
                name: "Live",
                type: "LiveStream",
                target: canvasRef.current,
                constraints: {
                    width: canvasRef.current?.offsetWidth,
                    height: canvasRef.current?.offsetHeight,
                    facingMode: "environment"
                },
            },
            decoder: {
                readers,
                debug: {
                    showCanvas: true,
                    showPatches: true,
                    showFoundPatches: true,
                    showSkeleton: true,
                    showLabels: true,
                    showPatchLabels: true,
                    showRemainingPatchLabels: true,
                    boxFromPatches: {
                        showTransformed: true,
                        showTransformedBox: true,
                        showBB: true
                    }
                }
            },
        }, (err) => {
            if (err) {
                console.log(err);
                return
            }
    
            console.log("Quagga initialization finished. Ready to start");
            Quagga.start();

            onStarted();
        });
    
        Quagga.onProcessed(onProcessed);
        Quagga.onDetected(onDetected);

        return () => {
            Quagga.offProcessed(onProcessed);
            Quagga.offDetected(onDetected);
            Quagga.stop();
        }
    }, []);

    return <div ref={canvasRef} id={canvasID}></div>;
}


