import * as React from 'react';
import { Box, Collapse, IconButton, makeStyles, TableCell, TableRow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
        borderBottom: 'unset',
        },
    },
});

export interface ITableCollapseRowProps {
    cells: React.ReactNode[];
    open: boolean;
    onToggle?: (open: boolean) => void;
}

export const TableCollapseRow: React.FunctionComponent<ITableCollapseRowProps> = (props) => {
    const {cells, children, open, onToggle} = props;
    const classes = useRowStyles();

    const onExpandClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onToggle) {
            onToggle(!open);
        }
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={onExpandClick}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {cells}
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={cells.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {children}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}