import { ActionType } from '../actions/types/ActionType';
import * as Types from '../actions/types/CacheFlagsActionType';

export const CacheFlagsReducer = (prevState: Map<string, boolean> | undefined, action: ActionType): Map<string, boolean> => {
    if (prevState === undefined) {
        return new Map<string, boolean>([]);
    }

    let newState = new Map<string, boolean>(prevState.entries());

    switch (action.type) {
        case Types.cacheFlagsClear:
            newState = new Map<string, boolean>(Array.from(prevState.entries()).map(it => [it[0], false]));
            break;

        case Types.cacheFlagSet:
            const flagSetAction = action as Types.CacheFlagSetAction;
            newState.set(flagSetAction.target, flagSetAction.value);
            break;

        default:
            return prevState;
    }

    return newState;
}