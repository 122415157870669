import * as React from 'react';
import { IMartzMixCompanyNameWithBranch, IMartzMixCompanyList, IMartzMixServerConstants, ServerConstants } from 'store/ServerConstants';
import { Store, GlobalState } from 'store/GlobalState';
import { SelectorWithLabel } from './SelectorWithLabel';
import { MenuItem, SelectProps } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';

interface ICompanySelectionMap {
    uq: IMartzMixCompanyNameWithBranch;
    cmCodeMap: Map<string, IMartzMixCompanyNameWithBranch>;    // key: code
    coNameBranchMap: Map<string, Map<string, IMartzMixCompanyNameWithBranch>>;  // primary key: comapny name, secondary key: code
}
const compayMapping = (data: IMartzMixCompanyList): ICompanySelectionMap => {
    const cmCodeMap = new Map<string, IMartzMixCompanyNameWithBranch>(data.cm.map(cm => [cm.code, cm]));
    
    const coNames = data.co.map(co => co.name).filter((name, idx, self) => self.indexOf(name) === idx);
    const coNameBranchMap = new Map<string, Map<string, IMartzMixCompanyNameWithBranch>>(coNames.map(name => {
        const sameCompanies = data.co.filter(co => co.name === name);
        const branchMap = new Map<string, IMartzMixCompanyNameWithBranch>(sameCompanies.map(co => [co.code, co]));
        return [name, branchMap];
    }));

    return {uq: data.uq, cmCodeMap, coNameBranchMap};
}

export interface ICompanySelectorProps {
    onSelect: (companyCode: string) => void;
    variant?: "standard" | "filled" | "outlined";
    orientation?: "row" | "column";
}
interface ICompanySelectorConnectionProps extends ICompanySelectorProps {
    serverConstants: ServerConstants;
}
export interface ICompanyDataSelectorProps {
    onSelect: (company: IMartzMixCompanyNameWithBranch | null) => void;
    variant?: "standard" | "filled" | "outlined";
    orientation?: "row" | "column";
}
interface ICompanyDataSelectorConnectionProps extends ICompanyDataSelectorProps {
    serverConstants: ServerConstants;
}

export const CompanySelector = connect(
    (state: GlobalState, ownProps: ICompanySelectorProps) => ({
        serverConstants: state.serverConstants,
        ...ownProps
    })
)((props: ICompanySelectorConnectionProps) => {
    const {onSelect, variant, orientation, serverConstants} = props;

    const orientationMode = orientation ?? "column";

    const constants = serverConstants.martzmix;
    const companies = React.useMemo(() => constants ? compayMapping(constants.companies) : null, [constants]);

    const [companyLevel, setCompanyLevel] = React.useState("");
    const [selectedCompany, setSelectedCompany] = React.useState<IMartzMixCompanyNameWithBranch | null>(null);

    const [contractor, setContractor] = React.useState<Map<string, IMartzMixCompanyNameWithBranch> | null>(null);

    if (!companies) {
        return <Spinner />;
    }

    const desideCompany = (company: IMartzMixCompanyNameWithBranch | null) => {
        setSelectedCompany(company);
        onSelect(company?.code ?? "");
    }
    const clearCompany = () => {
        setSelectedCompany(null);
        onSelect("");
    }

    const onCompanyLevelChange = (current: string) => {
        clearCompany();
        setContractor(null);
        setCompanyLevel(current);
        if (current === "UQ") {
            desideCompany(companies.uq);
        }
    }

    const onCMBranchSelectChange = (code: string) => {
        const company = companies.cmCodeMap.get(code) ?? null
        desideCompany(company);
    }

    const onCoNameSelectChange = (name: string) => {
        clearCompany();
        setContractor(companies.coNameBranchMap.get(name) ?? null);
    }
    const onCoBranchSelectChange = (code: string) => {
        if (!contractor) {
            return;
        }

        const company = contractor.get(code) ?? null;
        desideCompany(company);
    }

    const companyLevelSelector = (
        <SelectorWithLabel
            variant={variant}
            className="mr-2"
            style={{minWidth: "10em"}}
            label="会社種別"
            onChange={onCompanyLevelChange}
            value={companyLevel}
        >
            <MenuItem value="UQ">UQ契約管財</MenuItem>
            <MenuItem value="CM">一次送付先</MenuItem>
            <MenuItem value="Co">二次送付先</MenuItem>
        </SelectorWithLabel>
    );

    let companySelector: JSX.Element = <React.Fragment></React.Fragment>;
    if (companyLevel === "CM") {
        companySelector = (
            <SelectorWithLabel
                variant={variant}
                style={{minWidth: "10em"}}
                label="会社名/支社名"
                onChange={onCMBranchSelectChange}
                value={selectedCompany?.code}
            >
                {
                    Array.from(companies.cmCodeMap.entries())
                        .map(it => <MenuItem value={it[0]} key={`${it[0]} menu item`}>{it[1].branch ? it[1].branch : it[1].name}</MenuItem>)
                }
            </SelectorWithLabel>
        );
    }
    else if (companyLevel === "Co")
    {
        const nameMenus = Array.from(companies.coNameBranchMap.entries()).map(it => <MenuItem value={it[0]} key={`company=${it[0]}`}>{it[0]}</MenuItem>);
        const branchMenus = !contractor ? [] : Array.from(contractor.entries()).map(it => <MenuItem value={it[0]} key={`${it[0]} menu item`}>{it[1].branch ? it[1].branch : it[1].name}</MenuItem>);

        const selectedCompanyNameValue = contractor ? Array.from(contractor.values())[0].name : null;

        companySelector = (
            <div className={`d-flex ${orientationMode === "column" ? "flex-column" : ""}`}>
                <SelectorWithLabel
                    variant={variant}
                    className="mr-2"
                    style={{minWidth: "10em"}}
                    label="会社名"
                    onChange={onCoNameSelectChange}
                    value={selectedCompanyNameValue}
                >
                    {nameMenus}
                </SelectorWithLabel>
                <SelectorWithLabel
                    variant={variant}
                    style={{minWidth: "10em"}}
                    label="支社名"
                    onChange={onCoBranchSelectChange}
                    value={selectedCompany?.code}
                >
                    {branchMenus}
                </SelectorWithLabel>
            </div>
        );
    }

    return (
        <div className={`m-2 d-flex ${orientationMode === "column" ? "flex-column" : ""}`}>
            {companyLevelSelector}
            {companySelector}
        </div>
    );
})

export const CompanyDataSelector = connect(
    (state: GlobalState, ownProps: ICompanyDataSelectorProps) => ({
        serverConstants: state.serverConstants,
        ...ownProps
    })
)((props: ICompanyDataSelectorConnectionProps) => {
    const {onSelect, variant, orientation, serverConstants} = props;

    const orientationMode = orientation ?? "column";

    const constants = serverConstants.martzmix;
    const companies = React.useMemo(() => constants ? compayMapping(constants.companies) : null, [constants]);

    const [companyLevel, setCompanyLevel] = React.useState("");
    const [selectedCompany, setSelectedCompany] = React.useState<IMartzMixCompanyNameWithBranch | null>(null);

    const [contractor, setContractor] = React.useState<Map<string, IMartzMixCompanyNameWithBranch> | null>(null);

    if (!companies) {
        return <Spinner />;
    }

    const desideCompany = (company: IMartzMixCompanyNameWithBranch | null) => {
        setSelectedCompany(company);
        onSelect(company);
    }
    const clearCompany = () => {
        setSelectedCompany(null);
        onSelect(null);
    }

    const onCompanyLevelChange = (current: string) => {
        clearCompany();
        setContractor(null);
        setCompanyLevel(current);
        if (current === "UQ") {
            desideCompany(companies.uq);
        }
    }

    const onCMBranchSelectChange = (code: string) => {
        const company = companies.cmCodeMap.get(code) ?? null
        desideCompany(company);
    }

    const onCoNameSelectChange = (name: string) => {
        clearCompany();
        setContractor(companies.coNameBranchMap.get(name) ?? null);
    }
    const onCoBranchSelectChange = (code: string) => {
        if (!contractor) {
            return;
        }

        const company = contractor.get(code) ?? null;
        desideCompany(company);
    }

    const companyLevelSelector = (
        <SelectorWithLabel
            variant={variant}
            className="mr-2"
            style={{minWidth: "10em"}}
            label="会社種別"
            onChange={onCompanyLevelChange}
            value={companyLevel}
        >
            <MenuItem value="UQ">UQ契約管財</MenuItem>
            <MenuItem value="CM">一次送付先</MenuItem>
            <MenuItem value="Co">二次送付先</MenuItem>
        </SelectorWithLabel>
    );

    let companySelector: JSX.Element = <React.Fragment></React.Fragment>;
    if (companyLevel === "CM") {
        companySelector = (
            <SelectorWithLabel
                variant={variant}
                style={{minWidth: "10em"}}
                label="会社名/支社名"
                onChange={onCMBranchSelectChange}
                value={selectedCompany?.code}
            >
                {
                    Array.from(companies.cmCodeMap.entries())
                        .map(it => <MenuItem value={it[0]} key={`${it[0]} menu item`}>{it[1].branch ? it[1].branch : it[1].name}</MenuItem>)
                }
            </SelectorWithLabel>
        );
    }
    else if (companyLevel === "Co")
    {
        const nameMenus = Array.from(companies.coNameBranchMap.entries()).map(it => <MenuItem value={it[0]} key={`company=${it[0]}`}>{it[0]}</MenuItem>);
        const branchMenus = !contractor ? [] : Array.from(contractor.entries()).map(it => <MenuItem value={it[0]} key={`${it[0]} menu item`}>{it[1].branch ? it[1].branch : it[1].name}</MenuItem>);

        const selectedCompanyNameValue = contractor ? Array.from(contractor.values())[0].name : null;

        companySelector = (
            <div className={`d-flex ${orientationMode === "column" ? "flex-column" : ""}`}>
                <SelectorWithLabel
                    variant={variant}
                    className="mr-2"
                    style={{minWidth: "10em"}}
                    label="会社名"
                    onChange={onCoNameSelectChange}
                    value={selectedCompanyNameValue}
                >
                    {nameMenus}
                </SelectorWithLabel>
                <SelectorWithLabel
                    variant={variant}
                    style={{minWidth: "10em"}}
                    label="支社名"
                    onChange={onCoBranchSelectChange}
                    value={selectedCompany?.code}
                >
                    {branchMenus}
                </SelectorWithLabel>
            </div>
        );
    }

    return (
        <div className={`m-2 d-flex ${orientationMode === "column" ? "flex-column" : ""}`}>
            {companyLevelSelector}
            {companySelector}
        </div>
    );
})