import * as Types from 'actions/types/ServerConstantsActionType';
import { ActionType } from 'actions/types/ActionType';
import { ServerConstants, serverConstantsInitial } from 'store/ServerConstants';

export const ServerConstantsReducer = (prevState: ServerConstants | undefined, action: ActionType): ServerConstants => {
    if (prevState === undefined) {
        return serverConstantsInitial;
    }

    if (action.type !== Types.loadServerConstants) {
        return prevState;
    }

    const {data} = action as Types.LoadServerConstants;

    return data;
}