import * as React from 'react';
import { IROListDataChunk, getROListAsync, IROListData, IListFilter, IROFlattenItemsListDataChunk, IItemFilter, getROFlattenItemsAync, exportROFlattenItemsAsync } from 'actions/RO/ROListAction';
import { Store } from 'store/GlobalState';
import { cacheFlagSet } from 'actions/types/CacheFlagsActionType';
import { TableHead, TableRow, TableCell, Table, TableBody, TableFooter, Button, createStyles, makeStyles, Theme, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { TablePaginationWithAction } from 'components/TablePagination';
import { roDetailPath } from './RODetail';
import { routePush } from 'actions/utils/History';
import * as QueryString from 'query-string';
import { RouteComponentProps } from 'react-router';
import { filter } from 'lodash';
import { DateRangeBox } from 'components/DateRangeBox';
import SearchIcon from '@material-ui/icons/Search';
import { roItemDetailPath } from './ROItemDetail';
import { IDetailItemData, StoringStatus } from 'actions/RO/RODetailAction';
import { DownloadButton } from 'components/DownloadButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Constants } from 'app-env';

export const roListPath = '/ro';

const tableHeaders = [
    "受付番号",
    "版",
    "元請会社名",
    "局番",
    "局名",
    "アイテム数",
    "ステータス",
    "倉庫名"
];

const pageSizeSelection = [
    10,
    50,
    100
];

const tableCacheName = "RO List table cache";

const getFilter = (parser: QueryString.ParsedQuery<string>): IListFilter => {
    const {state, search, pending} = parser;

    const filter: IListFilter = {};

    if (typeof state === "string") {
        filter.state = parseInt(state, 10);
    }
    if (typeof search === "string") {
        filter.search = search;
    }
    if (typeof pending === "string") {
        filter.pending = (pending.toLowerCase() === "true");
    }

    return filter;
}

export const ROList = (props: RouteComponentProps) => {
    const [enumerationByAcceptedNumber, setEnumeraionByAcceptedNumber] = React.useState(true);

    const onSwitchButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setEnumeraionByAcceptedNumber(!enumerationByAcceptedNumber);

    const filter = getFilter(QueryString.parse(props.location.search));
    React.useEffect(() => {
        setEnumeraionByAcceptedNumber(true);    // 局情報の検索内容が変わったら局一覧に戻す
    }, [filter.state, filter.search, filter.pending]);

    const acceptedNumberModePage = (
        <React.Fragment>
            <div className="ml-3 mt-3 mb-4 d-flex">
                <span className="h4 d-block">局一覧</span>
                <Button variant="contained" style={{backgroundColor: "lime", marginLeft: "auto"}} onClick={onSwitchButtonClick} >アイテム一覧へ切替</Button>
            </div>
            <EnumerationByAcceptedNumber filter={filter} />
        </React.Fragment>
    );
    const itemModePage = (
        <React.Fragment>
            <div className="ml-3 mt-3 mb-4 d-flex">
                <span className="h4 d-block">アイテム一覧</span>
                <Button variant="contained" style={{backgroundColor: "lime", marginLeft: "auto"}} onClick={onSwitchButtonClick} >局一覧へ切替</Button>
            </div>
            <EnumerationByItem filter={filter} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {enumerationByAcceptedNumber ? acceptedNumberModePage : itemModePage}
        </React.Fragment>
    );
}

interface IEnumerationByAcceptedNumberProps {
    filter: IListFilter;
}
const EnumerationByAcceptedNumber = (props: IEnumerationByAcceptedNumberProps) => {
    const {filter} = props;

    const [chunkedData, setChunkedData] = React.useState<IROListDataChunk | null>(null);
    const [getChunkTask, setGetChunkTask] = React.useState<Promise<IROListDataChunk | null> | null>(null);
    const [chunkSize, setChunkSize] = React.useState(pageSizeSelection[0]);
    const [page, setPage] = React.useState(0);

    const isTableCached = Store.getState().cacheFlags.get(tableCacheName) ?? false;

    React.useEffect(() => {
        setGetChunkTask(getROListAsync(chunkSize, 0, filter, !isTableCached));
    }, [filter.state, filter.search, filter.pending]);

    React.useEffect(() => {
        if (!getChunkTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!getChunkTask) {
                return;
            }

            const result = await getChunkTask;
            if (!isUnmounted) {
                if (result) {
                    setChunkedData(result);
                    Store.dispatch({type: cacheFlagSet, target: tableCacheName});
                }
                setGetChunkTask(null);
            }
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [getChunkTask]);

    const onChangeChunkSize = (size: number) => {
        setChunkSize(size);
        setPage(0);

        setGetChunkTask(getROListAsync(size, 0, filter));
    }

    const onChangePage = (newPage: number) => {
        setPage(newPage);

        setGetChunkTask(getROListAsync(chunkSize, newPage, filter));
    }

    return (
        <React.Fragment>
            <div className="w-100 pr-5 text-right"><div className="d-inline-block">{`全 ${chunkedData?.totalCount ?? 0}件`}</div></div>
            <div style={{overflowX: "auto", maxWidth: "100vw"}}>
                <ROListTable chunkedData={chunkedData} onChunkSizeChange={onChangeChunkSize} onPageChange={onChangePage} loading={getChunkTask != null} />
            </div>
        </React.Fragment>
    );
}

interface IROListTableProps {
    chunkedData: IROListDataChunk | null;
    onChunkSizeChange: (size: number) => void;
    onPageChange: (page: number) => void;
    loading: boolean;
}
const ROListTable = (props: IROListTableProps) => {
    const header = React.useMemo(() => (
        <TableHead>
            <TableRow>
                {tableHeaders.map((h, idx) => (
                    <TableCell key={`${idx}th column ${h}`} style={{backgroundColor: "#8895dc"}}><span className="text-nowrap">{h}</span></TableCell>
                ))}
            </TableRow>
        </TableHead>
    ), []);

    const {chunkedData, onChunkSizeChange, onPageChange, loading} = props;

    const onChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        onPageChange(newPage);
    }

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChunkSizeChange(parseInt(event.target.value, 10));
    };

    if (!chunkedData || loading) {
        return (
            <Table>
                {header}
                <TableBody>
                    <TableRow>
                        <TableCell><Spinner /></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    const {chunk, chunkSize, page, totalCount} = chunkedData;

    const rowDOMs = <ROListTableRows list={chunk} />;

    return (
        <Table stickyHeader>
            {header}
            <TableBody>
                {rowDOMs}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePaginationWithAction
                        colSpan={tableHeaders.length /* 列数と同じ値を設定する */}
                        count={totalCount}
                        rowsPerPage={chunkSize}
                        rowsPerPageOptions={pageSizeSelection}
                        page={page}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
}

interface IROListTableRowsProps {
    list: IROListData[];
}
const ROListTableRows = (props: IROListTableRowsProps) => {
    const {list} = props;

    const [isMouseMove, setIsMouseMove] = React.useState(false);
    const onMouseDown = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(false);
    const onMouseMove = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(true);

    return (
        <React.Fragment>
            {list.map((data, idx) => {
                const onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                    if(isMouseMove) {
                        event.preventDefault();
                        return;
                    }

                    Store.dispatch(routePush(`${roDetailPath}/?accepted=${data.acceptedNumber}`));
                };

                const rowStyle: React.CSSProperties = {cursor: "pointer", backgroundColor: (data.pendingExists ? "red" : undefined)}

                return (
                    <TableRow hover key={`row of ${idx}`} onClick={onRowClick} style={rowStyle}>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className="text-nowrap">{data.acceptedNumber}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className="text-nowrap">{data.loadedVersion}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className="text-nowrap">{data.contractorName}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className="text-nowrap">{data.stationNumber}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className="text-nowrap">{data.stationName}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className="text-nowrap">{data.itemsCount}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className="text-nowrap">{data.status}</span></TableCell>
                        <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className="text-nowrap">{Constants.ro.warehouseNameMap.get(data.warehouse)}</span></TableCell>
                    </TableRow>
                );
            })}
        </React.Fragment>
    )
}


interface IEnumerationByItemProps {
    filter: IListFilter;
}
const toDayLastTime = (date: Date | null | undefined) => {
    if (!date) {
        return null;
    }

    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 99);
}
const itemListCacheName = "RO flattend items cache";
const EnumerationByItem = (props: IEnumerationByItemProps) => {
    const {filter} = props;

    const [storedDateStart, setStoredDateStart] = React.useState<Date | null>(null);
    const [storedDateEnd, setStoredDateEnd] = React.useState<Date | null>(null);
    const [inventoryDateStart, setInventoryDateStart] = React.useState<Date | null>(null);
    const [inventoryDateEnd, setInventoryDateEnd] = React.useState<Date | null>(null);
    const [shippedDateStart, setShippedDateStart] = React.useState<Date | null>(null);
    const [shippedDateEnd, setShippedDateEnd] = React.useState<Date | null>(null);

    const [chunkedData, setChunkedData] = React.useState<IROFlattenItemsListDataChunk | null>(null);
    const [getChunkTask, setGetChunkTask] = React.useState<Promise<IROFlattenItemsListDataChunk | null> | null>(null);
    const [chunkSize, setChunkSize] = React.useState(pageSizeSelection[0]);
    const [page, setPage] = React.useState(0);

    const isTableCached = Store.getState().cacheFlags.get(itemListCacheName) ?? false;

    let itemFilter: IItemFilter | undefined = undefined;
    if (storedDateStart || storedDateEnd || inventoryDateStart || inventoryDateEnd || shippedDateStart || shippedDateEnd) {
        itemFilter = {
            storedRangeStartDate: storedDateStart ?? undefined,
            storedRangeEndDate: toDayLastTime(storedDateEnd) ?? undefined,
            inventoryRangeStartDate: inventoryDateStart ?? undefined,
            inventoryRangeEndDate: toDayLastTime(inventoryDateEnd) ?? undefined,
            shippedRangeStartDate: shippedDateStart ?? undefined,
            shippedRangeEndDate: toDayLastTime(shippedDateEnd) ?? undefined
        };
    }

    React.useEffect(() => {
        setGetChunkTask(getROFlattenItemsAync(chunkSize, 0, filter, itemFilter, !isTableCached));
    }, [filter.state, filter.state, filter.pending]);

    React.useEffect(() => {
        if (!getChunkTask) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            if (!getChunkTask) {
                return;
            }

            const result = await getChunkTask;
            if (!isUnmounted) {
                if (result) {
                    setChunkedData(result);
                    Store.dispatch({type: cacheFlagSet, target: itemListCacheName});
                }
                setGetChunkTask(null);
            }
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [getChunkTask]);

    const onChangeChunkSize = (size: number) => {
        setChunkSize(size);
        setPage(0);

        setGetChunkTask(getROFlattenItemsAync(size, 0, filter, itemFilter));
    }

    const onChangePage = (newPage: number) => {
        setPage(newPage);

        setGetChunkTask(getROFlattenItemsAync(chunkSize, newPage, filter, itemFilter));
    }

    const onSearchButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setPage(0);
        setChunkSize(pageSizeSelection[0]);
        setGetChunkTask(getROFlattenItemsAync(pageSizeSelection[0], 0, filter, itemFilter));
    }

    return (
        <React.Fragment>
            <div className="container">
                <div className="row justify-content-center align-items-baseline">
                    <span style={{fontSize: "1.3rem", marginRight: "1em", display: "inline-block"}}>入庫処理日時: </span>
                    <DateRangeBox
                        startLabel="開始"
                        startDate={storedDateStart}
                        onStartDateChange={setStoredDateStart}
                        endLabel="終了"
                        endDate={storedDateEnd}
                        onEndDateChange={setStoredDateEnd}
                    />
                </div>
                <div className="row justify-content-center align-items-baseline">
                    <span style={{fontSize: "1.3rem", marginRight: "1em", display: "inline-block"}}>棚卸処理日時: </span>
                    <DateRangeBox
                        startLabel="開始"
                        startDate={inventoryDateStart}
                        onStartDateChange={setInventoryDateStart}
                        endLabel="終了"
                        endDate={inventoryDateEnd}
                        onEndDateChange={setInventoryDateEnd}
                    />
                </div>
                <div className="row justify-content-center align-items-baseline">
                    <span style={{fontSize: "1.3rem", marginRight: "1em", display: "inline-block"}}>出庫処理日時: </span>
                    <DateRangeBox
                        startLabel="開始"
                        startDate={shippedDateStart}
                        onStartDateChange={setShippedDateStart}
                        endLabel="終了"
                        endDate={shippedDateEnd}
                        onEndDateChange={setShippedDateEnd}
                    />
                </div>
                <IconButton onClick={onSearchButtonClick} style={{marginLeft: "85%"}} ><SearchIcon /></IconButton>
            </div>
            <div className="w-100 pr-3 d-flex justify-content-end">
                <div className="d-inline-block">{`全 ${chunkedData?.totalCount ?? 0}件`}</div>
                <Tooltip title="一覧DL" placement="top">
                    <div>
                        <DownloadButton
                            urlGetter={() => exportROFlattenItemsAsync(filter, itemFilter)}
                            size="small"
                            disabled={!!getChunkTask}
                        >
                            <GetAppIcon width="2em" />
                        </DownloadButton>
                    </div>
                </Tooltip>
            </div>
            <div style={{overflowX: "auto", maxWidth: "100vw"}}>
                <ROFlattenItemsTable chunkedData={chunkedData} onChunkSizeChange={onChangeChunkSize} onPageChange={onChangePage} loading={getChunkTask != null} />
            </div>
        </React.Fragment>
    )
}

const tableStyles = makeStyles((theme: Theme) => 
    createStyles({
        itemColor: {
            backgroundColor: "white"
        },
        showOnlyItemColor: {
            backgroundColor: "gainsboro"
        },
        pendingItemColor: {
            backgroundColor: "red"
        }
    })
);
const isShowOnlyItem = (item: IDetailItemData): boolean => {
    return item.treatment !== "UQ倉庫へ送付";
}
const flattenItemsTableHeader = [
    "項番",
    "撤去設備番号",
    "設備カテゴリ",
    "撤去設備名",
    "撤去区分",
    "受付数量",
    "枝番",
    "単位",
    "重量",
    "送付指示_処理区分",
    "状態",
    "入庫日時"
];
const storingStatusToStringMap = new Map<number, string>([
    [StoringStatus.NoCheck, "未受領"],
    [StoringStatus.Receipted, "受領済"],
    [StoringStatus.Stored, "入庫済"],
    [StoringStatus.Shipped, "出庫済"]
]);
interface IROFlattenItemsTableProps {
    chunkedData: IROFlattenItemsListDataChunk | null;
    onChunkSizeChange: (size: number) => void;
    onPageChange: (page: number) => void;
    loading: boolean;
}
const ROFlattenItemsTable = (props: IROFlattenItemsTableProps) => {
    const header = React.useMemo(() => (
        <TableHead>
            <TableRow>
                {flattenItemsTableHeader.map((h, idx) => (
                    <TableCell key={`${idx}th column ${h}`} style={{backgroundColor: "#8895dc"}}><span className="text-nowrap">{h}</span></TableCell>
                ))}
            </TableRow>
        </TableHead>
    ), []);

    const classes = tableStyles();

    const [isMouseMove, setIsMouseMove] = React.useState(false);
    const onMouseDown = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(false);
    const onMouseMove = (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => setIsMouseMove(true);

    const {chunkedData, onChunkSizeChange, onPageChange, loading} = props;

    const onChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        onPageChange(newPage);
    }

    const onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChunkSizeChange(parseInt(event.target.value, 10));
    };

    if (!chunkedData || loading) {
        return (
            <Table>
                {header}
                <TableBody>
                    <TableRow>
                        <TableCell><Spinner /></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    const {chunk, chunkSize, page, totalCount} = chunkedData;

    const rowDOMs = chunk.map((row, rowIndex) => {
        const onRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
            if(isMouseMove) {
                event.preventDefault();
                return;
            }

            Store.dispatch(routePush(`${roItemDetailPath}/?accepted=${row.acceptedNumber}&item=${row.itemNumber}&branch=${row.acceptedCountBranch}`));
        };

        const isShowOnly = isShowOnlyItem(row);

        let rowColoring: string;
        let fontColoring = "text-nowrap";
        let clickEventListener: ((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void) | undefined = undefined;
        let pointerStyle: React.CSSProperties | undefined = undefined;
        if (row.isPending) {
            rowColoring = classes.pendingItemColor;
        }
        else if (isShowOnly) {
            rowColoring = classes.showOnlyItemColor;
        }
        else {
            rowColoring = classes.itemColor;
            clickEventListener = onRowClick;
            pointerStyle = {cursor: "pointer"};

            if (row.storingStatus >= StoringStatus.Stored) {
                fontColoring = `${fontColoring} text-primary`;
            }
        }

        return (
            <TableRow hover key={`data list of ${rowIndex}th row`} className={rowColoring} onClick={clickEventListener} style={pointerStyle}>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{rowIndex + 1}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.facilityNumber}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.facilityCategory}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.facilityName}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.recycleType}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.acceptedCount}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.acceptedCountBranch}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.unit}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.weight}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.treatment}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{isShowOnly ? "-" : storingStatusToStringMap.get(row.storingStatus)}</span></TableCell>
                <TableCell onMouseDown={onMouseDown} onMouseMove={onMouseMove}><span className={fontColoring}>{row.storedDateString}</span></TableCell>
            </TableRow>
        )
    });

    return (
        <Table stickyHeader>
            {header}
            <TableBody>
                {rowDOMs}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePaginationWithAction
                        colSpan={flattenItemsTableHeader.length /* 列数と同じ値を設定する */}
                        count={totalCount}
                        rowsPerPage={chunkSize}
                        rowsPerPageOptions={pageSizeSelection}
                        page={page}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    );
}