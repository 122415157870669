import * as Action from "actions/types/HistoryType";
import { History } from 'history';

export const routePush = (path: string): Action.IRoutePushAction => ({
    type: Action.routePushAction,
    path
});
export const routeInject = (history: History<any>): Action.IRouterInject => ({
    type: Action.inject,
    history
});