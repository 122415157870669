import { HttpRequest } from "actions/utils/HttpRequest"
import { AppConfig } from "app-env"

export interface IOperationHistory {
    acceptedNumber: string;
    stationNumber: string;
    stationName: string;
    warehouse: number;
    totalCount: number;
    receiptCount: number;
    storeCount: number;
    stocktakeCount: number;
    shipCount: number;
    totalStoredCount: number;
}

export const researchDayOperationAsync = async (date: Date): Promise<IOperationHistory[]> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("ro/warehouse/operation/history", {target: date});

    if (!response) {
        alert("サーバーからの応答がありません");
        return [];
    }

    const json = await response.json();

    if (!response.ok) {
        console.error(`${json.message} status=${response.status}`);
        alert(json.message);
        return [];
    }

    return json.histories;
}


export interface IWorkingSchedule {
    acceptedNumber: string;
    stationNumber: string;
    stationName: string;
    warehouse: number;
    status: number;
}
export const getWorkingScheduleAsync = async (dayBefore: number): Promise<IWorkingSchedule[]> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("ro/warehouse/operation/schedule", {dayBefore});

    if (!response) {
        alert("サーバーからの応答がありません");
        return [];
    }

    const json = await response.json();

    if (!response.ok) {
        console.error(`${json.message} status=${response.status}`);
        alert(json.message);
        return [];
    }

    return json.schedules;
}