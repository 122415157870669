import * as React from 'react';
import { ServerConstants } from 'store/ServerConstants';
import { GlobalState } from 'store/GlobalState';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export interface IMixDocumentFieldProps {
    value: string;
    error?: boolean;
    helperText?: string;
    onChange?: (current: string) => void;
}

interface IMixDocumentFieldConnectionProps extends IMixDocumentFieldProps {
    serverConstants: ServerConstants;
}

export const MixDocumentField = connect(
    (state: GlobalState, ownProps: IMixDocumentFieldProps) => ({
        serverConstants: state.serverConstants,
        ...ownProps
    })
)((props: IMixDocumentFieldConnectionProps) => {
    const {value, onChange, error, helperText, serverConstants} = props;

    const docNames = serverConstants.martzmix?.documentNames;
    if (!docNames) {
        return <Spinner />;
    }

    const propagate = (current: string) => {
        if (onChange) {
            onChange(current);
        }
    }

    const onDocumentNameChanged = (event: React.ChangeEvent<{name?: string | undefined,value: unknown}>) => {
        propagate(event.target.value as string);
    }
    const onAutoCompleteChanged = (event: any, newValue: string | null) => {
        propagate(newValue ?? "");
    }

    return <Autocomplete
        options={docNames}
        style={{minWidth: "15em"}}
        freeSolo
        onChange={onAutoCompleteChanged}
        renderInput={params => (
            <TextField
                {...params}
                label="文書名"
                variant="outlined"
                fullWidth
                value={value}
                onChange={onDocumentNameChanged}
                error={error}
                helperText={error ? "必須項目です" : undefined}
                InputLabelProps={{shrink: !!value}}
            />
        )}
    />;
})