import 'react-app-polyfill/ie11';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { Login } from './pages/Login';
import { unregister } from './registerServiceWorker';
import { Switch, Route, Router } from 'react-router';
import { Auth } from './components/Auth';
import { Store, Persistor, BrowserHistory } from './store/GlobalState';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { userRegistrationPath, UserRegistration } from 'pages/UserRegistration';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={Store}>
        <PersistGate loading={null} persistor={Persistor}>
            <Router history={BrowserHistory}>
                <Switch>
                    <Route exact path={userRegistrationPath} component={UserRegistration} />
                    <Route exact path='/login' component={Login} key='Login' />
                    <Auth key='Auth'>
                        <Route exact component={App} key='App' />
                    </Auth>
                </Switch>
            </Router>
        </PersistGate>
    </Provider>,
rootElement);

// PWA対応する場合は有効化する。キャッシュが効きすぎるのでそれ以外では無効化しておく
// registerServiceWorker();
unregister();

