import * as React from 'react';

export const LeftArrowIcon: React.StatelessComponent<React.CSSProperties> = (props) => {
    return (
        <svg className="MuiSvgIcon-root"
            style={props}
            viewBox="0 0 200 50">
                <path id="leftArrow"
                    d="M 50.00,4.00
                    C 50.00,4.00 13.00,25.00 13.00,25.00
                      13.00,25.00 13.00,27.00 13.00,27.00
                      13.00,27.00 51.00,48.00 51.00,48.00
                      51.00,48.00 51.00,36.00 51.00,36.00
                      51.00,36.00 184.00,36.00 184.00,36.00
                      184.00,36.00 184.00,16.00 184.00,16.00
                      184.00,16.00 51.00,16.00 51.00,16.00
                      51.00,16.00 50.00,4.00 50.00,4.00 Z"
                />
        </svg>
    );
};