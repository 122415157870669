import * as React from 'react';
import { GoogleMap, Marker, useLoadScript, InfoWindow } from '@react-google-maps/api';
import { Spinner } from 'reactstrap';
import { isEqual } from '../actions/utils/LocationUtil';
import { BaseStationSammary } from '../types/StationTypes';
import { Divider } from '@material-ui/core';
import * as Action from '../actions/GoogleMapAction';
import { GlobalState } from '../store/GlobalState';
import { connect } from 'react-redux';
import { ActionType } from 'actions/types/ActionType';

export type MarkerEventListenerBuilder = (station: BaseStationSammary) => (() => void);

export interface IBaseStationMapProps {
    
    currentLocation: google.maps.LatLngLiteral;
    onShowStationDetailListenerBuilder: MarkerEventListenerBuilder;
    onForcusChanged: (forcusLocation: google.maps.LatLngLiteral) => void;
}

interface IControlableMapProps extends IBaseStationMapProps {
    apiKey: string | null;
    mapRef: google.maps.Map | null;
    stations: BaseStationSammary[];
    bindGoogleMapInstance: (ref: google.maps.Map | null) => void;
}

const infoWindowOpenMapContext = React.createContext<{setter: ((prev: Map<string, boolean>, key: string, value: boolean) => void), value: Map<string, boolean>}>({
    setter: (prev, key, value) => {},
    value: new Map([])
});

export const BaseStationsOnGoogleMap = connect(
    (state: GlobalState, ownProps: IBaseStationMapProps) => ({
        apiKey: state.homePage.googleMapsAPIKey,
        mapRef: state.googleMap,
        stations: state.homePage.stations,
        ...ownProps
    }),
    (dispatch: React.Dispatch<ActionType>, ownProps: IBaseStationMapProps) => ({
        bindGoogleMapInstance: (ref: google.maps.Map | null) => dispatch(Action.bindGoogleMapInstance(ref)),
        ...ownProps
    }),
    ({apiKey, mapRef, stations}, {bindGoogleMapInstance}, ownProps: IBaseStationMapProps): IControlableMapProps => ({
        apiKey, mapRef, stations, ...ownProps, bindGoogleMapInstance
    })
)((props: IControlableMapProps) => {

    const {mapRef, bindGoogleMapInstance, apiKey, ...ownProps} = props;
    const {onShowStationDetailListenerBuilder, stations, currentLocation, onForcusChanged} = ownProps;

    const [mapCenter, setMapCenter] = React.useState(currentLocation);
    const [currentLocationCentered, setCurrentLocationCentered] = React.useState(false);
    
    
    React.useEffect(() => {
        if (!currentLocationCentered && mapRef !== null) {
            // 最初の一回だけ現在地にセンタリングする
            console.log("initialize centering to current location");
            mapRef.panTo(currentLocation);
            setCurrentLocationCentered(true);
        }
    }, [currentLocationCentered, currentLocation, mapRef]);

    React.useEffect(() => {
        if (mapRef === null) {
            return;
        }

        setCurrentLocationCentered(true);

        // オブジェクト参照が変更されたら中心を移動
        mapRef.panTo(mapCenter);
    }, [mapCenter, mapRef]);
    

    React.useEffect(() => {
        if (mapRef === null) {
            return;
        }

        mapRef.panTo(currentLocation);
    }, [mapRef, currentLocation]);

    const bindMapRef = (map: google.maps.Map) => {bindGoogleMapInstance(map)};
    const moveMapCenter = () => {
        if (mapRef !== null) {
            const center = mapRef.getCenter();

            const centerLiteral: google.maps.LatLngLiteral = {lat: center.lat(), lng: center.lng()};
            if (!isEqual(mapCenter, centerLiteral)) {
                // 副作用式はここに書けないのでstateを経由してuseEffect()で変更する
                setMapCenter(centerLiteral);
            }
        }
    }
    const onMapClick = (event: google.maps.MouseEvent) => {
        if (mapRef == null) {
            return;
        }

        const clickedLocation: google.maps.LatLngLiteral = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        };

        if (!isEqual(mapCenter, clickedLocation)) {
            setMapCenter(clickedLocation);
            onForcusChanged(clickedLocation);
        }
    }

    let content: JSX.Element;
    if (apiKey !== null) {
        

        content = <BaseStationMap
            apiKey={apiKey}
            center={mapCenter}
            currentLocation={currentLocation}
            stations={stations}
            onLoad={bindMapRef}
            onDragEnd={moveMapCenter}
            onClick={onMapClick}
            onShowStationDetailListenerBuilder={onShowStationDetailListenerBuilder}
        />;
    }
    else {
        content = <Spinner />;
    }

    return (
        <div style={{width: "100%", height: "100%", position: "relative"}}>
            {content}
        </div>
    )
});


interface IMapProps {
    apiKey: string;
    center: google.maps.LatLngLiteral;
    currentLocation: google.maps.LatLngLiteral;
    stations:BaseStationSammary[];
    onLoad: (map: google.maps.Map) => void;
    onDragEnd: () => void;
    onClick: (e: google.maps.MouseEvent) => void;
    onShowStationDetailListenerBuilder: MarkerEventListenerBuilder;
}

const BaseStationMap: React.FunctionComponent<IMapProps> = (props) => {
    const {apiKey, center, currentLocation, stations, onLoad, onDragEnd, onClick, onShowStationDetailListenerBuilder} = props;

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: apiKey,
        language: "ja",
        region: "JP"
    });

    const [infoWindowOpenMap, setInfoWindowOpenMap] = React.useState<Map<string, boolean>>(new Map(stations.map(st => ([st.planned.id, false]))));
    const updateOpenMap = (prev: Map<string, boolean>, key: string, value: boolean) => {
        let next: Map<string, boolean>;

        if (value) {
            next = new Map([]);

            // 一度に開くinfoWindowは一つだけなので他を閉じる
            for (const it of prev.entries()) {
                next.set(it[0], false);
            }
        }
        else {
            next = new Map(prev.entries());
        }

        next.set(key, value);
        setInfoWindowOpenMap(next);
    };

    if (loadError) {
        return <span className="text-danger h1">Sorry. Cannot use google map now. Try again</span>
    }
    if (!isLoaded) {
        return <Spinner />;
    }

    const currentLocationPin = <CurrentLocationMarker currentLocation={currentLocation} />;

    const stationPins = stations.map((st) => (
        <infoWindowOpenMapContext.Provider value={{value: infoWindowOpenMap, setter: updateOpenMap}} key={`${st.planned.id}: lat=${st.location.latitude}, lng=${st.location.longitude}`}>
            <StationMarker
                station={st}
                clickEventBuilder={onShowStationDetailListenerBuilder}
            />
        </infoWindowOpenMapContext.Provider>
    ));

    return (
        <GoogleMap
            id="StationsMap"
            mapContainerStyle={{position: "static", height: "100%", width: "100%"}}
            zoom={15}
            center={center}
            onLoad={onLoad}
            onDragEnd={onDragEnd}
            onClick={onClick}
            options={{
                disableDefaultUI: true
            }}
        >
            {currentLocationPin}
            {stationPins}
        </GoogleMap>
    );
}


interface IStationMarkerProps {
    station: BaseStationSammary;
    clickEventBuilder: MarkerEventListenerBuilder;
}
const StationMarker = (props: IStationMarkerProps) => {
    const {station} = props;

    const {value: infoWindowOpenMap, setter: updateInfoWindowOpenMap} = React.useContext(infoWindowOpenMapContext);
    const isInfoWindowOpen = infoWindowOpenMap.get(station.planned.id);
    const setIsInfoWindowOpen = (open: boolean) => updateInfoWindowOpenMap(infoWindowOpenMap, station.planned.id, open);

    const listener = props.clickEventBuilder(station);
    const onInfoWindowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => listener();
    
    const onInfoWindowCloseClick = () => setIsInfoWindowOpen(false);
    const onMarkerClick = (event: google.maps.MouseEvent) => setIsInfoWindowOpen(true);

    const [markerRef, setMarkerRef] = React.useState<google.maps.Marker | null>(null);
    const bindMarkerInstance = (marker: google.maps.Marker) => setMarkerRef(marker);

    const position = {lat: station.location.latitude, lng: station.location.longitude};

    const infoWindowContent = (
        <React.Fragment>
            <span className="text-nowrap">{"計画局番: "}{station.planned.id}</span>
            <Divider />
            <span className="text-nowrap">{"計画局名: "}{station.planned.name}</span>
            <Divider />
            {station.implemented === null ? <React.Fragment></React.Fragment> : <React.Fragment>
                <span className="text-nowrap">{"正式局番: "}{station.implemented.id}</span>
                <Divider />
                <span className="text-nowrap">{"正式局名: "}{station.implemented.name}</span>
            </React.Fragment>}
        </React.Fragment>
    );

    const infoWindow = isInfoWindowOpen && markerRef && (<InfoWindow onCloseClick={onInfoWindowCloseClick} anchor={markerRef} >
        <div onClick={onInfoWindowClick} style={{cursor: "pointer", overflowX: "auto", minWidth: "17em"}}>
            {infoWindowContent}
        </div>
    </InfoWindow>);
    

    const marker = (
        <Marker
            position={position}
            onClick={onMarkerClick}
            onLoad={bindMarkerInstance}
            cursor="pointer"
        >
            {infoWindow}
        </Marker>
    );

    return marker;
}

interface ICurrentLocationMarkerProps {
    currentLocation: google.maps.LatLngLiteral;
}
const CurrentLocationMarker = (props: ICurrentLocationMarkerProps) => {
    const {currentLocation} = props;
    return (
        <Marker
            position={currentLocation}
            key={`latitude: ${currentLocation.lat}, longitude: ${currentLocation.lng}`}
            title="現在地"
            icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                scaledSize: new google.maps.Size(48, 48)    // 調整値
            }}
        />
    )
}