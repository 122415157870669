import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { IValidateDocumentRouteCompleteResponse, useRegisterDocumentRouteCompleteApi, useValidateDocumentRouteCompleteApi } from 'actions/MartzMix/DocumentActions';
import { cacheFlagsClear } from 'actions/types/CacheFlagsActionType';
import { PageTitle } from 'components/PageTitle';
import { XlsxLoader } from 'components/XlsxLoader';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';

export const setCompletePath = '/martzmix/completion';
export const setCompletePageName = '完了ステータス設定';

interface IValidateTableRow {
    code: string;
    isOk?: boolean;
    comment?: string;
}

const tableHeaders = [
    "文書番号",
    "完了可否",
    "備考"
];

export const SetComplete = () => {
    const [xlsxLoading, setXlsxLoading] = React.useState(false);
    const [isValidated, setIsValidated] = React.useState(false);
    const [rowDatas, setRowDatas] = React.useState<IValidateTableRow[]>([]);
    const dispatch = useDispatch();

    const onValid = React.useCallback((result: IValidateDocumentRouteCompleteResponse[]) => {
        setIsValidated(true);
        let sortedResult: IValidateDocumentRouteCompleteResponse[] = [];
        sortedResult = sortedResult.concat(result.filter(r => !r.isSettableComplete));
        sortedResult = sortedResult.concat(result.filter(r => r.isSettableComplete));

        setRowDatas(sortedResult.map((val) => ({code: val.code, isOk: val.isSettableComplete, comment: val.comment})));
    }, [setIsValidated]);

    const {isProcessing: isValidating, validate, result} = useValidateDocumentRouteCompleteApi(onValid);


    const onRegisterFinish = React.useCallback(() => {
        dispatch({type: cacheFlagsClear});
    }, [dispatch]);

    const {isProcessing: isRegistering, registrar} = useRegisterDocumentRouteCompleteApi(onRegisterFinish);

    const rowLoader = React.useCallback((cols: string[]) => cols[1], []);
    const onLoadStart = React.useCallback((file: File) => {
        const agree = window.confirm(`${file.name}の読み込みを開始します。\nよろしいですか？`);

        if (agree) {
            setIsValidated(false);
            setXlsxLoading(true);
            setRowDatas([]);
        }

        return agree;
    }, [setXlsxLoading, setIsValidated, setRowDatas]);
    const onLoadEnd = React.useCallback((result: string[]) => {
        setRowDatas(result.map((code) => ({code})));
        validate(result);
        setXlsxLoading(false);
    }, [validate, setRowDatas]);

    const headers = React.useMemo(() => tableHeaders.map((h, idx) => <TableCell key={`${idx}th header`}>{h}</TableCell>), []);
    
    const rows = React.useMemo(() => rowDatas.map((r, idx) => {
        let okCell = <TableCell>チェック中</TableCell>;
        let commentCell = <TableCell>-</TableCell>;
        if (r.isOk === true) {
            okCell = <TableCell><span style={{color: "springgreen"}}>完了可能</span></TableCell>;
        }
        else if (r.isOk === false) {
            okCell = <TableCell><span style={{color: "red"}}>完了不可</span></TableCell>;
            commentCell = <TableCell>{r.comment}</TableCell>;
        }

        return (
            <TableRow key={`${idx}th row`}>
                <TableCell>{r.code}</TableCell>
                {okCell}
                {commentCell}
            </TableRow>
        );
    }), [rowDatas]);

    const onRegisterButtonClick = React.useCallback((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!rowDatas.every(r => r.isOk)) {
            alert("完了ステータスに設定できない文書が含まれています。すべて設定可能な場合のみ実行できます");
            return;
        }

        registrar(rowDatas.map(r => r.code));
    }, [rowDatas]);

    return (
        <React.Fragment>
            <PageTitle>
                <span className="h4">{setCompletePageName}</span>
            </PageTitle>
            <XlsxLoader<string>
                colSize={2}
                sheet={0}
                rowLoader={rowLoader}
                onLoadStart={onLoadStart}
                onLoadEnd={onLoadEnd}
                identifierCol={0}
                buttonVariant="outlined"
                disabled={xlsxLoading || isValidating}
            >
                Excelから読み込み
            </XlsxLoader>
            {xlsxLoading ? <React.Fragment><span>読み込み中...</span><Spinner size="small" /></React.Fragment> : null}
            {isValidating ? <React.Fragment><span>経路を検証中...</span><Spinner size="small" /></React.Fragment> : null}
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {headers}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
            <br />
            <div className="text-right">
                {isRegistering ? (
                    <React.Fragment>
                        登録中...<Spinner size="small" />
                    </React.Fragment>
                ) : (
                    <Button variant="outlined" color="primary" disabled={!isValidated} onClick={onRegisterButtonClick}>完了ステータスを設定</Button>
                )}
            </div>
        </React.Fragment>
    )
}