import * as React from 'react';
import { IDateRangeBoxProps, DateRangeBox } from './DateRangeBox';
import { SelectorWithLabel } from './SelectorWithLabel';
import { MenuItem } from '@material-ui/core';

export interface IDateRangeSelectorProps extends IDateRangeBoxProps {
    onTypeChange: (type: string) => void;
    typeValue: string | null;
    variant?: "outlined" | "standard";
    typeOptions: {value: string, label: string}[];
}

export const DateRangeSelector: React.StatelessComponent<IDateRangeSelectorProps> = (props) => {
    const {onTypeChange, typeValue, variant, typeOptions, ...dateRangeBoxProps} = props;

    return (
        <div className={`d-inline-flex align-items-center m-2 pt-3 pl-2 pr-2 ${variant === "outlined" ? "border rounded" : ""}`}>
            <SelectorWithLabel
                className="ml-2"
                style={{minWidth: "12em"}}
                label="期間"
                onChange={onTypeChange}
                value={typeValue}
            >
                {typeOptions.map(t => <MenuItem value={t.value} key={t.value}>{t.label}</MenuItem>)}
            </SelectorWithLabel>
            <DateRangeBox {...dateRangeBoxProps}/>
        </div>
    )
}