import { HttpRequest } from "actions/utils/HttpRequest"
import { AppConfig } from "app-env"

export interface ISendRegisterDocument {
    documentCode: string;
    copiesNum: number;
}
export interface ISendRegister {
    documentCodeList: string[];
    slipNumber: string;
    comment: string;
}

export const registerSendAsync = async (data: ISendRegister): Promise<number | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("sendRegister/register", data);

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    const {historyId} = await response.json();
    return historyId;
}

export const getSlipAsync = async (historyID: number): Promise<{downloadUrl: string, filename: string} | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("sendHistory/slip/excel", {historyID});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return undefined;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}


export interface IDocumentData {
    code: string;
    name: string;
    copies: number;
    stationNumber: string;
    stationName: string;
}

export const getDocumentDataAsync = async (code: string, includeSentDocuments?: boolean): Promise<IDocumentData | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("document/resolve", {code, includeSentDocuments: includeSentDocuments ? "true" : "false"});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}

export const getDocumentHistoryDataAsync = async (code: string, slipId: number): Promise<IDocumentData | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("document/resolve/history", {code, slipId});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    return await response.json();
}

export const validateDocumentRoutingAsync = async (code: string): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("sendRegister/validate", {code});

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return false;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}

export const getGeneratedSlipNumber = async (): Promise<string | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getAsync("sendRegister/slipnumber");

    if (!response) {
        const msg = "サーバーからの応答がありません";
        alert(msg);
        console.error(msg);
        return null;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return null;
    }

    const {slipNumber} = await response.json();

    return slipNumber;
}