export interface ServerConstants {
    martzmix: IMartzMixServerConstants | null;
}

export const serverConstantsInitial: ServerConstants = {
    martzmix: null
}


export interface IMartzMixCompanyNameWithBranch {
    name: string;
    branch: string | null;
    code: string;
}
export interface IMartzMixCompanyList {
    uq: IMartzMixCompanyNameWithBranch;
    cm: IMartzMixCompanyNameWithBranch[];
    co: IMartzMixCompanyNameWithBranch[];
}

export interface IMartzMixServerConstants {
    documentNames: string[];
    companies: IMartzMixCompanyList;
}