export enum SystemTypes {
    None = "No SYSTEM",
    Next = "WICKY NEXT",
    Mix = "Wicky_MARTZ MIX",
    RO = "WICKY RO"
}

export interface SystemConstants {
    system: SystemTypes;
}

export const systemConstantsInitial: SystemConstants = {
    system: SystemTypes.None
}