import { HttpRequest } from 'actions/utils/HttpRequest';
import { AppConfig } from 'app-env';
import { Store } from 'store/GlobalState';


export const openBooks = async (stationID: string, projectID: string, classification: string, action: string) => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.putAsync("stationProjects/books/open", {stationID, projectID, classification, action});

    return response ? response.ok : false;
}

export const uploadImage = async (title: string, fileID: number, file: File, stationId: string, classification: string, projectId: string, action: string) => {
    const request = new HttpRequest(AppConfig.serverURL);

    const data = {
        files: {formFile: file},
        params: {
            stationId,
            classification,
            projectId,
            action,
            fileTitle: title,
            fileID: fileID.toString(10)
        }
    };

    let retry = true;
    let response: Response | undefined = undefined;
    
    while (retry) {
        response = await request.postFormDataAsync("StationProjects/books/image/v2", data);

        if (!response || !response.ok) {
            retry = window.confirm("送信に失敗しました。再送信を試みますか？");
        }
        else {
            break;
        }
    }
    
    
    return response ? response.ok : false;
}

export const getImageTitles = async (stationID: string, projectID: string, classification: string, action: string): Promise<string[] | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("stationProjects/books/image/titles", {stationID, projectID, classification, action});

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    return await response.json();
}

export const getOverview = async (stationID: string, projectID: string, classification: string, action: string): Promise<{title: string, imageExists: boolean}[] | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("stationProjects/books/overview", {stationID, projectID, classification, action});

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    return await response.json();
}

export const getImage = async (stationID: string, projectID: string, classification: string, action: string, fileID: number): Promise<File | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("stationProjects/books/image/binary", {stationID, projectID, classification, action, fileID}, 120000);

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;    // file not found
    }

    const blob = await response.blob();

    let filename = fileID.toString(10);

    const contentDisposition = response.headers.get("Content-Disposition");
    if (contentDisposition !== null) {
        const nameDefine = decodeURI(contentDisposition).match(/filename\*=.*''.+\..+/);
        if (nameDefine !== null) {
            const split = nameDefine[0].split("'");
            filename = split[2];
        }
    }


    const f: any = blob;
    f.lastModifiedDate = new Date();
    f.name = filename;

    return f;
}

export const getImageFileInfo = async (stationID: string, classification: string, fileID: number): Promise<{blobPath: string, fileName: string, uploadedDate: string} | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("stationProjects/books/image/info", {stationID, classification, fileID});

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    return await response.json();
}

export const deleteImage = async (stationID: string, projectCode: string, fileID: number) => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.deleteAsync("stationProjects/books/image", {stationID, projectCode, fileID});

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    return response.ok;
}


const reportDownloadTimeout = 1200000;
export const downloadReport = async (stationID: string, projectID: string, classification: string, action: string): Promise<{downloadUrl: string, filename: string} | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);

    const response = await request.getWithQueryAsync("stationProjects/books/report", {stationID, projectID, classification, action}, reportDownloadTimeout);

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }
    
    return await response.json();
}

export const getStatusInfo = async (stationID: string, classification: string, infoNumber: number): Promise<{date: (string | null), userName: (string | null)} | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("stationProjects/books/status", {
        stationID,
        classification,
        infoNumber
    });

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    const responseData = await response.json();
    return {date: responseData.date, userName: responseData.userName};
}

export const updateStatusInfo = async (stationID: string, projectID: string, classification: string, action: string, infoNumber: number): Promise<{date: (string | null), userName: (string | null)} | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("stationProjects/books/status", {
        stationID,
        projectID,
        classification,
        action,
        infoNumber
    });

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    const responseData = await response.json();
    return {date: responseData.date, userName: responseData.userName};
}

export const getComment = async (stationID: string, projectID: string, classification: string, action: string, commentNumber: number): Promise<string | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("stationProjects/books/comment", {stationID, projectID, classification, action, commentNumber});

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    const responseData = await response.json();
    return responseData.comment;
}

export const updateComment = async (stationID: string, projectID: string, classification: string, action: string, commentNumber: number, comment: string) => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("stationProjects/books/comment", {stationID, projectID, classification, action, commentNumber, comment});

    return response ? response.ok : false;
}