export class GlobalEvent {
    private eventList = new Map<string, Map<number, () => void>>([]);

    public register(event: string, id: number, listener: () => void) {
        const list = this.eventList.get(event);

        if (list) {
            list.set(id, listener);
        }
        else {
            this.eventList.set(event, new Map([[id, listener]]));
        }
    }

    public remove(event: string, id: number) {
        const list = this.eventList.get(event);
        if (list && list.has(id)) {
            list.delete(id);
        }
    }

    public fire(event: string) {
        const list = this.eventList.get(event);
        if (list) {
            Array.from(list.entries()).forEach((v, i) => v[1]());
        }
    }
}