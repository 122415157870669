import * as React from 'react';
import { TextField, Button } from '@material-ui/core';
import { LoadingButton } from './LoadingButton';

export interface ILoginFormProps {
    isLoading: boolean;
    id: string;
    password: string;
    userAccessKey: string;
    message?: string;
    onIDChanged: (id: string) => void;
    onPasswordChanged: (password: string) => void;
    onAccessKeyChanged: (accessKey: string) => void;
    onLoginClick: () => void;
}
export const LoginForm = (props: ILoginFormProps) => {
    const {isLoading, id, password, userAccessKey: accessKey, message, onIDChanged, onPasswordChanged, onAccessKeyChanged, onLoginClick} = props;
    console.log(message);

    const onChangeIDField = (event: React.ChangeEvent<HTMLInputElement>) => onIDChanged(event.currentTarget.value);
    const onChangePasswordField = (event: React.ChangeEvent<HTMLInputElement>) => onPasswordChanged(event.currentTarget.value);
    const onChangeAccessKeyField = (event: React.ChangeEvent<HTMLInputElement>) => onAccessKeyChanged(event.currentTarget.value);
    const onLoginButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => onLoginClick();

    const idForm = <TextField
        id="id-field"
        label="ID"
        onChange={onChangeIDField}
        required={true}
        type="username"
        variant="outlined"
        value={id}
    />;

    const passwordForm = <TextField
        id="password-field"
        label="パスワード"
        onChange={onChangePasswordField}
        required={true}
        type="password"
        autoComplete="current-password"
        variant="outlined"
        value={password}
    />;

    const accessKeyForm = <TextField
        id="accessKey-field"
        label="アクセスキー"
        onChange={onChangeAccessKeyField}
        required={true}
        variant="outlined"
        value={accessKey}
    />;

    let button: JSX.Element;
    if (isLoading) {
        button = <LoadingButton />;
    }
    else {
        button = (
            <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={onLoginButtonClick}
            >
                ログイン
            </Button>
        );
    }

    let msg: JSX.Element;
    if (message === undefined) {
        msg = <span></span>;
    }
    else {
        msg = <span className="h5 text-danger">{message}</span>;
    }

    return (
        <div className="text-center" style={{marginTop: "10vh"}}>
            <span className="h1">Wicky Next</span>
            <form>
                <div>
                    {idForm}
                </div>
                <br />
                <div>
                    {passwordForm}
                </div>
                <br />
                <div>
                    {accessKeyForm}
                </div>
                <br />
                <div>
                    {msg}
                </div>
                <div>
                    {button}
                </div>
            </form>
        </div>
    );
}