export const isCameraUseable = async (): Promise<boolean> => {
    let cameraUseable = false;

    const md = window.navigator.mediaDevices as (MediaDevices | undefined);
    if (md && md.enumerateDevices) {
        const devices = await md.enumerateDevices();
        cameraUseable = devices.some((d) => d.kind === "videoinput");
    }

    return cameraUseable;
}