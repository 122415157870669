import * as React from 'react';

export const RightArrowIcon: React.StatelessComponent<React.CSSProperties> = (props) => {
    return (
        <svg className="MuiSvgIcon-root"
            style={props}
            viewBox="0 0 200 50">
                <path id="rightArrow"
                    d="M 149.00,4.00
                    C 149.00,4.00 149.00,16.00 149.00,16.00
                        149.00,16.00 16.00,16.00 16.00,16.00
                        16.00,16.00 16.00,36.00 16.00,36.00
                        16.00,36.00 149.00,36.00 149.00,36.00
                        149.00,36.00 149.00,48.00 149.00,48.00
                        149.00,48.00 187.00,27.00 187.00,27.00
                        187.00,27.00 187.00,25.00 187.00,25.00
                        187.00,25.00 149.00,4.00 149.00,4.00 Z"
                />
        </svg>
    );
};