import * as React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';
import { BrowserRouter, Router } from 'react-router-dom';
import { CssBaseline, AppBar, Toolbar, IconButton, Typography, Drawer, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Grid, TextField } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, useTheme, Theme, createStyles, duration } from '@material-ui/core/styles';
import { Token } from './store/Token';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Persistor, Store, GlobalState, BrowserHistory } from './store/GlobalState';
import { reset, ActionType } from './actions/types/ActionType';
import { registerEvent, removeEvent } from 'actions/EventRegisterAction';
import { NextApp, NextRootPath } from 'pages/Next/NextApp';
import { NotFound } from 'pages/NotFound';
import { MartzMixApp, martzMixRootPath } from 'pages/MartzMix/MartzMixApp';
import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core'; //fontawesomeのコアファイル
import { fas } from '@fortawesome/free-solid-svg-icons'; //fontawesomeのsolidアイコンのインポート
import { routeInject, routePush } from 'actions/utils/History';
import { History } from 'history';
import { IHistoryWrapper } from 'actions/types/HistoryType';
import { SystemTypes } from 'store/SystemConstants';
import { roRootPath, ROApp } from 'pages/RO/ROApp';
import * as Login from './store/LoginPageState';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Constants } from 'app-env';
import { getGeneratedUserId, registerTemporaryUser } from 'actions/AuthorizationAction';
import { Spinner } from 'reactstrap';
import { managementRootPath, ManagementApp } from 'pages/Management/ManagementApp';

library.add(fas);

const drawerWidth = 240;

const appBarStyles = (isPersistentDrawerOpened: boolean) => makeStyles((theme: Theme) => 
    createStyles({
        appBar: {
            marginLeft: drawerWidth,
            [theme.breakpoints.up('md')]: {
                width: isPersistentDrawerOpened ? `calc(100% - ${drawerWidth}px)` : "100%"
            }
        }
    })
)();
const drawerStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawerRoot: {
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        },
        drawerRootMobile: {
            [theme.breakpoints.up("md")]: {
                display: "none"
            }
        },
        drawer: {
            height: "100vh",
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0
            }
        },
        drawerPaper: {
            width: drawerWidth
        }
    })
);
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        appBar: {
            marginLeft: drawerWidth,
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`
            }
        },
        logoutButton: {
            padding: 0,
            [theme.breakpoints.down('xs')]: {
                marginLeft: "auto"  // userInfo が非表示の場合
            }
        },
        userInfo: {
            marginLeft: "auto",
            width: "30%",
            [theme.breakpoints.down('xs')]: {
                display: "none"
            }
        },
        menuButtonMobile: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up("md")]: {
                display: "none"
            }
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            padding: theme.spacing(1),
            width: "100%",
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`
            }
        },
        page: {
            width: "100%"
        }
    })
);

export const App = connect(
    (state: GlobalState): IHistoryWrapper => (state.history)
)((props: IHistoryWrapper) => {
    React.useEffect(() => {
        
        Store.dispatch(routeInject(props.history));
        
        return () => {
            Token.instance.deleteToken();
            Persistor.purge();
            Store.dispatch({type: reset});
        };
    }, []);

    return (
        <Router history={props.history} children={(
            <Switch>
                <Route path={managementRootPath} component={ManagementApp} />
                <Route component={UserContents} />
            </Switch>
        )} />
    )
});

const UserContents = (props: IHistoryWrapper)=> {
    const classes = useStyles();
    const [temporaryDrawerOpen, setTemporaryDrawerOpen] = React.useState(false);
    const [persistentDrawerOpen, setPersistentDrawerOpen] = React.useState(true);

    const appBarClasses = appBarStyles(persistentDrawerOpen);
    
    React.useEffect(() => {
        const action = registerEvent("on station filtered", () => setPersistentDrawerOpen(false));
        Store.dispatch(action);
        return () => {
            Store.dispatch(removeEvent("on station filtered", action.id));
        };
    }, []);

    const handleTemporaryDrawerToggle = () => {
        setTemporaryDrawerOpen(!temporaryDrawerOpen);
    };
    const handlePersistentDrawerToggle = () => {
        setPersistentDrawerOpen(!persistentDrawerOpen);
    }

    const logout = () => {
        Token.instance.deleteToken();
        Persistor.purge();
        Store.dispatch({type: reset});
        window.location.href = '/login';
    };
    const logoutButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => logout();

    const onItemClick = (index: number) => {
        switch (index) {
            case 1:
                //window.location.href = '/management';
                Store.dispatch(routePush(managementRootPath))
                break;
            
            default:
                // do nothing
                break;
        }
    }

    const appBar = (
        <AppBar position="absolute" className={appBarClasses.appBar}>
            <Toolbar style={{paddingRight: "0.5rem", paddingLeft: "0.5rem"}}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleTemporaryDrawerToggle}
                    className={classes.menuButtonMobile}
                >
                    <MenuIcon />
                </IconButton>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handlePersistentDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <div>
                    <RedirectToSystemHomeButton />
                    <span style={{display: "block", fontSize: "0.3rem", marginLeft: "0.5rem"}}>{`© ${(new Date()).getFullYear()} KYOCERA Communication Systems Co., Ltd.`}</span>
                </div>
                <UserInfo className={classes.userInfo} />
                <AccountControlButton onItemClick={onItemClick}/>
                <Button className={classes.logoutButton} onClick={logoutButtonClick}>
                    <div>
                        <span className="d-block" style={{fontSize: "0.5em", color: "white"}}>LOGOUT</span>
                        <ExitToAppIcon style={{color: "white"}} />
                    </div>
                </Button>
            </Toolbar>
        </AppBar>
    );

    const nav = <GlobalMenuConnecter
        persistentDrawerOpen={persistentDrawerOpen}
        temporaryDrawerOpen={temporaryDrawerOpen}
        handlePersistentDrawerToggle={handlePersistentDrawerToggle}
        handleTemporaryDrawerToggle={handleTemporaryDrawerToggle}
    />;
    return (
        <div className={classes.root}>
            <CssBaseline />
            {appBar}
            {nav}
            <main className={classes.content}>
                <div className={classes.toolbar}></div>{/* ヘッダ分の高さ調整 */}
                <div className={classes.page}>
                    <Router history={props.history} children={(
                        <Switch>
                            <Route path={NextRootPath} component={NextApp} />
                            <Route path={martzMixRootPath} component={MartzMixApp} />
                            <Route path={roRootPath} component={ROApp} />
                            <Route exact component={NotFound} />
                        </Switch>
                    )} />
                </div>
            </main>
        </div>
    );
};

const systemRootMap = new Map<SystemTypes, string>([
    [SystemTypes.None, "/login"],
    [SystemTypes.Next, NextRootPath],
    [SystemTypes.Mix, martzMixRootPath],
    [SystemTypes.RO, roRootPath]
]);
interface IRedirectToSystemHomeButtonConnectionProps {
    system: SystemTypes;
}
const RedirectToSystemHomeButton = connect(
    (state: GlobalState) => ({
        system: state.systemConstants.system
    })
)((props: IRedirectToSystemHomeButtonConnectionProps) => {
    const systemType = props.system;

    const gotoHomeButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        window.location.href = systemRootMap.get(systemType)!;
    }

    return <Button onClick={gotoHomeButtonClick} style={{textTransform: "none"}}><Typography variant="h6" noWrap style={{color: "white"}}>{systemType}</Typography></Button>;
});

interface IUserInfoProps {
    className?: string;
    style?: React.CSSProperties;
}
interface IUserInfoConnectionProps extends IUserInfoProps {
    loginPage: Login.State
}
const UserInfo = connect(
    (state: GlobalState, ownProps: IUserInfoProps) => ({
        loginPage: state.loginPage,
        ...ownProps
    })
)(((): React.StatelessComponent<IUserInfoConnectionProps> => (props) => {
    const {loginPage, className, style} = props;
    const {userName, companyName, accessKey} = loginPage;

    return (
        <div className={className} style={style}>
            <span className="w-100 d-block text-white ml-auto text-nowrap overflow-hidden" style={{textOverflow: "ellipsis"}}>{`${userName} : ${accessKey}`}</span>
            <span className="w-100 d-block text-white ml-auto text-nowrap overflow-hidden" style={{textOverflow: "ellipsis"}}>{companyName}</span>
        </div>
    )
})())

interface IAccountControlButtonProps {
    onItemClick: (index: number) => void;
}
const AccountControlButton = (props: IAccountControlButtonProps) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen((prevOpen) => !prevOpen);
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    }
    const onUserRegistrationClick = (event: React.MouseEvent<EventTarget>) => {
        handleClose(event);
        props.onItemClick(1);
    }
    
    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'account-menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={onButtonClick}
            >
                <div>
                    <span className="d-block mx-auto" style={{fontSize: "0.5em", color: "white"}}>ACCOUNT</span>
                    <div className="d-flex align-items-center mx-auto">
                        <AccountBoxIcon style={{color: "white"}} />
                        <ArrowDropDownIcon style={{color: "white", fontSize: "1em"}} />
                    </div>
                </div>
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="account-menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {/* <MenuItem onClick={handleClose}>登録情報変更</MenuItem> */}
                                    <MenuItem onClick={onUserRegistrationClick}>ユーザー管理</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}


interface IGlobalMenuConnecterProps {
    persistentDrawerOpen: boolean;
    temporaryDrawerOpen: boolean;
    handleTemporaryDrawerToggle: () => void;
    handlePersistentDrawerToggle: () => void;
}
interface IGlobalMenuConnecterConnectionProps extends IGlobalMenuConnecterProps {
    menu: JSX.Element | null | undefined;
}

const GlobalMenuConnecter = connect(
    (state: GlobalState, ownProps: IGlobalMenuConnecterProps) => ({
        menu: state.globalMenu,
        ...ownProps
    })
)((props: IGlobalMenuConnecterConnectionProps) => {
    const {persistentDrawerOpen, temporaryDrawerOpen, handleTemporaryDrawerToggle, handlePersistentDrawerToggle, menu} = props;
    const classes = drawerStyles();
    const theme = useTheme();

    return (
        <nav className={classes.drawer} aria-label="global menu" style={{display: persistentDrawerOpen ? undefined : "none"}}>
            <div className={classes.drawerRootMobile}>
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={temporaryDrawerOpen}
                    onClose={handleTemporaryDrawerToggle}
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {menu}
                </Drawer>
            </div>
            <div className={classes.drawerRoot}>
                <Drawer
                    classes={{
                    paper: classes.drawerPaper,
                    }}
                    variant="persistent"
                    onClose={handlePersistentDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    open={persistentDrawerOpen}
                >
                    {menu}
                </Drawer>
            </div>
        </nav>
    );
});


interface IAppRouterProps {
    children: React.ReactNode;
}
interface IAppRouterConnectionProps extends IAppRouterProps {
    history: IHistoryWrapper;
}

const AppRouter = connect(
    (state: GlobalState, ownProps: IAppRouterProps) => ({
        history: state.history,
        ...ownProps
    })
)((props: IAppRouterConnectionProps) => (
    <Router history={props.history.history} children={props.children} />
))