import { BaseStationSammary, BaseStationInfo } from "types/StationTypes";
import * as Home from 'actions/types/HomeActionType';
import { HttpRequest } from "actions/utils/HttpRequest";
import { AppConfig } from 'app-env';

export const getSecureData = async (): Promise<Home.PreparateFinished> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getAsync("ClientSecureData");

    const typing = {type: Home.preparateFinished};

    if (!response) {
        alert("タイムアウトしました。5秒後に再読み込みを行います");
        await new Promise(resolve => setTimeout(resolve, 5000));
        window.location.reload();
        return typing;
    }
    if (!response.ok) {
        return typing;
    }

    console.log("success get secure data");

    const responseData = await response.json();

    return {
        ...typing,
        apiKey: responseData.googleMapsAPIKey
    };
};

export const SearchBaseStationsByLocation = async (location: google.maps.LatLngLiteral, radius: number) => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("BaseStation/location", {latitude: location.lat, longitude: location.lng, radius});

    const typing = {type: Home.loadBaseStation};

    if (!response || !response.ok) {
        return {
            ...typing,
            stations: []
        };
    }

    console.log(`success get ${radius}m around base station list`);

    const responseData = await response.json();

    return {
        ...typing,
        stations: responseData
    };
}

export const searchByFuzzyWord = async (word: string): Promise<Home.LoadBaseStation> => {
    console.log(`search by ${word}`);

    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("BaseStation/search/fuzzy", {word});

    const typing = {type: Home.loadBaseStation};

    if (!response || !response.ok) {
        return {
            ...typing,
            stations: []
        };
    }

    console.log(`success get stations searched by fuzzy word = ${word}`);

    const responseData = await response.json();
    console.log(`hit ${responseData.length} stations`);

    return {
        ...typing,
        stations: responseData
    };
}

export const changeSelectedStation = async (station: BaseStationSammary | null): Promise<Home.ChangeSelectedStation> => {
    const typing = {type: Home.changeSelectedStation};

    if (station === null) {
        return Promise.resolve({
            ...typing,
            station: null
        });
    }

    const plannedID = station.planned.id;

    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("BaseStation/plannedID", {plannedID});

    

    console.log(`success get station(${plannedID}) detail`);

    if (!response || !response.ok) {
        return {
            ...typing,
            station: null
        }
    }

    const responseData = await response.json();

    return {
        ...typing,
        station: responseData
    };
};

export const pickupProjects = async (station: BaseStationSammary): Promise<Home.PickupProject> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("StationProjects/pickup/plannedID", {id: station.planned.id});

    if (!response || !response.ok) {
        return {
            type: Home.pickupProjects,
            projects: []
        };
    }

    console.log(`success pickup ${station.planned.id}'s projects`);

    const responseData = await response.json();

    return {
        type: Home.pickupProjects,
        projects: responseData
    };
};

export const updateCurrentLocation = (location: google.maps.LatLngLiteral): Home.UpdateCurrentLocation => {
    return {
        type: Home.updateCurrentLocation,
        location
    };
};

export const getInvidualProjectNames = async (): Promise<{code: string, name: string}[] | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getAsync("stationProjects/invidual/names");

    if (!response || !response.ok) {
        return null;
    }

    return await response.json();
}

export const getInvidualTroubleShootingProjectNames = async (): Promise<{code: string, name: string}[] | null> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getAsync("StationProjects/invidual/troubleShooting/names");

    if (!response || !response.ok) {
        return null;
    }

    return await response.json();
}

export const loadFilteredBaseStation = async (code: string): Promise<Home.LoadBaseStation> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("BaseStation/search/location/projectFilter", {projectCode: code});

    const typing = {type: Home.loadBaseStation};

    if (!response || !response.ok) {
        return {
            ...typing,
            stations: []
        };
    }

    console.log("success get filtered base station list");

    const responseData = await response.json();

    return {
        ...typing,
        stations: responseData
    };
}

export const updateEntryAndExitHistory = async (stationID: string, step: number): Promise<string | null | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("BaseStation/update/entryAndExit", {stationID, step});

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    const responseData = await response.json();
    return responseData.date;
}


export interface ICompletedData {
    date: string;
    staff: string;
}

export interface IProjectData {
    stationID: string;
    stationName: string;
    started: ICompletedData | null;
    finished: ICompletedData | null;
    checked1: ICompletedData | null;
    checked2: ICompletedData | null;
    submited: ICompletedData | null;
    downloaded: ICompletedData | null;
}

export const getProjectStatusInfoList = async (classification: string): Promise<IProjectData[] | undefined> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.getWithQueryAsync("StationProjects/status/list", {classification});

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return [];
    }

    const responseData: IProjectData[] = await response.json();
    return responseData;
}

export const saveSearchedProjectCode = (projectCode: string | null): Home.SaveSearchedProjectCode => {
    return {
        type: Home.saveSearchedProjectCode,
        projectCode
    };
}

export interface IBooksStatus {
    started: boolean;
    finished: boolean;
    checked: boolean;
    submited: boolean;
    downloaded: boolean;
}

export interface IRowData {
    planned: BaseStationInfo;
    implemented: BaseStationInfo | null;
    booksStatus: IBooksStatus | null;
}

export const convertProjectStatusToRowData = (stations: BaseStationSammary[], projectDataList: IProjectData[]): IRowData[] => {
    const dataMap = new Map<string, IProjectData>(projectDataList.map((pd) => [pd.stationID, pd]));

    const result: IRowData[] = [];
    for (const st of stations) {
        const {planned, implemented} = st;
        const pd = dataMap.get(planned.id);

        const stationInfo = {planned, implemented};

        if (pd) {
            result.push({
                ...stationInfo,
                booksStatus: {
                    started: !!pd.started,
                    finished: !!pd.finished,
                    checked: !!pd.checked1,
                    submited: !!pd.submited,
                    downloaded: !!pd.downloaded
                }
            });
        }
        else {
            result.push({...stationInfo, booksStatus: null});
        }
    }

    return result;
}

export const exportProjectStatusList = async (rows: IRowData[], titles: string[], filetitle: string): Promise<{downloadUrl: string, filename: string} | null | undefined> => {
    const table: string[][] = [];

    table.push(titles);

    for (const row of rows) {
        const {planned, implemented, booksStatus} = row;


        let columns: string[] = [
            planned.id,
            planned.name
        ];

        if (implemented) {
            columns = [...columns, implemented.id, implemented.name];
        }
        else {
            columns = [...columns, "", ""]; // 以降がずれてしまうので空を入れる
        }

        if (!booksStatus) {
            columns = [...columns, "-", "-", "-", "-", "-"];
        }
        else {
            columns = [
                ...columns,
                booksStatus.started ? "済" : "未",
                booksStatus.finished ? "済" : "未",
                booksStatus.checked ? "済" : "未",
                booksStatus.submited ? "済" : "未",
                booksStatus.downloaded ? "済" : "未"
            ]
        }

        table.push(columns);
    }
    

    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("Export/excel", {title: filetitle, table});

    if (!response) {
        return undefined;
    }
    if (!response.ok) {
        return null;
    }

    const {downloadUrl, filename} = await response.json();
    return {downloadUrl, filename};
}