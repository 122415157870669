import * as HistoryType from 'actions/types/HistoryType';
import { History } from 'history';
import { ActionType } from 'actions/types/ActionType';


export const createHistoryReducer = (history: History<any>) => (prevState: HistoryType.IHistoryWrapper | undefined, action: ActionType): HistoryType.IHistoryWrapper => {
    if (prevState === undefined) {
        return {history};
    }

    let newState: HistoryType.IHistoryWrapper = {...prevState};

    switch (action.type) {
        case HistoryType.routePushAction:
            newState.history.push((action as HistoryType.IRoutePushAction).path);
            break;

        case HistoryType.routeGoBackAction:
            newState.history.goBack();
            break;

        case HistoryType.inject:
            newState.history = (action as HistoryType.IRouterInject).history;
        
        default:
            return prevState;
    }

    return newState;
};