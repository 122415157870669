import * as React from 'react';
import { Button } from '@material-ui/core';
import { Spinner } from 'reactstrap';

interface IDownloadButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    urlGetter: () => Promise<{downloadUrl: string, filename: string} | null | undefined>;
    onSuccess?: (response: {downloadUrl: string, filename: string}) => void;
    onFail?: () => void;
    variant?: "text" | "outlined" | "contained" | undefined;
    style?: React.CSSProperties;
    color?: "inherit" | "primary" | "secondary" | "default" | undefined;
    size?: "small" | "medium" | "large" | undefined;
    buttonRef?: React.RefObject<HTMLButtonElement> | undefined;
}
export const DownloadButton: React.FunctionComponent<IDownloadButtonProps> = (props) => {

    const {onClick, disabled, urlGetter: getter, onSuccess, onFail, style, variant, color, size, children, buttonRef} = props;

    // ダウンロードリンク
    const [downloadUrl, setDownloadUrl] = React.useState<string | null>(null);
    const [filename, setFilename] = React.useState("");
    const [isDownloadNow, setIsDownloadNow] = React.useState(false);
    const [downloadLinkRef, setDownloadLinkRef] = React.useState<HTMLAnchorElement | null>(null);

    React.useEffect(() => {
        if (!isDownloadNow || downloadUrl !== null) {
            return;
        }

        // ボタン押下後
        const asyncFunc = async () => {
            const response = await getter();
            if (!response) {
                setIsDownloadNow(false);
                if (onFail) {
                    onFail();
                }
                return;
            }

            const {downloadUrl, filename} = response;

            setDownloadUrl(downloadUrl);
            setFilename(filename);

            if (onSuccess) {
                onSuccess(response);
            }
        };
        asyncFunc();
    });
    React.useEffect(() => {
        if (!isDownloadNow || downloadLinkRef === null) {
            return;
        }

        if (downloadUrl === null) {
            return;
        }

        // ダウンロードリンクが構成された
        downloadLinkRef.click();

        // 初期状態に戻す
        setDownloadLinkRef(null);
        setDownloadUrl(null);
        setIsDownloadNow(false);
    });
    

    if (downloadUrl === null && isDownloadNow) {
        // ボタンクリック後レスポンスがくるまで
        return <Spinner style={style} />
    }

    const bindRef = (ref: HTMLAnchorElement) => setDownloadLinkRef(ref);
    const onDownloadClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIsDownloadNow(true);
        if (onClick) {
            onClick();
        }
    }

    const link = downloadUrl === null ? <React.Fragment></React.Fragment> : <a href={downloadUrl} download={filename} ref={bindRef} style={{display: "none"}} />;

    return (
        <div style={{marginLeft: "auto"}}>
            {link}
            {isDownloadNow ? <Spinner style={style} /> : <Button ref={buttonRef} variant={variant} size={size} color={color} onClick={onDownloadClick} style={style} disabled={disabled}>{children}</Button>}
        </div>
    )
}