import * as React from 'react';
import { Spinner } from 'reactstrap';

export interface IRedirectToHomeProps {
    message?: string;
}
export const RedirectToHome: React.StatelessComponent<IRedirectToHomeProps> = (props: IRedirectToHomeProps) => {
    const msg = props.message === undefined ? "" : props.message;
    React.useEffect(() => {
        const asyncFunc = async () => {
            window.location.href = '/';
        };

        asyncFunc();
    });
    return (
        <React.Fragment>
            <span className="h1 text-danger">{`${msg} トップページへ遷移します。`}</span>
            <Spinner />
        </React.Fragment>
    );
}