import { ActionType } from '../actions/types/ActionType';
import * as Types from '../actions/types/GoogleMapActionTypes';

export const GoogleMapReducer = (prevState: google.maps.Map | null | undefined, action: ActionType): (google.maps.Map | null) => {
    if (prevState === undefined) {
        return null;
    }

    if (action.type !== Types.bindGoogleMapInstance) {
        return prevState;   // do nothing
    }

    const mapAction = action as Types.BindGoogleMapInstance;
    if (prevState === mapAction.instance) {
        return prevState;
    }

    return mapAction.instance;
}