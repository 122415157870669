import { HttpRequest } from "./utils/HttpRequest"
import { AppConfig } from "app-env"

export const setNotificationConfig = async (userID: string, enable: boolean): Promise<boolean> => {
    const request = new HttpRequest(AppConfig.serverURL);
    const response = await request.postAsync("UserConfig/notification", {userID, enable});

    if (!response) {
        alert("サーバーの応答がありません");
        console.error("not responsed (/api/Authorize/register)");
        return false;
    }
    if (!response.ok) {
        const msg = `${await response.text()} code=${response.status}`;
        alert(msg);
        console.error(msg);
        return false;
    }

    return true;
}