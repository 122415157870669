import * as React from 'react';

export const RightNextPlusIcon: React.StatelessComponent<React.CSSProperties> = (props) => {
    return (
        <svg className="MuiSvgIcon-root"
            style={props}
            viewBox="0 0 50 50">
                <path id="rightNextPlus"
                    d="M 10.00,2.00
                    C 10.00,2.00 7.00,5.00 7.00,5.00
                      11.43,11.19 17.81,17.57 24.00,22.00
                      24.00,22.00 7.00,39.00 7.00,39.00
                      7.00,39.00 10.00,43.00 10.00,43.00
                      10.00,43.00 31.00,23.00 31.00,23.00
                      31.00,23.00 21.00,12.00 21.00,12.00
                      21.00,12.00 10.00,2.00 10.00,2.00 Z
                    M 28.00,34.00
                    C 28.00,34.00 28.00,40.00 28.00,40.00
                      28.00,40.00 22.00,40.00 22.00,40.00
                      22.00,40.00 22.00,43.00 22.00,43.00
                      22.00,43.00 28.00,43.00 28.00,43.00
                      28.00,43.00 28.00,49.00 28.00,49.00
                      28.00,49.00 31.00,49.00 31.00,49.00
                      31.00,49.00 31.00,43.00 31.00,43.00
                      31.00,43.00 37.00,43.00 37.00,43.00
                      37.00,43.00 37.00,40.00 37.00,40.00
                      37.00,40.00 31.00,40.00 31.00,40.00
                      31.00,40.00 31.00,34.00 31.00,34.00
                      31.00,34.00 28.00,34.00 28.00,34.00 Z"
                />
        </svg>
    );
};