import * as React from 'react';
import { Redirect } from 'react-router';
import { Store } from 'store/GlobalState';


export class Role {
    public static NextUser = "NextUser";
    public static MartzMixUser = "MartzMixUser";
}

export interface IRoleProps {
    accessableRoles: string[];
}

/**
 * RBACフィルタ
 * @param props アクセス可能なRoleのリスト
 */
export const RoleFilter: React.FunctionComponent<IRoleProps> = (props) => {
    const {roles: actorRoles} = Store.getState().loginPage;
    const {children, accessableRoles} = props;

    // children以外はほとんど変化しないはずなのでメモ化の依存関係を分割しておく
    const isEnabled = React.useMemo(() => {
        if (!actorRoles) {
            return false;
        }

        if (accessableRoles.length <= 0) {
            return false;
        }

        let isMatchRoleExists = false;
        for (let i = 0; i < accessableRoles.length && !isMatchRoleExists; i++) {
            for (let j = 0; j < actorRoles.length && !isMatchRoleExists; j++) {
                isMatchRoleExists = (accessableRoles[i] === actorRoles[j]);
            }
        }

        return isMatchRoleExists;
    }, [actorRoles, accessableRoles]);

    return React.useMemo(() => {
        if (!isEnabled) {
            return <React.Fragment></React.Fragment>;
        }

        return <React.Fragment>{children}</React.Fragment>;
    }, [children, isEnabled]);
}