import * as React from 'react';
import QRCode from 'react-qr-reader';
import { Modal, Paper, Tooltip, IconButton, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Spinner } from 'reactstrap';
import { isCameraUseable } from 'actions/utils/UserDevices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'styles/barcode.min.css';
import { toTimeString } from 'actions/utils/StringFormatter';

export interface IQrCodeReaderButtonProps {
    size?: "small" | "medium";
    className?: string;
    styles?: React.CSSProperties;
    onRead: (code: string) => void;
    onFinish?: () => void;
    onClick?: () => void;
}

export interface IMultiQrCodeReaderButtonProps {
    size?: "small" | "medium";
    className?: string;
    styles?: React.CSSProperties;
    onFinish: (codeList: string[]) => void;
    onClick?: () => void;
}

const isSingleProps = (arg: any): arg is IQrCodeReaderButtonProps => {
    return (typeof arg === "object") && (typeof arg.onRead === "function");
}

export const QrCodeReaderButton: React.FunctionComponent<IQrCodeReaderButtonProps | IMultiQrCodeReaderButtonProps> = (props) => {
    const {size, className, onClick, styles, ...others} = props;

    const [open, setOpen] = React.useState(false);

    let modal: JSX.Element;
    if (open) {
        if (isSingleProps(props)) {
            const {onFinish, onRead} = props;

            const onReadComplete = (code: string) => {
                if (code) {
                    onRead(code);
                }

                setOpen(false);

                if (onFinish) {
                    onFinish();
                }
            }

            const onClose = () => {
                setOpen(false);
                if (onFinish) {
                    onFinish();
                }
            }

            modal = <ScannerModalSingle onRead={onReadComplete} onClose={onClose} />;
        }
        else {
            const {onFinish} = props;

            const onClose = (codeList: string[]) => {
                onFinish(codeList);
                setOpen(false);
            }

            modal = <ScannerModalMultiple onClose={onClose} />;
        }
    }
    else {
        modal = <React.Fragment></React.Fragment>;
    }

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(true);

        if (onClick) {
            onClick();
        }
    }

    const [isCameraExists, setIsCameraExists] = React.useState(false);
    React.useEffect(() => {
        const asyncFunc = async () => setIsCameraExists(await isCameraUseable());
        asyncFunc();
    }, []);

    return (
        <div>
            <IconButton size={size} style={styles} onClick={onButtonClick} className={className} disabled={!isCameraExists}>
                <FontAwesomeIcon icon={["fas", "qrcode"]} style={{width: "2em", height: "1.5em"}} />
            </IconButton>
            {modal}
        </div>
    );
}

interface IScannerModalProps {
    onRead: (code: string) => void;
    onClose: () => void;
}
const ScannerModalSingle = (props: IScannerModalProps) => {
    const {onRead, onClose} = props;

    // material-uiのmodalがfocusをrestoreしてしまうので、むりやりフォーカスを外す
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        buttonRef.current?.focus();
        modalRef.current?.click();
    }, [buttonRef.current, modalRef.current]);

    const focusRestore = (event: React.FocusEvent<HTMLButtonElement>) => {
        buttonRef.current?.focus();
    }

    const [isStarted, setIsStarted] = React.useState(false);
    const onStarted = () => setIsStarted(true);

    const onBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClose();
    }

    const onScan = (code: string | null) => {
        if (code) {
            onRead(code);
        }
    }

    const onError = (err: any) => {
        alert("読込失敗");
    }

    return (
        <Modal ref={modalRef} open={true} style={{width: "100vw", height: "100vh"}} disableRestoreFocus={true}>
            <Paper className="w-100 h-100">
                <Tooltip title="キャンセル" placement="bottom">
                    <IconButton
                        color="inherit"
                        onClick={onBackButtonClick}
                        ref={buttonRef}
                        onBlur={focusRestore}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                </Tooltip>
                <div className="ml-auto mr-auto">
                    {isStarted ? null : <Spinner />}
                    <div id="interactive" style={{visibility: isStarted ? undefined : "hidden"}}>
                        <QRCode
                            onError={onError}
                            onScan={onScan}
                            onLoad={onStarted}
                            delay={2000}
                            style={{width: "100%"}}
                            resolution={1000}
                        />
                    </div>
                </div>
            </Paper>
        </Modal>
    );
}

interface IScannerModalMultipleProps {
    onClose: (codeList: string[]) => void;
}
const ScannerModalMultiple = (props: IScannerModalMultipleProps) => {
    const {onClose} = props;

    const [readHistory, setReadHistory] = React.useState<{time: Date, code: string}[]>([]);

    const onBackButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClose(readHistory.map(h => h.code).reverse());    // 読み取った順で渡す
    }

    const [isStarted, setIsStarted] = React.useState(false);
    const onStarted = () => setIsStarted(true);

    // material-uiのmodalがfocusをrestoreしてしまうので、むりやりフォーカスを外す
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        buttonRef.current?.focus();
        modalRef.current?.click();
    }, [buttonRef.current, modalRef.current]);

    const focusRestore = (event: React.FocusEvent<HTMLButtonElement>) => {
        buttonRef.current?.focus();
    }

    const onScan = (code: string | null) => {
        if (!code) {
            return;
        }

        console.log(`QRCode detected and processed : [${code}]`);

        if (readHistory.some(before => before.code === code)) {
            console.log(`same code ${code} read before`);
            return;
        }

        readHistory.unshift({time: new Date(), code: code});
        setReadHistory([...readHistory]);
    }

    const onError = (err: any) => {
        alert("読込失敗");
    }

    return (
        <Modal ref={modalRef} open={true} style={{width: "100vw", height: "100vh"}} disableRestoreFocus={true}>
            <Paper className="w-100 h-100 overflow-auto">
                <Tooltip title="終了" placement="bottom">
                    <IconButton
                        color="inherit"
                        onClick={onBackButtonClick}
                        ref={buttonRef}
                        onBlur={focusRestore}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                </Tooltip>
                <div className="ml-auto mr-auto">
                    {isStarted ? null : <Spinner />}
                    <div id="interactive" style={{visibility: isStarted ? undefined : "hidden"}}>
                        <QRCode
                            onError={onError}
                            onScan={onScan}
                            onLoad={onStarted}
                            delay={2000}
                            style={{width: "100%"}}
                            resolution={1000}
                        />
                    </div>
                </div>
                <div className="w-75 h-25 overflow-auto border border-primary mx-auto">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>時刻</TableCell>
                                <TableCell>読み取り値</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {readHistory.map((h, idx) => (
                                <TableRow key={`history ${idx}th row`}>
                                    <TableCell>{toTimeString(h.time)}</TableCell>
                                    <TableCell>{h.code}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </Modal>
    );
}